<div class="job-stats-container">
  <div class="actions-wrapper">
    <fancy-table #jobStatsTable *ngIf="!loading"
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(availableFilters)]="availableFilters"
                 [(filters)]="appliedFilters"
                 [config]="tableConfig">

      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value" *ngSwitchCase="'requested-trucks'">
            <div class="data">{{ (row.numTrucks | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'confirmed-trucks'">
            <div class="data">{{ (row.confirmedTrucks | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'dispatched-trucks'">
            <div class="data">{{ (row.dispatchedDriversCount | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'truck-types'">
            <div class="data" *ngIf="row.truckTypes" [ngPlural]="row.truckTypes.length">
              <ng-template ngPluralCase="=0">0 Types</ng-template>
              <ng-template ngPluralCase="=1">{{ row.truckTypes.length }} Type</ng-template>
              <ng-template ngPluralCase="other">{{ row.truckTypes.length }} Types</ng-template>
            </div>
            <div class="data" *ngIf="!row.truckTypes">-</div>
          </div>
          <div class="value" *ngSwitchCase="'drivers-on-shift'">
            <div class="data">{{ (row.driversOnShift | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'ordered-loads'">
            <div class="data">{{ (row.totalOrdered | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'delivered-loads'">
            <div class="data">{{ (row.totalLoads | number) || '-' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'avg-trip-time'">
            <div class="data">{{ row.avgTripTime || '0' }}</div>
          </div>

          <div class="value" *ngSwitchDefault>{{ value || '-' }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="title" translate>Day Summary</div>
      </div>
    </fancy-table>
  </div>
</div>
