import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { find as _find } from 'lodash';
import { NgForm } from '@angular/forms';
import { each } from 'lodash';
import * as moment from 'moment-timezone';

import { TruckTypeService } from '../trucks/truck-type.service';
import { JobEventService } from '../job-events/job-event.service';
import { AuthenticationService, DropdownComponent } from '../shared';
import { User } from '../users/user';

@Component({
  selector: 'edit-job-event-dialog',
  templateUrl: './edit-job-event-dialog.component.html',
  styleUrls: ['./edit-job-event-dialog.component.scss']
})

export class EditJobEventDialogComponent implements OnInit {
  loading = false;
  @Input() jobEvent: any = {};
  anyTruckType = true;
  user: User;
  errors = [];
  callback: Function;
  truckTypes = [];
  allSelectedTrucks = [];
  selectedTrucks = [];
  anyTypeSelected = false;
  truckTypeDropdownConfig = {
    searchable: true,
    showLoading: true,
    multiselect: true,
    nameProperty: 'name',
    optionIcon: true,
    iconProperty: 'icon',
    sortBy: '-is_favorite',
    selectText: 'Select Truck Type',
    loadingText: 'Loading Truck Types...',
    noResultsText: 'No Truck Types',
    service: TruckTypeService,
    serviceFunction: 'list',
    query: {}
  };
  dailyDeliveryTargetTypeOptions = [
    { id: 'tons', name: 'Tons' },
    { id: 'metric-tons', name: 'Metric Tons' },
    { id: 'tonnes', name: 'Tonnes' },
    { id: 'loads', name: 'Loads' },
    { id: 'lbs', name: 'Pounds' },
    { id: 'kgs', name: 'Kilograms' },
    { id: 'cuyds', name: 'Cubic Yards' },
    { id: 'bushels', name: 'Bushels' },
    { id: 'bags', name: 'Bags' }
  ];
  selectedDailyDeliveryTargetType;
  shiftOneStartTime: string;
  shiftOneEndTime: string;
  shiftTwoStartTime: string;
  shiftTwoEndTime: string;

  @ViewChild('truckTypesDropdown', { static: false }) truckTypesDropdown: DropdownComponent;

  constructor(
    public dialogRef: MatDialogRef<EditJobEventDialogComponent>,
    private jobEventService: JobEventService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.user = this.authenticationService.user();
    if (!this.jobEvent.defaultYardBufferTime || !this.jobEvent.defaultYardBufferMinutes) {
      this.loadYardTimeDefaults();
    }
    this.anyTruckType = this.jobEvent.allowAnyTruckType;
    if (!this.anyTruckType) {
      each(this.jobEvent.truckTypes, (truck) => {
        this.selectedTrucks.push(truck);
      });
    }
    this.selectedDailyDeliveryTargetType = _find(this.dailyDeliveryTargetTypeOptions, { id: this.jobEvent.dailyDeliveryTargetType });
    // dev note: refactor this method so its only called one time
    this.checkIfOvernight('shift1');
    this.checkIfOvernight('shift2');
  }

  anyTypeTruckSelected(filterType: string, event, form: NgForm) {
    form.controls[filterType].markAsDirty();
    this.anyTypeSelected = event.target.checked;
    this.allSelectedTrucks = [];
    if (this.anyTypeSelected) {
      this.anyTruckType = true;
      this.truckTypes.forEach(truck => {
        this.allSelectedTrucks.push(truck.id);
      });
    } else {
      this.anyTruckType = false;
    }
  }

  submit(): void {
    this.loading = true;
    this.jobEvent.allowAnyTruckType = this.anyTruckType;
    if (this.anyTruckType && this.allSelectedTrucks.length > 0) {
      this.jobEvent.truckTypes = this.allSelectedTrucks;
    } else if (this.selectedTrucks.length > 0) {
      this.jobEvent.truckTypes = this.selectedTrucks.map(truckType => truckType.id);
    }

    // removing ownerOrganization property
    const { ownerOrganization, ...jobEvent } = this.jobEvent;
    this.jobEventService.save(jobEvent).subscribe((_jobEvent) => {
      this.loading = false;
      this.callback({ jobEvent: _jobEvent });
      this.dialogRef.close();
    }, (err) => {
      this.errors = err;
      this.loading = false;
    });
  }

  checkIfOvernight(shift: string): void {
    if (shift === 'shift1' && this.jobEvent.shift1StartTimestamp && this.jobEvent.shift1EndTimestamp) {
      const startTime = Number(moment(this.jobEvent.shift1StartTimestamp).format('YYYYMMDD'));
      const endTime = Number(moment(this.jobEvent.shift1EndTimestamp).format('YYYYMMDD'));
      this.jobEvent.shift1Overnight = (endTime - startTime) > 0;
      if (!this.shiftOneStartTime) {
        this.shiftOneStartTime = this.formatTimeFromTimestamp('shift1StartTimestamp');
      }
      if (!this.shiftOneEndTime) {
        this.shiftOneEndTime = this.formatTimeFromTimestamp('shift1EndTimestamp');
      }
    } else if (shift === 'shift2' && this.jobEvent.shift2StartTimestamp && this.jobEvent.shift2EndTimestamp) {
      const startTime = Number(moment(this.jobEvent.shift2StartTimestamp).format('YYYYMMDD'));
      const endTime = Number(moment(this.jobEvent.shift2EndTimestamp).format('YYYYMMDD'));
      this.jobEvent.shift2Overnight = (endTime - startTime) > 0;
      if (!this.shiftTwoStartTime) {
        this.shiftTwoStartTime = this.formatTimeFromTimestamp('shift2StartTimestamp');
      }
      if (!this.shiftTwoEndTime) {
        this.shiftTwoEndTime = this.formatTimeFromTimestamp('shift2EndTimestamp');
      }
    }
  }

  timeChange(prop: string, e, form: NgForm): void {
    this.jobEvent[prop] = e.target.value;
    form.controls[prop].markAsDirty();
    this.checkIfOvernight('shift1');
    this.checkIfOvernight('shift2');
  }

  formatTimeFromTimestamp = (timestampType: string) => moment(this.jobEvent[timestampType]).format('HH:mm');


  /**
   * @param  {string} prop
   * @param  {} e
   * @param  {NgForm} form
   * @returns void
   * This will be called onChange of daily delivery target value
   * And also will mark the form as dirty to enable save button.
   */
  dailyDeliveryTargetChange(prop: string, e: Event, form: NgForm): void {
    this.jobEvent[prop] = e.target && e.target['value'] || 0;
    form.controls[prop].markAsDirty();
  }

  dailyDeliveryTargetTypeChange(prop: string, option: any, form: NgForm): void {
    this.jobEvent[prop] = option.id;
    form.controls[prop].markAsDirty();
  }

  onSelect(filterType: string, options, form: NgForm): void {
    form.controls[filterType].markAsDirty();
    if (options) {
      this.selectedTrucks = options;
    }
  }

  addShift(): void {
    this.jobEvent.shift2StartTimestamp = this.jobEvent.shift2StartTimestamp || this.jobEvent.shift1StartTimestamp;
    this.jobEvent.shift2EndTimestamp = (new Date()).toISOString();
    this.checkIfOvernight('shift2');
  }

  loadYardTimeDefaults(): void {
    if (this.jobEvent && this.jobEvent.job.defaultYardBufferTime) {
      this.jobEvent.defaultYardBufferTime = this.jobEvent.job.defaultYardBufferTime;
      this.jobEvent.defaultYardBufferMinutes = this.jobEvent.job.defaultYardBufferMinutes;
    } else if (this.user && this.user.organization) {
      this.jobEvent.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
      this.jobEvent.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
    }
  }
}
