import { Observable, throwError as observableThrowError, timer as observableTimer, combineLatest, Subject } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { Response } from '@angular/http';
import { each, clone, filter as _filter, sortBy } from 'lodash';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Serializer } from './serializer';
import { Resource } from './resource';
import { FieldOption } from '../shared/export-dialog/export-dialog.component';

export class ResourceService<T extends Resource> {
  baseUrl = environment.serverUrl;
  public nextUri;
  public previousUri;
  public count;
  public listAllProgress = new Subject<number>();
  public unreadCount;
  public allSelected = false;
  public slug;
  public metaData;
  mockEndpoint = false;
  mockSearchKeys = ['name'];
  resourceUrl = '';

  constructor(
    protected http: HttpClient,
    protected endpoint: string,
    protected serializer: Serializer
  ) {
    this.resourceUrl = this.baseUrl + this.endpoint;
    if (this.endpoint.includes('LOCAL:')) {
      let parts = this.endpoint.split('LOCAL:');
      if (parts) { this.resourceUrl = parts[parts.length - 1]; }
    }
    this.listAllProgress.next(0);
  }

  list(query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    if (this.slug) {
      this.resourceUrl = this.baseUrl + this.slug;
    } else if (this.endpoint.includes('LOCAL:')) {
      // Do not manipulate the URL
    } else {
      this.resourceUrl = this.baseUrl + this.endpoint;
    }

    return this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(data => this.filterLocally(data, params)),
      map(data => this.paginateLocally(data, params)),
      map(data => this.convertData(data)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  listNext(nextUri = null, query?: any): Observable<T[]> {
    if (!nextUri) { nextUri = this.nextUri; }
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    if (this.nextUri) {
      return this.http.get(nextUri, {
        headers: this.requestHeaders(),
        params: params
      }).pipe(
        map(res => this.captureMetaData(res)),
        map(data => this.convertData(data)),
        catchError((res: Response) => this.handleError(res))
      );
    } else {
      return null;
    }
  }

  listUpdate(timer = 30000, query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    if (this.slug) { this.resourceUrl = this.baseUrl + this.slug; }

    return observableTimer(0, timer).pipe(
      switchMap(() => this.http.get(this.resourceUrl, {
          headers: this.requestHeaders(),
          params: params
        }).pipe(
          map(res => this.captureMetaData(res)),
          map(data => this.filterLocally(data, params)),
          map(data => this.paginateLocally(data, params)),
          map(data => this.convertData(data)),
          catchError((res: Response) => this.handleError(res))
        )
      )
    );
  }

  listAll(pageSize = 50, query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    params = params.set('page_size', '1');

    if (this.slug) { this.resourceUrl = this.baseUrl + this.slug; }

    let requestCount = 0;
    this.listAllProgress.next(0);

    return this.http.get(this.resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => (this.captureMetaData(res))),
      mergeMap(() => {
        params = params.set('page_size', pageSize.toString());
        let nextReqs: Observable<T[]>[] = [];
        if (this.count < pageSize) {
          nextReqs.push(
            this.http.get(this.resourceUrl, {
              headers: this.requestHeaders(),
              params: params
            }).map(res => {
              this.listAllProgress.next(1);
              return this.captureMetaData(res);
            })
          );
        } else {
          this.listAllProgress.next(1 / Math.ceil(this.count / pageSize));
          for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
            params = params.set('page', i.toString());
            nextReqs.push(
              this.http.get(this.resourceUrl, {
                headers: this.requestHeaders(),
                params: params
              }).pipe(
                map(res => {
                  requestCount++;
                  this.listAllProgress.next(requestCount / Math.ceil(this.count / pageSize));
                  return res;
                }),
                map(res => this.captureMetaData(res))
              )
            );
          }
        }
        return combineLatest(nextReqs);
      }),
      map(data => {
        let mergedList: T[] = [];
        data.forEach(list => {
          mergedList = mergedList.concat(list);
        });
        return this.convertData(mergedList);
      }),
      catchError((res: Response) => this.handleError(res))
    );
  }

  listAllUpdate(pageSize = 50, timer = 30000, query?: any): Observable<T[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    params = params.set('page_size', '1');

    if (this.slug) { this.resourceUrl = this.baseUrl + this.slug; }

    return observableTimer(0, timer).pipe(
      switchMap(() => this.http.get(this.resourceUrl, {
          headers: this.requestHeaders(),
          params: params
        }).pipe(
          map(res => (this.captureMetaData(res))),
          mergeMap(() => {
            params = params.set('page_size', pageSize.toString());
            let nextReqs: Observable<T[]>[] = [];
            if (this.count < pageSize) {
              nextReqs.push(
                this.http.get(this.resourceUrl, {
                  headers: this.requestHeaders(),
                  params: params
                }).map(res => {
                  return this.captureMetaData(res);
                })
              );
            } else {
              for (let i = 1; i <= Math.ceil(this.count / pageSize); i++) {
                params = params.set('page', i.toString());
                nextReqs.push(
                  this.http.get(this.resourceUrl, {
                    headers: this.requestHeaders(),
                    params: params
                  }).pipe(
                    map(res => {
                      return res;
                    }),
                    map(res => this.captureMetaData(res))
                  )
                );
              }
            }
            return combineLatest(nextReqs);
          }),
          map(data => {
            let mergedList: T[] = [];
            data.forEach(list => {
              mergedList = mergedList.concat(list);
            });
            return this.convertData(mergedList);
          }),
          catchError((res: Response) => this.handleError(res))
        )
      )
    );
  }

  get(id: string, query?: any, useId = true): Observable<T> {
    if (this.slug) { this.resourceUrl = this.baseUrl + this.slug; }
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }

    const resourceUrl = useId ? `${this.resourceUrl}${id}/` : `${this.resourceUrl}`;

    return this.http.get(resourceUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => this.captureMetaData(res, false)),
      map(data => this.convertRecord(data)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  getUpdate(timer = 30000, id: string): Observable<T> {
    if (this.slug) { this.resourceUrl = this.baseUrl + this.slug; }
    const resourceUrl = `${this.resourceUrl}${id}/`;

    return observableTimer(0, timer).pipe(
      switchMap(() => this.http.get(resourceUrl, {
          headers: this.requestHeaders()
        }).pipe(
          map(res => this.captureMetaData(res, false)),
          map(data => this.convertRecord(data)),
          catchError((res: Response) => this.handleError(res))
        )
      )
    );
  }

  save(model, query?: any, raw = false): Observable<T> {
    const resourceUrl = this.resourceUrl;
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });

      if (query.filters) {
        let joinedFilters = this.mergeFilters(query.filters);
        params = params.set('filters', joinedFilters);
      }
    }
    model = clone(model);
    if (!raw) { model = <T>this.serializer.toJson(model); }

    if (!model.id) {
      delete model.id;
      return this.http.post(resourceUrl, model, {
        headers: this.requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertRecord(res))
      );
    } else {
      return this.http.put(`${resourceUrl}${model.id}/`, model, {
        headers: this.requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertRecord(res))
      );
    }
  }

  remove(model) {
    const resourceUrl = this.resourceUrl;
    const id = typeof model === 'string' ? model : model.id;
    return this.http.delete(`${resourceUrl}${id}/`, {
      headers: this.requestHeaders()
    });
  }

  listFilters(slug: string, query?: any): Observable<T[]> {
    const filtersUrl = `${this.resourceUrl}${slug}/`;

    let params: HttpParams = new HttpParams();
    if (query && query['page_size']) {
      params = params.set('page_size', query['page_size']);
    } else {
      params = params.set('page_size', '6');
    }
    if (query && query['search']) {
      params = params.set('search', query['search']);
    }
    if (query && query['filter_search']) {
      params = params.set('filter_search', query['filter_search']);
    }

    return this.http.get(filtersUrl, {
      headers: this.requestHeaders(),
      params: params
    }).pipe(
      map(res => this.captureMetaData(res)),
      map(res => this.convertData(res)),
      catchError((res: Response) => this.handleError(res))
    );
  }

  getExportFields(customUrl = null) {
    let exportFieldUrl = `${this.resourceUrl}` + 'pandas-export/';
    if (customUrl && customUrl.length) {
      exportFieldUrl = `${this.baseUrl}${customUrl}`;
    }
    return this.http.get(exportFieldUrl, { headers: this.requestHeaders() }).pipe(
      map(res => sortBy(<FieldOption[]>res, 'label')),
      catchError(this.handleError)
    );
  }

  export(body, query: any = null, endpointUrl = 'export/', customUrl = null): Observable<any> {
    const customExportSupported = ['punchcards', 'trips', 'cybertrips', 'shifts'].some(endpoint => this.resourceUrl.includes(endpoint));
    endpointUrl = (customExportSupported ? 'pandas-export/' : endpointUrl);
    let exportUrl = `${this.resourceUrl}${endpointUrl}`;
    if (customUrl && customUrl.length) {
      exportUrl = `${this.baseUrl}${customUrl}`;
    }
    let params: HttpParams = new HttpParams();

    if (body.endpoint && body.endpoint.includes('operators')) {
      exportUrl = this.resourceUrl;
      exportUrl = exportUrl.replace('assignments/all/', 'assignments/operators/');
      const excludedParams = ['include', 'exclude', 'endpoint'];
      const allParams = { ...body, ...query };
      Object.keys(allParams).forEach(key => {
        if (!excludedParams.includes(key) && allParams[key]) {
          params = params.set(key, allParams[key].toString());
        }
      });
    } else if (query) {
      if (typeof query === 'string') {
        exportUrl = exportUrl + '?' + query;
      } else {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
    }
    if (exportUrl.includes('all/')) { exportUrl = exportUrl.replace('all/', ''); }

    return this.http.post(exportUrl, body, {
      responseType: 'text',
      headers: this.requestHeaders(),
      params: params
    });
  }

  downloadCSV(filename: string, data: any[]) {
    let csvBase: any[] = ['"' + Object.keys(data[0]).join('","') + '"'];
    data.forEach(o => (csvBase.push('"' + Object.keys(o).map(v => (o[v])).join('","') + '"')));
    const csv = csvBase.join('\n').replace(/(^\[)|(\]$)/mg, '');

    let link = document.createElement('a');
    const encodedUri = encodeURIComponent(csv);
    document.body.appendChild(link);
    link.href = 'data:text/csv;charset=UTF-8,' + encodedUri;
    link.download = filename + '.csv';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(encodedUri);
      document.body.removeChild(link);
    }, 0);

    return csv;
  }

  public captureMetaData(res: any, paginated = true): any {
    let json = res;
    if (paginated) {
      this.nextUri = json['next'];
      this.previousUri = json['previous'];
    }
    this.count = json['count'] || json['results'] && json['results'].length;
    this.unreadCount = json['unread_count'] || 0;
    this.mockEndpoint = json['mock'];
    this.mockSearchKeys = json['mockSearchKeys'];
    this.metaData = json['meta'];
    return json.results || json;
  }

  public convertData(data: any): T[] {
    return data && data.map(item => this.serializer.fromJson(item));
  }

  public convertRecord(data: any): T {
    return <T>this.serializer.fromJson(data);
  }

  public filterLocally(data: any, params: any): T[] {
    const ignoredKeys = ['ordering', 'filters', 'page', 'page_size'];
    if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
      params.forEach((values, key) => {
        if (values && values.length && !ignoredKeys.includes(key)) {
          data = _filter(data, (o) => {
            if (o.hasOwnProperty(key)) {
              let value = values.join(',');
              let property = o[key];
              if (property && value) {
                return property.toLowerCase() === value.toLowerCase();
              } else {
                return false;
              }
            } else if (key === 'search') {
              let filter = false;
              for (const mockKey of this.mockSearchKeys) {
                if (o.hasOwnProperty(mockKey)) {
                  let value = values.join(',');
                  let property = o[mockKey];
                  if (property && value) {
                    filter = property.toLowerCase() === value.toLowerCase();
                  }
                  if (filter) { break; }
                }
              }
              return filter;
            } else {
              return true;
            }
          });
        } else if (values && values.length && key === 'filters') {
          let _values = clone(values);
          _values.filter(Boolean).forEach(value => {
            value = value.replace(/\(|\)/g, '');
            let [_key, _value] = value.split('=');
            data = _filter(data, (o) => {
              if (o.hasOwnProperty(_key)) {
                let property = o[_key];
                if (property && _value) {
                  return property.toLowerCase() === _value.toLowerCase();
                } else {
                  return false;
                }
              }
            });
          });
        }
      });
      this.count = data.length;
    }

    return data;
  }

  public paginateLocally(data: any, params: any): T[] {
    if (this.endpoint.includes('LOCAL:') || this.mockEndpoint) {
      this.count = clone(data.length);
      let _params = {};
      params.forEach((values, key) => {
        if (values && values.length) {
          _params[key] = values.join(',');
        }
      });
      if ((_params['page'] && _params['page'] !== '1') || !_params['page']) {
        _params['recordsToSkip'] = (_params['page'] - 1) * _params['page_size'];
      } else {
        _params['recordsToSkip'] = 0;
      }
      data = data.slice(
        _params['recordsToSkip'],
        _params['recordsToSkip'] + _params['page_size']
      );
    }

    return data;
  }

  public mergeFilters(filters): string {
    let filterString = filters.map(filter => {
      if (filter.multiple && filter.values) {
        return filter.values.map(value => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.customField && filter.values) {
        let values = filter.values;
        if (values === true) {
          values = 'None';
          filter.displayValues = 'None';
        } else {
          filter.displayValues = values;
        }
        const _value = [filter.key, values].join(',');
        return `custom_field=${_value}`;
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) { values = 'True'; }
        if (values === false) { values = 'False'; }
        const _value = [filter.key, values].join('=');
        return `${_value}`;
      }
    }).filter(Boolean).join('&');

    return '(' + filterString + ')';
  }

  requestHeaders(xhr: XMLHttpRequest = null): HttpHeaders {
    let tokenString;
    let headerObject = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      const token = currentUser && currentUser.token;
      tokenString = 'Token ' + token;
    }
    if (tokenString) { headerObject['Authorization'] = tokenString; }
    if (xhr && tokenString) { xhr.setRequestHeader('Authorization', tokenString); }

    return new HttpHeaders(headerObject);
  }

  public handleError(error: Response | any) {
    return observableThrowError(this.parseErrors(error));
  }

  private parseErrors(err) {
    let errors = [];
    if (err.status >= 500) {
      errors.push(err.statusText);
    } else if (typeof err._body === 'string') {
      try {
        const body = JSON.parse(err._body);
        if (body.detail) {
          errors.push(body.detail);
        } else {
          errors = this.rescurseErrorObject(body, errors);
        }
      } catch (e) { }
    } else {
      errors.push(err);
    }
    return errors;
  }

  private rescurseErrorObject(obj, errors) {
    each(obj, (msg, key) => {
      if (Array.isArray(msg)) {
        errors = errors.concat(msg.map(err => (key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + err));
      } else if (typeof msg === 'string') {
        errors.push((key === 'non_field_errors' ? '' : key.replace(/_/g, ' ') + ': ') + msg);
      } else if (typeof msg === 'object') {
        errors = this.rescurseErrorObject(msg, errors);
      }
    });
    return errors;
  }
}
