import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

import { OrderService } from '../order.service';
import { Location } from '../../locations/location';
import { CondensedTrip } from '../../trips/condensed-trip';
import { LocationUpdate } from '../../jobs/locationUpdate';
import { DriverSerializer } from '../../drivers/driver.serializer';
import { JobEvent } from '../../job-events/job-event';
import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { TicketDetailDialogComponent } from '../ticket-detail-dialog/ticket-detail-dialog.component';
import { JobEventStat } from '../../job-event-stats/job-event-stat';
import { AuthenticationService } from '../../shared/authentication.service';
import { JobEventService } from '../../job-events/job-event.service';
import { Device } from '../../shared/device';
import { parseErrors } from '../../shared';

type TruckDetails = {
  name: string,
  weight: number,
  eta: number,
  ticket: CondensedTrip
};

@Component({
  selector: 'order-summary-status',
  templateUrl: './order-summary-status.component.html',
  styleUrls: ['../order.scss']
})
export class OrderSummaryStatusComponent implements OnInit, OnDestroy {
  device: Device = new Device();
  loading = false;
  errors = [];

  @Input() jobEvent: JobEvent;
  jobEventStats: JobEventStat;
  jobEventStatsReq: Subscription;
  trips: CondensedTrip[];
  latestLocations: {
    locationUpdates: any[],
    locations: any[],
    routes: string[]
  };
  orderStats = {
    weight: { delivered: 0, remaining: 0, target: 0 },
    loads: { delivered: 0, remaining: 0, target: 0 }
  };
  completedPercentage = 0;
  nextTruck: TruckDetails;
  followingTruck: TruckDetails;
  tripsEnroute: CondensedTrip[] = [];
  tripsOnSite: CondensedTrip[] = [];
  lastUpdated: string;
  activeStats = 'weight';
  jobEventStatuses = [
    {
      action: () => this.changeStatus('cancel'),
      name: 'Cancel Job'
    }
  ];
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService,
    public orderService: OrderService,
    public jobEventService: JobEventService,
    public jobEventStatService: JobEventStatService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.allSubscriptionsToUnsubscribe.push(
      observableCombineLatest(
        this.route.params, this.route.queryParams,
        (params, qparams) => ({ params, qparams })
      ).subscribe(result => {
        if (result.params['jobEventId']) { this.orderService.setJobEventId(result.params['jobEventId']); }
      })
    );
    this.orderService.orderData.subscribe(orderData => {
      this.setupOrderData(orderData);
    });
  }

  ngOnDestroy() {
    if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
      this.jobEventStatsReq.unsubscribe();
    }
    if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
      this.jobEventStatsReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setupOrderData(orderData: [CondensedTrip[], any]) {
    this.trips = orderData[0].filter(trip => (trip.completed === false));
    this.latestLocations = orderData[1];
    this.lastUpdated = new Date().toISOString();
    this.tripsEnroute = []; this.tripsOnSite = [];
    this.trips.forEach(trip => {
      if (!trip.completed) {
        if (trip.tripStatus === 'loading_complete' || trip.tripStatus === 'enroute_unloading') {
          this.tripsEnroute.push(trip);
        } else if (trip.tripStatus === 'unloading') {
          this.tripsOnSite.push(trip);
        }
      }
    });
    this.latestLocations.locationUpdates = this.latestLocations.locationUpdates.map(update => {
      if (update.driver) { update.driver = new DriverSerializer().fromJson(update.driver); }
      return update;
    });
    if (this.jobEvent) {
      let jobLocation: Location;
      this.getOrderStats(this.jobEvent);
      if (this.jobEvent.job && this.jobEvent.job.endLocation) {
        jobLocation = this.latestLocations.locations.find(location => (location.id === this.jobEvent.job.endLocation.id));
      }
      this.getTruckInfo(this.tripsEnroute, this.latestLocations.locationUpdates, jobLocation);
    } else {
      this.jobEventService.getJobEvent(this.orderService.jobEventId.getValue()).subscribe(jobEvent => {
        this.jobEvent = jobEvent;
        let jobLocation: Location;
        this.getOrderStats(this.jobEvent);
        if (this.jobEvent.job && this.jobEvent.job.endLocation) {
          jobLocation = this.latestLocations.locations.find(location => (location.id === this.jobEvent.job.endLocation.id));
        }
        this.getTruckInfo(this.tripsEnroute, this.latestLocations.locationUpdates, jobLocation);
      }, err => parseErrors(err));
    }
  }

  getOrderStats(jobevent: JobEvent) {
    if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
      this.jobEventStatsReq.unsubscribe();
    }
    if (!this.jobEvent.stats) {
      let effectiveRateCalculator = '';
      const enabledFeatures = this.authenticationService.enabledFeatures();
      if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
        effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
      }
      this.jobEventStatsReq = this.jobEventStatService.getStats(jobevent.id, {
        calculator: effectiveRateCalculator
      }).subscribe(stats => {
        this.setupOrderStats(stats);
        this.loading = false;
      }, err => parseErrors(err));
    } else { this.setupOrderStats(this.jobEvent.stats); }
  }

  setupOrderStats(stats: JobEventStat) {
    this.jobEventStats = stats;
    this.orderStats = {
      weight: {
        delivered: Math.round(stats.totalTons * 100) / 100 || 0,
        remaining: Math.round((stats.dailyDeliveryTarget - stats.totalTons) * 100) / 100,
        target: Math.round(stats.dailyDeliveryTarget * 100) / 100 || 0
      },
      loads: {
        delivered: stats.totalLoads,
        remaining: stats.weightInTransit,
        target: stats.dispatchedDriversCount
      }
    };
    this.completedPercentage = this.orderStats.weight.delivered && this.orderStats.weight.target ?
      Math.round((this.orderStats.weight.delivered * 100) / this.orderStats.weight.target) : 0;
  }

  changeOrderStats() {
    switch (this.activeStats) {
      case 'weight':
        this.activeStats = 'loads';
        break;
      case 'loads':
        this.activeStats = 'weight';
        break;
      default:
        this.activeStats = 'weight';
        break;
    }
  }

  getTruckInfo(trips: CondensedTrip[], currentLocationUpdates: LocationUpdate[], jobLocation: Location) {
    let truckInfoList: TruckDetails[] = [];
    trips.forEach(trip => {
      const tripLocationUpdate: LocationUpdate = currentLocationUpdates.find(update => (update.driver['id'] === trip.driverId));
      if (tripLocationUpdate && tripLocationUpdate.location) {
        truckInfoList.push({
          name: trip.truckName,
          weight: Number(trip.weight),
          eta: jobLocation && jobLocation.location ? this.orderService.getDistanceInMiles(
            { lat: tripLocationUpdate.location.coordinates[0] , lng: tripLocationUpdate.location.coordinates[0] },
            { lat: jobLocation.location.coordinates[0] , lng: jobLocation.location.coordinates[0] }
          ) : null,
          ticket: trip
        });
      }
    });
    truckInfoList.sort((a: TruckDetails, b: TruckDetails) => (
      (a.eta < b.eta) ? -1 : (a.eta > b.eta) ? 1 : 0
    ));
    this.nextTruck = truckInfoList[0];
    this.followingTruck = truckInfoList[1];
  }

  openTicketDetails(ticket: CondensedTrip) {
    const dialog = this.dialog.open(TicketDetailDialogComponent, {
      width: this.device.mobile ? '320px' : '480px',
      data: {
        ticket: ticket,
        jobEvent: this.jobEvent
      }
    });
    dialog.componentInstance.callback = (updatedTrip: CondensedTrip) => {
      this.trips[this.trips.findIndex(t => (t.id === updatedTrip.id))] = updatedTrip;
      this.setupOrderData([this.trips, this.latestLocations]);
    };
  }

  changeStatus(status: 'cancel') {
    let jobEventUpdates: JobEvent = <JobEvent>{ id: this.jobEvent.id };
    switch (status) {
      case 'cancel':
        jobEventUpdates.cancelled = true;
        break;
    }
    this.jobEventService.save(jobEventUpdates).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
    }, err => {
      this.errors = err;
    });
  }
}
