import { Assignment } from './assignment';
import { Driver } from '../drivers/driver';
import { Truck } from '../trucks/truck';

export class Slot {
  id: string;
  over: boolean;
  updating: boolean;
  numberOfLoadsType: string;
  saveButton: boolean;
  assignment: Assignment;
  driver: Driver;
  truck: Truck;
  dispatched: boolean;
}
