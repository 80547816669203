"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var lodash_1 = require("lodash");
var material_1 = require("@angular/material");
var preference_service_1 = require("../preferences/preference.service");
var assignment_service_1 = require("./assignment.service");
var driver_service_1 = require("../drivers/driver.service");
var shared_1 = require("../shared");
var dialogs_1 = require("../shared/dialogs");
var api_service_1 = require("../shared/api.service");
var truck_service_1 = require("../trucks/truck.service");
var DriverAssignmentsListComponent = /** @class */ (function () {
    function DriverAssignmentsListComponent(preferenceService, assignmentService, driverService, authenticationService, _eref, dialog) {
        this.preferenceService = preferenceService;
        this.assignmentService = assignmentService;
        this.driverService = driverService;
        this.authenticationService = authenticationService;
        this._eref = _eref;
        this.dialog = dialog;
        this.open = false;
        this.loading = false;
        this.edit = false;
        this.preferenceKey = 'DriverAssignmentsListComponent-AssignmentService';
        this.dispatchPreferences = {
            type: ''
        };
        this.hasCopyAssignment = false;
        this.hasAllDriversEnabled = false;
        this.allAssignmentsSelected = false;
        this.errors = [];
        this.assignmentsList = [];
        this.driversList = [];
        this.selectedAssignments = [];
        this.selectedDrivers = [];
        this.assignmentIds = [];
        this.driverIds = [];
        this.driverDropdownConfig = {
            nameProperty: 'name',
            searchable: false,
            group: false,
            loadingOptions: false,
            multiselect: true
        };
        this.trucksDropdownConfig = {
            small: true,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'ticketName',
            service: truck_service_1.TruckService,
            query: { ordering: 'name', carrier: null }
        };
        this.jobEventDropdownConfig = {
            nameProperty: 'jobDisplayName'
        };
        this.availableJobEvents = [];
        this.dispatch = false;
        this.assignmentsDeleted = new core_1.EventEmitter();
        this.assignmentsReload = new core_1.EventEmitter();
        this.updateAssignmentData = new core_1.EventEmitter();
        if (!this.dispatch) {
            this.getDriversList();
        }
        var organization = this.authenticationService.getOrganization();
        this.hasCopyAssignment = organization && organization.hasCopyAssignment;
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    }
    DriverAssignmentsListComponent.prototype.documentClick = function (event) {
        if (!this._eref.nativeElement.contains(event.target) &&
            !lodash_1.includes(event.target.classList, 'assignment-count') &&
            !lodash_1.includes(event.target.classList, 'assignment-number') &&
            !lodash_1.includes(event.target.classList, 'edit-button') &&
            !lodash_1.includes(event.target.classList, 'assignment-label') &&
            !lodash_1.includes(event.target.classList, 'btn-warning') &&
            !lodash_1.includes(event.target.classList, 'btn-cancel') &&
            !lodash_1.includes(event.target.classList, 'assignment-menu')) {
            this.setClosed();
        }
    };
    DriverAssignmentsListComponent.prototype.ngAfterViewInit = function () {
        this.getPreferences();
        // if (this.driver) { this.trucksDropdownConfig.query = { ordering: 'name', carrier: this.driver.carrier.id }; }
    };
    DriverAssignmentsListComponent.prototype.ngOnDestroy = function () {
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
    };
    DriverAssignmentsListComponent.prototype.getPreferences = function () {
        var _this = this;
        var user = this.authenticationService.user();
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                preferences.forEach(function (preference) {
                    if (preference.blob.dispatchPreference) {
                        _this.preference = preference;
                    }
                });
            }
        });
    };
    DriverAssignmentsListComponent.prototype.getAssignmentDetails = function () {
        var _this = this;
        this.loading = true;
        var requests = [];
        this.assignmentsList = this.driver.assignments;
        this.driver.assignments.forEach(function (assignment) {
            assignment['notesOpen'] = false;
            if (!assignment['deleted']) {
                var request = _this.assignmentService.get(assignment.id);
                requests.push(request);
            }
        });
        rxjs_1.forkJoin(requests).subscribe(function (assignments) { return _this.setupAssignments(assignments); }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
        }, function () { return _this.loading = false; });
    };
    DriverAssignmentsListComponent.prototype.setupAssignments = function (assignments) {
        var _this = this;
        if (this.driver) {
            assignments.forEach(function (a) {
                var assignment = _this.driver.assignments.find(function (record) { return (record.id === a.id); });
                a['deleted'] = false;
                a['startTime'] = a.uniqueStart ?
                    moment(a.uniqueStart).format('h:mm a') :
                    moment(a.jobevent[a.shift + 'StartTimestamp']).format('h:mm a');
                a['endTime'] = moment(a.jobevent[a.shift + 'EndTimestamp']).format('h:mm a');
                assignment['details'] = a;
                if (assignment.completed === undefined) {
                    assignment['completed'] = a['completed'];
                    assignment['driverStatus'] = a['driverStatus'];
                    assignment['maxNumberOfLoads'] = a['maxNumberOfLoads'];
                }
                if (!assignment['numberOfLoadsType']) {
                    assignment['numberOfLoadsType'] = assignment.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
                }
                assignment['job'] = a['job'];
                assignment['jobevent'] = a['jobevent'];
                // if (!this.preference || !this.preference.hasOwnProperty('name') || this.preference.name === 'all-day') {
                //   assignment['maxNumberOfLoads'] = 0;
                // } else if (this.preference.name === 'by-load') {
                //   assignment['maxNumberOfLoads'] = 1;
                // }
                assignment.uniqueStartDate = moment(a.uniqueStart).toDate();
                _this.shiftIndex = assignment.details.shift ? parseInt(assignment.details.shift.replace(/[^\d]/g, ''), 10) - 1 : 0;
            });
            this.driver.assignments = lodash_1.sortBy(this.driver.assignments, 'uniqueStartDate');
        }
        this.loading = false;
    };
    DriverAssignmentsListComponent.prototype.editAssignments = function (event) {
        event.preventDefault();
        if (!this.allAssignmentsSelected) {
            this.selectAllAssignments(true);
        }
        this.edit = true;
    };
    DriverAssignmentsListComponent.prototype.changeAssignmentJobEvent = function (assignment, jobEvent) {
        assignment.details.jobevent = jobEvent;
    };
    // Update selected and modified assignments when Save button is clicked
    DriverAssignmentsListComponent.prototype.updateAssignments = function () {
        var _this = this;
        if (this.selectAssignments) {
            this.selectedAssignments.forEach(function (assignment) {
                assignment.updating = true;
                var _assignment = lodash_1.clone(assignment);
                var dateString = _this.date.month + '/' + _this.date.day + '/' + _this.date.year;
                var timeString = _assignment.details && _assignment.details.startTime;
                var formatString = 'MM/DD/YYYY h:mm A';
                var parsedDate = moment(dateString + ' ' + timeString, [formatString]).format();
                if (timeString && parsedDate) {
                    _assignment['uniqueStart'] = parsedDate;
                }
                if (!_assignment['maxNumberOfLoads']) {
                    _assignment['maxNumberOfLoads'] = assignment.numberOfLoadsType === 'allDay' ? 0 : _assignment['maxNumberOfLoads'] || 1;
                }
                if (assignment.details && assignment.details.jobevent.id !== assignment.jobevent.id) {
                    _this.assignmentService.remove(assignment.id).subscribe(function () {
                        _this.assignmentService.save({
                            driver: _assignment.driver,
                            truck: _assignment.truck,
                            jobevent: _assignment.details.jobevent,
                            uniqueStart: _assignment.details.uniqueStart,
                            shift: 'shift1',
                            maxNumberOfLoads: assignment.details.maxNumberOfLoads
                        }, { can_dispatch: 'True' }).subscribe(function (newAssignment) {
                            // DEV NOTE: we need the updated jobEvent data in order to properly apply the new assignment data
                            _this.assignmentService.get(newAssignment.id).subscribe(function (assignmentUpdates) {
                                _this.driver.assignments[_this.driver.assignments.findIndex(function (a) { return a.id === assignment.id; })] = assignmentUpdates;
                                _this.setupAssignments(_this.driver.assignments);
                            });
                        }, function (err) {
                            _this.errors = api_service_1.parseErrors(err);
                            assignment.updating = false;
                        });
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        assignment.updating = false;
                    });
                }
                else {
                    _this.assignmentService.save(_assignment, { can_dispatch: 'True' }).subscribe(function () {
                        _this.applyAssignmentChanges(assignment, _assignment);
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        assignment.updating = false;
                    });
                }
            });
        }
        else {
            this.allAssignmentsSelected = false;
            this.selectedAssignments = [];
        }
    };
    DriverAssignmentsListComponent.prototype.applyAssignmentChanges = function (assignment, assignmentUpdates) {
        assignment.checked = false;
        assignment.updating = false;
        this.allAssignmentsSelected = false;
        this.selectAllAssignments(false);
        this.edit = false;
        var driverAssignment = this.driver.assignments.find(function (record) { return (record.id === assignmentUpdates.id || record.details.id === assignmentUpdates.id); });
        driverAssignment.uniqueStartDate = moment(assignmentUpdates['uniqueStart']).toDate();
        this.driver.assignments = lodash_1.sortBy(this.driver.assignments, 'uniqueStartDate');
        if (this.dispatch) {
            this.updateAssignmentData.emit(this.driver.assignments);
            this.getAssignmentDetails();
        }
    };
    // Delete selected assignments after confirmation
    DriverAssignmentsListComponent.prototype.deleteAssignments = function (assignmentsList) {
        var _this = this;
        if (this.allAssignmentsSelected || this.selectedAssignments.length) {
            this.loading = true;
            this.confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
                width: '430px',
                height: '250px'
            });
            this.confirmDialog.componentInstance.attributes = {
                title: 'Delete Assignments?',
                body: assignmentsList.length + " assignment/s will be deleted and cannot be recovered.",
                close: 'Cancel',
                accept: 'Delete'
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.loading = true;
                    assignmentsList.forEach(function (assignment) {
                        _this.removeAssignment(assignment.id);
                    });
                    _this.loading = false;
                    // this.setClosed();
                    _this.allAssignmentsSelected = false;
                    _this.selectedAssignments = [];
                }
                _this.confirmDialog = null;
            });
        }
    };
    DriverAssignmentsListComponent.prototype.setDefaultLoadCount = function (assignment, type) {
        if (type === void 0) { type = 'allDay'; }
        if (type === 'numbered' && (assignment.maxNumberOfLoads === 0 || assignment.maxNumberOfLoads === null)) {
            assignment.maxNumberOfLoads = 1;
        }
        else if (type === 'allDay') {
            assignment.maxNumberOfLoads = 0;
        }
    };
    // Get list of drivers to copy assignments
    DriverAssignmentsListComponent.prototype.getDriversList = function () {
        var _this = this;
        this.driversList = [];
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        this.driversReq = this.driverService.list().subscribe(function (drivers) {
            _this.driversList = drivers.filter(function (driver) { return driver.truck; });
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    DriverAssignmentsListComponent.prototype.removeAssignment = function (id) {
        var _this = this;
        this.loading = true;
        var matchedAssignmentIndex = this.driver.assignments.findIndex(function (a) { return (a.id === id); });
        if (matchedAssignmentIndex > -1) {
            this.assignmentService.remove(id).subscribe(function (res) {
                if (_this.driver.assignments[matchedAssignmentIndex]) {
                    _this.driver.assignments[matchedAssignmentIndex]['deleted'] = true;
                    if (_this.dispatch) {
                        _this.assignmentsDeleted.emit([_this.driver.assignments[matchedAssignmentIndex]]);
                    }
                }
                _this.loading = false;
            }, function (error) {
                _this.errors = api_service_1.parseErrors(error);
                _this.loading = false;
            });
        }
    };
    DriverAssignmentsListComponent.prototype.setClosed = function () {
        this.open = false;
        this.driver = null;
        if (this.assignmentsTimerSub) {
            try {
                this.assignmentsTimerSub.unsubscribe();
                this.assignmentsTimer = null;
            }
            catch (e) {
                this.assignmentsTimerSub = null;
                this.assignmentsTimer = null;
            }
        }
    };
    DriverAssignmentsListComponent.prototype.setOpen = function (driverData) {
        var _this = this;
        this.driver = driverData;
        this.open = true;
        if (this.date && this.date.year) {
            this.displayDate = moment([this.date.year, this.date.month - 1, this.date.day]).format('MMM D, YYYY');
        }
        else {
            this.displayDate = moment(this.date).format('MMM D, YYYY');
        }
        this.assignmentsTimer = rxjs_1.timer(1, 30000);
        if (this.assignmentsTimerSub && typeof this.assignmentsTimerSub.unsubscribe === 'function') {
            this.assignmentsTimerSub.unsubscribe();
        }
        this.assignmentsTimerSub = this.assignmentsTimer.subscribe(function (t) {
            _this.getAssignmentDetails();
        });
    };
    // Select all assignments when 'Select all assignments' is checked
    // Create an array of assignment Ids for copy-assignment API
    DriverAssignmentsListComponent.prototype.selectAllAssignments = function (event) {
        var _this = this;
        var checked = false;
        checked = (event && event.target) ? event.target.checked : event;
        this.allAssignmentsSelected = checked;
        this.assignmentsList.forEach(function (assignment) {
            if (_this.allAssignmentsSelected) {
                assignment.checked = checked;
                _this.edit = true;
                _this.selectedAssignments = _this.assignmentsList.slice();
                _this.assignmentIds = _this.selectedAssignments.map(function (allAssignments) { return allAssignments.id; });
            }
            else {
                assignment.checked = checked;
                _this.edit = false;
                _this.selectedAssignments = [];
            }
        });
    };
    // Select assignment/s when particular assignment is checked. 'Select all assignments' should be unchecked
    // Create an array of assignment Ids for copy-assignment API
    DriverAssignmentsListComponent.prototype.selectAssignments = function () {
        this.allAssignmentsSelected = false;
        this.edit = true;
        this.selectedAssignments = this.assignmentsList.filter(function (assignment) { return assignment.checked; });
        this.assignmentIds = this.selectedAssignments.map(function (assignment) { return assignment.id; });
    };
    // Pass the assignmentIds and driverIds to the copy-assignment API
    DriverAssignmentsListComponent.prototype.copyAssignments = function (event) {
        var _this = this;
        this.loading = true;
        this.selectedDrivers = [];
        this.assignmentService.copyAssignment({
            assignments: this.assignmentIds,
            drivers: this.driverIds
        }).subscribe(function (res) {
            _this.loading = false;
            _this.assignmentsReload.emit(true);
            _this.setClosed();
            _this.allAssignmentsSelected = false;
            _this.selectedAssignments = [];
            _this.selectedDrivers = [];
            _this.getDriversList();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    // Select drivers from dropdown and create array of driver ids.
    DriverAssignmentsListComponent.prototype.setSelectedDriver = function (option) {
        if (option) {
            this.selectedDrivers = option;
        }
        this.driverIds = this.selectedDrivers.map(function (driver) { return driver.id; });
    };
    DriverAssignmentsListComponent.prototype.changeTruck = function (truck) {
        var _this = this;
        if ((this.dispatch && (!this.driver.truck || (this.driver.truck.id !== truck.id))) ||
            (!this.dispatch && (!this.driver.latestTruck || (this.driver.latestTruck.id !== truck.id)))) {
            if (this.dispatch) {
                this.driver.truck = truck;
            }
            else {
                this.driver.latestTruck = truck;
            }
            if (this.driver.assignments) {
                this.driver.assignments = this.driver.assignments.map(function (a) { return (Object.assign(a, { truck: truck })); });
                this.driverService.save({ id: this.driver.id, truck: truck }).subscribe(function (res) {
                    _this.assignmentService.bulkUpdate(_this.driver.assignments).subscribe(function (bulkRes) {
                        if (bulkRes.errors) {
                            _this.errors = bulkRes.errors.map(function (e) { return (e.error); });
                        }
                    });
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.loading = false;
                });
            }
        }
    };
    return DriverAssignmentsListComponent;
}());
exports.DriverAssignmentsListComponent = DriverAssignmentsListComponent;
