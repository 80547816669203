import { TranslateService } from '@ngx-translate/core';

export const DISPLAYEDCOLUMNS = (hasLoadListsEnabled = false) => [
  'select',
  'organization',
  'days-allowed',
  ...(hasLoadListsEnabled
    ? ['requested-loads', 'confirmed-loads', 'dispatched-loads']
    : ['requested-amount', 'confirmed-amount', 'dispatched-trucks']),
  'rate',
  'rate-tracking',
  'notes',
  'internal-notes',
  'form-actions',
];

export const AVAILABLECOLUMNS = (
  translationService: TranslateService,
  hasLoadListsEnabled = false
): {
  key: string;
  title?: string;
  sortable?: boolean;
  sortBy?: string;
  required?: boolean;
}[] => [
  { key: 'select' },
  {
    key: 'organization',
    title: translationService.instant('Organization'),
    sortable: true,
    sortBy: 'organization',
  },
  { key: 'days-allowed', title: translationService.instant('Days Allowed') },
  ...(hasLoadListsEnabled
    ? [
        {
          key: 'requested-loads',
          title: translationService.instant('Requested Amount'),
        },
        {
          key: 'confirmed-loads',
          title: translationService.instant('Confirmed Amount'),
        },
        {
          key: 'dispatched-loads',
          title: translationService.instant('Dispatched Amount'),
        },
      ]
    : [
        {
          key: 'requested-amount',
          title: translationService.instant('Requested Amount'),
        },
        {
          key: 'confirmed-trucks',
          title: translationService.instant('Confirmed Trucks'),
        },
        {
          key: 'dispatched-trucks',
          title: translationService.instant('Dispatched Trucks'),
        },
      ]),
  { key: 'rate', title: translationService.instant('Rate') },
  { key: 'rate-tracking', title: translationService.instant('Rate Tracking') },
  { key: 'broker-rate-code', title: translationService.instant('Rate Code') },
  { key: 'notes', title: translationService.instant('Notes') },
  {
    key: 'internal-notes',
    title: translationService.instant('Internal Notes'),
  },
  {
    key: 'confirmed-amount',
    title: translationService.instant('Confirmed Amount'),
  },
  { key: 'form-actions', title: translationService.instant('Actions') },
];
