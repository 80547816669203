"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@angular/core");
// angular material
var material_1 = require("@angular/material");
// libraries
var moment = require("moment");
// services
var shift_service_1 = require("../../shifts/shift.service");
var shared_1 = require("../../shared");
var DailyShiftsComponent = /** @class */ (function () {
    function DailyShiftsComponent(shiftReportService, authenticationService, dialog) {
        this.shiftReportService = shiftReportService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.shifts = [];
        this.loading = true;
        this.errors = [];
        this.sortBy = 'driver__first_name';
        this.sortAsc = true;
        this.contextMenuEventSubject = new rxjs_1.Subject();
    }
    DailyShiftsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        if (window.innerWidth > 900) {
            this.getShifts({}, true);
            this.shiftsTimerSub = rxjs_1.timer(1, 120000);
            this.shiftsTimerSub.subscribe(function (t) {
                _this.getShifts({}, false);
            });
        }
    };
    DailyShiftsComponent.prototype.ngOnDestroy = function () {
        if (this.shiftsTimerSub) {
            try {
                this.shiftsTimerSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
            this.shiftsReq.unsubscribe();
        }
    };
    DailyShiftsComponent.prototype.onResize = function (event) {
        if (this.shifts.length === 0) {
            this.ngOnInit();
        }
    };
    DailyShiftsComponent.prototype.getShifts = function (query, loading) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (loading === void 0) { loading = true; }
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.loading = loading;
        var startDate = new Date();
        var endDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        if (this.shiftsReq && typeof this.shiftsReq.unsubscribe === 'function') {
            this.shiftsReq.unsubscribe();
        }
        this.shiftsReq = this.shiftReportService.list(__assign({ ordering: order }, query, { start_time__gte: startDate.toISOString(), start_time__lte: endDate.toISOString() })).subscribe(function (shifts) {
            var momentObj = moment();
            _this.shifts = shifts;
            // TODO: Should figure out whether on active shift or nor and calculate the shift duration.
            // moment();
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DailyShiftsComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getShifts({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    DailyShiftsComponent.prototype.openContextMenu = function (event, shift) {
        this.contextMenuEventSubject.next({
            event: event,
            shiftReport: shift,
            driverId: shift.driver.id,
        });
    };
    return DailyShiftsComponent;
}());
exports.DailyShiftsComponent = DailyShiftsComponent;
