import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// services
import { TruckService } from './truck.service';
import { TruckTypeService } from './truck-type.service';
import { CustomFieldService } from '../custom-fields/custom-field.service';
import { parseErrors } from '../shared/api.service';
import { ConnectionService } from '../connections/connection.service';
import { AuthenticationService } from '../shared';

// types
import { CustomField, CustomFieldKind } from '../custom-fields/custom-field';
import { TruckType } from './truck-type';
import { Tag } from '../tags/tag';
import { Carrier } from '../carriers/carrier';

@Component({
  selector: 'new-truck-dialog',
  templateUrl: './new-truck-dialog.component.html',
  styleUrls: ['./new-truck-dialog.component.scss'],
})
export class NewTruckDialogComponent implements OnInit {
  loading = false;
  model: any = { tags: [], customFieldData: {} };
  customFields: CustomField[];
  errors = [];
  carrierId: string;
  selectedTruckType: TruckType;
  callback;
  device;

  truckTypesDropdownConfig = {
    selectText: this.translate.instant('Select Truck Type'),
    loadingText: this.translate.instant('Loading Truck Types...'),
    noResultsText: this.translate.instant('No Truck Types'),
    nameProperty: 'name',
    service: TruckTypeService,
    query: { ordering: 'name' },
  };

  // carriers dropdown config
  carriersConfig = {
    searchable: true,
    nameProperty: 'name',
    idProperty: 'organization.carrier.id',
    selectText: this.translate.instant('Select Fleet'),
    loadingText: this.translate.instant('Loading Fleets...'),
    noResultsText: this.translate.instant('No Fleets'),
    service: ConnectionService,
    serviceFunction: 'list',
    query: {
      ordering: 'organization__name',
      is_carrier: 'True',
      allow_dispatch: 'True',
    },
    prefilledOptions: [],
  };

  constructor(
    public dialogRef: MatDialogRef<NewTruckDialogComponent>,
    private truckService: TruckService,
    private elementRef: ElementRef,
    private deviceDetectorService: DeviceDetectorService,
    private customFieldService: CustomFieldService,
    private translate: TranslateService,
    private authentiationService: AuthenticationService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop(),
    };
  }

  ngOnInit() {
    const organization = this.authentiationService.getOrganization();
    if (organization && organization.carrier) {
      this.carriersConfig.prefilledOptions.push(organization.carrier);
      if (this.carrierId) {
        this.model.carrier = this.carrierId;
      } else {
        this.carrierId = organization.carrier.id;
        this.model.carrier = organization.carrier.id;
      }
    }

    this.getCustomFields();
  }

  getCustomFields(): void {
    const type = CustomFieldKind.Truck;

    this.customFieldService
      .getFieldsForKind(type)
      .subscribe((fields) => (this.customFields = fields));
  }

  tagChange(tags: Tag[]): void {
    this.model['tags'] = tags.map((t) => t.name);
  }

  selectTruckType(truckType: TruckType): void {
    this.selectedTruckType = truckType;
    this.model.truckType = truckType;
  }

  selectCarrier(carrier: Carrier): void {
    this.carrierId = carrier.id;
    this.model.carrier = carrier.id;
  }

  fileChange(e): void {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (_e) => {
      this.model.image = _e.target['result'];
    };
    reader.readAsDataURL(file);
  }

  submit(): void {
    this.loading = true;
    const fileInput =
      this.elementRef.nativeElement.querySelector('input[name="file"]');
    let model = cloneDeep(this.model);
    delete model.image;
    this.truckService.save(model).subscribe(
      (truck) => {
        if (this.model.image && truck && truck.id) {
          this.truckService
            .uploadImage('PUT', [truck.id], fileInput.files)
            .subscribe(
              () => {
                if (typeof this.callback === 'function') {
                  this.callback();
                }
              },
              function (err) {
                console.error('err', err);
              }
            );
        } else {
          if (typeof this.callback === 'function') {
            this.callback();
          }
        }
        this.dialogRef.close();
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }
}
