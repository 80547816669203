import { Resource } from '../resource';

export enum UserNotificationKind {
  JobCreated = 'job-created',
  JobDayChanged = 'job-day-changed',
  JobEventShared = 'jobevent-shared',
  JobEventShareAccepted = 'jobevent-share-accepted',
  JobEventShareDeclined = 'jobevent-share-declined',
  JobEventUpdated = 'jobevent_update',
  NewConnectionInvitation = 'new-connection-invitation',
  NewConnectionAccepted = 'new-connection-accepted',
  OwnDriversDispatched = 'own-drivers-dispatched',
  SharedDriversDispatched = 'shared-drivers-dispatched',
  AugmentedFleetDriversDispatched = 'af-drivers-dispatched',
  AssignmentAccepted = 'assignment-accept',
  AssignmentRejected = 'assignment-reject',
  AssignmentUpdated = 'assignment_update',
  Dispatch = 'dispatch',
  QRCodeScanned = 'qr_scanned',
  Retake = 'retake'
}

export class UserNotification extends Resource {
  verb: UserNotificationKind;
  description: string;
  data: any;
  target: string;
  targetId: string;
  actor: string;
  timestamp: string;
  unread: boolean;
  icon: string;
  url: string;
}
