import {
  Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, forkJoin, Observable } from 'rxjs';

// angular material
import { MatDialogRef } from '@angular/material';

// lodash
import { filter, find as _find, pickBy } from 'lodash';

// components
import { DropdownComponent } from '../../shared';

// services
import { parseErrors } from '../../shared/api.service';
import { CollaboratorService } from '../collaborator.service';
import { ConnectionService } from '../../connections/connection.service';
import { UnitsOfMeasureService } from '../../units/units-of-measure.service';

// models
import { JobEvent } from '../../job-events/job-event';
import { Connection } from '../../connections/connection';
import { CollaborationTemplate } from '../collaboration-template';
import { JobLoad } from '../../dispatch/dispatch-by-job/job-load';

// constants
import { JOBWEIGHTOPTIONS } from './../../app.constants';

// animations
import { collaboratorsAnimation } from '../../shared/animations/collaborators.animation';

@Component({
  selector: 'add-collaborators-dialog',
  templateUrl: './add-collaborators-dialog.component.html',
  styleUrls: ['./add-collaborators-dialog.component.scss'],
  animations: [collaboratorsAnimation]
})
export class AddCollaboratorsDialogComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() brokerRateKey: string;
  @Input() selectedConnections: string[];
  @Input() hasLoadListsEnabled: boolean = false;
  @Input() loadList: JobLoad[] = [];
  loading = false;
  errors = [];
  callback: Function;
  search: string;
  allConnectionsSelected = false;
  collaborationTemplate: CollaborationTemplate = {
    jobEventsLoading: false,
    jobEventOptions: [],
    jobevents: [],
    numTrucks: null,
    invoiceWeightUnit: null,
    invoiceType: null,
    invoiceRate: null,
    notes: '',
    cancelMissing: false,
    requestedAmount: null,
    requestedUnit: null,
  };
  connections: Connection[] = [];
  connectionsReq: Subscription;
  rateTrackingOptions = [
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  rateWeightConfig = {
    nameProperty: 'label',
    loadingOptions: false
  };
  weightOptions = [...JOBWEIGHTOPTIONS];
  weightOption;
  haulTypeOption;
  unitsOfMeasure: any[];
  displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
  noResultsStyles = {
    'min-height': 'unset', 'height': '100%', 'display': 'flex',
    'flex-direction': 'column', 'justify-content': 'center'
  };

  @ViewChildren('jobEventsDropdown') jobEventDropdowns: QueryList<DropdownComponent>;
  @ViewChild('addCollaborators', { static: false }) addCollaborators: NgForm;

  constructor(
    public dialogRef: MatDialogRef<AddCollaboratorsDialogComponent>,
    private collaboratorService: CollaboratorService,
    private unitsOfMeasureService: UnitsOfMeasureService,
    private connectionService: ConnectionService,
  ) { }

  ngOnInit() {
    if (this.selectedConnections) {
      this.preselectConnections(this.selectedConnections);
    } else {
      this.getConnections();
    }
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });

    if (this.jobEvent.job.allowWeight) {
      this.rateTrackingOptions = this.rateTrackingOptions.concat([
        { value: 'weight', label: 'Weight', name: 'Weight' }
      ]);
    }
    this.weightOption = _find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
    this.getUnitsOfMeasure();
  }

  ngOnDestroy() {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
  }

  submit(): void {
    this.loading = true;
    const selectedConnections = filter(this.connections, { selected: true });
    const collaborations = selectedConnections.map(connection => {
      let collaboration = connection.collaboration;
      if (!collaboration.customFieldData) { collaboration.customFieldData = {}; }
      collaboration.customFieldData[this.brokerRateKey] = collaboration.brokerRateCode;
      if (collaboration.requestedUnit && collaboration.requestedUnit.name === 'Trucks') {
        collaboration.numTrucks = collaboration.requestedAmount;
      } else {
        collaboration.numTrucks = 0;
      }
      collaboration.requestedUnit = collaboration.requestedUnit && collaboration.requestedUnit.value ? collaboration.requestedUnit.value : null;
      collaboration.invoiceWeightUnit = (typeof collaboration.invoiceWeightUnit === 'string') ? collaboration.invoiceWeightUnit : collaboration.invoiceWeightUnit.value;
      return collaboration;
    });
    if (this.jobEvent && this.jobEvent.job) {
      this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(res => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  getConnections(query = {}): void {
    if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
      this.connectionsReq.unsubscribe();
    }
    this.loading = true;
    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      leased_org: 'False',
      is_carrier: 'True',
      customer_only: 'False',
      status: 'active',
      search: this.search,
      page_size: 100,
      ...query
    }).subscribe(connections => {
      this.connections = this.connections.filter(c => (c.selected)).concat(this.setCollaborationDefaults(connections));
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  preselectConnections(connections: string[]) {
    let connectionReqs: Observable<any>[] = [];
    connections.forEach(id => {
      let request = this.connectionService.get(id);
      connectionReqs.push(request);
    });
    forkJoin(connectionReqs).subscribe(res => {
      this.connections = this.setCollaborationDefaults(
        res.map(c => (Object.assign(c, { selected: true })))
      );
      this.addCollaborators.form.markAsDirty();
    });
  }

  onScroll(event): void {
    if (!this.loading && event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight * 3) {
      let request = this.connectionService.listNext();
      if (request) {
        this.loading = true;
        this.connectionsReq = request.subscribe(connections => {
          this.connections = this.connections.concat(this.setCollaborationDefaults(connections));
          this.loading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
        });
      }
    }
  }

  changeConnectionSearch(term: string): void {
    this.search = term;
    this.getConnections();
  }

  connectionToggle(event: Event): void {
    this.connections.map(_connection => {
      _connection.selected = this.allConnectionsSelected;
    });
  }

  loadDropdownSelectionChanged(event: any, load: JobLoad, connection: Connection): void {
    const loadSchedule: JobLoad[] = connection.collaboration.loadSchedule;
    const existingLoad = loadSchedule.findIndex(l => l.loadNumber === load.loadNumber);
    if (event.checked) {
      if (existingLoad === -1) {
        loadSchedule.push(load);
      }
    } else {
      if (existingLoad > -1) {
        loadSchedule.splice(existingLoad, 1);
      }
    }
    loadSchedule.sort((a, b) => a.loadNumber - b.loadNumber);
  }

  setCollaborationDefaults(connections: Connection[]): Connection[] {
    return connections.map(connection => {
      if (!connection.collaboration) { connection.collaboration = {}; }
      connection.collaboration['invoiceWeightUnit'] = this.jobEvent.haulWeightUnit || this.jobEvent.invoiceWeightUnit;
      connection.collaboration['invoiceType'] = this.jobEvent.haulType || this.jobEvent.invoiceType;
      connection.collaboration['invoiceRate'] = this.jobEvent.haulRate || this.jobEvent.rate;
      connection.collaboration['numTrucks'] = null;
      connection.collaboration['jobevents'] = [this.jobEvent.id];
      connection.collaboration['brokerRateCodeKey'] = this.brokerRateKey;
      connection.collaboration['loadSchedule'] = [];

      if (this.unitsOfMeasure && this.unitsOfMeasure.length) {
        connection.collaboration['requestedUnit'] = this.unitsOfMeasure.find(u => u.name === 'Trucks');
      }

      return connection;
    });
  }

  setSelectedAction(connection: Connection, option): void {
    connection.collaboration.invoiceType = option.value;
  }

  setSelectedWeightUnit(connection: Connection, option): void {
    connection.collaboration.invoiceWeightUnit = option.value;
  }

  setSelectedTemplateWeightUnit(option: any): void {
    this.collaborationTemplate.invoiceWeightUnit = option;
  }

  selectJobEvent(connection: Connection, jobEvents: JobEvent[]): void {
    connection.collaboration.jobevents = jobEvents;
  }

  setSelectedTemplateAction(template: CollaborationTemplate, option): void {
    template.invoiceType = option.value;
    if (option.value === 'weight') {
      template.invoiceWeightUnit = 'ton';
    } else {
      template.invoiceWeightUnit = template.invoiceType;
    }
  }

  selectTemplateJobEvent(template: CollaborationTemplate, jobEvents: JobEvent[]): void {
    template.jobevents = jobEvents;
  }

  expandSearch(): void {
    this.search = null;
    this.getConnections();
  }

  applyTemplate(form: NgForm): void {
    let selectedConnections = filter(this.connections, { selected: true });
    const template = pickBy(this.collaborationTemplate, v => v !== null && v !== undefined && v !== '' && v['length'] !== 0);
    selectedConnections.forEach(connection => {
      connection.collaboration = {
        ...connection.collaboration,
        ...template
      };
    });
    form.form.markAsDirty();
  }

  getUnitsOfMeasure() {
    this.unitsOfMeasureService.list().subscribe((units) => {
      const unitsOfMeasure = units.map(unit => ({
        value: unit.id,
        label: unit.name,
        name: unit.name,
        selected: false,
      }));
      this.unitsOfMeasure = [...unitsOfMeasure];
      this.collaborationTemplate.requestedUnit = this.unitsOfMeasure.find(u => u.name === 'Trucks');
    });
  }

  onUnitOfMeasureSelect(connectionId: string, event: any) {
    const connection = this.connections.find(con => con.id === connectionId);
    connection.collaboration.requestedUnit = event;
  }
}
