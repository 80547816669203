import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { clone } from 'lodash';

import { AssignmentService } from './assignment.service';
import { AssignmentSerializer } from './assignment.serializer';
import { JobSerializer } from '../jobs/job.serializer';

@Component({
  selector: 'ruckit-confirm-assignment',
  templateUrl: './confirm-assignment.component.html',
  styleUrls: ['./confirm-assignment.component.scss'],
  providers: [AssignmentService]
})
export class ConfirmAssignmentComponent implements OnInit {
  loading = true;
  errors = [];
  assignment: any;
  jobEvent: any;
  job: any;
  assignmentReq;
  displayNotes = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    private assignmentService: AssignmentService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.errors = [];
    this.disableSidebar();
    this.route.params.subscribe((params: Params) => {
      this.getAssignment(params['id']);
    });
  }

  disableSidebar() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) { currentUser.sidebar = false; }

    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  getAssignment(id: string) {
    if (this.assignmentReq) {
      this.assignmentReq.unsubscribe();
    }

    this.assignmentReq = this.assignmentService.get(id)
      .subscribe(assignment => {
        this.assignment = new AssignmentSerializer().fromJson(assignment);
        this.jobEvent = assignment.jobevent;
        if (this.jobEvent && this.jobEvent.job) {
          this.job = new JobSerializer().fromJson(this.jobEvent.job);
        }
        this.loading = false;
      }, err => {
        this.errors = err;
        this.loading = false;
      });
  }

  confirmAssignment() {
    if (this.assignmentReq) {
      this.assignmentReq.unsubscribe();
    }

    let _assignment = clone(this.assignment);
    _assignment.driverConfirmed = true;
    this.assignmentReq = this.assignmentService.save(_assignment).subscribe(assignment => {
        this.assignment = new AssignmentSerializer().fromJson(assignment);
        this.jobEvent = assignment.jobevent;
        if (this.jobEvent && this.jobEvent.job) {
          this.job = new JobSerializer().fromJson(this.jobEvent.job);
        }
        this.loading = false;
      }, err => {
        this.errors = err;
        this.loading = false;
      });
  }
}
