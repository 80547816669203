"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var cyber_trip_1 = require("./cyber-trip");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var CyberTripSerializer = /** @class */ (function () {
    function CyberTripSerializer() {
    }
    /**
    * @param  {any} json
    * @returns CyberTrip
    */
    CyberTripSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var cyberTrip = new cyber_trip_1.CyberTrip();
        if (!json) {
            return cyberTrip;
        }
        cyberTrip.id = json.id;
        cyberTrip.carrier = json.carrier;
        cyberTrip.carrierId = json.carrierId;
        cyberTrip.checkinOptions = json.checkinOptions;
        cyberTrip.checkoutOptions = json.checkoutOptions;
        cyberTrip.completed = json.completed;
        cyberTrip.customer = json.customer;
        cyberTrip.customerId = json.customerId;
        cyberTrip.date = json.date;
        cyberTrip.distance = json.distance;
        cyberTrip.driver = json.driver;
        cyberTrip.driverId = json.driverId;
        cyberTrip.driverUniqueBillingId = json.driverUniqueBillingId;
        cyberTrip.endTime = json.endTime;
        cyberTrip.haulRate = json.haulRate;
        cyberTrip.haulType = json.haulType;
        cyberTrip.haulWeightUnit = json.haulWeightUnit;
        cyberTrip.invoiceRate = json.invoiceRate;
        cyberTrip.invoiceType = json.invoiceType;
        cyberTrip.invoiceWeightUnit = json.invoiceWeightUnit;
        cyberTrip.jobDate = json.jobDate;
        cyberTrip.job = json.job;
        cyberTrip.jobDisplayName = json.jobDisplayName;
        cyberTrip.jobId = json.jobId;
        cyberTrip.jobEventId = json.jobEventId;
        cyberTrip.phaseCode = json.phaseCode;
        cyberTrip.loadingArrivalDatetime = json.loadingArrivalDatetime;
        cyberTrip.loadingCompleteDatetime = json.loadingCompleteDatetime;
        cyberTrip.loadingLocationName = json.loadingLocationName;
        cyberTrip.loadingTicket = json.loadingTicket;
        cyberTrip.loadingWeight = json.loadingWeight;
        cyberTrip.localizedEndTime = json.localizedEndTime;
        cyberTrip.localizedEndDatetime = json.localizedEndDatetime;
        cyberTrip.localizedEndDate = json.localizedEndDate;
        cyberTrip.localizedJobDate = json.localizedJobDate;
        cyberTrip.localizedLoadingArrivalDatetime = json.localizedLoadingArrivalDatetime;
        cyberTrip.localizedLoadingCompleteDatetime = json.localizedLoadingCompleteDatetime;
        cyberTrip.localizedStartTime = json.localizedStartTime;
        cyberTrip.localizedStartDatetime = json.localizedStartDatetime;
        cyberTrip.localizedStartDate = json.localizedStartDate;
        cyberTrip.localizedUnloadingArrivalDatetime = json.localizedUnloadingArrivalDatetime;
        cyberTrip.localizedUnloadingCompleteDatetime = json.localizedUnloadingCompleteDatetime;
        cyberTrip.material = json.material;
        cyberTrip.payableTo = json.payableTo;
        cyberTrip.payableToId = json.payableToId;
        cyberTrip.project = json.project;
        cyberTrip.projectId = json.projectId;
        cyberTrip.startTime = json.startTime;
        cyberTrip.tripStatus = json.tripStatus;
        cyberTrip.truck = json.truck;
        cyberTrip.truckId = json.truckId;
        cyberTrip.truckType = json.truckType;
        cyberTrip.unloadingArrivalDatetime = json.unloadingArrivalDatetime;
        cyberTrip.unloadingCompleteDatetime = json.unloadingCompleteDatetime;
        cyberTrip.unloadingTicket = json.unloadingTicket;
        cyberTrip.unloadingWeight = json.unloadingWeight;
        return cyberTrip;
    };
    /**
     * @param  {CyberTrip} CyberTrip
     * @returns any
     */
    CyberTripSerializer.prototype.toJson = function (cyberTrip) {
        var json = {
            id: cyberTrip.id,
            carrier: cyberTrip.carrier,
            carrierId: cyberTrip.carrierId,
            checkinOptions: cyberTrip.checkinOptions,
            checkoutOptions: cyberTrip.checkoutOptions,
            completed: cyberTrip.completed,
            customer: cyberTrip.customer,
            customerId: cyberTrip.customerId,
            date: cyberTrip.date,
            distance: cyberTrip.distance,
            driver: cyberTrip.driver,
            driverId: cyberTrip.driverId,
            driverUniqueBillingId: cyberTrip.driverUniqueBillingId,
            endTime: cyberTrip.endTime,
            haulRate: cyberTrip.haulRate,
            haulType: cyberTrip.haulType,
            haulWeightUnit: cyberTrip.haulWeightUnit,
            invoiceRate: cyberTrip.invoiceRate,
            invoiceType: cyberTrip.invoiceType,
            invoiceWeightUnit: cyberTrip.invoiceWeightUnit,
            jobDate: cyberTrip.jobDate,
            job: cyberTrip.job,
            jobId: cyberTrip.jobId,
            jobEventId: cyberTrip.jobEventId,
            loadingArrivalDatetime: cyberTrip.loadingArrivalDatetime,
            loadingCompleteDatetime: cyberTrip.loadingCompleteDatetime,
            loadingLocationName: cyberTrip.loadingLocationName,
            loadingTicket: cyberTrip.loadingTicket,
            loadingWeight: cyberTrip.loadingWeight,
            localizedEndTime: cyberTrip.localizedEndTime,
            localizedEndDatetime: cyberTrip.localizedEndDatetime,
            localizedEndDate: cyberTrip.localizedEndDate,
            localizedJobDate: cyberTrip.localizedJobDate,
            localizedLoadingArrivalDatetime: cyberTrip.localizedLoadingArrivalDatetime,
            localizedLoadingCompleteDatetime: cyberTrip.localizedLoadingCompleteDatetime,
            localizedStartTime: cyberTrip.localizedStartTime,
            localizedStartDatetime: cyberTrip.localizedStartDatetime,
            localizedStartDate: cyberTrip.localizedStartDate,
            localizedUnloadingArrivalDatetime: cyberTrip.localizedUnloadingArrivalDatetime,
            localizedUnloadingCompleteDatetime: cyberTrip.localizedUnloadingCompleteDatetime,
            material: cyberTrip.material,
            payableTo: cyberTrip.payableTo,
            payableToId: cyberTrip.payableToId,
            project: cyberTrip.project,
            projectId: cyberTrip.projectId,
            startTime: cyberTrip.startTime,
            tripStatus: cyberTrip.tripStatus,
            truck: cyberTrip.truck,
            truckId: cyberTrip.truckId,
            truckType: cyberTrip.truckType,
            unloadingArrivalDatetime: cyberTrip.unloadingArrivalDatetime,
            unloadingCompleteDatetime: cyberTrip.unloadingCompleteDatetime,
            unloadingTicket: cyberTrip.unloadingTicket,
            unloadingWeight: cyberTrip.unloadingWeight,
        };
        return decamelizeKeysDeep(json);
    };
    return CyberTripSerializer;
}());
exports.CyberTripSerializer = CyberTripSerializer;
