import { Component, OnInit, Input } from '@angular/core';
import { CondensedTrip } from '../../condensed-trip';
import { Field } from '../field';

@Component({
  selector: 'trip-history-billing',
  templateUrl: './trip-history-billing.component.html',
  styleUrls: ['./trip-history-billing.component.scss']
})
export class TripHistoryBillingComponent implements OnInit {
  fields: Field[];

  @Input()
  set trip(trip: CondensedTrip) {
    if (!trip) {
      return;
    }

    this.fields = this.getFieldsFromTrip(trip);
  }

  constructor() { }

  ngOnInit() { }

  getValueString(value: string): string {
    return value ? value : '-';
  }

  getFieldsFromTrip(trip: CondensedTrip): Field[] {
    const getInvoiceNumber = () => {
      const status = trip.invoiceSent ? 'Sent' : 'Draft';

      if (trip.invoiceNumber) {
        return `${trip.invoiceNumber} - ${status}`;
      }

      return null;
    };

    const fields: Field[] = [
      {
        name: 'Invoice Rate',
        value: `$${trip.invoiceRate} per ${trip.invoiceRateUnit}`,
      },
      {
        name: 'Invoice Total',
        value: `$${trip.invoiceTotal}`,
        color: 'green',
      },
      {
        name: 'Haul Rate',
        value: `$${trip.haulRate} per ${trip.haulRateUnit}`,
      },
      {
        name: 'Haul Total',
        value: (trip.haulTotal ? `$${trip.haulTotal}` : null),
        color: 'red',
      },
      {
        name: 'Invoice #',
        value: getInvoiceNumber(),
        url: `/invoices/${trip.invoiceId}`,
      },
      {
        name: 'Expense #',
        value: (trip.expenseNumber ? `${trip.expenseNumber}` : null),
        url: `/expenses/${trip.expenseId}`,
      },
      {
        name: 'Paystub #',
        value: (trip.paystubNumber ? `${trip.paystubNumber}` : null),
        url: `/paystubs/${trip.paystubId}`,
      }
    ];

    return fields;
  }
}
