<mat-table #tripsTable *ngIf="dataSource.data && dataSource.data.length" [dataSource]="dataSource">
  <ng-container matColumnDef="total-time">
    <mat-header-cell *matHeaderCellDef translate>TOTAL TIME</mat-header-cell>
    <mat-cell fxLayout="column" *matCellDef="let row">
      {{ row.duration || '&mdash;' }}
      <div class="trip-status" *ngIf="row.void">
        <div class="label-void">Void</div>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="start-time">
    <mat-header-cell *matHeaderCellDef translate>START TIME</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <date-time-picker-inline 
        [disabled]="!(row?.jobEvent?.job?.project?.ownerOrganization === user.organization.id)"
        [value]="row.startTimeTimestamp"
        (dateTimeChange)="editTripTime(row, 'start', $event)">
      </date-time-picker-inline>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="end-time">
    <mat-header-cell *matHeaderCellDef translate>END TIME</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <date-time-picker-inline 
        [disabled]="!(row?.jobEvent?.job?.project?.ownerOrganization === user.organization.id)"
        [value]="row.endTimeTimestamp"
        (dateTimeChange)="editTripTime(row, 'end', $event)">
      </date-time-picker-inline>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="loading-ticket-number">
    <mat-header-cell *matHeaderCellDef translate>LOAD TICKET</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.loadingTicketNumber"><i class="icon-ticket"></i></span>
      <span class="ticket-text">{{ row.loadingTicketNumber || '&mdash;' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="unloading-ticket-number">
    <mat-header-cell *matHeaderCellDef translate>UNLOAD TICKET</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <span *ngIf="row.unloadingTicketNumber"><i class="icon-ticket"></i></span>
      <span class="ticket-text">{{ row.unloadingTicketNumber || '&mdash;' }}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="amount">
    <mat-header-cell *matHeaderCellDef translate>AMOUNT</mat-header-cell>
    <mat-cell *matCellDef="let row">
      {{ row.weight }} {{ row.weightUnit }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="invoice">
    <mat-header-cell *matHeaderCellDef translate>INVOICE</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngIf="invoiceUnit == 'load'">
        {{ invoiceRate | currency : 'USD' : 'symbol' }}
      </ng-container>
      <ng-container *ngIf="invoiceUnit == 'ton'">
        {{ invoiceRate * row.weight | currency : 'USD' : 'symbol' }}
      </ng-container>
      <ng-container *ngIf="invoiceUnit == 'hour'">
        {{ invoiceRate * (row.completedTripDuration ? (row.completedTripDuration / 3600000) : 0) | currency : 'USD' : 'symbol' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="expense">
    <mat-header-cell *matHeaderCellDef translate>EXPENSE</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngIf="haulUnit == 'load'">
        {{ haulRate | currency : 'USD' : 'symbol' }}
      </ng-container>
      <ng-container *ngIf="haulUnit == 'ton'">
        {{ haulRate * row.weight | currency : 'USD' : 'symbol' }}
      </ng-container>
      <ng-container *ngIf="haulUnit == 'hour'">
        {{ haulRate * (row.completedTripDuration ? (row.completedTripDuration / 3600000) : 0) | currency : 'USD' : 'symbol' }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="note">
    <mat-header-cell *matHeaderCellDef translate title="Notes are set within the Ruckit Field App">NOTE</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-icon 
        (click)="onDriverNoteClick(row.id, row.reviewerNotes)" 
        title="{{ row.reviewerNotes || 'No Notes' | translate }}" 
        [ngClass]="{empty: !row.reviewerNotes}"
        >
          {{row.reviewerNotes ? 'comment' : 'add_comment'}}
      </mat-icon>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="approved-by">
    <mat-header-cell *matHeaderCellDef translate>APPROVED BY</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngIf="row.latestDecisionStatus === 'approved'">
        {{ row.latestDeciderName }}
      </ng-container>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="exported">
    <mat-header-cell *matHeaderCellDef translate>EXPORTED</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <input type="checkbox" [ngModel]="row.exported" (change)="toggleExportedFlag(row, $event.target.checked)" />
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef translate>APPROVED</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div *ngIf="row.loading" [ngClass]="{loading: row.loading}">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div *ngIf="!row.loading" [ngClass]="{
        approved: row.latestDecisionStatus === 'approved',
        unapproved: !row.latestDecisionStatus || row.latestDecisionStatus !== 'approved'
      }">
        <span class="icon-success" *ngIf="row.endTimeTimestamp"></span>
        <ng-container *ngIf="!row.endTimeTimestamp">
          <span class="icon-info" title="{{ 'End Time must be set before approving' | translate }}"></span> {{ 'Set End Time' | translate }}
        </ng-container>
      </div>
      <button *ngIf="row.endTimeTimestamp && (!row.latestDecisionStatus || row.latestDecisionStatus !== 'approved')"
        class="btn btn-primary" [ngClass]="{loading: row.loading}"
        (click)="approveTrip(row)" translate
      >
        Approve
      </button>
      <button *ngIf="row.endTimeTimestamp && (row.latestDecisionStatus && row.latestDecisionStatus === 'approved')"
        class="btn btn-delete" [ngClass]="{loading: row.loading}"
        (click)="unapproveTrip(row)" translate
      >
        Unapprove
      </button>

      <action-menu icon="icon-more">
        <a *ngIf="!row.void" mat-menu-item (click)="setSelectedBulkAction('void', row)" translate>Void Ticket</a>
        <a *ngIf="row.void" mat-menu-item (click)="setSelectedBulkAction('unvoid', row)" translate>Unvoid Ticket</a>
        <a mat-menu-item [ngClass]="{'disabled-link': !(row?.jobEvent?.job?.project?.ownerOrganization === user.organization.id)}" (click)="editTrip(row)" translate>Edit Trip</a>
      </action-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div class="no-results" *ngIf="!dataSource.data || !dataSource.data.length">
  <ng-container *ngIf="hideApproved && !loading" translate>
    All Trips for this job have been approved.
  </ng-container>
  <ng-container *ngIf="!hideApproved && !loading" translate>
    No Trips
  </ng-container>
  <ng-container *ngIf="loading" translate>
    Loading Trips
  </ng-container>
</div>
