<form #editCollaborators="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Edit Collaborator</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">
      <div class="selected-shares">
        <!-- apply to all section -->
        <div class="template-wrapper">
          <div class="template">
            <div class="row apply-to-all-row">
              <div class="field trucks" *ngIf="!hasLoadListsEnabled" [style.width.%]="45">
                <div class="label" translate>Requested Amount</div>
                <div class="input-group">
                  <input 
                    type="number" 
                    name="organization_requested_amount"
                    min="0" 
                    [(ngModel)]="collaborationTemplate.requestedAmount" />
                    <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="collaborationTemplate.requestedUnit"
                    (onSelect)="collaborationTemplate.requestedUnit = $event"></dropdown>
                </div>
              </div>

              <div class="field rate-tracking" [style.width.%]="45">
                <div class="label" translate>How is the rate tracked?</div>
                <dropdown [options]="rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [selectedOption]="collaborationTemplate.invoiceType ? (collaborationTemplate.invoiceType | titlecase) : null"
                          (onSelect)="setSelectedTemplateAction(collaborationTemplate, $event)"
                          class="rate-tracking-dropdown"></dropdown>
              </div>

              <div class="field rate" [style.width.%]="45">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input type="number" name="organization_haul_rate" min="0"
                         [(ngModel)]="collaborationTemplate.invoiceRate" />
                  <dropdown [options]="weightOptions"
                            [selectedOption]="collaborationTemplate.invoiceWeightUnit ? (collaborationTemplate.invoiceWeightUnit | titlecase) : null"
                            (onSelect)="setSelectedWeightUnitTemplateAction(collaborationTemplate, $event)"
                            *ngIf="collaborationTemplate.invoiceType === 'weight'" title="-"></dropdown>
                  <div class="add-on" *ngIf="collaborationTemplate.invoiceType !== 'weight'">
                    {{ (collaborationTemplate.invoiceType || '-') | titlecase }}
                  </div>
                </div>
              </div>

              <div class="field notes" [style.width.%]="hasLoadListsEnabled ? 100 : 45">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea [(ngModel)]="collaborationTemplate.notes" name="organization_notes"></textarea>
                </div>
              </div>
            </div>

            <div class="row apply-to-all">
              <button class="btn btn-primary-alt" (click)="applyTemplate(editCollaborators)"
                [ngClass]="{loading: loading}" translate>
                Apply To All
              </button>
            </div>
          </div>
        </div>
        <!-- end apply to all section -->

        <!-- start load list accordion -->
        <div class="loadlist-accordion" *ngIf="loadList.length">
          <div class="loadlist-accordion-header">
            <div translate>LOAD LIST</div>
            <div class="loadlist-accordion-arrow">
              <mat-icon (click)="loadListExpanded=true" *ngIf="!loadListExpanded">expand_more</mat-icon>
              <mat-icon (click)="loadListExpanded=false" *ngIf="loadListExpanded">expand_less</mat-icon>
            </div>
          </div>
          <div class="loadlist-accordion-body" [@expanded]="loadListExpanded ? 'expanded' : 'collapsed'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="loadList">
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.vehicle && row.ruckit.vehicle.description ? '#' + row.ruckit.vehicle.description : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <!-- end load list accordion -->

        <!-- start shares -->
        <div class="option" *ngFor="let share of jobEventShares">
          <h3>{{ share.organization }}</h3>
          <div class="share">
            <div class="row">
              <input required type="hidden" name="share_{{ share.id }}" [(ngModel)]="share.organizationId" />
              <div class="field days">
                <div class="label required" translate>Days Allowed</div>
                <input required name="organization_{{ share.id }}_job_events"
                       type="hidden" [(ngModel)]="share.collaboration.jobevents" />
                <button class="calendar-button btn btn-default">
                  <div class="calendar-icon"></div> {{ (jobEvent.shift1StartTimestamp | date:'mediumDate') || '&nbsp;' }}
                </button>
              </div>

              <!-- if load list -->
              <div class="field loads" *ngIf="loadList.length > 0">
                <div class="label" translate>Loads</div>
                <div class="dropdown">
                  <div class="dropdown-button" [matMenuTriggerFor]="loads">
                    <ng-container *ngIf="share && share.loadSchedule && share.loadSchedule.length; else none_selected">
                      <ng-container *ngFor="let load of share.loadSchedule; let i = index">
                        {{ i === 0 ? load.loadNumber : ', ' + load.loadNumber }}
                      </ng-container>
                    </ng-container>
                    <ng-template #none_selected>
                      <span translate>None Selected</span>
                    </ng-template>
                  </div>
                  <mat-menu #loads="matMenu" class="loadlist-dropdown-menu" [overlapTrigger]="false">
                      <div class="loadlist-load-option" *ngFor="let load of loadList" (click)="$event.stopPropagation()">
                        <mat-checkbox 
                          [checked]="share.loadSchedule | isInArray: load:'loadNumber'"
                          (change)="loadDropdownSelectionChanged($event, load, share, editCollaborators)" 
                          translate
                        >
                          {{ load.loadNumber }} &nbsp;&nbsp; Load Time - {{ load.ticketEvents.LOADING_STARTED | moment: 'h:mm a' }}
                        </mat-checkbox>
                      </div>
                  </mat-menu>
                </div>
              </div>

              <div class="field trucks" *ngIf="!loadList.length">
                <div class="label required" translate>Requested Amount</div>
                <div class="input-group">
                  <input 
                    type="number" 
                    name="share{{ share.id }}_requested_amount"
                    min="0" 
                    [(ngModel)]="share.collaboration.requestedAmount" />
                    <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="share.collaboration.requestedUnit"
                    (onSelect)="share.collaboration.requestedUnit = $event"></dropdown>
                </div>
              </div>

              <div class="field rate-tracking">
                <div class="label" translate>How is the rate tracked?</div>
                <input required type="hidden" name="share_{{ share.id }}_rate_tracking"
                      [(ngModel)]="share.collaboration.invoiceType" />
                <dropdown [options]="rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [disabled]="share.readonly"
                          [selectedOption]="share.collaboration.invoiceType ? (share.collaboration.invoiceType | titlecase) : haulTypeOption"
                          (onSelect)="setSelectedAction(share, $event, editCollaborators)"
                          class="rate-tracking-dropdown"></dropdown>
              </div>
            </div>

            <div class="row">
              <div class="field rate">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input type="number" name="share_{{ share.id }}_haul_rate"
                         [disabled]="share.readonly" min="0"
                         [(ngModel)]="share.collaboration.invoiceRate" />
                  <dropdown [options]="weightOptions"
                            [selectedOption]="share.collaboration.invoiceWeightUnit"
                            [disabled]="share.readonly" 
                            (onSelect)="setSelectedWeightUnit(share, $event, editCollaborators)"
                            title="-"
                            *ngIf="share.collaboration.invoiceType === 'weight' || share.collaboration.invoiceType === 'Weight'"></dropdown>
                  <div class="add-on" *ngIf="share.collaboration.invoiceType !== 'weight' && share.collaboration.invoiceType !== 'Weight'">
                    {{ share.collaboration.invoiceType | titlecase }}
                  </div>
                </div>
              </div>

              <div class="field broker-rate-code">
                <div class="label" translate>What is the broker rate code?</div>
                <input name="share_{{ share.id }}_broker_rate_code"
                       [(ngModel)]="share.collaboration.brokerRateCode" />
              </div>

              <div class="field notes">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea [(ngModel)]="share.collaboration.notes" [disabled]="share.readonly" name="share_{{ share.id }}_notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end shares -->
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="dialogRef.close()"
      [ngClass]="{loading: loading}" translate>
      Cancel
    </button>
    <button class="btn btn-primary save-button"
      (click)="editCollaborators.form.valid && submit(); false"
      [disabled]="!editCollaborators.form.valid || editCollaborators.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Save
    </button>
  </div>
</form>
