import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { CollaboratorsRoutingModule } from './collaborators-routing.module';

import { CollaboratorsComponent } from './collaborators.component';
import { AddCollaboratorComponent } from './add-collaborator.component';
import { CollaboratorListComponent } from './collaborator-list/collaborator-list.component';
import { CollaboratorSummaryComponent } from './collaborator-summary.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CollaboratorsRoutingModule
  ],
  declarations: [
    CollaboratorsComponent,
    AddCollaboratorComponent,
    CollaboratorListComponent,
    CollaboratorSummaryComponent,
  ],
  exports: [
    AddCollaboratorComponent,
    CollaboratorListComponent,
    CollaboratorSummaryComponent
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class CollaboratorsModule { }
