"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MapStatusMarkerComponent = /** @class */ (function () {
    function MapStatusMarkerComponent() {
        this.markerBackground = '';
    }
    MapStatusMarkerComponent.prototype.ngOnInit = function () {
        this.setMarkerBackground();
    };
    MapStatusMarkerComponent.prototype.ngOnChanges = function (changes) {
        this.setMarkerBackground();
    };
    MapStatusMarkerComponent.prototype.setMarkerBackground = function () {
        if (this.markerType === 'driver-initials') {
            this.markerBackground = this.getDriverBackground();
        }
        else {
            this.markerBackground = this.getTruckBackground();
        }
    };
    MapStatusMarkerComponent.prototype.getDriverBackground = function () {
        var status = this.locationUpdate.status || this.locationUpdate.tripStatus;
        var background = 'assets/img/map/truck-marker-unloading.png';
        if (this.markerStatus === 'each-segment') {
            switch (status) {
                case 'enroute_unloading':
                    background = 'assets/img/map/truck-marker-enroute-unloading.png';
                    break;
                case 'unloading':
                    background = 'assets/img/map/truck-marker-unloading.png';
                    break;
                case 'enroute_loading':
                    background = 'assets/img/map/truck-marker-enroute-loading.png';
                    break;
                case 'loading':
                    background = 'assets/img/map/truck-marker-loading.png';
                    break;
                default:
                    background = 'assets/img/map/truck-marker-unloading.png';
                    break;
            }
        }
        else if (this.markerStatus === 'load-status') {
            switch (status) {
                case 'enroute_unloading':
                    background = 'assets/img/map/truck-marker-enroute-unloading.png';
                    break;
                case 'unloading':
                    background = 'assets/img/map/truck-marker-yellow.png';
                    break;
                case 'enroute_loading':
                    background = 'assets/img/map/truck-marker-enroute-loading.png';
                    break;
                case 'loading':
                    background = 'assets/img/map/truck-marker-enroute-unloading.png';
                    break;
                default:
                    background = 'assets/img/map/truck-marker-yellow.png';
                    break;
            }
        }
        return background;
    };
    MapStatusMarkerComponent.prototype.getTruckBackground = function () {
        var status = this.locationUpdate.status || this.locationUpdate.tripStatus;
        var background = 'assets/img/map/truck-number-bubble-green.png';
        if (this.markerStatus === 'each-segment') {
            switch (status) {
                case 'enroute_unloading':
                    background = 'assets/img/map/truck-number-bubble-green.png';
                    break;
                case 'unloading':
                    background = 'assets/img/map/truck-number-bubble-dkgreen.png';
                    break;
                case 'enroute_loading':
                    background = 'assets/img/map/truck-number-bubble-ltblue.png';
                    break;
                case 'loading':
                    background = 'assets/img/map/truck-number-bubble-blue.png';
                    break;
                default:
                    background = 'assets/img/map/truck-number-bubble-dkgreen.png';
                    break;
            }
        }
        else if (this.markerStatus === 'load-status') {
            switch (status) {
                case 'enroute_unloading':
                    background = 'assets/img/map/truck-number-bubble-green.png';
                    break;
                case 'unloading':
                    background = 'assets/img/map/truck-number-bubble-yellow.png';
                    break;
                case 'enroute_loading':
                    background = 'assets/img/map/truck-number-bubble-yellow.png';
                    break;
                case 'loading':
                    background = 'assets/img/map/truck-number-bubble-green.png';
                    break;
                default:
                    background = 'assets/img/map/truck-number-bubble-yellow.png';
                    break;
            }
        }
        return background;
    };
    return MapStatusMarkerComponent;
}());
exports.MapStatusMarkerComponent = MapStatusMarkerComponent;
