"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var map_1 = require("@ngui/map");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ngx_device_detector_1 = require("ngx-device-detector");
var map_service_1 = require("../map.service");
var locationUpdate_service_1 = require("../../jobs/locationUpdate.service");
var driver_serializer_1 = require("../../drivers/driver.serializer");
var authentication_service_1 = require("../../shared/authentication.service");
var static_data_1 = require("../../shared/static-data");
var job_event_1 = require("../../job-events/job-event");
var preference_serializer_1 = require("../../preferences/preference.serializer");
var preference_service_1 = require("../../preferences/preference.service");
var device_1 = require("../../shared/device");
var edit_location_details_component_1 = require("../../locations/edit-location-details/edit-location-details.component");
var key_codes_enum_1 = require("../../shared/enums/key-codes.enum");
var truck_serializer_1 = require("../../trucks/truck.serializer");
var polyline = require('google-polyline');
var JobMapComponent = /** @class */ (function () {
    function JobMapComponent(cdr, mapService, deviceDetectorService, locationUpdateService, authenticationService, preferenceService, dialog) {
        this.cdr = cdr;
        this.mapService = mapService;
        this.deviceDetectorService = deviceDetectorService;
        this.locationUpdateService = locationUpdateService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.dialog = dialog;
        this.onMarkerDragEnd = new core_1.EventEmitter();
        this.device = new device_1.Device();
        this.preference = new preference_serializer_1.PreferenceSerializer().fromJson({
            blob: { mapStyle: 'ruckit-map-style' }
        });
        this.preferenceReqs = {};
        this.loadingMapPreferences = true;
        this.enableLocationEditMode = false;
        this.drawingMode = undefined;
        this.polygons = [];
        this.routePolyline = [];
        this.hours = [];
        this.customLocationMarkers = [];
        this.customUpdateMarkers = [];
        this.customPaverUpdateMarkers = [];
        this.heatmapPoints = [];
        this.mapCentered = false;
        this.markerType = 'driver-initials';
        this.markerStatus = 'each-segment';
        this.assignments = {
            items: [],
            errors: [],
            loading: false,
        };
        this.mapControls = {
            stats: true,
            traffic: false,
            heatmap: false,
            heatmapRadius: 20
        };
        this.search = '';
        this.searching = false;
        this.searchChanged = new rxjs_1.Subject();
        this.searchResultsOpen = false;
        this.loading = true;
        this.errors = [];
        this.paverLocationUpdates = [];
        this.locationUpdates = [];
        this.locations = [];
        this.routes = [];
        this.parsedRoutes = [];
        this.coordinates = [];
        this.mapOptions = static_data_1.mapOptions({
            zoom: 10,
            disableDefaultUI: true,
            fullscreenControl: true,
            streetViewControl: false,
            mapTypeControl: true
        });
        this.direction = {
            origin: '',
            destination: '',
            travelMode: 'DRIVING'
        };
        this.polylineOptions = {
            strokeColor: '#002649',
            strokeWeight: '4',
            strokeOpacity: 0.6
        };
        this.driverListOpen = false;
        this.jobOverviewOpen = false;
        this.assignmentDetails = {};
        this.isGeoFenceAvailable = false;
    }
    JobMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.loading = true;
        if (this.jobEvent) {
            this.jobEventDate = new Date(this.jobEvent.shift1StartTimestamp);
            this.plotPolygons();
            this.mapOptions['center'] = this.jobEvent.mapCenterCoordinates;
            if (this.jobEvent.job && this.jobEvent.job.startLocationMappable &&
                this.jobEvent.job.endLocationMappable &&
                this.jobEvent.startCoordinates && this.jobEvent.endCoordinates) {
                this.direction = {
                    origin: this.jobEvent.startCoordinates.latitude + ', ' + this.jobEvent.startCoordinates.longitude,
                    destination: this.jobEvent.endCoordinates.latitude + ', ' + this.jobEvent.endCoordinates.longitude,
                    travelMode: 'DRIVING'
                };
            }
        }
        var currentOrganization = this.authenticationService.getOrganization();
        if (currentOrganization) {
            this.getPreferences('GeneralOrganizationPreferences', {
                name: 'GeneralOrganizationPreferences',
                type: 'organization',
                organization: currentOrganization.id
            });
        }
        this.getPreferences('GeneralUserPreferences');
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.mapCentered = false;
            _this.search = search;
            _this.getMapLocationUpdates(true);
        });
    };
    JobMapComponent.prototype.ngOnDestroy = function () {
        if (this.locationUpdateSub) {
            try {
                this.locationUpdateSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.assignments && this.assignments.items.length) {
            try {
                this.assignments.items.forEach(function (assignment) {
                    if (assignment.durationTimer) {
                        assignment.durationTimer.unsubscribe();
                    }
                });
            }
            catch (e) { }
        }
        if (this.statusChangeTimerSub) {
            try {
                this.statusChangeTimerSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        if (this.preferenceReq && typeof this.preferenceReq.unsubscribe === 'function') {
            this.preferenceReq.unsubscribe();
        }
    };
    JobMapComponent.prototype.getPreferences = function (preferenceKey, params) {
        var _this = this;
        if (preferenceKey === void 0) { preferenceKey = 'GeneralUserPreferences'; }
        if (params === void 0) { params = null; }
        if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
            try {
                this.preferenceReqs[preferenceKey].unsubscribe();
            }
            catch (e) { }
        }
        var currentUser = this.authenticationService.user();
        if (!params) {
            params = {
                name: preferenceKey,
                type: 'user',
                profile: currentUser.id
            };
        }
        this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                _this.parsePreferences(preferenceKey);
            }
            else if (preferenceKey === 'GeneralUserPreferences') {
                _this.loadingMapPreferences = false;
                _this.parsePreferences(preferenceKey);
            }
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loadingMapPreferences = false;
        });
    };
    /**
     * Examine the found preference for the provided key to determine if it has
     * the necessary data.
     *
     * Once the preferences data is handled, update the map
     */
    JobMapComponent.prototype.parsePreferences = function (preferenceKey) {
        if (preferenceKey === void 0) { preferenceKey = 'JobDetail'; }
        if (preferenceKey === 'GeneralOrganizationPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preference && this.preference.blob && this.preference.blob['mapStyle']) {
                mapStyle = this.preference.blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preference && this.preference.blob && this.preference.blob['markerType']) {
                this.markerType = this.preference.blob['markerType'];
            }
            this.markerStatus = 'each-segment';
            if (this.preference && this.preference.blob && this.preference.blob['markerStatus']) {
                this.markerStatus = this.preference.blob['markerStatus'];
            }
            this.loadingMapPreferences = false;
            this.mapOptions = static_data_1.mapOptions({
                disableDefaultUI: true,
                scrollwheel: true,
                fullscreenControl: true,
                streetViewControl: false
            }, {}, {
                mapStyle: mapStyle
            });
        }
        if (preferenceKey === 'GeneralUserPreferences') {
            var mapStyle = 'ruckit-map-style';
            if (this.preference && this.preference.blob && this.preference.blob['mapStyle']) {
                mapStyle = this.preference.blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preference && this.preference.blob && this.preference.blob['markerType']) {
                this.markerType = this.preference.blob['markerType'];
            }
            if (!this.markerStatus) {
                this.markerStatus = 'each-segment';
            }
            if (this.preference && this.preference.blob && this.preference.blob['markerStatus']) {
                this.markerStatus = this.preference.blob['markerStatus'];
            }
            this.mapOptions = static_data_1.mapOptions({
                zoom: 10,
                disableDefaultUI: true,
                fullscreenControl: true,
                streetViewControl: false,
                mapTypeControl: true,
                center: this.jobEvent.mapCenterCoordinates
            }, {}, {
                mapStyle: mapStyle
            });
            this.triggerMapUpdate();
        }
    };
    JobMapComponent.prototype.onSearchboxKeyPress = function (event) {
        if (event.code === key_codes_enum_1.KeyCodes.Enter) {
            this.searchChanged.next(this.search);
            this.assignmentDetails = {};
            event.preventDefault();
        }
    };
    JobMapComponent.prototype.clearSearch = function () {
        this.searching = false;
        this.search = '';
        this.getMapLocationUpdates();
        if (this.nguiMapComponent) {
            this.nguiMapComponent.closeInfoWindow('driver-info-window');
        }
    };
    JobMapComponent.prototype.plotPolygons = function () {
        this.polygons = [];
        if (this.jobEvent && this.jobEvent.job) {
            if (this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.paths && this.jobEvent.job.startLocation.paths.length) {
                this.polygons.push({ paths: this.jobEvent.job.startLocation.paths });
            }
            if (this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.paths && this.jobEvent.job.endLocation.paths.length) {
                this.polygons.push({ paths: this.jobEvent.job.endLocation.paths });
            }
        }
    };
    JobMapComponent.prototype.getMapLocationUpdates = function (openInfoWindow) {
        var _this = this;
        if (openInfoWindow === void 0) { openInfoWindow = false; }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        this.mapReq = this.mapService.getLocationUpdates({
            driver__assignments__jobevent: this.jobEvent && this.jobEvent.id,
            ordering: 'jobs__name',
            search: this.search
        }).subscribe(function (locationUpdates) {
            _this.resetMarkers();
            if (locationUpdates.locationUpdates) {
                _this.locationUpdates = locationUpdates.locationUpdates.map(function (update) {
                    if (update.driver) {
                        update.driver = new driver_serializer_1.DriverSerializer().fromJson(update.driver);
                    }
                    return update;
                });
            }
            if (locationUpdates.paverLocationUpdates) {
                _this.paverLocationUpdates = locationUpdates.paverLocationUpdates.map(function (update) {
                    if (update.truck) {
                        update.truck = new truck_serializer_1.TruckSerializer().fromJson(update.truck);
                    }
                    return update;
                });
            }
            if (!_this.mapCentered) {
                _this.centerMap();
                _this.mapCentered = true;
            }
            if (openInfoWindow && _this.search && _this.search.length &&
                _this.locationUpdates.length === 1 && window.innerWidth > 900) {
                _this.openDriverInfoWindow(0, _this.locationUpdates[0]);
            }
            else if (!_this.search.length || _this.locationUpdates.length === 0) {
                if (_this.nguiMapComponent) {
                    _this.nguiMapComponent.closeInfoWindow('driver-info-window');
                }
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    JobMapComponent.prototype.triggerMapUpdate = function () {
        var _this = this;
        this.getMapLocationUpdates();
        var jobDetailMapRefresh = this.authenticationService.getFeature('jobDetailMapRefresh') || 300000;
        this.locationUpdateTimer = rxjs_1.timer(jobDetailMapRefresh, jobDetailMapRefresh);
        this.locationUpdateSub = this.locationUpdateTimer.subscribe(function (t) {
            _this.getMapLocationUpdates();
        });
    };
    JobMapComponent.prototype.updateHeatmap = function () {
        var _this = this;
        this.locationUpdateService.coordinates(this.map.getBounds(), {
            jobevent: this.jobEvent && this.jobEvent.id,
            page_size: 2500
        }).subscribe(function (coordinates) {
            _this.coordinates = coordinates;
            _this.heatmapPoints = coordinates && coordinates.map(function (coordinate) {
                return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
            });
            if (_this.heatmapPoints && _this.heatmapPoints.length > 0) {
                _this.heatmap.setData(_this.heatmapPoints);
            }
            _this.cdr.detectChanges();
        }, function (err) { console.error(err); });
    };
    JobMapComponent.prototype.onHeatmapInitialized = function (e) {
        if (e === void 0) { e = null; }
        this.heatmap = e;
        this.coordinates = [];
        this.updateHeatmap();
    };
    JobMapComponent.prototype.onMapIdle = function (e) {
        if (e === void 0) { e = null; }
        if (this.heatmap) {
            this.updateHeatmap();
        }
    };
    JobMapComponent.prototype.initMap = function (event) {
        if (event) {
            this.map = event;
            this.initDrawingManager();
        }
        if (this.jobEvent.job.routePolyline) {
            this.routePolyline = polyline.decode(this.jobEvent.job.routePolyline).map(function (path) {
                return new google.maps.LatLng(path[0], path[1]);
            });
        }
    };
    JobMapComponent.prototype.onMapLoaded = function (e) {
        if (e === void 0) { e = null; }
        if (e) {
            this.map = e.target.map;
        }
    };
    JobMapComponent.prototype.centerMap = function (withPadding) {
        if (withPadding === void 0) { withPadding = true; }
        var _centerOnStartLocation = true;
        if (this.map) {
            var bounds_1 = new google.maps.LatLngBounds();
            var latLngList = [];
            var updates = this.locationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            var paverUpdates = this.paverLocationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            if (this.jobEvent && this.jobEvent.startCoordinates && this.jobEvent.endCoordinates) {
                latLngList = [
                    this.jobEvent.job.startLocationMappable ?
                        new google.maps.LatLng(this.jobEvent.startCoordinates.latitude, this.jobEvent.startCoordinates.longitude) : null,
                    this.jobEvent.job.endLocationMappable ?
                        new google.maps.LatLng(this.jobEvent.endCoordinates.latitude, this.jobEvent.endCoordinates.longitude) : null
                ].concat(updates, paverUpdates);
            }
            for (var i = 0, LtLgLen = latLngList.length; i < LtLgLen; i++) {
                var list = latLngList[i];
                // if (list[0] && list[1]) {
                bounds_1.extend(list);
                // }
            }
            this.polygons = [];
            if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.geofence) {
                if (this.jobEvent.job.startLocation.paths && this.jobEvent.job.startLocation.paths.length) {
                    this.polygons.push({ paths: this.jobEvent.job.startLocation.paths });
                    this.jobEvent.job.startLocation.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds_1.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
            }
            if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.geofence) {
                if (this.jobEvent.job.endLocation.paths && this.jobEvent.job.endLocation.paths.length) {
                    this.polygons.push({ paths: this.jobEvent.job.endLocation.paths });
                    this.jobEvent.job.endLocation.paths.forEach(function (path) {
                        if (path.lat && path.lng) {
                            bounds_1.extend(new google.maps.LatLng(path.lat, path.lng));
                        }
                    });
                }
            }
            if (this.map.getZoom() > 17) {
                this.map.setZoom(17);
            }
            if (latLngList && latLngList.length || this.polygons && this.polygons.length) {
                _centerOnStartLocation = false;
            }
            if (withPadding) {
                var padding = { left: 300, right: 20 };
                this.map.fitBounds(bounds_1, padding);
            }
            else {
                this.map.fitBounds(bounds_1);
            }
        }
    };
    JobMapComponent.prototype.centerOnStartLocation = function () {
        if (this.jobEvent && this.jobEvent.job) {
            var fallbackCenter = new google.maps.LatLng(this.jobEvent.job.startCoordinates.latitude, this.jobEvent.job.startCoordinates.longitude);
            this.mapOptions = static_data_1.mapOptions({
                zoom: 10,
                center: fallbackCenter,
                disableDefaultUI: true,
                geoFallbackCenter: fallbackCenter
            });
        }
    };
    JobMapComponent.prototype.onCustomLocationMarkerInit = function (customMarker) {
        this.customLocationMarkers.push(customMarker);
    };
    JobMapComponent.prototype.onCustomUpdateMarkerInit = function (customMarker) {
        this.customUpdateMarkers.push(customMarker);
    };
    JobMapComponent.prototype.onCustomPaverUpdateMarkerInit = function (customMarker) {
        this.customPaverUpdateMarkers.push(customMarker);
    };
    JobMapComponent.prototype.openDriverInfoWindow = function (index, update) {
        this.driverInfoWindowModel = update;
        if (update && update.driver) {
            var markers = lodash_1.filter(this.customUpdateMarkers, function (m) {
                return m.htmlEl.id === update.driver.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('driver-info-window', markers[0]);
            }
        }
    };
    JobMapComponent.prototype.openTruckInfoWindow = function (index, update) {
        this.truckInfoWindowModel = update;
        if (update && update.truck) {
            var markers = lodash_1.filter(this.customPaverUpdateMarkers, function (m) {
                return m.htmlEl.id === update.truck.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('truck-info-window', markers[0]);
            }
        }
    };
    JobMapComponent.prototype.openLocationInfoWindow = function (location, index) {
        this.selectedLocation = location;
        var markers = lodash_1.filter(this.customLocationMarkers, function (m) {
            return m.htmlEl.id === location.id;
        });
        if (markers && markers.length) {
            this.nguiMapComponent.openInfoWindow('location-info-window', markers[0]);
        }
        this.isGeoFenceAvailable = !!this.selectedLocation.geofence;
    };
    JobMapComponent.prototype.toggleStats = function () {
        if (this.mapControls) {
            this.mapControls.stats = !this.mapControls.stats;
        }
        this.cdr.detectChanges();
    };
    JobMapComponent.prototype.toggleTraffic = function () {
        if (this.mapControls) {
            this.mapControls.traffic = !this.mapControls.traffic;
        }
        this.cdr.detectChanges();
    };
    JobMapComponent.prototype.toggleHeatmap = function () {
        if (this.mapControls) {
            this.mapControls.heatmap = !this.mapControls.heatmap;
        }
        this.cdr.detectChanges();
    };
    JobMapComponent.prototype.changeHeatmapRadius = function (e) {
        if (e === void 0) { e = null; }
        if (e) {
            if (this.mapControls) {
                this.mapControls.heatmapRadius = e.target.value;
                this.heatmap.set('radius', e.target.value);
                // this.cdr.detectChanges();
            }
        }
    };
    JobMapComponent.prototype.openAssignmentDetails = function (update, index) {
        if (window.innerWidth < 900) {
            var latLng = void 0;
            this.assignmentDetails = {};
            // if map marker triggers this method
            if (update && update.location && update.driver && update.driver.id) {
                // this.driverListOpen = true;
                this.assignmentDetails = lodash_1.find(this.assignments.items, { driver: { id: update.driver.id } });
                if (update.location.latitude && update.location.longitude) {
                    latLng = new google.maps.LatLng(parseFloat(update.location.coordinates[1]), parseFloat(update.location.coordinates[0]));
                }
                // else if a list item triggers this method
            }
            else if (update && update.shift && update.driver && update.driver.id) {
                this.assignmentDetails = update;
                var locationUpdate = lodash_1.find(this.locationUpdates, { driver: { id: update.driver.id } });
                if (locationUpdate && locationUpdate.location &&
                    locationUpdate.location.coordinates &&
                    locationUpdate.location.coordinates.length === 2) {
                    latLng = new google.maps.LatLng(parseFloat(locationUpdate.location.coordinates[1]), parseFloat(locationUpdate.location.coordinates[0]));
                }
            }
            if (latLng && this.map && typeof this.map.setCenter === 'function') {
                this.map.setCenter(latLng);
                this.map.setZoom(17);
            }
        }
        else {
            this.openDriverInfoWindow(index, update);
        }
    };
    JobMapComponent.prototype.showSearch = function () {
        var _this = this;
        this.searching = true;
        setTimeout(function () {
            if (_this.mapSearch) {
                _this.mapSearch.nativeElement.focus();
            }
        }, 300);
    };
    JobMapComponent.prototype.resetMarkers = function () {
        this.customUpdateMarkers = [];
        this.customPaverUpdateMarkers = [];
    };
    JobMapComponent.prototype.replaceLocation = function (location) {
        if (this.jobEvent && this.jobEvent.job) {
            if (this.jobEvent.job.startLocation && this.jobEvent.job.startLocation.id === location.id) {
                this.jobEvent.job.startLocation = location;
                this.jobEvent.startCoordinates = {
                    longitude: location.longitude, latitude: location.latitude
                };
            }
            if (this.jobEvent.job.endLocation && this.jobEvent.job.endLocation.id === location.id) {
                this.jobEvent.job.endLocation = location;
                this.jobEvent.endCoordinates = {
                    longitude: location.longitude, latitude: location.latitude
                };
            }
            this.direction = {
                origin: this.jobEvent.startCoordinates.latitude + ', ' + this.jobEvent.startCoordinates.longitude,
                destination: this.jobEvent.endCoordinates.latitude + ', ' + this.jobEvent.endCoordinates.longitude,
                travelMode: 'DRIVING'
            };
        }
    };
    JobMapComponent.prototype.placeChanged = function (location) {
        this.replaceLocation(location);
        this.centerMap(false);
    };
    JobMapComponent.prototype.setDrawingManagerOptions = function () {
        var options = __assign({ 'drawingControl': this.enableLocationEditMode }, this.drawingManagerInstance.drawingControlOptions);
        this.drawingManagerInstance.setOptions(options);
    };
    JobMapComponent.prototype.toggleLocationEditMode = function (location) {
        var _this = this;
        this.enableLocationEditMode = !this.enableLocationEditMode;
        if (this.drawingManager) {
            this.setDrawingManagerOptions();
        }
        if (!this.enableLocationEditMode) {
            if (location) {
                this.replaceLocation(location);
            }
            this.drawingMode = undefined;
            if (this.map) {
                this.map.data.forEach(function (feature) {
                    _this.deleteSelectedOverlay(_this.map, feature, location);
                });
            }
            this.selectedLocation = null;
            if (this.polygon) {
                this.polygon.setMap(null);
                this.polygon = null;
            }
            this.centerMap();
        }
        else {
            this.polygons = [];
            this.nguiMapComponent.closeInfoWindow('location-info-window');
            var latLng = new google.maps.LatLng(parseFloat(location.latitude), parseFloat(location.longitude));
            if (latLng && this.map) {
                this.map.panTo(latLng);
                this.map.setZoom(16);
            }
            if (!this.polygon) {
                this.polygon = new google.maps.Polygon({
                    paths: this.selectedLocation && this.selectedLocation.paths || [],
                    strokeColor: '#FF8C00',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF8C00',
                    fillOpacity: 0.35,
                    editable: true,
                    draggable: true
                });
                this.polygon.setMap(this.map);
            }
            google.maps.event.addListener(this.polygon, 'mouseup', function (e) {
                _this.drawingComplete(_this.polygon);
            });
            google.maps.event.addListener(this.polygon, 'rightclick', function (e) {
                _this.polygon.setMap(null);
                _this.selectedLocation.geofence = null;
            });
        }
    };
    JobMapComponent.prototype.initDrawingManager = function () {
        var _this = this;
        if (this.polygon) {
            this.polygon.setMap(null);
        }
        if (this.drawingManager) {
            this.drawingManager['initialized$'].subscribe(function (dm) {
                _this.drawingManagerInstance = dm;
                dm.setDrawingMode(null);
                _this.setDrawingManagerOptions();
                google.maps.event.addListener(dm, 'overlaycomplete', function (event) {
                    dm.map.data.setStyle({
                        editable: true,
                        draggable: true
                    });
                    if (event.type !== google.maps.drawing.OverlayType.MARKER) {
                        _this.drawingComplete(event.overlay);
                        dm.setDrawingMode(null);
                    }
                });
            });
        }
    };
    JobMapComponent.prototype.drawingComplete = function (overlay) {
        var _this = this;
        var map = overlay.map;
        map.data.add(new google.maps.Data.Feature({
            geometry: new google.maps.Data.Polygon([overlay.getPath().getArray()])
        }));
        overlay.setMap(null); // Remove the original overlay from the map
        map.data.toGeoJson(function (json) { return _this.selectedGeoFence = json.features; });
        map.data.forEach(function (feature) {
            map.data.overrideStyle(feature, {
                strokeColor: '#FF8C00',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF8C00',
                fillOpacity: 0.35,
                editable: true,
                draggable: true
            });
        });
        this.isGeoFenceAvailable = true;
        map.data.addListener('click', function (event) {
            // this.setSelectedOverlay(map, event.feature);
        });
        map.data.addListener('rightclick', function (event) {
            _this.deleteSelectedOverlay(map, event.feature, null);
        });
        map.data.addListener('setgeometry', function (event) {
            // this.setSelectedOverlay(map, event.feature);
            console.log('setgeometry');
            map.data.toGeoJson(function (json) { return _this.selectedGeoFence = json.features; });
        });
    };
    JobMapComponent.prototype.deleteSelectedOverlay = function (map, overlay, location) {
        var _this = this;
        if (overlay === void 0) { overlay = null; }
        this.isGeoFenceAvailable = false;
        if (overlay) {
            map.data.remove(overlay);
            map.data.toGeoJson(function (json) { return _this.selectedGeoFence = json.features; });
            this.isGeoFenceAvailable = !!this.selectedGeoFence;
            if (location) {
                map.data.toGeoJson(function (json) { return location.geofence = json.features; });
            }
        }
    };
    JobMapComponent.prototype.updatePaths = function (pathArray) {
        this.selectedLocation.paths = pathArray.map(function (path) {
            return { lat: path.lat(), lng: path.lng() };
        });
        if (this.selectedLocation.paths[0] === this.selectedLocation.paths.reverse[0]) {
            console.log('Valid GeoJSON');
        }
        else {
            console.log('Invalid GeoJSON!');
        }
    };
    JobMapComponent.prototype.markerDragEnd = function (event) {
        if (event.coords) {
            this.selectedLocation.latitude = event.coords.lat;
            this.selectedLocation.longitude = event.coords.lng;
        }
        else if (event.latLng) {
            this.selectedLocation.latitude = event.latLng.lat();
            this.selectedLocation.longitude = event.latLng.lng();
        }
        this.onMarkerDragEnd.emit(true);
        if (this.editLocationDetails) {
            this.editLocationDetails.reverseGeocode();
        }
    };
    JobMapComponent.prototype.onClearGeoFence = function () {
        var _this = this;
        if (this.map) {
            if (this.polygon) {
                this.polygon.setMap(null);
                this.polygon = null;
            }
            this.map.data.forEach(function (feature) {
                _this.map.data.remove(feature);
            });
            this.selectedLocation.geofence = null;
        }
        this.selectedGeoFence = null;
        this.isGeoFenceAvailable = false;
    };
    return JobMapComponent;
}());
exports.JobMapComponent = JobMapComponent;
