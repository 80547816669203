"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment-timezone");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var forms_1 = require("@angular/forms");
var job_service_1 = require("../jobs/job.service");
var authentication_service_1 = require("../shared/authentication.service");
var api_service_1 = require("../shared/api.service");
var EditJobDialogComponent = /** @class */ (function () {
    function EditJobDialogComponent(dialogRef, data, jobService, authenticationService, elementRef) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.jobService = jobService;
        this.authenticationService = authenticationService;
        this.elementRef = elementRef;
        this.completed = new core_1.EventEmitter();
        this.errors = [];
        this.existingDates = [];
        this.selectedDates = [];
        this.loading = false;
        this.shiftValid = false;
        this.secondShift = false;
        this.isCreator = false;
        this.model = {
            dates: []
        };
        this.job = this.data && this.data.job;
        this.jobId = this.data && this.data.jobId;
    }
    EditJobDialogComponent.prototype.ngOnInit = function () {
        if (this.jobId) {
            this.getJob();
        }
        else if (this.job) {
            this.checkShiftValidity();
            this.processJob();
        }
    };
    EditJobDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
            this.jobDaysReq.unsubscribe();
        }
    };
    EditJobDialogComponent.prototype.getJob = function () {
        var _this = this;
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        this.loading = true;
        this.jobReq = this.jobService.get(this.jobId).subscribe(function (job) {
            _this.job = job;
            _this.checkShiftValidity();
            _this.processJob();
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditJobDialogComponent.prototype.processJob = function () {
        var myOrganization = this.authenticationService.getOrganization();
        if (myOrganization && this.job && this.job.project) {
            this.isCreator = this.job.project.ownerOrganization === myOrganization.id;
        }
        this.getJobDays();
    };
    EditJobDialogComponent.prototype.onDatesChanged = function (dates) {
        this.model.dates = dates;
        this.selectedDates = this.model.dates.map(function (d) { return moment(d).toDate(); });
        if (this.editJobDays && this.editJobDays.form) {
            this.editJobDays.form.markAsTouched();
        }
    };
    EditJobDialogComponent.prototype.checkShiftValidity = function () {
        if (!this.job) {
            this.shiftValid = false;
        }
        else if (this.job.shift1StartTime === null) {
            this.shiftValid = this.model['shift1_start_time'] && this.model['shift1_end_time'];
        }
        else {
            this.shiftValid = true;
            this.checkIfOvernight('shift1');
            this.checkIfOvernight('shift2');
        }
    };
    EditJobDialogComponent.prototype.checkIfOvernight = function (shift) {
        if (shift === 'shift1' && this.job.shift1StartTime && this.job.shift1EndTime) {
            var startTime = moment(this.job.shift1StartTime, 'h:mm a');
            var endTime = moment(this.job.shift1EndTime, 'h:mm a');
            this.job.shift1Overnight = endTime.isBefore(startTime);
        }
        else if (shift === 'shift2' && this.job.shift2StartTime && this.job.shift2EndTime) {
            var startTime = moment(this.job.shift2StartTime, 'h:mm a');
            var endTime = moment(this.job.shift2EndTime, 'h:mm a');
            this.job.shift2Overnight = endTime.isBefore(startTime);
        }
    };
    EditJobDialogComponent.prototype.submit = function () {
        var _this = this;
        var addedDates;
        if (this.model.dates.length > 0) {
            addedDates = this.model.dates.filter(function (d) {
                return !_this.existingDates.map(function (date) { return moment(date).format('YYYY-MM-DD'); }).includes(d);
            });
            this.model.dates = this.model.dates.map(function (d) { return moment(d).format('YYYY-MM-DD'); });
        }
        else {
            this.model.dates = [];
        }
        if (this.model['shift1_start_time']) {
            // Convert shift times to 24-hour format
            this.model['shift1_start_time'] = moment(this.model['shift1_start_time'], ['h:mm A']).format('HH:mm');
            this.model['shift1_end_time'] = moment(this.model['shift1_end_time'], ['h:mm A']).format('HH:mm');
            if (this.model['shift2_start_time']) {
                this.model['shift2_start_time'] = moment(this.model['shift2_start_time'], ['h:mm A']).format('HH:mm');
            }
            if (this.model['shift2_end_time']) {
                this.model['shift2_end_time'] = moment(this.model['shift2_end_time'], ['h:mm A']).format('HH:mm');
            }
        }
        this.jobService.updateDays(this.job.id, this.model).subscribe(function (result) {
            _this.loading = false;
            _this.dialogRef.close();
            _this.callback(addedDates);
        }, function (err) {
            _this.errors = ['Job could not be updated.'];
            _this.errors.push(api_service_1.parseErrors(err));
            _this.loading = false;
        });
    };
    EditJobDialogComponent.prototype.getJobDays = function () {
        var _this = this;
        if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
            this.jobDaysReq.unsubscribe();
        }
        this.loading = true;
        this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(function (days) {
            _this.existingDates = days.map(function (day) { return moment(day).toDate(); });
            _this.selectedDates = lodash_1.clone(_this.existingDates);
            _this.model.dates = days.map(function (day) { return moment(day).format('YYYY-MM-DD'); });
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    return EditJobDialogComponent;
}());
exports.EditJobDialogComponent = EditJobDialogComponent;
