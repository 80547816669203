import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ResourceService } from '../shared/resource.service';
import { Assignment } from './assignment';
import { AssignmentSerializer } from './assignment.serializer';
import { requestHeaders, handleError } from '../shared/index';

@Injectable()
export class AssignmentService extends ResourceService<Assignment> {

  constructor(http: HttpClient) {
    super(http, 'assignments/all/', new AssignmentSerializer());
  }

  saveBulkAssignments(body: {}): Observable<{errors: any, assignments: Assignment[]}> {
    if (body) {
      const assigmentBulkUrl = this.baseUrl + 'assignments/';
      return this.http.post(assigmentBulkUrl, body, {
        headers: requestHeaders()
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkCreate(assignments: Assignment[], query?: any): Observable<{errors: any, assignments: Assignment[]}> {
    if (assignments && assignments.length) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const _assignments = assignments.map(assignment => {
        return new AssignmentSerializer().toJson(assignment);
      });
      const assigmentBulkUrl = this.baseUrl + 'assignments/';
      return this.http.post(assigmentBulkUrl, _assignments, {
        headers: requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkUpdate(assignments: Assignment[], query?: any): Observable<{errors: any, assignments: Assignment[]}> {
    if (assignments && assignments.length) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const _assignments = assignments.map(assignment => {
        return new AssignmentSerializer().toJson(assignment);
      });
      const assigmentBulkUrl = this.baseUrl + 'assignments/bulk-update/';
      return this.http.put(assigmentBulkUrl, _assignments, {
        headers: requestHeaders(),
        params: params
      }).pipe(
        map(res => this.convertBulkResponse(res)),
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  bulkRemove(recordIds: string[], query?: any): Observable<any> {
    if (recordIds) {
      let params: HttpParams = new HttpParams();
      if (query) {
        Object.keys(query).forEach((key) => {
          if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
            params = params.set(key, query[key].toString());
          }
        });
      }
      const assigmentBulkUrl = this.baseUrl + 'assignments/bulk-delete/';
      return this.http.request('delete', assigmentBulkUrl, {
        body: recordIds,
        headers: requestHeaders(),
        params: params
      }).pipe(
        catchError(err => observableThrowError(this.convertBulkResponse(err.error)))
      );
    }
  }

  copyAssignment(body: {}): Observable<any> {
    if (body) {
      const assigmentCopyUrl = this.baseUrl + 'assignments/copy/';
      return this.http.post(assigmentCopyUrl, body, {
        headers: requestHeaders()
      }).pipe(
        map(res => { return res; }),
        catchError(handleError)
      );
    }
  }

  private convertBulkResponse(res: any): {errors: any, assignments: Assignment[]} {
    let errors = [];
    let assignments: Assignment[] = [];
    if (res && res.errors) { errors = res.errors; }
    if (res && res.success) {
      assignments = res.success.map(record => new AssignmentSerializer().fromJson(record));
    }

    return { errors: errors, assignments: assignments };
  }
}
