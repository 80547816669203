"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var api_service_1 = require("../shared/api.service");
var job_service_1 = require("../jobs/job.service");
var trip_service_1 = require("../trips/trip.service");
var invoice_service_1 = require("./invoice.service");
var trip_filters_dialog_component_1 = require("../trips/trip-filters-dialog.component");
var view_signatures_dialog_component_1 = require("../checkins/view-signatures-dialog.component");
var index_1 = require("../shared/dialogs/index");
var organization_service_1 = require("../organizations/organization.service");
var app_constants_1 = require("../app.constants");
var InvoiceTripsDialogComponent = /** @class */ (function () {
    function InvoiceTripsDialogComponent(route, router, jobService, tripService, invoiceService, organizationService, dialog, dialogRef) {
        this.route = route;
        this.router = router;
        this.jobService = jobService;
        this.tripService = tripService;
        this.invoiceService = invoiceService;
        this.organizationService = organizationService;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.jobsConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.selectedCount = 0;
        this.trips = [];
        this.allSelected = false;
        this.selectedTrips = [];
        this.excludeTrips = [];
        this.loading = true;
        this.errors = [];
        this.routeToInvoice = true;
        this.search = '';
        this.sortBy = '';
        this.sortAsc = true;
        this.filters = [];
        this.startDate = null;
        this.endDate = null;
        this.customers = [];
        this.customersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false
        };
        this.menuOptions = [
            { name: 'Edit', action: 'edit', link: true },
            { name: 'Add to Invoice', action: 'add', link: false },
            { name: 'Void', action: 'void', link: false }
        ];
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
    }
    InvoiceTripsDialogComponent.prototype.ngOnInit = function () {
        this.getJob();
        this.getCustomer();
        this.getJobs();
        this.getTrips();
        this.getCustomers('');
    };
    InvoiceTripsDialogComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.tripService.getNextCondensed();
            if (o) {
                this.loading = true;
                o.subscribe(function (trips) {
                    _this.trips = _this.trips.concat(trips);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    InvoiceTripsDialogComponent.prototype.getTrips = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.trips = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        if (this.tripReq) {
            this.tripReq.unsubscribe();
        }
        if (this.startDate) {
            var date = new Date(this.startDate);
            date.setHours(0, 0, 0, 0);
            query['start_time__gte'] = date.toISOString();
        }
        if (this.endDate) {
            var date = new Date(this.endDate);
            date.setHours(23, 59, 59, 999);
            query['start_time__lte'] = date.toISOString();
        }
        this.tripFilters = __assign({ ordering: order, search: this.search, invoiceable: 'True', exclude_cf: 'True', invoiceable_to_customer: this.customerId, jobevent__job: this.jobId }, filters, query);
        this.tripReq = this.tripService.getCondensedTrips(this.tripFilters).subscribe(function (trips) {
            if (append) {
                _this.trips = _this.trips.concat(trips);
            }
            else {
                _this.trips = trips;
            }
            _this.count = _this.tripService.count;
            _this.loading = false;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    InvoiceTripsDialogComponent.prototype.getJob = function () {
        var _this = this;
        if (this.jobId) {
            this.jobService.get(this.jobId).subscribe(function (job) {
                _this.job = job;
            }, function (err) { return console.error(err); });
        }
    };
    InvoiceTripsDialogComponent.prototype.getCustomer = function () {
        var _this = this;
        if (this.customerId && !this.customer) {
            this.organizationService.getOrganization(this.customerId).subscribe(function (res) {
                _this.customer = res;
            }, function (err) { return console.error(err); });
        }
    };
    InvoiceTripsDialogComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getTrips({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    InvoiceTripsDialogComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getTrips((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    InvoiceTripsDialogComponent.prototype.openSignatures = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            var dialog = _this.dialog.open(view_signatures_dialog_component_1.ViewSignaturesDialogComponent, {
                width: '722px',
                data: {
                    trip: _trip,
                    callback: function () { return _this.getTrips(); }
                }
            });
        }, function (err) { return console.error(err); });
    };
    InvoiceTripsDialogComponent.prototype.openAddTrip = function () {
        // this.newTrip = true;
        // const dialog = this.dialog.open(NewTripDialogComponent, {
        //   width: '430px'
        // });
        // dialog.componentInstance.callback = this.saveTripCallback;
    };
    InvoiceTripsDialogComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getTrips();
    };
    InvoiceTripsDialogComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    InvoiceTripsDialogComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(trip_filters_dialog_component_1.TripFiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        var startDate = lodash_1.get(lodash_1.find(this.filters, { key: 'startDate' }), 'value');
        if (startDate) {
            dialog.componentInstance.model.startDate = startDate;
        }
        var endDate = lodash_1.get(lodash_1.find(this.filters, { key: 'endDate' }), 'value');
        if (endDate) {
            dialog.componentInstance.model.endDate = endDate;
        }
        dialog.componentInstance.model.job = lodash_1.get(lodash_1.find(this.filters, { key: 'job' }), 'value');
        dialog.componentInstance.model.project = lodash_1.get(lodash_1.find(this.filters, { key: 'project' }), 'value');
        dialog.componentInstance.model.customer = lodash_1.get(lodash_1.find(this.filters, { key: 'customer' }), 'value');
        dialog.componentInstance.model.driver = lodash_1.get(lodash_1.find(this.filters, { key: 'driver' }), 'value');
        dialog.componentInstance.model.truck = lodash_1.get(lodash_1.find(this.filters, { key: 'truck' }), 'value');
        dialog.componentInstance.model.payableTo = lodash_1.get(lodash_1.find(this.filters, { key: 'payableTo' }), 'value');
        dialog.componentInstance.model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    InvoiceTripsDialogComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            payableTo: 'jobevent__owner_organization',
            job: 'jobevent__job',
            jobNumber: 'jobevent__job__job_number',
            driver: 'driver',
            truck: 'truck',
            startDate: 'start_time__gte',
            endDate: 'start_time__lte',
            edited: 'edited',
            incomplete: 'completed',
            retake: 'retake_status'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (key === 'incomplete' && value) {
                    displayValue = value.toString();
                    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
                    value = !value;
                    var filterValue = value.toString();
                    filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (key === 'retake' && value) {
                    value = 'requested';
                    query[queryKeys[key]] = value;
                }
                else if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var filter = {
                key: key,
                value: displayValue || value,
                query: query
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        this.getTrips();
    };
    InvoiceTripsDialogComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.filters, filter);
        this.getTrips();
    };
    InvoiceTripsDialogComponent.prototype.menuAction = function (name, trip) {
        switch (name) {
            case 'add':
                trip ? this.addToInvoice([trip.id]) : this.addToInvoice();
                break;
            case 'void':
                trip ? this.voidSelectedTrips([trip]) : this.voidSelectedTrips();
                break;
        }
    };
    InvoiceTripsDialogComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    InvoiceTripsDialogComponent.prototype.selector = function (event, trip) {
        if (trip === void 0) { trip = null; }
        if (trip) {
            if (!event.target.checked) {
                trip.selected = false;
                lodash_1.pull(this.selectedTrips, trip.id);
                if (this.allSelected) {
                    this.excludeTrips.push(trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedCount = this.selectedTrips.length;
                }
            }
            else {
                trip.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludeTrips, trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedTrips.push(trip.id);
                    this.selectedCount = this.selectedTrips.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.trips.forEach(function (_trip) { _trip.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludeTrips.length);
            }
            this.selectedTrips = [];
            this.excludeTrips = [];
        }
    };
    InvoiceTripsDialogComponent.prototype.addToInvoice = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        var model = {};
        if (this.invoice && this.invoice.id) {
            Object.assign(model, { id: this.invoice.id });
            if (trips || this.selectedTrips.length) {
                Object.assign(model, { trips: trips || this.selectedTrips });
            }
            else if (this.allSelected) {
                var params_1 = new http_1.HttpParams();
                var filters_1 = lodash_1.omit(this.tripFilters, ['ordering', 'invoice']);
                if (filters_1) {
                    Object.keys(filters_1).map(function (key) {
                        if (filters_1[key] && filters_1[key].length) {
                            params_1 = params_1.set(key, filters_1[key]);
                        }
                    });
                }
                if (params_1.toString().length) {
                    Object.assign(model, { filters: params_1.toString() });
                }
                Object.assign(model, { excludeTrips: this.excludeTrips });
            }
            this.invoiceReq = this.invoiceService.addToInvoice(model).subscribe(function (res) {
                _this.invoice = res;
                _this.callback();
                _this.dialogRef.close();
            }, function (err) { return console.error(err); });
        }
        else {
            this.saveInvoice();
        }
    };
    InvoiceTripsDialogComponent.prototype.voidSelectedTrips = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        trips.forEach(function (trip) {
            trip.loading = true;
            _this.confirmDialog = _this.dialog.open(index_1.RuckitConfirmDialogComponent, {
                width: '430px',
                height: '250px'
            });
            _this.confirmDialog.componentInstance.attributes = {
                title: 'Void Trip?',
                body: 'This trip will be marked as \'Void\' and will not be visible for the Job.',
                close: 'Cancel',
                accept: 'Void'
            };
            _this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    trip.void = true;
                    _this.tripService.save(trip)
                        .subscribe(function (result) {
                        trip.loading = false;
                        _this.getTrips();
                    }, function (err) {
                        _this.errors = err;
                        trip.loading = false;
                    });
                }
                _this.confirmDialog = null;
            });
        });
    };
    InvoiceTripsDialogComponent.prototype.saveInvoice = function () {
        var _this = this;
        var model = {
            job: this.jobId,
            customerOrganization: this.trips[0].customerId,
            trips: this.selectedTrips,
            excludeTrips: (this.excludeTrips && this.excludeTrips.length > 0) ? this.excludeTrips : null
        };
        if (this.selectedTrips && this.selectedTrips.length) {
            Object.assign(model, { trips: this.selectedTrips });
        }
        else if (this.allSelected) {
            var params_2 = new http_1.HttpParams();
            var filters_2 = lodash_1.omit(this.tripFilters, ['ordering', 'invoice']);
            Object.keys(filters_2).map(function (key) {
                if (filters_2[key] && filters_2[key].length) {
                    params_2 = params_2.set(key, filters_2[key]);
                }
            });
            if (params_2 && params_2.toString().length) {
                Object.assign(model, { filters: params_2.toString() });
            }
        }
        this.invoiceReq = this.invoiceService.save(model).subscribe(function (res) {
            _this.invoice = res;
            _this.dialogRef.close();
            if (_this.routeToInvoice) {
                _this.router.navigate(['/invoices/' + _this.invoice.id + '/edit']);
            }
        }, function (err) { return console.error(err); });
    };
    InvoiceTripsDialogComponent.prototype.onDateChanged = function (values, type) {
        if (values && values.length) {
            switch (type) {
                case 'startDate':
                    this.startDate = values[0];
                    break;
                case 'endDate':
                    this.endDate = values[0];
                    break;
            }
        }
        this.getTrips();
    };
    InvoiceTripsDialogComponent.prototype.onSelect = function (filterType, e) {
        if (filterType === 'job') {
            this.jobId = e && e.id;
        }
        else {
            this.customerId = e && e.id;
        }
        this.getTrips();
    };
    InvoiceTripsDialogComponent.prototype.getJobs = function (search) {
        var _this = this;
        if (search === void 0) { search = null; }
        this.jobs = [{ id: null, name: 'All Jobs' }];
        this.jobsConfig.loadingOptions = true;
        this.jobsConfig = lodash_1.clone(this.jobsConfig);
        if (this.jobsReq) {
            this.jobsReq.unsubscribe();
        }
        this.jobsReq = this.jobService.list({
            has_billable_items: 'True',
            search: search,
            customer_organization: this.customerId
        }).subscribe(function (jobs) {
            _this.jobs = [{ id: null, name: 'All Jobs' }];
            _this.jobs = _this.jobs.concat(jobs);
            if (_this.job) {
                var selectedOption = lodash_1.find(_this.jobs, { id: _this.job.id });
                _this.jobs = lodash_1.reject(_this.jobs, selectedOption);
                _this.jobs.unshift(_this.job);
                _this.jobsDropdown.selectedOption = selectedOption;
            }
            _this.jobsDropdown.config.loadingOptions = false;
            _this.jobsConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
            _this.jobsDropdown.config.loadingOptions = false;
            _this.jobsConfig.loadingOptions = false;
            _this.jobsConfig = lodash_1.clone(_this.jobsConfig);
        });
    };
    InvoiceTripsDialogComponent.prototype.getCustomers = function (q) {
        var _this = this;
        this.customers = [{ id: null, name: 'All Customers' }];
        this.customersConfig.loadingOptions = true;
        this.customersConfig = lodash_1.clone(this.customersConfig);
        if (this.customersReq) {
            this.customersReq.unsubscribe();
        }
        this.customersReq = this.organizationService.getOrganizations(q).subscribe(function (customers) {
            _this.customers = [{ id: null, name: 'All Customers' }];
            _this.customers = _this.customers.concat(customers);
            customers.forEach(function (customer) {
                if (customer) {
                    var selectedOption = lodash_1.find(_this.customers, { id: customer.id });
                    _this.customers = lodash_1.reject(_this.customers, selectedOption);
                    _this.customers.push(customer);
                    _this.customersDropdown.selectedOption = selectedOption;
                }
            });
            _this.customersDropdown.config.loadingOptions = false;
            _this.customersConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
            _this.customersDropdown.config.loadingOptions = false;
            _this.customersConfig.loadingOptions = false;
            _this.customersConfig = lodash_1.clone(_this.customersConfig);
        });
    };
    InvoiceTripsDialogComponent.prototype.dropdownNextPage = function (e, type) {
        var _this = this;
        var config;
        var options;
        switch (type) {
            case 'job':
                config = this.jobsConfig;
                options = this.jobs;
                break;
            case 'customer':
                config = this.customersConfig;
                options = this.customers;
                break;
        }
        if (!config.loadingOptions) {
            var o = this.jobService.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'job':
                            _this.jobs = _this.jobs.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
            var op = this.organizationService.getNext();
            if (op) {
                config.loadingOptions = true;
                op.subscribe(function (results) {
                    switch (type) {
                        case 'customer':
                            _this.customers = _this.customers.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    InvoiceTripsDialogComponent.prototype.dropdownSearch = function (term, type) {
        if (term === void 0) { term = ''; }
        switch (type) {
            case 'job':
                this.getJobs(term);
                break;
            case 'customer':
                this.getCustomers(term);
                break;
            default:
                throw 'invalid dropdown type';
        }
    };
    return InvoiceTripsDialogComponent;
}());
exports.InvoiceTripsDialogComponent = InvoiceTripsDialogComponent;
