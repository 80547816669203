import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest, forkJoin } from 'rxjs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// libraries
import * as moment from 'moment-timezone';
import { find as _find } from 'lodash';

// services
import { TripService } from './../trip.service';
import { JobService } from '../../jobs/job.service';
import { CheckinService } from '../../checkins/checkin.service';
import { parseErrors } from '../../shared/api.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { AuthenticationService } from '../../shared/authentication.service';

// models
import { Job } from '../../jobs/job';
import { Checkin } from '../../checkins/checkin';
import { Carrier } from '../../carriers/carrier';
import { Truck } from '../../trucks/truck';
import { Driver } from '../../drivers/driver';
import { JobEvent } from '../../job-events/job-event';
import { CondensedTrip } from './../condensed-trip';

// components
import { MultipleImagesEditComponent } from '../../shared/multiple-images-edit/multiple-images-edit.component';
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

// constants
import {
  LOADINGTRIPSIGNATUREIMAGETYPE,
  LOADINGTRIPTICKETIMAGETYPE,
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
  UNLOADINGTRIPSIGNATUREIMAGETYPE,
  UNLOADINGTRIPTICKETIMAGETYPE,
} from '../../app.constants';

import {
  CARRIER_DROPDOWN_CONFIG,
  DRIVER_DROPDOWN_CONFIG,
  JOB_DROPDOWN_CONFIG,
  JOB_EVENTS_DROPDOWN_CONFIG,
  TRUCK_DROPDOWN_CONFIG,
} from './dropdownsConfig';

// validators
import { globalTripTimeValidator } from '../trip-time-validators';

@Component({
  selector: 'edit-trip',
  templateUrl: './edit-trip.component.html',
  styleUrls: ['./edit-trip.component.scss'],
})
export class EditTripComponent implements OnInit {
  trip: CondensedTrip;
  jobDates: Date[] = [];
  returnTo: string;
  weightRequired = false;
  loadingCheckinFile;
  unloadingCheckinFile;
  errors = [];
  jobEventError: string = null;
  job: Job;
  carrier: Carrier;
  truck: Truck;
  driver: Driver;
  jobEvent: JobEvent;

  checkinsReq = { loading: null, unloading: null };

  loading = false;
  daysLoading = false;
  jobEventsLoading = false;
  @ViewChild('editImagesContainer', { static: false })
  editImagesContainer: MultipleImagesEditComponent;

  loadingTicketImages = [];
  unloadingTicketImages = [];
  loadingSignatures = [];
  unloadingSignatures = [];
  imagesToDelete = [];
  uploading = false;

  editTripForm: FormGroup;

  @ViewChild('jobDropdown', { static: false })
  jobsDropdown: RuckitDropdownComponent;
  jobDropdownConfig = JOB_DROPDOWN_CONFIG(this.translate);

  @ViewChild('jobEventsDropdown', { static: false })
  jobEventsDropdown: RuckitDropdownComponent;
  jobEventsDropdownConfig = JOB_EVENTS_DROPDOWN_CONFIG(this.translate);

  @ViewChild('carriersDropdown', { static: false })
  carriersDropdown: RuckitDropdownComponent;
  carrierDropdownConfig = CARRIER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('driversDropdown', { static: false })
  driversDropdown: RuckitDropdownComponent;
  driverDropdownConfig = DRIVER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('truckDropdown', { static: false })
  truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = TRUCK_DROPDOWN_CONFIG(this.translate);

  fieldsToCheck = [
    'activeTracking',
    'ticketImage',
    'signatureImage',
    'ticketNumber',
    'weight',
  ];
  firstLoad = true;

  requiredImages = {
    loadingImages: false,
    unloadingImages: false,
    loadingSignature: false,
    unloadingSignature: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tripService: TripService,
    private jobService: JobService,
    private checkinService: CheckinService,
    private attachmentService: AttachmentService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.loading = true;
    let combinedParams = observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    combinedParams.subscribe((result) => {
      this.returnTo = result && result.qparams['returnTo'];
      if (result && result.params['id']) {
        this.getTrip(result.params['id']);
      }
    });
  }

  getMomentDateFromDate(date: Date, format = 'YYYY-MM-DD') {
    return date ? moment(date).format(format) : null;
  }

  initForm() {
    this.editTripForm = this.fb.group(
      {
        job: [this.job, Validators.required],
        jobEvent: [this.jobEvent, Validators.required],
        driver: [this.driver, Validators.required],
        truck: [this.truck, Validators.required],
        carrier: [this.carrier, Validators.required],
        start: this.fb.group({
          date: [this.trip.startDate, [Validators.required]],
          time: [
            moment(this.trip.startDate).format('HH:mm'),
            [Validators.required],
          ],
        }),
        end: this.fb.group({
          date: [this.getMomentDateFromDate(this.trip.endDate)],
          time: [this.getMomentDateFromDate(this.trip.endDate, 'HH:mm')],
        }),

        loadingCheckin: this.fb.group({
          ticketNumber: [this.trip.loadingCheckin.ticketNumber],
          weight: [this.trip.loadingCheckin.weight],
          date: [this.trip.loadingCheckin.date],
          time: [this.trip.loadingCheckin.time],
          images: [null],
          signatures: [null],
        }),

        unloadingCheckin: this.fb.group({
          ticketNumber: [this.trip.unloadingCheckin.ticketNumber],
          weight: [this.trip.unloadingCheckin.weight],
          date: [this.trip.unloadingCheckin.date],
          time: [this.trip.unloadingCheckin.time],
          images: [null],
          signatures: [null],
        }),
      },
      { validators: globalTripTimeValidator }
    );
    const user = this.authenticationService.user();
    this.carrierDropdownConfig.query = {
      exclude: user.organization.id,
    };
    this.carrierDropdownConfig.prefilledOptions.push(user.organization);
  }

  getTrip(id: string): void {
    if (id) {
      this.tripService.getCondensedTrip(id).subscribe(
        (trip) => {
          this.trip = trip;
          this.job = {
            id: trip.jobId,
            name: trip.job,
          } as Job;
          this.jobEvent = new JobEvent({ id: trip.jobEventId });
          this.carrier = new Carrier({
            id: trip.carrierId,
            name: trip.carrier,
          });
          this.driver = {
            id: trip.driverId,
            name: trip.driver,
          } as Driver;
          this.truck = {
            id: trip.truckId,
            name: trip.truck,
          } as Truck;
          this.setRequiredImages(trip.checkinOptions, trip.checkoutOptions);
          this.getCheckins(this.trip.id, 'loading');
          this.getCheckins(this.trip.id, 'unloading');
          this.initForm();
        },
        (err) => {
          this.errors = err;
        }
      );
    }
  }

  submitTrip() {
    this.loading = true;

    const requests = this.attachmentService.combineTicketImagesAndAttachments(
      this.imagesToDelete,
      this.loadingTicketImages,
      this.loadingSignatures,
      this.unloadingTicketImages,
      this.unloadingSignatures,
      this.trip.loadingCheckin.id,
      this.trip.unloadingCheckin.id
    );

    const parsedTrip = this.parseTripForSubmit();
    delete parsedTrip.loadingCheckin.attachments;
    delete parsedTrip.unloadingCheckin.attachments;
    const updateTrip$ =
      this.tripService.saveWithCheckinsAndAttachments(parsedTrip);

    forkJoin([...requests, updateTrip$]).subscribe(
      () => {
        this.loading = false;
        this.router.navigateByUrl(this.returnTo);
      },
      (err) => {
        this.errors = parseErrors([err]);
        this.loading = false;
      }
    );
  }

  parseTripForSubmit(): any {
    return {
      ...this.trip,
      id: this.trip.id,
      job: this.editTripForm.value.job.id,
      jobEvent: this.editTripForm.value.jobEvent.id,
      jobevent: this.editTripForm.value.jobEvent.id,
      carrier: this.editTripForm.value.carrier.id,
      driver: this.editTripForm.value.driver.id,
      truck: this.editTripForm.value.truck.id,
      startTime: this.parseDateTime(
        this.editTripForm.value.start.date,
        this.editTripForm.value.start.time
      ),
      endTime: this.parseDateTime(
        this.editTripForm.value.end.date,
        this.editTripForm.value.end.time
      ),
      loadingCheckin: {
        ...this.editTripForm.value.loadingCheckin,
        id: this.trip.loadingCheckin.id,
        date: this.parseDateTime(
          this.editTripForm.value.loadingCheckin.date,
          this.editTripForm.value.loadingCheckin.time
        ),
      },
      unloadingCheckin: {
        ...this.editTripForm.value.unloadingCheckin,
        id: this.trip.unloadingCheckin.id,
        date: this.parseDateTime(
          this.editTripForm.value.unloadingCheckin.date,
          this.editTripForm.value.unloadingCheckin.time
        ),
      },
    };
  }

  deleteLegacyImagesfromTrip(): {
    legacyImagesToBeDeleted: any[];
    otherImagesToBeDeleted: any[];
  } {
    const [legacyImagesToBeDeleted, otherImagesToBeDeleted] =
      this.imagesToDelete.reduce(
        ([p, f], e) => (e.legacy ? [[...p, e], f] : [p, [...f, e]]),
        [[], []]
      );

    return { legacyImagesToBeDeleted, otherImagesToBeDeleted };
  }

  parseDateTime = (date: any, time: string): string => {
    return !!date && !!time
      ? moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toISOString()
      : null;
  };

  onStartDateChanged(dates: Date[]) {
    this.trip.startDate = dates[0];
  }

  onEndDateChanged(dates: Date[]) {
    this.trip.endDate = dates[0];
  }

  onCheckinLoadingDateChanged(dates: Date[]) {
    if (this.trip && this.trip.loadingCheckin) {
      this.trip.loadingCheckin.date = dates[0];
    }
  }

  onCheckinUnloadingDateChanged(dates: Date[]) {
    if (this.trip && this.trip.unloadingCheckin) {
      this.trip.unloadingCheckin.date = dates[0];
    }
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        if (this.job !== e) {
          if (this.firstLoad) {
            this.firstLoad = false;
          } else {
            this.job = e;
            this.jobEventsDropdown.getRecords({
              job: e.id,
            });
            this.resetForm();
          }
          this.jobEventsDropdown.getRecords({
            job: e.id,
          });
          this.getJobDays(e.id);
        }
        break;
      case 'jobEvent':
        if (!this.jobEvent || this.jobEvent.id !== e.id) {
          this.jobEvent = e;
          this.setRequiredImages(e.job.checkinOptions, e.job.checkoutOptions);
          this.newTripFormSetRequiredFields(e);
        }
        const query = {
          ...this.carrierDropdownConfig.query,
          ...{ carriers_for_jobevent: e.id },
        };
        this.carriersDropdown.config.query = query;
        this.carriersDropdown.getRecords(query);
        break;
      case 'carrier':
        const carrier = e.carrier;
        if (this.carrier === null || this.carrier.id !== carrier.id) {
          this.carrier = carrier;
          this.driver = null;
          this.truck = null;
          this.driversDropdown.getRecords({
            carrier: carrier.id,
            shared_jobevent: this.jobEvent.id,
          });
          this.truckDropdown.getRecords({
            carrier: carrier.id,
          });
        }
        break;
      case 'driver':
        this.driver = e;
        break;
      case 'truck':
        this.truck = e;
        break;
    }
    this.newTripFormPatchValue(filterType, e);
  }

  getCheckins(tripId: string, kind = 'loading'): void {
    if (
      this.checkinsReq[kind] &&
      typeof this.checkinsReq[kind].unsubscribe === 'function'
    ) {
      this.checkinsReq[kind].unsubscribe();
    }

    this.checkinsReq[kind] = this.checkinService
      .getCheckins({
        ordering: 'name',
        kind: kind,
        trip: tripId,
      })
      .subscribe(
        (checkins) => {
          if (checkins && checkins.length) {
            let checkin = checkins[0];
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'ticketNumber'],
              checkin.ticketNumber
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'weight'],
              checkin.weight
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'date'],
              checkin.date
            );
            this.editTripFormPatchValueNested(
              [`${kind}Checkin`, 'time'],
              moment(checkin.date).format('HH:mm')
            );
            switch (kind) {
              case 'loading':
                this.trip.loadingCheckin = checkin;
                this.loadingTicketImages = this.trip.loadingCheckin.attachments
                  .filter((img) => img.fileType === TRIPTICKETIMAGETYPE)
                  .map((a) => ({
                    id: a.id,
                    src: a.file,
                    file: a.file,
                    legacy: a.legacy,
                    imgType: LOADINGTRIPTICKETIMAGETYPE,
                  }));
                this.loadingSignatures = this.trip.loadingCheckin.attachments
                  .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
                  .map((file) => ({
                    id: file.id,
                    src: file.file,
                    file: file.file,
                    legacy: file.legacy,
                    imgType: LOADINGTRIPSIGNATUREIMAGETYPE,
                  }));
                break;

              case 'unloading':
                this.trip.unloadingCheckin = checkin;
                this.unloadingTicketImages =
                  this.trip.unloadingCheckin.attachments
                    .filter((img) => img.fileType === TRIPTICKETIMAGETYPE)
                    .map((a) => ({
                      id: a.id,
                      src: a.file,
                      file: a.file,
                      legacy: a.legacy,
                      imgType: UNLOADINGTRIPTICKETIMAGETYPE,
                    }));
                this.unloadingSignatures =
                  this.trip.unloadingCheckin.attachments
                    .filter((att) => att.fileType === TRIPSIGNATUREIMAGETYPE)
                    .map((file) => ({
                      id: file.id,
                      src: file.file,
                      file: file.file,
                      legacy: file.legacy,
                      imgType: UNLOADINGTRIPSIGNATUREIMAGETYPE,
                    }));
                break;
            }
          }
        },
        (err) => {
          this.errors = err;
        },
        () => {
          this.loading = false;
        }
      );
  }

  getJobDays(jobId: string): void {
    this.jobService.getDays(jobId).subscribe(
      (days) => {
        this.jobDates = days.map((day) => moment(day).toDate());
        this.daysLoading = false;
      },
      (err) => {
        this.errors = err;
        this.daysLoading = false;
      }
    );
  }

  requestRetake(checkin: Checkin): void {
    this.checkinService.requestRetake(checkin.id).subscribe(
      () => {
        this.trip.retakeStatus = 'requested';
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  undoRequestRetake(trip: CondensedTrip): void {
    this.tripService.undoRetakeRequest(trip).subscribe(
      () => {
        this.trip.retakeStatus = null;
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  parseImageOnChange(image: any) {
    if (image && (image.isNew || image.isEdited)) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
    }
    if (image && image.isDeleted) {
      this.imagesToDelete.push(image);
    }
    return image;
  }

  handleImagesDialogVisibility() {
    this.editImagesContainer.handleVisibility();
  }

  onImagesChangeParser = (images: any[]): any[] =>
    images.map((img: any) =>
      img.isNew || img.isEdited
        ? Object.assign(img, {
            tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
          })
        : this.parseImageOnChange(img)
    );

  onImagesChange(
    type:
      | 'loadingTicketImages'
      | 'unloadingTicketImages'
      | 'unloadingSignatures'
      | 'loadingSignatures',
    checkin: 'loadingCheckin' | 'unloadingCheckin',
    images: any[]
  ) {
    const parsedImages = this.onImagesChangeParser(images);
    this[type] = parsedImages;
    this.editTripFormPatchValueNested(
      [checkin, type.includes('Signature') ? 'signatures' : 'images'],
      [...parsedImages]
    );
  }

  fileChange(type: any, img: any): void {
    this.onImagesChange(
      type,
      type === 'loading' ? 'loadingCheckin' : 'unloadingCheckin',
      [img]
    );
  }

  fileUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  editTripFormPatchValueNested(keys: string[], value: any) {
    if (keys && keys.length > 0 && value) {
      let objToPatch = {};
      keys.reduce((prev, curr, i) => {
        return (prev[curr] = i + i === keys.length ? value : {});
      }, objToPatch);
      this.editTripForm.patchValue(objToPatch);
    }
  }

  onDateChange(args: string[], dates: Date[]) {
    if (args && args.length > 0 && dates) {
      this.editTripFormPatchValueNested(args, dates[0]);
    }
  }

  newTripFormPatchValue(key, value): void {
    this.editTripForm.patchValue({
      [key]: value,
    });
  }

  newTripFormSetRequiredFields(jobEvent: JobEvent) {
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'loadingCheckin',
        field,
        jobEvent.job.checkinOptions[`${field}Config`] === 'required'
      );
    });
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'unloadingCheckin',
        field,
        jobEvent.job.checkoutOptions[`${field}Config`] === 'required'
      );
    });

    this.updateFormValueAndValidity(this.editTripForm);
  }

  updateFormValueAndValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.updateFormValueAndValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  newTripFormValidatorsSwitch(parent: string, key: string, required: boolean) {
    switch (key) {
      case 'activeTracking':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'date'],
            [Validators.required, Validators.minLength(1)]
          );
          this.editTripFormSetValidators(
            [parent, 'time'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'date']);
          this.editTripFormClearValidators([parent, 'time']);
        }
        break;

      case 'ticketImage':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'images'],
            [Validators.required]
          );
        } else {
          this.editTripFormClearValidators([parent, 'images']);
        }
        break;
      case 'signatureImage':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'signatures'],
            [Validators.required]
          );
        } else {
          this.editTripFormClearValidators([parent, 'signatures']);
        }
        break;

      case 'ticketNumber':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'ticketNumber'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'ticketNumber']);
        }
        break;

      case 'weight':
        if (required) {
          this.editTripFormSetValidators(
            [parent, 'weight'],
            [Validators.required, Validators.min(0)]
          );
        } else {
          this.editTripFormClearValidators([parent, 'weight']);
        }
        break;

      default:
        break;
    }
  }

  editTripFormSetValidators(field: string[], validators) {
    this.editTripForm.get(field).setValidators(validators);
  }

  editTripFormClearValidators(field: string[]) {
    this.editTripForm.get(field).clearValidators();
  }

  setRequiredImages(checkinOptions, checkoutOptions) {
    this.requiredImages = {
      loadingImages: checkinOptions.ticketImageConfig === 'required',
      unloadingImages: checkoutOptions.ticketImageConfig === 'required',
      loadingSignature: checkinOptions.signatureImageConfig === 'required',
      unloadingSignature: checkoutOptions.signatureImageConfig === 'required',
    };
  }

  resetForm() {
    this.jobEvent = null;
    this.carrier = null;
    this.driver = null;
    this.truck = null;
    this.editTripForm.reset();
  }

  get startDate() {
    return this.editTripForm.get(['start', 'date']).value;
  }

  get endDate() {
    return this.editTripForm.get(['end', 'date']).value;
  }

  get loadingCheckingDate() {
    return this.editTripForm.get(['loadingCheckin', 'date']).value;
  }

  get unloadingCheckingDate() {
    return this.editTripForm.get(['unloadingCheckin', 'date']).value;
  }

  get loadingCheckingTime() {
    return this.editTripForm.get(['loadingCheckin', 'time']).value;
  }

  get unloadingCheckingTime() {
    return this.editTripForm.get(['unloadingCheckin', 'time']).value;
  }
}
