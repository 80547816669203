"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mock_profile_1 = require("./mock-profile");
var mock_carrier_1 = require("../carriers/mock-carrier");
exports.DRIVER = {
    id: '00000000-0000-0000-0000-000000000001',
    profile: mock_profile_1.PROFILE,
    image: '',
    city: 'Austin',
    state: 'TX',
    country: 'US',
    street: '123 Main Street',
    zipcode: '12345',
    name: 'Testy Tester',
    activeShift: true,
    cdl: '123456',
    requireAssignmentConfirmation: false,
    initials: 'JL',
    carrierName: 'Jeff Lee',
    uniqueBillingId: '',
    conflictIds: null,
    carrier: mock_carrier_1.CARRIER,
    carrierOrganization: null,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    }
};
exports.DRIVER_TWO = {
    id: '00000000-0000-0000-0000-000000000002',
    profile: mock_profile_1.PROFILE,
    image: '',
    city: 'Austin',
    state: 'TX',
    country: 'US',
    street: '456 Main Street',
    zipcode: '67890',
    name: 'Testy Tester Jr.',
    activeShift: false,
    cdl: '78910',
    requireAssignmentConfirmation: true,
    initials: 'AC',
    carrierName: 'Alton Chaney',
    carrierOrganization: null,
    uniqueBillingId: '',
    conflictIds: null,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    }
};
