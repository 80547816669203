<div class="app-content">
  <my-toolbar title="{{ 'Punch Cards' | translate }}" (updatedMarkets)="getPunchCards()"></my-toolbar>

  <action-bar>
    <div class="subnav">
      <div class="links">
        <a [routerLink]="['/trips']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" queryParamsHandling="merge" translate>Trips</a>
        <a [routerLink]="['/geotrips']" routerLinkActive="active" translate>GeoTrips</a>
        <a [routerLink]="['/punchcards']" routerLinkActive="active" translate>Punch Cards</a>
        <a [routerLink]="['/shifts']" routerLinkActive="active" translate>Shifts</a>
        <a [routerLink]="['/driver-reports']" routerLinkActive="active" translate>Driver Job Reports</a>
        <a [routerLink]="['/end-of-day-reports']" routerLinkActive="active" translate>End of Day Reports</a>
      </div>
    </div>

    <div class="left-actions">
      <button class="btn btn-default btn-filters" (click)="openFilters()" [ngClass]="{active: filters.length}">
        <i class="icon-filter"></i> {{ 'Filters' | translate }}
      </button>
      <button class="btn btn-default duplicate-check" (click)="duplicateCheck()" [ngClass]="{active: filters.length && checkingDuplicates}"
        translate>
        Duplicate Check
      </button>
    </div>
    <div class="right-actions">
      <div class="search-container {{ loading ? 'loading' : '' }}">
        <search (searchTermChange)="changeSearch($event)" [(searchTerm)]="search"></search>
      </div>
      <dropdown 
        title="Actions" 
        class="actions-dropdown" 
        [disabled]="loading"
        [options]="multipleActionDropdownOptions" 
        [changeTitle]="false" 
        (onSelect)="setSelectedBulkAction($event)"
      >
      </dropdown>
      <button class="btn btn-primary" (click)="openAddPunchCard()"><span class="icon-plus"></span> {{ 'Add Punch Card' | translate }}</button>
    </div>
  </action-bar>

  <div class="filters" *ngIf="filters.length">
    <ng-container *ngIf="!loading">{{ count }} {{ 'Results' | translate }}</ng-container>
    <ng-container *ngIf="loading">{{ 'Loading...' | translate }}</ng-container>
    <button class="filter btn btn-primary-inverted" *ngFor="let filter of filters">
      {{ filter.key | titleCase }}: {{ filter.value && (filter.value.selectName || filter.value.name || filter.value) }}
      <i  class="icon-close" (click)="removeFilter(filter)"></i>
    </button>
  </div>

  <div class="punch-cards">
    <div class="list-table" (scroll)="onScroll($event)">
      <div class="table-body">
        <div class="header">
          <div class="head selector">
            <label>
              <input type="checkbox" (change)="selector($event)" [(ngModel)]="allSelected" />
            </label>
          </div>
          <div class="head expand"></div>
          <div class="head actions" translate>
            Actions
          </div>
          <div class="head date sort" (click)="sort('jobevent__shift1_start')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__shift1_start'}" translate>
            Job Date
          </div>
          <div class="head job-title sort" (click)="sort('jobevent__job__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__job__name'}" translate>
            Job
          </div>
          <div class="head time sort" (click)="sort('start_time')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'start_time'}">
            Time
          </div>
          <div class="head ticket-number sort" (click)="sort('ticket_number')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'ticket_number'}" translate>
            Ticket #
          </div>
          <div class="head ticket-image" translate>
            Ticket Image
          </div>
          <!-- <div class="head carrier" translate>
            Carrier
          </div> -->
          <div class="head driver sort" (click)="sort('driver__profile__last_name,driver__profile__first_name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'driver__profile__first_name'}" translate>
            Driver
          </div>
          <div class="head truck sort" (click)="sort('truck__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'truck__name'}" translate>
            Truck
          </div>
          <!-- <div class="head payable-to" translate>
            Payable To
          </div> -->
          <div class="head customer sort" (click)="sort('jobevent__customer_organization__name')" [ngClass]="{asc: sortAsc, sorted: sortBy === 'jobevent__customer_organization__name'}" translate>
            Customer
          </div>
          <div class="head invoice" translate>
            Invoice Rate
          </div>
          <div class="head invoice-total" translate>
            Invoice Total
          </div>
          <div class="head haul" translate>
            Haul Rate
          </div>
          <div class="head haul-total" translate>
            Haul Total
          </div>
          <div class="head invoice-number" translate>
            Invoice #
          </div>
          <div class="head expense-title">
            Expense
          </div>
          <div class="head paystub-title">
            Paystub
          </div>
          <div class="head billing-id" translate>
            Billing ID
          </div>
        </div>

        <div class="no-results" *ngIf="!loading && punchCards.length === 0" fxLayout="column" fxLayoutAlign="start center">
          <div class="icon"><i class="icon-team"></i></div>
          <h2 translate>No Punch Cards</h2>
          <p translate>When you complete punch cards you’ll see them here.</p>
          <div class="actions">
            <button class="btn btn-primary" (click)="openAddPunchCard()"><span class="icon-plus"></span> {{ 'Add Punch Card' | translate }}</button>
          </div>
        </div>

        <div *ngFor="let punchCard of punchCards">
          <div class="row" [ngClass]="{selected: allSelected || punchCard.selected}">
            <div class="selector">
              <label>
                <input type="checkbox" (change)="selector($event, punchCard)" [checked]="allSelected || punchCard.selected" />
              </label>
            </div>
            <div class="expand" [ngClass]="{'expand-disabled': !punchCard.trips?.length}" (click)="expandPunchCard(punchCard)">
              <i *ngIf="!expandedPunchCards[punchCard.id]" class="icon-down-arrow"></i>
              <i *ngIf="expandedPunchCards[punchCard.id]" class="icon-up-arrow"></i>
            </div>
            <div class="actions">
              <action-menu icon="icon-more">
                <ng-container *ngFor="let option of punchCard.filterOptions(menuOptions)">
                  <a *ngIf="option.link" mat-menu-item [routerLink]="punchCard.url(option.action)" [queryParams]="{ returnTo: '/punchcards' }">{{ option.name }}</a>
                  <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, punchCard)">{{ option.name }}</a>
                </ng-container>
              </action-menu>
            </div>
            <div class="date">
              <div class="value">{{ punchCard.startDate | date:'mediumDate' || '&nbsp;' }}</div>
              <div class="punch-card-status">
                <div class="label-{{punchCard.administrativeStatus}}" *ngIf="punchCard.displayableStatus">{{ punchCard.administrativeStatus }}</div>
                <div class="spacer" *ngIf="!punchCard.displayableStatus">&nbsp;</div>
              </div>
            </div>
            <div class="job-title">
              <div class="value" title="{{ punchCard.jobDisplayName }}">{{ punchCard.jobDisplayName || '&nbsp;' }}</div>
              <div class="label">{{ punchCard.project || '&nbsp;' }}</div>
            </div>
            <div class="time">
              <div class="value">{{ (punchCard.endTimeTimestamp ? punchCard.duration : formattedDuration(punchCard.startTimeTimestamp)) || '&nbsp;' }}</div>
              <div class="label">{{ punchCard.schedule || '&nbsp;' }}</div>
            </div>
            <div class="ticket-number">
              <div class="value" [ngClass]="{'missing-required': !punchCard.ticketNumber}">{{ punchCard.ticketNumber || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="ticket-image">
              <a [routerLink]="punchCard.url('edit')" [queryParams]="{ returnTo: '/punchcards' }">
                <div class="ticket-photo" *ngIf="!punchCard.hasTicketImage">
                  <div class="value" class="missing-required">&nbsp;</div>
                  <div class="label" translate>No Ticket</div>
                </div>
                <div class="ticket-photo" *ngIf="punchCard.hasTicketImage">
                  <span><i class="icon-ticket"></i></span>
                </div>
              </a>
            </div>
            <!-- <div class="carrier">
              <div class="value">{{ punchCard.carrier || '&nbsp;' }}</div>
              <div class="label">Carrier</div>
            </div> -->
            <div class="driver">
              <div class="value" (contextmenu)="openContextMenu($event, punchCard.driverId)">{{ punchCard.driver || '&nbsp;' }}</div>
              <div class="label"><a class="replay-link" target="_blank" href="/replay?date={{ punchCard.jobDate | moment: 'YYYYMMDD' }}&driver={{ punchCard.driverId }}">View Replay</a></div>
            </div>
            <div class="truck">
              <div class="value">{{ punchCard.truckName || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <!-- <div class="payable-to">
              <div class="value">{{ punchCard.payableTo || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div> -->
            <div class="customer">
              <div class="value">{{ punchCard.customer || '&nbsp;' }}</div>
              <div class="label">{{ (punchCard.customerPhone | telephone) || '&nbsp;' }}</div>
            </div>
            <div class="invoice">
              <div class="value" [ngClass]="{'missing-required': punchCard.invoiceRateUnit === null}">{{ ((punchCard.invoiceRate || 0) | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
              <div class="label">{{ punchCard.invoiceRateUnit || '&nbsp;' | titleCase }}</div>
            </div>
            <div class="invoice-total">
              <div class="value" [ngClass]="{'missing-required': punchCard.invoiceTotal === null}">{{ ((punchCard.invoiceTotal || 0) | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="haul">
              <div class="value" [ngClass]="{'missing-required': punchCard.haulRateUnit === null}">{{ ((punchCard.haulRate || 0) | currency : 'USD' : 'symbol' : '1.2-4') || '&nbsp;' }}</div>
              <div class="label">{{ punchCard.haulRateUnit || '&nbsp;' | titleCase }}</div>
            </div>
            <div class="haul-total">
              <div class="value" [ngClass]="{'missing-required': punchCard.invoiceTotal === null}">{{ ((punchCard.haulTotal || 0) | currency : 'USD' : 'symbol') || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="invoice-number">
              <div class="value">{{ punchCard.invoiceTitle || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="expense-title">
              <div class="value">{{ punchCard.expenseTitle || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="paystub-title">
              <div class="value">{{ punchCard.paystubTitle || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
            <div class="billing-id">
              <div class="value">{{ punchCard.billingId || '&nbsp;' }}</div>
              <div class="label">&nbsp;</div>
            </div>
          </div>

          <ruckit-trip-detail [trips]="punchCard.trips" *ngIf="expandedPunchCards[punchCard.id]"></ruckit-trip-detail>
        </div>

        <notification *ngFor="let error of errors">{{error}}</notification>
        <my-loader *ngIf="loading" [ngClass]="{'full-height': punchCards.length === 0}" class="loader"></my-loader>
      </div>
    </div>
  </div>
</div>

<!-- driver context menu -->
<app-driver-context-menu *ngIf="contextMenuOpened" [contextMenuEventSubject]="contextMenuEventSubject">
</app-driver-context-menu>