"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
var moment = require("moment");
var truck_type_service_1 = require("../trucks/truck-type.service");
var job_service_1 = require("./job.service");
var tag_input_component_1 = require("../shared/tag-input/tag-input.component");
var location_serializer_1 = require("../locations/location.serializer");
var project_service_1 = require("../projects/project.service");
var connection_service_1 = require("../connections/connection.service");
var location_service_1 = require("../locations/location.service");
var new_customer_dialog_component_1 = require("../customers/new-customer-dialog.component");
var new_location_dialog_component_1 = require("../locations/new-location-dialog.component");
var api_service_1 = require("../shared/api.service");
var shared_1 = require("../shared");
var custom_field_service_1 = require("../custom-fields/custom-field.service");
var custom_field_1 = require("../custom-fields/custom-field");
var work_order_template_service_1 = require("../work-orders/work-order-templates/work-order-template.service");
var preference_service_1 = require("../preferences/preference.service");
var job_serializer_1 = require("./job.serializer");
var organization_1 = require("../organizations/organization");
var project_serializer_1 = require("../projects/project.serializer");
var edit_location_dialog_component_1 = require("../locations/edit-location-dialog.component");
var app_constants_1 = require("../app.constants");
var NewJobComponent = /** @class */ (function () {
    function NewJobComponent(route, truckTypeService, jobService, projectService, connectionService, locationService, workOrderTemplateService, router, dialog, formBuilder, authenticationService, preferenceService, customFieldService, deviceDetectorService) {
        var _this = this;
        this.route = route;
        this.truckTypeService = truckTypeService;
        this.jobService = jobService;
        this.projectService = projectService;
        this.connectionService = connectionService;
        this.locationService = locationService;
        this.workOrderTemplateService = workOrderTemplateService;
        this.router = router;
        this.dialog = dialog;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.customFieldService = customFieldService;
        this.deviceDetectorService = deviceDetectorService;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.loading = false;
        this.hasWorkOrder = false;
        this.days = [];
        this.jobTypeOptions = {
            loading: [],
            unloading: []
        };
        this.job = new job_serializer_1.JobSerializer().fromJson({
            project: new project_serializer_1.ProjectSerializer().fromJson({ id: '', name: '' }),
            customerOrganization: new organization_1.Organization({ id: '', name: '' }),
            qrJobType: 'loading_import',
            haulType: 'weight',
            haulWeightUnit: 'ton',
            invoiceType: 'weight',
            invoiceWeightUnit: 'ton',
            dailyDeliveryTargetType: 'tons',
            totalAmountType: 'tons',
            deliveryIntervalUnit: 'minutes',
            dates: [],
            allowAnyTruckType: true,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        this.truckTypes = [];
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.orderTypeOptions = [
            { id: 'tons', name: 'Tons' },
            { id: 'metric-tons', name: 'Metric Tons' },
            { id: 'tonnes', name: 'Tonnes' },
            { id: 'loads', name: 'Loads' },
            { id: 'lbs', name: 'Pounds' },
            { id: 'kgs', name: 'Kilograms' },
            { id: 'cuyds', name: 'Cubic Yards' },
            { id: 'bushels', name: 'Bushels' },
            { id: 'bags', name: 'Bags' }
        ];
        this.deliveryIntervalUnits = [{
                id: 'hours', name: 'Hours'
            }, {
                id: 'minutes', name: 'Minutes', selected: true
            }];
        this.truckTypeMap = {};
        this.loadingLocations = [{
                displayName: 'Add a New Address',
                id: 'new-loading-location',
                button: true
            }];
        this.unloadingLocations = [{
                displayName: 'Add a New Address',
                id: 'new-unloading-location',
                button: true
            }];
        this.jobWeightOptions = lodash_1.clone(this.weightOptions);
        this.haulWeightOptions = lodash_1.clone(this.weightOptions);
        this.locationsDropdownConfig = {
            start: { searchable: true, nameProperty: 'displayName' },
            end: { searchable: true, nameProperty: 'displayName' }
        };
        this.errors = [];
        this.newProject = true;
        this.projectOptions = [];
        this.projectValueSet = false;
        this.customerSearch = '';
        this.customerOptions = [];
        this.customerLoading = false;
        this.customerDropdownConfig = {
            nameProperty: 'name'
        };
        this.projectControl = new forms_1.FormControl();
        this.secondShift = false;
        this.truckTypesExpanded = false;
        this.customFields = [];
        this.workOrderTemplateOptions = [];
        this.workOrderTemplateDropdownConfig = {
            nameProperty: 'name'
        };
        this.allSubscriptionsToUnsubscribe = [];
        this.configOptions = [
            { name: 'Required', id: 'required' },
            { name: 'Optional', id: 'optional' },
            { name: 'Hidden', id: 'hidden' },
        ];
        this.saveCustomerCallback = function (customer) {
            _this.reloadCustomerOptions();
            _this.job.customerOrganization = customer;
        };
    }
    NewJobComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.user = this.authenticationService.user();
        this.loadProjectOptions();
        this.allSubscriptionsToUnsubscribe.push(this.projectAutocomplete.opened.subscribe(function () {
            Promise.resolve(null).then(function () { return _this.projectScrollEvent(); });
        }));
        this.customerDropdownConfig = { searchable: true, loadingOptions: false };
        this.reloadCustomerOptions();
        this.truckTypesReq = this.truckTypeService.list({ page_size: 100 }).subscribe(function (res) {
            _this.truckTypes = res;
            _this.truckTypesExpanded = lodash_1.find(_this.truckTypes, { isFavorite: true }) ? false : true;
        });
        this.paramsSub = this.route.params.subscribe(function (params) {
            if (params['jobId']) {
                _this.jobService.get(params['jobId']).subscribe(function (job) {
                    _this.copyToJob(job);
                });
            }
            if (params['id']) {
                _this.projectService.get(params['id']).subscribe(function (project) {
                    _this.project = project;
                });
            }
        });
        this.queryParamSub = this.route.queryParams.subscribe(function (params) {
            if (params['returnTo'] && params['returnTo'].length) {
                _this.returnTo = params['returnTo'];
            }
        });
        this.locationsReq = this.locationService.list({
            archived: 'False'
        }).subscribe(function (res) {
            res = res.map(function (loc) {
                loc['displayName'] = loc.displayName + ' - ' + loc.street;
                return loc;
            });
            if (_this.device.mobile) {
                _this.loadingLocations = [];
                _this.unloadingLocations = [];
                _this.loadingLocations = _this.loadingLocations.concat(res);
                _this.unloadingLocations = _this.unloadingLocations.concat(lodash_1.clone(res));
            }
            else {
                _this.loadingLocations = _this.loadingLocations.concat(res);
                _this.unloadingLocations = _this.unloadingLocations.concat(lodash_1.clone(res));
            }
            _this.setStartLocation();
            _this.setEndLocation();
        });
        this.locationByIPReq = this.locationService.getLocationByIP().subscribe(function (res) {
            _this.defaultLocation = res;
        });
        this.hasWorkOrder = this.authenticationService.hasWorkOrder();
        if (this.hasWorkOrder) {
            this.getWorkOrderTemplates();
        }
        if (this.job && this.user && this.user.organization && !this.user.organization.qrEnabled) {
            this.job.qrJobType = 'other';
        }
        this.loadCustomFields();
        this.getPreferences();
    };
    NewJobComponent.prototype.loadCustomFields = function () {
        var _this = this;
        this.customFieldsReq = this.customFieldService.getFieldsForKind(custom_field_1.CustomFieldKind.Job).subscribe(function (fields) {
            _this.customFields = fields;
        });
    };
    NewJobComponent.prototype.ngOnDestroy = function () {
        if (this.searchReq && typeof this.searchReq.unsubscribe === 'function') {
            this.searchReq.unsubscribe();
        }
        if (this.paramsSub && typeof this.paramsSub.unsubscribe === 'function') {
            this.paramsSub.unsubscribe();
        }
        if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
            this.customFieldsReq.unsubscribe();
        }
        if (this.locationByIPReq && typeof this.locationByIPReq.unsubscribe === 'function') {
            this.locationByIPReq.unsubscribe();
        }
        if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
            this.locationsReq.unsubscribe();
        }
        if (this.projectsReq && typeof this.projectsReq.unsubscribe === 'function') {
            this.projectsReq.unsubscribe();
        }
        if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
            this.truckTypesReq.unsubscribe();
        }
        if (this.connectionsReq && typeof this.connectionsReq.unsubscribe === 'function') {
            this.connectionsReq.unsubscribe();
        }
        if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
            this.workOrderTemplatesReq.unsubscribe();
        }
        if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
            this.jobDaysReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    NewJobComponent.prototype.timeChange = function (prop, e) {
        this.job[prop] = e;
    };
    NewJobComponent.prototype.changeTotalAmountType = function (type) {
        this.job.totalAmountType = type.id;
    };
    NewJobComponent.prototype.changeDailyDeliveryTargetType = function (type) {
        this.job.dailyDeliveryTargetType = type.id;
    };
    NewJobComponent.prototype.changeDeliveryIntervalUnits = function (interval) {
        this.job.deliveryIntervalUnit = interval.id;
    };
    NewJobComponent.prototype.weightUnitChange = function (tracking, val) {
        this.job[tracking + 'WeightUnit'] = val.value;
        if (tracking === 'invoice') {
            this.changeCloneHaulRate();
        }
    };
    NewJobComponent.prototype.getCoord = function (which, index) {
        var defaultCoords = [
            lodash_1.get(this.defaultLocation, 'longitude', -97.6023238),
            lodash_1.get(this.defaultLocation, 'latitude', 30.2178214)
        ];
        return lodash_1.get(this.job, which + 'Location.location.coordinates[' + index + ']', defaultCoords[index]);
    };
    NewJobComponent.prototype.getPaths = function (which) {
        var paths = [];
        var location = (new location_serializer_1.LocationSerializer).fromJson(lodash_1.get(this.job, which + 'Location'));
        if (location && location.geofence) {
            paths = location.geofence.coordinates[0].map(function (path) {
                return { lat: path[1], lng: path[0] };
            });
        }
        return [paths];
    };
    NewJobComponent.prototype.tagChange = function (tags) {
        this.job['tags'] = tags.map(function (t) { return (t.name); });
    };
    NewJobComponent.prototype.getWorkOrderTemplates = function (query, next) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (next === void 0) { next = false; }
        if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
            this.workOrderTemplatesReq.unsubscribe();
        }
        var request = this.workOrderTemplateService.list(query);
        if (next) {
            request = this.workOrderTemplateService.listNext();
        }
        else {
            this.workOrderTemplateOptions = [];
        }
        if (request) {
            this.workOrderTemplatesReq = request.subscribe(function (res) {
                _this.workOrderTemplateOptions = _this.workOrderTemplateOptions.concat(res);
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    NewJobComponent.prototype.truckTypeChange = function (e) {
        var _this = this;
        if (e.target && e.target.name === 'anyTruckType') {
            lodash_1.each(this.truckTypeMap, function (val, key) {
                _this.truckTypeMap[key] = false;
            });
            this.job.truckTypes = this.truckTypes;
            this.job.allowAnyTruckType = true;
        }
        else {
            this.job.allowAnyTruckType = false;
            this.truckTypeMap[e.target.value] = e.target.checked;
            var truckTypeIds_1 = lodash_1.filter(lodash_1.keys(this.truckTypeMap), function (key) { return _this.truckTypeMap[key]; });
            this.job.truckTypes = lodash_1.filter(this.truckTypes, function (truckType) { return truckTypeIds_1.includes(truckType.id); });
        }
        if (!this.job.truckTypes || !this.job.truckTypes.length) {
            this.job.allowAnyTruckType = true;
            this.job.truckTypes = this.truckTypes;
        }
    };
    NewJobComponent.prototype.dateToDatepickerFormat = function (date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };
    };
    NewJobComponent.prototype.onDateChanged = function (values) {
        this.job.dates = values;
    };
    NewJobComponent.prototype.changeLoadingLocation = function (location, place) {
        var _this = this;
        if (place === void 0) { place = 'start'; }
        if (location['id'] === 'new-loading-location' || location['id'] === 'new-unloading-location') {
            // deselect
            location.selected = false;
            var dialog = this.dialog.open(new_location_dialog_component_1.NewLocationDialogComponent, {
                width: '100%',
                height: '100%',
                disableClose: true
            });
            this.tagUrlWith('new-location');
            dialog.afterClosed().subscribe(function () {
                _this.tagUrlWith(null);
            });
            dialog.componentInstance.callback = function (newLocation) {
                newLocation.displayName = newLocation.street ? (newLocation.displayName + ' - ' + newLocation.street) : newLocation.displayName;
                if (place === 'start') {
                    _this.loadingLocations.forEach(function (_location) {
                        _location['selected'] = false;
                    });
                    newLocation.selected = true;
                    _this.loadingLocations.push(newLocation);
                    if (_this.startLocationDropdown) {
                        _this.startLocationDropdown.toggleOption(_this.loadingLocations.find(function (l) { return (l.id === newLocation.id); }), false);
                    }
                    var _newLocation = lodash_1.clone(newLocation);
                    _newLocation.selected = false;
                    _this.unloadingLocations.push(_newLocation);
                }
                else {
                    _this.unloadingLocations.forEach(function (_location) {
                        _location['selected'] = false;
                    });
                    newLocation.selected = true;
                    _this.unloadingLocations.push(newLocation);
                    if (_this.endLocationDropdown) {
                        _this.endLocationDropdown.toggleOption(_this.unloadingLocations.find(function (l) { return (l.id === newLocation.id); }), false);
                    }
                    var _newLocation = lodash_1.clone(newLocation);
                    _newLocation.selected = false;
                    _this.loadingLocations.push(_newLocation);
                }
                _this.job[place + 'Location'] = newLocation;
            };
        }
        else {
            this.job[place + 'Location'] = location;
        }
    };
    NewJobComponent.prototype.changeUnloadingLocation = function (location) {
        this.changeLoadingLocation(location, 'end');
    };
    NewJobComponent.prototype.editLocation = function (location, place) {
        var _this = this;
        if (place === void 0) { place = 'start'; }
        location.selected = false;
        var dialog = this.dialog.open(edit_location_dialog_component_1.EditLocationDialogComponent, {
            width: '100%',
            height: '100%',
            disableClose: true,
            data: { locationId: location && location.id }
        });
        this.tagUrlWith('edit-location');
        dialog.afterClosed().subscribe(function () {
            _this.tagUrlWith(null);
        });
        dialog.componentInstance.callback = function (editLocation) {
            editLocation.displayName = editLocation.street ? (editLocation.displayName + ' - ' + editLocation.street) : editLocation.displayName;
            if (place === 'start') {
                _this.loadingLocations.forEach(function (_location) {
                    _location['selected'] = false;
                });
                editLocation.selected = true;
                _this.loadingLocations.push(editLocation);
                if (_this.startLocationDropdown) {
                    _this.startLocationDropdown.toggleOption(_this.loadingLocations.find(function (l) { return (l.id === editLocation.id); }), false);
                }
                var _editLocation = lodash_1.clone(editLocation);
                _editLocation.selected = false;
                _this.unloadingLocations.push(_editLocation);
            }
            else {
                _this.unloadingLocations.forEach(function (_location) {
                    _location['selected'] = false;
                });
                editLocation.selected = true;
                _this.unloadingLocations.push(editLocation);
                if (_this.endLocationDropdown) {
                    _this.endLocationDropdown.toggleOption(_this.unloadingLocations.find(function (l) { return (l.id === editLocation.id); }), false);
                }
                var _editLocation = lodash_1.clone(editLocation);
                _editLocation.selected = false;
                _this.loadingLocations.push(_editLocation);
            }
            _this.job[place + 'Location'] = editLocation;
        };
    };
    NewJobComponent.prototype.tagUrlWith = function (fragment) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams),
            fragment: fragment
        });
    };
    NewJobComponent.prototype.invoiceTypeChanged = function () {
        this.changeCloneHaulRate();
        this.jobTypeChanged();
    };
    NewJobComponent.prototype.haulTypeChanged = function () {
        this.jobTypeChanged();
    };
    NewJobComponent.prototype.changeCloneHaulRate = function () {
        if (this['cloneHaulRate']) {
            this.job['haulType'] = this.job.invoiceType;
            this.job['haulWeightUnit'] = this.job['invoiceWeightUnit'];
            this.job['haulRate'] = this.job['rate'];
        }
    };
    NewJobComponent.prototype.locationDropdownSearch = function (place, term) {
        var _this = this;
        if (this.searchReq) {
            this.searchReq.unsubscribe();
        }
        var optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
        this.locationsDropdownConfig[place].loadingOptions = true;
        this.searchReq = this.locationService.list({
            search: term,
            archived: 'False'
        }).subscribe(function (res) {
            _this[optionsKey].splice(0, _this[optionsKey].length);
            if (optionsKey === 'loadingLocations') {
                _this[optionsKey] = [{
                        displayName: 'Add a New Address',
                        id: 'new-loading-location',
                        button: true
                    }];
            }
            else if (optionsKey === 'unloadingLocations') {
                _this[optionsKey] = [{
                        displayName: 'Add a New Address',
                        id: 'new-unloading-location',
                        button: true
                    }];
            }
            _this[optionsKey] = _this[optionsKey].concat(res);
            _this.locationsDropdownConfig[place].loadingOptions = false;
        });
    };
    NewJobComponent.prototype.locationDropdownNextPage = function (place) {
        var _this = this;
        var optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
        this.locationsDropdownConfig[place].loadingOptions = true;
        var next = this.locationService.listNext();
        if (next) {
            next.subscribe(function (res) {
                _this[optionsKey] = _this[optionsKey].concat(res);
                _this.locationsDropdownConfig[place].loadingOptions = false;
            });
        }
        else {
            this.locationsDropdownConfig[place].loadingOptions = false;
        }
    };
    NewJobComponent.prototype.updateOptionsFromOrgFeatureFlag = function (organization) {
        if (organization.features && organization.features.checkinOptions) {
            this.job['checkinOptions']['activeTrackingConfig'] = organization.features.checkinOptions['activeTrackingConfig'];
            this.job['checkinOptions']['qrConfig'] = organization.features.checkinOptions['qrConfig'];
            this.job['checkinOptions']['weightConfig'] = organization.features.checkinOptions['weightConfig'];
            this.job['checkinOptions']['loadImageConfig'] = organization.features.checkinOptions['loadImageConfig'];
            this.job['checkinOptions']['ticketImageConfig'] = organization.features.checkinOptions['ticketImageConfig'];
            this.job['checkinOptions']['ticketNumberConfig'] = organization.features.checkinOptions['ticketNumberConfig'];
            this.job['checkinOptions']['signatureImageConfig'] = organization.features.checkinOptions['signatureImageConfig'];
        }
        if (organization.features && organization.features.checkoutOptions) {
            this.job['checkoutOptions']['activeTrackingConfig'] = organization.features.checkoutOptions['activeTrackingConfig'];
            this.job['checkoutOptions']['qrConfig'] = organization.features.checkoutOptions['qrConfig'];
            this.job['checkoutOptions']['weightConfig'] = organization.features.checkoutOptions['weightConfig'];
            this.job['checkoutOptions']['loadImageConfig'] = organization.features.checkoutOptions['loadImageConfig'];
            this.job['checkoutOptions']['ticketImageConfig'] = organization.features.checkoutOptions['ticketImageConfig'];
            this.job['checkoutOptions']['ticketNumberConfig'] = organization.features.checkoutOptions['ticketNumberConfig'];
            this.job['checkoutOptions']['signatureImageConfig'] = organization.features.checkoutOptions['signatureImageConfig'];
        }
    };
    NewJobComponent.prototype.jobTypeChanged = function () {
        if (this.job && this.job.qrJobType === 'loading_import') {
            if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
                this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
            }
            else {
                this.job.checkinOptions.ticketNumberConfig = 'required';
                this.job.checkinOptions.ticketImageConfig = 'required';
                this.job.checkinOptions.qrConfig = 'optional';
            }
            if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
                this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
            }
            else {
                this.job.checkoutOptions.ticketNumberConfig = 'optional';
                this.job.checkoutOptions.ticketImageConfig = 'optional';
                this.job.checkoutOptions.qrConfig = 'required';
            }
            this.job.checkinOptions.states['ticket_number'] = true;
            this.job.checkinOptions.states['ticket_image'] = true;
            this.job.checkinOptions.states['signature_image'] = false;
            this.job.checkinOptions.states['active_tracking'] = false;
            this.job.checkinOptions.states['log_weight'] = false;
            this.job.checkinOptions.states['qr_code'] = true;
            this.job.checkoutOptions.states['ticket_number'] = false;
            this.job.checkoutOptions.states['ticket_image'] = true;
            this.job.checkoutOptions.states['signature_image'] = false;
            this.job.checkoutOptions.states['active_tracking'] = false;
            this.job.checkoutOptions.states['log_weight'] = true;
            this.job.checkoutOptions.states['qr_code'] = true;
        }
        else if (this.job && this.job.qrJobType === 'unloading_import') {
            if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
                this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
            }
            else {
                this.job.checkinOptions.ticketNumberConfig = 'optional';
                this.job.checkinOptions.ticketImageConfig = 'optional';
                this.job.checkinOptions.weightConfig = 'optional';
                this.job.checkinOptions.qrConfig = 'optional';
            }
            if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
                this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
            }
            else {
                this.job.checkoutOptions.ticketNumberConfig = 'optional';
                this.job.checkoutOptions.ticketImageConfig = 'optional';
                this.job.checkoutOptions.weightConfig = 'optional';
                this.job.checkoutOptions.qrConfig = 'required';
            }
            this.job.checkinOptions.states['ticket_number'] = true;
            this.job.checkinOptions.states['ticket_image'] = true;
            this.job.checkinOptions.states['signature_image'] = false;
            this.job.checkinOptions.states['active_tracking'] = false;
            this.job.checkinOptions.states['log_weight'] = true;
            this.job.checkinOptions.states['qr_code'] = true;
            this.job.checkoutOptions.states['ticket_number'] = true;
            this.job.checkoutOptions.states['ticket_image'] = true;
            this.job.checkoutOptions.states['signature_image'] = false;
            this.job.checkoutOptions.states['active_tracking'] = false;
            this.job.checkoutOptions.states['log_weight'] = true;
            this.job.checkoutOptions.states['qr_code'] = true;
        }
        else if (this.job && this.job.qrJobType === 'export') {
            if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
                this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
            }
            else {
                this.job.checkinOptions.ticketNumberConfig = 'optional';
                this.job.checkinOptions.ticketImageConfig = 'optional';
                this.job.checkinOptions.weightConfig = 'optional';
                this.job.checkinOptions.qrConfig = 'required';
            }
            if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
                this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
            }
            else {
                this.job.checkoutOptions.qrConfig = 'optional';
            }
            this.job.checkinOptions.states['ticket_number'] = true;
            this.job.checkinOptions.states['ticket_image'] = true;
            this.job.checkinOptions.states['signature_image'] = false;
            this.job.checkinOptions.states['active_tracking'] = false;
            this.job.checkinOptions.states['log_weight'] = true;
            this.job.checkinOptions.states['qr_code'] = true;
            this.job.checkoutOptions.states['ticket_number'] = false;
            this.job.checkoutOptions.states['ticket_image'] = false;
            this.job.checkoutOptions.states['signature_image'] = false;
            this.job.checkoutOptions.states['active_tracking'] = false;
            this.job.checkoutOptions.states['log_weight'] = false;
            this.job.checkoutOptions.states['qr_code'] = true;
        }
        else {
            if (this.preference && this.preference.blob && this.preference.blob['checkinOptions']) {
                this.job.checkinOptions = Object.assign(this.job.checkinOptions, this.preference.blob['checkinOptions']);
            }
            else {
                this.job.checkinOptions.qrConfig = 'optional';
            }
            if (this.preference && this.preference.blob && this.preference.blob['checkoutOptions']) {
                this.job.checkoutOptions = Object.assign(this.job.checkoutOptions, this.preference.blob['checkoutOptions']);
            }
            else {
                this.job.checkoutOptions.qrConfig = 'optional';
            }
            this.job.checkinOptions.states['ticket_number'] = false;
            this.job.checkinOptions.states['ticket_image'] = false;
            this.job.checkinOptions.states['signature_image'] = false;
            this.job.checkinOptions.states['active_tracking'] = false;
            this.job.checkinOptions.states['log_weight'] = false;
            this.job.checkinOptions.states['qr_code'] = true;
            this.job.checkoutOptions.states['ticket_number'] = false;
            this.job.checkoutOptions.states['ticket_image'] = false;
            this.job.checkoutOptions.states['signature_image'] = false;
            this.job.checkoutOptions.states['active_tracking'] = false;
            this.job.checkoutOptions.states['log_weight'] = false;
            this.job.checkoutOptions.states['qr_code'] = true;
        }
    };
    NewJobComponent.prototype.isValid = function () {
        var timeValid = true;
        if (this.job.dates && this.job.dates.length > 0) {
            timeValid = this.job.shift1StartTime ? true : false;
        }
        var amountNeededValue = true;
        if (this.job['amountNeeded']) {
            this.job['amountNeeded'] = this.job['amountNeeded'] && this.job['amountNeeded'].replace(/[^\d.]/g, '');
            amountNeededValue = (Number(this.job['amountNeeded']) >= 0) ? true : false;
        }
        return !!this.job['name'] && this.job['material'] &&
            this.job.rate !== null && this.job.rate !== undefined && parseFloat(this.job.rate) >= 0 &&
            (this.cloneHaulRate || (this.job.haulRate !== null && this.job.haulRate !== undefined && parseFloat(this.job.haulRate) >= 0)) &&
            timeValid && amountNeededValue &&
            lodash_1.get(this, 'job.startLocation.id');
    };
    NewJobComponent.prototype.submit = function (dispatch) {
        var _this = this;
        if (dispatch === void 0) { dispatch = false; }
        if (this.loading || !this.isValid()) {
            return;
        }
        this.loading = true;
        // Convert shift times to 24-hour format
        if (this.job['shift1StartTime']) {
            this.job['shift1StartTime'] = moment(this.job['shift1StartTime'], ['h:mm A']).format('HH:mm');
            this.job['shift1EndTime'] = moment(this.job['shift1EndTime'], ['h:mm A']).format('HH:mm');
            if (this.job['shift2StartTime']) {
                this.job['shift2StartTime'] = moment(this.job['shift2StartTime'], ['h:mm A']).format('HH:mm');
            }
            if (this.job['shift2EndTime']) {
                this.job['shift2EndTime'] = moment(this.job['shift2EndTime'], ['h:mm A']).format('HH:mm');
            }
        }
        var _job = lodash_1.cloneDeep(this.job);
        if (this.newProject || !this.projectControl || !this.projectControl.value ||
            (this.projectControl.value.name !== _job.project.name)) {
            delete _job.project.id;
            if (this.projectControl && this.projectControl.value && this.projectControl.value.name) {
                _job.project.name = this.projectControl.value.name;
            }
            else {
                _job.project.name = this.projectControl.value;
            }
            // TODO(jlee): Remove after migration to new processes
            if (_job.project && (!_job.project.customerOrganization || !_job.project.customerOrganization.id)) {
                _job.project.customerOrganization = _job.customerOrganization;
            }
            this.projectService.save(_job.project).pipe(operators_1.mergeMap(function (project) {
                _job.project = project;
                _this.newProject = false;
                return _this.jobService.save(_job);
            })).subscribe(function (res) {
                _this.loading = false;
                _this.routeAfterSave(res, dispatch);
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
                _this.loading = false;
            });
        }
        else {
            // TODO(jlee): Remove after migration to new processes
            if (_job.project && (!_job.project.customerOrganization || !_job.project.customerOrganization.id)) {
                _job.project.customerOrganization = _job.customerOrganization;
            }
            this.jobService.save(_job).subscribe(function (res) {
                _this.loading = false;
                _this.routeAfterSave(res, dispatch);
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    NewJobComponent.prototype.routeAfterSave = function (job, dispatch) {
        if (dispatch) {
            this.router.navigate(['/dispatch', job.id]);
        }
        else if (this.returnTo && this.returnTo.length) {
            if (this.returnTo.includes('?')) {
                var route = this.returnTo.split('?')[0];
                var params_1 = {};
                this.returnTo.split('?')[1].split('&').forEach(function (param) {
                    var paramData = param.split('=');
                    params_1[paramData[0]] = paramData[1];
                });
                this.router.navigate([route], { queryParams: params_1 });
            }
            else {
                this.router.navigate([this.returnTo]);
            }
        }
        else if (this.device.mobile || this.device.tablet) {
            this.router.navigate(['/dispatch']);
        }
        else {
            this.router.navigate(['/daily']);
        }
    };
    NewJobComponent.prototype.selectProject = function (project) {
        this.job.project = project;
        this.projectControl.setValue(this.job.project);
        this.projectValueSet = true;
    };
    NewJobComponent.prototype.loadProjectOptions = function () {
        var _this = this;
        this.allSubscriptionsToUnsubscribe.push(this.projectControl.valueChanges.pipe(operators_1.startWith(''), operators_1.debounceTime(300), operators_1.map(function (value) { return typeof value === 'string' ? value : value.name; })).subscribe(function (term) {
            _this.projectsReq = _this.projectService.list({
                ordering: 'name',
                search: term
            }).subscribe(function (projects) {
                _this.projectOptions = projects.map(function (project) {
                    return { name: project.name, id: project.id };
                });
                if (_this.job.project && !_this.projectValueSet) {
                    var project = lodash_1.find(_this.projectOptions, { id: _this.job.project.id });
                    if (project) {
                        _this.selectProject(project);
                        _this.projectOptions = lodash_1.reject(_this.projectOptions, project);
                        _this.projectOptions.unshift(project);
                    }
                }
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }));
    };
    NewJobComponent.prototype.loadProjectsOnScroll = function (event) {
        var _this = this;
        if (event.target.scrollTop > 300) {
            var o = this.projectService.listNext();
            if (o) {
                o.subscribe(function (projects) {
                    _this.projectOptions = _this.projectOptions.concat(projects.map(function (project) {
                        return { name: project.name, id: project.id };
                    }));
                    _this.customerDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                });
            }
        }
    };
    NewJobComponent.prototype.projectScrollEvent = function () {
        var _this = this;
        if (this.projectAutocomplete && this.projectAutocomplete.panel) {
            var dropdown = this.projectAutocomplete.panel.nativeElement;
            dropdown.addEventListener('scroll', function (event) { return _this.loadProjectsOnScroll(event); });
        }
    };
    NewJobComponent.prototype.projectDisplayFn = function (project) {
        return project ? project.name : undefined;
    };
    NewJobComponent.prototype.selectCustomer = function (customer) {
        if (customer.name === 'New Customer') {
            this.openNewCustomer();
        }
        else {
            this.job.customerOrganization = customer;
        }
    };
    NewJobComponent.prototype.reloadCustomerOptions = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            if (this.device.mobile || this.device.tablet) {
                this.customerOptions = [];
            }
            else {
                this.customerOptions = [{ name: 'New Customer', id: 'new-customer', button: true }];
            }
        }
        this.customerLoading = true;
        if (this.connectionsReq) {
            this.connectionsReq.unsubscribe();
        }
        this.connectionsReq = this.connectionService.list({
            ordering: 'organization__name',
            search: this.customerSearch
        }).subscribe(function (connections) {
            if (append) {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return new organization_1.Organization({ name: connection.organization.name, id: connection.organization.id });
                }));
            }
            else {
                _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                    return new organization_1.Organization({ name: connection.organization.name, id: connection.organization.id });
                }));
            }
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.customerLoading = false;
            _this.customerDropdownConfig.loadingOptions = false;
        });
    };
    NewJobComponent.prototype.customerDropdownSearch = function (term) {
        this.customerOptions = [];
        this.customerDropdownConfig.loadingOptions = true;
        this.customerSearch = term;
        this.reloadCustomerOptions();
        this.customerDropdownConfig.loadingOptions = false;
    };
    NewJobComponent.prototype.customerDropdownNextPage = function () {
        var _this = this;
        if (!this.customerDropdownConfig.loadingOptions) {
            var o = this.connectionService.listNext();
            if (o) {
                this.customerDropdownConfig.loadingOptions = true;
                o.subscribe(function (connections) {
                    _this.customerOptions = _this.customerOptions.concat(connections.map(function (connection) {
                        return { name: connection.organization.name, id: connection.organization.id };
                    }));
                    _this.customerDropdownConfig.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    _this.customerDropdownConfig.loadingOptions = false;
                });
            }
        }
    };
    NewJobComponent.prototype.openNewCustomer = function () {
        var _this = this;
        this.customerDialogRef = this.dialog.open(new_customer_dialog_component_1.NewCustomerDialogComponent, {
            width: '444px'
        });
        this.customerDialogRef.componentInstance.callback = this.saveCustomerCallback;
        this.customerDialogRef.afterClosed().subscribe(function (result) {
            if (_this.job.customerOrganization) {
                _this.selectCustomer(new organization_1.Organization({
                    name: _this.job.customerOrganization.name,
                    id: _this.job.customerOrganization.id
                }));
            }
            else {
                _this.customerDropdown.deselectAll();
            }
        });
    };
    NewJobComponent.prototype.onSelect = function (dropdownType, e) {
        switch (dropdownType) {
            case 'workOrderTemplate':
                this.job.workOrderTemplate = e;
                break;
        }
        this.job[dropdownType] = e;
    };
    NewJobComponent.prototype.dropdownSearch = function (term, type) {
        switch (type) {
            case 'workOrderTemplate':
                this.getWorkOrderTemplates({ search: term });
                break;
        }
    };
    NewJobComponent.prototype.dropdownNextPage = function (type, e) {
        var _this = this;
        var config, service, options, o;
        switch (type) {
            case 'workOrderTemplate':
                config = this.workOrderTemplateDropdownConfig;
                service = this.workOrderTemplateService;
                options = this.workOrderTemplateOptions;
                o = service.listNext();
                break;
        }
        if (!config.loadingOptions) {
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'workOrderTemplate':
                            _this.workOrderTemplateOptions = _this.workOrderTemplateOptions.concat(results);
                            break;
                    }
                    config.loadingOptions = false;
                }, function (err) {
                    _this.errors = api_service_1.parseErrors(err);
                    config.loadingOptions = false;
                });
            }
        }
    };
    NewJobComponent.prototype.getJobDays = function (futureDatesOnly) {
        var _this = this;
        if (futureDatesOnly === void 0) { futureDatesOnly = true; }
        if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
            this.jobDaysReq.unsubscribe();
        }
        this.loading = true;
        this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(function (days) {
            if (futureDatesOnly) {
                days = days.filter(function (day) { return moment(day).toDate() >= moment().startOf('day').toDate(); });
            }
            _this.job.dates = days.map(function (day) { return moment(day).toDate(); });
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    NewJobComponent.prototype.copyToJob = function (job) {
        var _this = this;
        this.job = lodash_1.cloneDeep(job);
        this.getJobDays();
        this.job.id = null;
        this.tagInput.forceSelectedTags(this.job.tags);
        if (this.job.haulRate === this.job.rate &&
            this.job.haulType === this.job.invoiceType &&
            this.job.haulWeightUnit === this.job.invoiceWeightUnit) {
            this['cloneHaulRate'] = true;
        }
        this.setStartLocation();
        this.setEndLocation();
        if (this.job.shift1StartTime) {
            this.job.shift1StartTime = moment(this.job.shift1StartTime, 'HH:mm:ss').format('h:mm A');
        }
        if (this.job.shift1EndTime) {
            this.job.shift1EndTime = moment(this.job.shift1EndTime, 'HH:mm:ss').format('h:mm A');
        }
        if (this.job.shift2StartTime) {
            this.job.shift2StartTime = moment(this.job.shift2StartTime, 'HH:mm:ss').format('h:mm A');
        }
        if (this.job.shift2EndTime) {
            this.job.shift2EndTime = moment(this.job.shift2EndTime, 'HH:mm:ss').format('h:mm A');
        }
        if (job.project && job.project.id) {
            this.selectProject(job.project);
        }
        if (!job.allowAnyTruckType) {
            lodash_1.each(this.truckTypes, function (val) { _this.truckTypeMap[val.id] = false; });
            lodash_1.each(job.truckTypes, function (val) { _this.truckTypeMap[val.id] = true; });
        }
        this.jobTypeChanged();
    };
    NewJobComponent.prototype.setStartLocation = function () {
        if (this.job && this.job.startLocation && this.job.startLocation.id) {
            var _location = lodash_1.find(this.loadingLocations, { id: this.job.startLocation.id });
            if (_location) {
                this.loadingLocations = lodash_1.reject(this.loadingLocations, _location);
            }
            else {
                _location = { id: this.job.startLocation.id, displayName: this.job.startLocation.displayName, button: false };
            }
            this.loadingLocations.unshift(_location);
            this.startLocationDropdown.selectedOption = _location;
        }
    };
    NewJobComponent.prototype.setEndLocation = function () {
        if (this.job && this.job.endLocation && this.job.endLocation.id) {
            var _location = lodash_1.find(this.unloadingLocations, { id: this.job.endLocation.id });
            if (_location) {
                this.unloadingLocations = lodash_1.reject(this.unloadingLocations, _location);
            }
            else {
                _location = { id: this.job.endLocation.id, displayName: this.job.endLocation.displayName, button: false };
            }
            this.unloadingLocations.unshift(_location);
            this.endLocationDropdown.selectedOption = _location;
        }
    };
    NewJobComponent.prototype.checkIfOvernight = function (shift) {
        if (shift === 'shift1' && this.job.shift1StartTime && this.job.shift1EndTime) {
            var startTime = moment(this.job.shift1StartTime, 'h:mm a');
            var endTime = moment(this.job.shift1EndTime, 'h:mm a');
            this.job.shift1Overnight = endTime.isBefore(startTime);
        }
        else if (shift === 'shift2' && this.job.shift2StartTime && this.job.shift2EndTime) {
            var startTime = moment(this.job.shift2StartTime, 'h:mm a');
            var endTime = moment(this.job.shift2EndTime, 'h:mm a');
            this.job.shift2Overnight = endTime.isBefore(startTime);
        }
    };
    NewJobComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferenceService.list({
            name: 'GeneralOrganizationPreferences',
            type: 'organization',
            organization: this.user && this.user.organization && this.user.organization.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                var preference = preferences[0];
                _this.preference = preference;
                if ((_this.user.organization.features && _this.user.organization.features.checkinOptions === undefined) && preference.blob) {
                    _this.user.organization.features.checkinOptions = preference.blob.checkinOptions;
                }
                if ((_this.user.organization.features && _this.user.organization.features.checkoutOptions === undefined) && preference.blob) {
                    _this.user.organization.features.checkoutOptions = preference.blob.checkoutOptions;
                }
            }
            _this.jobTypeChanged();
        }, 
        // error
        function () { }, 
        // finally 
        function () {
            _this.loadOrganizationDefaults();
        });
    };
    NewJobComponent.prototype.loadOrganizationDefaults = function () {
        if (this.user && this.user.organization) {
            this.job.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
            this.job.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
            this.updateOptionsFromOrgFeatureFlag(this.user.organization);
        }
    };
    return NewJobComponent;
}());
exports.NewJobComponent = NewJobComponent;
