"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var router_1 = require("@angular/router");
var http_1 = require("@angular/common/http");
var material_1 = require("@angular/material");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var ngx_device_detector_1 = require("ngx-device-detector");
var api_service_1 = require("../shared/api.service");
var job_service_1 = require("./job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var cancel_job_dialog_component_1 = require("./cancel-job-dialog.component");
var new_days_collaboration_dialog_component_1 = require("../collaborators/new-days-collaboration-dialog.component");
var assignment_service_1 = require("../assignments/assignment.service");
var authentication_service_1 = require("../shared/authentication.service");
var edit_job_dialog_component_1 = require("./edit-job-dialog.component");
var edit_slorder_id_dialog_component_1 = require("./edit-slorder-id-dialog.component");
var publish_job_dialog_component_1 = require("./publish-job-dialog.component");
var completed_trips_component_1 = require("../trips/completed-trips/completed-trips.component");
var completed_punch_cards_component_1 = require("../punch-cards/completed-punch-cards.component");
var message_drivers_dialog_component_1 = require("../messages/message-drivers-dialog.component");
var export_dialog_component_1 = require("../shared/export-dialog/export-dialog.component");
var shared_1 = require("../shared");
var control_option_1 = require("../shared/control-option");
var job_map_component_1 = require("../map/job-map/job-map.component");
var job_event_stat_service_1 = require("../job-event-stats/job-event-stat.service");
var static_data_1 = require("../shared/static-data");
var preference_service_1 = require("../preferences/preference.service");
var device_1 = require("../shared/device");
var JobDetailComponent = /** @class */ (function () {
    function JobDetailComponent(cdr, route, router, location, deviceDetectorService, jobService, jobEventService, jobEventStatService, assignmentService, preferenceService, authenticationService, dialog) {
        var _this = this;
        this.cdr = cdr;
        this.route = route;
        this.router = router;
        this.location = location;
        this.deviceDetectorService = deviceDetectorService;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.jobEventStatService = jobEventStatService;
        this.assignmentService = assignmentService;
        this.preferenceService = preferenceService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.device = new device_1.Device();
        this.view = 'stats';
        this.jobEvents = [];
        this.jobEventsLoading = true;
        // preference: Preference = new PreferenceSerializer().fromJson({
        //   blob: { mapStyle: 'google-map-style' }
        // });
        this.preferenceReqs = {};
        this.preferences = {};
        this.loadingMapPreferences = true;
        this.polygons = [];
        this.controlState = 'trips';
        this.controls = [];
        this.hours = [];
        this.assignments = {
            items: [],
            errors: [],
            loading: false,
        };
        this.loading = true;
        this.errors = [];
        this.locationUpdates = [];
        this.locations = [];
        this.routes = [];
        this.parsedRoutes = [];
        this.coordinates = [];
        this.markerType = 'driver-initials';
        this.markerStatus = 'each-segment';
        this.actionsDropdownOptions = [];
        this.actionsDropdownConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.mapOptions = {
            zoom: 10,
            disableDefaultUI: true,
            fullscreenControl: true,
            streetViewControl: false,
            mapTypeControl: false
        };
        this.driverListOpen = false;
        this.jobOverviewOpen = false;
        this.assignmentDetails = {};
        this.allSubscriptionsToUnsubscribe = [];
        this.saveJobDaysCallback = function (daysAdded) {
            if (_this.jobEvent && _this.jobEvent.canShare) {
                _this.openNewDaysDialog(daysAdded);
            }
        };
        this.saveSLOrderIDCallback = function (e) {
            _this.jobEvent.slorderId = e.slorderId;
        };
        this.sendJobCallback = function (e) {
            // Update Sent Status
        };
        this.publishJobCallback = function (e) {
            // Update Published Status
        };
        this.cancelJobCallback = function (e) {
            _this.jobEvent = e.length && e[0];
        };
        this.viewSignaturesCallback = function () {
            // Update Signature Status Icon
        };
        this.messageDriversCallback = function () {
            // Update Notice Message
        };
    }
    JobDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.loading = true;
        this.getPreferences('GeneralUserPreferences');
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            if (result && result.params['jobId']) {
                _this.getJob(result.params['jobId'], result.params['jobEventId']);
                _this.getJobEvents(result.params['jobId']);
            }
        }));
    };
    JobDetailComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.locationUpdateSub) {
            try {
                this.locationUpdateSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.assignments && this.assignments.items.length) {
            try {
                this.assignments.items.forEach(function (assignment) {
                    if (assignment.durationTimer) {
                        assignment.durationTimer.unsubscribe();
                    }
                });
            }
            catch (e) { }
        }
        if (this.statusChangeTimerSub) {
            try {
                this.statusChangeTimerSub.unsubscribe();
            }
            catch (e) { }
        }
        if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
            this.jobReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        Object.keys(this.preferenceReqs).forEach(function (preferenceReqKey) {
            if (_this.preferenceReqs[preferenceReqKey] && typeof _this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
                try {
                    _this.preferenceReqs[preferenceReqKey].unsubscribe();
                }
                catch (e) { }
            }
        });
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    JobDetailComponent.prototype.getPreferences = function (preferenceKey, params) {
        var _this = this;
        if (preferenceKey === void 0) { preferenceKey = 'GeneralUserPreferences'; }
        if (params === void 0) { params = null; }
        if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
            try {
                this.preferenceReqs[preferenceKey].unsubscribe();
            }
            catch (e) { }
        }
        var currentUser = this.authenticationService.user();
        if (!params) {
            params = {
                name: preferenceKey,
                type: 'user',
                profile: currentUser.id
            };
        }
        this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preferences[preferenceKey] = preferences[0];
                _this.parsePreferences(preferenceKey);
            }
            else if (preferenceKey === 'GeneralUserPreferences') {
                _this.loadingMapPreferences = false;
                _this.parsePreferences(preferenceKey);
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Examine the found preference for the provided key to determine if it has
     * the necessary data.
     *
     * Once the preferences data is handled, update the map
     */
    JobDetailComponent.prototype.parsePreferences = function (preferenceKey) {
        if (preferenceKey === void 0) { preferenceKey = 'JobDetail'; }
        if (preferenceKey === 'GeneralOrganizationPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
                mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
                this.markerType = this.preferences[preferenceKey].blob['markerType'];
            }
            this.markerStatus = 'each-segment';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
                this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
            }
            this.loadingMapPreferences = false;
            this.mapOptions = static_data_1.mapOptions({
                disableDefaultUI: true,
                scrollwheel: true,
                fullscreenControl: true,
                streetViewControl: false
            }, {}, {
                mapStyle: mapStyle
            });
        }
        if (preferenceKey === 'GeneralUserPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
                mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
                this.markerType = this.preferences[preferenceKey].blob['markerType'];
            }
            if (!this.markerStatus) {
                this.markerStatus = 'each-segment';
            }
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
                this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
            }
            this.loadingMapPreferences = false;
            this.mapOptions = static_data_1.mapOptions({
                zoom: 10,
                disableDefaultUI: true,
                fullscreenControl: true,
                streetViewControl: false,
                mapTypeControl: false
            }, {}, {
                mapStyle: mapStyle
            });
        }
    };
    JobDetailComponent.prototype.getJob = function (id, jobEventId) {
        var _this = this;
        if (jobEventId === void 0) { jobEventId = null; }
        if (id) {
            if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
                this.jobReq.unsubscribe();
            }
            this.jobReq = this.jobService.get(id).subscribe(function (res) {
                _this.job = res;
                if (jobEventId) {
                    _this.getJobEvent(jobEventId);
                }
                else {
                    _this.getJobEvents(_this.job.id, {}, true);
                }
            }, function (err) {
                _this.errors = err;
                _this.loading = false;
            });
        }
    };
    JobDetailComponent.prototype.getJobEvent = function (id) {
        var _this = this;
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        this.jobEventReq = this.jobEventService.getJobEvent(id).subscribe(function (jobEvent) {
            _this.selectJobEvent(jobEvent);
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    /**
     * @param  {} jobId
     * @param  {} query = {}
     * @param  {} selectFirst = false
     * fetch available job events for new job event picker
     * sets the first jobevent available as selected
     */
    JobDetailComponent.prototype.getJobEvents = function (jobId, query, selectFirst) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (selectFirst === void 0) { selectFirst = false; }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        this.jobEventsReq = this.jobEventService.listCompact(__assign({ ordering: '-shift1_start', job: jobId }, query)).subscribe(function (jobEvents) {
            _this.jobEvents = jobEvents;
            if (selectFirst && jobEvents[0]) {
                _this.getJobEvent(jobEvents[0].id);
            }
            _this.jobEventsLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.jobEventsLoading = false;
        });
    };
    JobDetailComponent.prototype.getNextJobEvents = function () {
        var _this = this;
        if (this.jobEventService.nextUri) {
            if (this.jobEventsNextReq && typeof this.jobEventsNextReq.unsubscribe === 'function') {
                this.jobEventsNextReq.unsubscribe();
            }
            this.jobEventsNextReq = this.jobEventService.listNext().subscribe(function (results) {
                _this.jobEvents = _this.jobEvents.concat(results);
            }, function (err) {
                _this.errors = api_service_1.parseErrors(err);
            });
        }
    };
    /**
     * @param  {} jobEvent
     * @returns void
     * This function is called when jobevent is changed
     */
    JobDetailComponent.prototype.selectJobEvent = function (jobEvent) {
        var _this = this;
        if (!jobEvent.job || !jobEvent.job.id) {
            this.getJobEvent(jobEvent.id);
        }
        else {
            this.jobEvent = jobEvent;
            if (this.jobEventsDropdown) {
                this.jobEventsDropdown.selectedOption = this.jobEvent;
                if (this.jobEvents && this.jobEvents.findIndex(function (j) { return (j.id === _this.jobEvent.id); }) === -1) {
                    this.jobEvents.unshift(this.jobEvent);
                }
            }
            this.updateUrl();
            this.triggerMapUpdate();
            this.getAssignmentsAndStats();
            this.addActionButtons();
            this.setControlState();
        }
    };
    JobDetailComponent.prototype.updateUrl = function () {
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var jobId = this.jobEvent && this.jobEvent.job && this.jobEvent.job.id;
        var url = this.route.snapshot.url[0] && this.route.snapshot.url[0].path;
        if (this.job && this.jobEvent) {
            this.location.replaceState(url + '/' + jobId + '/' + jobEventId);
        }
    };
    JobDetailComponent.prototype.addActionButtons = function () {
        this.actionsDropdownOptions = [
            { name: 'Clone Job', button: true },
            { name: 'Message Drivers', button: true }
        ];
        if (this.jobEvent && this.jobEvent.canShare) {
            // this.actionsDropdownOptions.push({ name: 'Share to Marketplace', button: true });
        }
        if (this.jobEvent && this.jobEvent.canEdit) {
            if (this.authenticationService.hasLafargeRegion()) {
                this.actionsDropdownOptions.push({ name: 'Edit SLOrder ID', button: true });
            }
            this.actionsDropdownOptions.push({ name: 'Edit Job', button: true });
            this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
            this.actionsDropdownOptions.push({ name: 'Cancel Day', button: true });
        }
        if (this.jobEvent && !this.jobEvent.future) {
            this.actionsDropdownOptions.push({ name: 'Create Trip', button: true });
            this.actionsDropdownOptions.push({ name: 'Create Punch Card', button: true });
        }
        this.actionsDropdownOptions.push({ name: 'View Tickets', button: true });
    };
    JobDetailComponent.prototype.addAssignmentExportActionButton = function () {
        if (this.assignments && this.assignments.items && this.assignments.items.length) {
            this.actionsDropdownOptions.splice(0, 0, { name: 'Export Assignments', button: true });
        }
        else {
            this.actionsDropdownOptions.splice(0, 0, { name: 'Export Assignments', button: true, disabled: true });
        }
    };
    JobDetailComponent.prototype.getAssignmentsAndStats = function () {
        var _this = this;
        this.assignments.loading = true;
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var effectiveRateCalculator = '';
        var enabledFeatures = this.authenticationService.enabledFeatures();
        if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
            effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
        }
        this.jobEventStatService.getStats(jobEventId, {
            calculator: effectiveRateCalculator
        }).subscribe(function (stats) {
            _this.jobEvent.stats = stats;
            _this.assignmentService.listAll(5, {
                jobevent: jobEventId,
                completed: 'False',
                dispatched: 'True',
                include_trips: 'True',
            }).subscribe(function (assignments) {
                var _jobStart = moment(lodash_1.clone(_this.jobEvent.jobStart));
                var _jobEnd = moment(new Date(lodash_1.clone(_this.jobEvent.jobEnd)));
                _jobEnd.add(2, 'hour');
                while (_jobStart <= _jobEnd) {
                    var hour = moment(_jobStart).format('h a');
                    if (!_this.hours.includes(hour)) {
                        _this.hours.push(hour);
                    }
                    _jobStart.add(1, 'hour');
                }
                _this.assignments.items = assignments.map(function (assignment) {
                    if (assignment.driver) {
                        _this.loadAssignmentTrip(assignment);
                        _this.loadAssignmentPunchCard(assignment);
                    }
                    return assignment;
                }).sort(function (a, b) { return ((a.uniqueStart < b.uniqueStart) ? -1 : (a.uniqueStart > b.uniqueStart) ? 1 : 0); });
                _this.addAssignmentExportActionButton();
                _this.monitorStatusChanges();
                var currentMinTime;
                _this.assignments.items.forEach(function (a) {
                    var loadingArrivalTimes = [];
                    a.trips.forEach(function (trip) {
                        if (trip.loadingArrivalTime) {
                            loadingArrivalTimes.push(moment(trip.loadingArrivalTime));
                        }
                    });
                    if (loadingArrivalTimes.length) {
                        var assignmentMinTime = moment.min.apply(moment, loadingArrivalTimes);
                        var formattedAssignmentMinTime = assignmentMinTime && assignmentMinTime.format('h:mm A');
                        if (!_this.jobEvent.stats.firstLoad || (_this.jobEvent.stats.firstLoad && assignmentMinTime && assignmentMinTime.isBefore(currentMinTime))) {
                            _this.jobEvent.stats.firstLoad = a.truck.name + ' - ' + formattedAssignmentMinTime;
                            currentMinTime = assignmentMinTime;
                        }
                    }
                });
            }, function (err) {
                _this.assignments.errors = err;
            }, function () {
                _this.assignments.loading = false;
            });
        }, function (err) { return console.error(err); });
    };
    JobDetailComponent.prototype.loadAssignmentTrip = function (assignment) {
        var incompleteTrips = lodash_1.filter(assignment.trips, { completed: false });
        if (incompleteTrips && incompleteTrips.length) {
            assignment.driver.trip = incompleteTrips[0];
            this.durationInMinutes(assignment.driver.trip);
        }
    };
    JobDetailComponent.prototype.loadAssignmentPunchCard = function (assignment) {
        if (this.jobEvent && assignment.punchCards && assignment.punchCards.length) {
            var _punchCard = assignment.punchCards[0];
            var _punchCardStart = moment(lodash_1.clone(_punchCard.startTimeTimestamp));
            var _punchCardEnd = moment(lodash_1.clone(_punchCard.endTimeTimestamp));
            var _start = moment(lodash_1.clone(this.jobEvent.jobStart));
            var _end = moment(new Date(lodash_1.clone(this.jobEvent.jobEnd)));
            while (_punchCardStart <= _start) {
                _start.subtract(1, 'hour');
                var hour = moment(_start).format('h a');
                if (!this.hours.includes(hour)) {
                    this.hours.unshift(hour);
                }
            }
            while (_punchCardEnd > _end) {
                _end.add(1, 'hour');
                var hour = moment(_end).format('h a');
                if (!this.hours.includes(hour)) {
                    this.hours.push(hour);
                }
            }
            assignment.driver.punchCard = _punchCard;
            this.durationInMinutes(assignment.driver.punchCard);
        }
    };
    JobDetailComponent.prototype.triggerMapUpdate = function () {
        if (this.jobMap) {
            this.jobMap.triggerMapUpdate();
        }
    };
    JobDetailComponent.prototype.isDispatchable = function () {
        if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.project && this.jobEvent.job.project.customerOrganization) {
            var organization = this.authenticationService.getOrganization();
            if (organization && organization.id !== this.jobEvent.job.project.customerOrganization.id) {
                var endDate = new Date(this.jobEvent.jobEnd);
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                if (endDate >= today) {
                    return true;
                }
            }
        }
        return false;
    };
    JobDetailComponent.prototype.isSendable = function () {
        // TODO: What scenarios would render a Job "unsendable"?
        return true;
    };
    JobDetailComponent.prototype.openShareJob = function () {
        if (this.isSendable()) {
            var dialog = this.dialog.open(publish_job_dialog_component_1.PublishJobDialogComponent, {
                width: '870px',
                data: { job: this.job, jobevent: this.jobEvent }
            });
            dialog.componentInstance.callback = this.publishJobCallback;
        }
    };
    JobDetailComponent.prototype.openJobDays = function () {
        var dialog = this.dialog.open(edit_job_dialog_component_1.EditJobDialogComponent, {
            width: '320px',
            data: { job: this.job }
        });
        dialog.componentInstance.callback = this.saveJobDaysCallback;
    };
    JobDetailComponent.prototype.openEditSLOrderID = function () {
        var dialog = this.dialog.open(edit_slorder_id_dialog_component_1.EditSLOrderIDDialogComponent, {
            width: '320px',
            data: { jobEvent: this.jobEvent }
        });
        dialog.componentInstance.callback = this.saveSLOrderIDCallback;
    };
    JobDetailComponent.prototype.openCancelJob = function () {
        if (!this.jobEvent.cancelled) {
            var dialog = this.dialog.open(cancel_job_dialog_component_1.CancelJobDialogComponent, {
                width: '430px',
                data: { jobEventIds: [this.jobEvent.id] }
            });
            dialog.componentInstance.callback = this.cancelJobCallback;
        }
    };
    JobDetailComponent.prototype.openCloneJob = function () {
        this.router.navigate(['/jobs/clone/' + this.job.id]);
    };
    JobDetailComponent.prototype.openEditJob = function () {
        this.router.navigate(["/jobs/" + this.job.id + "/edit"], {
            queryParams: {
                returnTo: "/jobs/" + this.job.id + "/" + this.jobEvent.id
            }
        });
    };
    JobDetailComponent.prototype.openNewDaysDialog = function (daysAdded) {
        if (daysAdded && daysAdded.length > 0) {
            var dialog = this.dialog.open(new_days_collaboration_dialog_component_1.NewDaysCollaborationDialogComponent, {
                width: '850px'
            });
            dialog.componentInstance.jobEvent = this.jobEvent;
            dialog.componentInstance.daysAdded = daysAdded;
        }
    };
    JobDetailComponent.prototype.openMessageDrivers = function () {
        var dialog = this.dialog.open(message_drivers_dialog_component_1.MessageDriversDialogComponent, {
            width: '870px'
        });
        if (dialog && this.jobEvent) {
            dialog.componentInstance.jobEventId = this.jobEvent.id;
            dialog.componentInstance.callback = this.messageDriversCallback;
        }
    };
    JobDetailComponent.prototype.openExportAssignments = function () {
        var _this = this;
        var filters = { jobevent: this.jobEvent.id };
        this.assignmentService.export({}, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: { type: 'assignments' }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    params: params,
                    service: _this.assignmentService,
                    buttonText: 'Try to Export Again'
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    JobDetailComponent.prototype.openCreateTrip = function () {
        this.router.navigate(['/trips/new/', this.job.id, this.jobEvent.id]);
    };
    JobDetailComponent.prototype.openCreatePunchCard = function () {
        this.router.navigate(['/punchcards/new/', this.job.id, this.jobEvent.id]);
    };
    JobDetailComponent.prototype.viewTickets = function (jobEvent) {
        console.log(jobEvent);
        this.router.navigate(['/orders', jobEvent.id, 'tickets'], {
            queryParams: { resizeTo: this.router.url }
        });
    };
    JobDetailComponent.prototype.setSelectedAction = function (option, jobEvent) {
        switch (option.name) {
            case 'Share to Marketplace':
                this.openShareJob();
                break;
            case 'Clone Job':
                this.openCloneJob();
                break;
            case 'Edit Job':
                this.openEditJob();
                break;
            case 'Message Drivers':
                this.openMessageDrivers();
                break;
            case 'Export Assignments':
                this.openExportAssignments();
                break;
            case 'Edit Job Days':
                this.openJobDays();
                break;
            case 'Edit SLOrder ID':
                this.openEditSLOrderID();
                break;
            case 'Cancel Day':
                this.openCancelJob();
                break;
            case 'Create Trip':
                this.openCreateTrip();
                break;
            case 'Create Punch Card':
                this.openCreatePunchCard();
                break;
            case 'View Tickets':
                this.viewTickets(jobEvent);
                break;
        }
        this.actionsDropdown.deselectAll();
    };
    JobDetailComponent.prototype.openDriverList = function () {
        this.driverListOpen = !this.driverListOpen;
    };
    JobDetailComponent.prototype.openJobOverview = function () {
        this.jobOverviewOpen = !this.jobOverviewOpen;
    };
    JobDetailComponent.prototype.durationInMinutes = function (obj) {
        obj.durationTimer = rxjs_1.timer(1, 60000);
        this.allSubscriptionsToUnsubscribe.push(obj.durationTimer.subscribe(function (t) {
            var duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
            obj.duration = duration;
        }));
    };
    JobDetailComponent.prototype.monitorStatusChanges = function () {
        var _this = this;
        this.assignmentService.listAllUpdate(5, 120000, {
            jobevent: this.jobEvent && this.jobEvent.id,
            completed: 'False',
            dispatched: 'True',
            include_trips: 'True',
        }).subscribe(function (assignments) {
            _this.assignments.items = assignments.map(function (_assignment) {
                if (_assignment.driver) {
                    _this.loadAssignmentTrip(_assignment);
                    _this.loadAssignmentPunchCard(_assignment);
                }
                return _assignment;
            }).sort(function (a, b) { return ((a.uniqueStart < b.uniqueStart) ? -1 : (a.uniqueStart > b.uniqueStart) ? 1 : 0); });
        }, function (err) { return console.error(err); });
    };
    JobDetailComponent.prototype.onScroll = function (e) {
        if (this.job.invoiceType === 'hour' && this.completedPunchCardsComponent) {
            this.completedPunchCardsComponent.onScroll(e);
        }
        else if (this.completedTripsComponent) {
            this.completedTripsComponent.onScroll(e);
        }
    };
    JobDetailComponent.prototype.openAssignmentDetails = function (update, index) {
        if (this.jobMap) {
            this.jobMap.openAssignmentDetails(update, index);
        }
    };
    JobDetailComponent.prototype.setControlState = function () {
        var _this = this;
        this.controls = [];
        if (this.jobEvent) {
            this.controls.push(new control_option_1.ControlOption({ name: 'trips', label: 'Trips', classes: 'trips-toggle' }));
            if (this.jobEvent.hasGeofences) {
                this.controls.push(new control_option_1.ControlOption({ name: 'gps', label: 'GPS', classes: 'gps-toggle' }), new control_option_1.ControlOption({ name: 'timeline', label: 'Timeline', classes: 'timeline-toggle' }));
            }
            else {
                this.controls.push(new control_option_1.ControlOption({ name: 'timeline', label: 'Timeline', classes: 'timeline-toggle' }));
            }
            this.controlState = this.jobEvent.invoiceType === 'hour' ? 'timeline' : 'trips';
            this.controls[this.controls.findIndex(function (c) { return (_this.jobEvent.invoiceType === 'hour' ? c.name === 'timeline' : c.name === 'trips'); })].selected = true;
        }
    };
    /**
     * @param  {} control
     * @param  {} event
     * @returns void
     * This function is called on toggling the segmented controls: GPS, Trips, or Hourly
     */
    JobDetailComponent.prototype.onSelectToggle = function (control, event) {
        var checkbox = event.target;
        this.controls.forEach(function (_control) { _control.selected = false; });
        control.selected = checkbox.checked;
        this.controlState = control.name;
    };
    return JobDetailComponent;
}());
exports.JobDetailComponent = JobDetailComponent;
