import { Component, Input, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DispatchJobEvent } from '../dispatch-schedule.component';
import { ItemGroup, ItemList } from '../../../shared/item-grid/item-grid.component';

@Component({
  selector: 'dispatch-schedule-jobevent-grid',
  templateUrl: './dispatch-schedule-jobevent-grid.component.html',
  styleUrls: ['../dispatch-schedule.component.scss']
})
export class DispatchScheduleJobEventGridComponent {
  searchTerm: string;
  @Input() loadingProgress = 0;
  @Input() jobEvents: DispatchJobEvent[];
  jobEventList: ItemList;
  filteredJobEventList: ItemList;
  displayKeys = ['jobName', 'orderNumber', 'orderNumberJobName'];
  jobEventCount = { available: 0 };
  groupByOptions = [ 'Market', 'Customer', 'Origin', 'Destination' ];
  activeGroupBy: 'Market' | 'Customer' | 'Origin' | 'Destination' = 'Customer';

  assignedSearch = '';

  @Input() highlightedJobEvents: string[] = [];
  selectedJobEventsValue: string[] = [];
  @Output() selectedJobEventsChange: EventEmitter<string[]> = new EventEmitter();
  @Input() get selectedJobEvents() { return this.selectedJobEventsValue; }
  set selectedJobEvents(data: string[]) {
    this.selectedJobEventsValue = data;
    this.selectedJobEventsChange.emit(data);
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jobEvents && changes.jobEvents.currentValue) {
      this.setupJobEventList(changes.jobEvents.currentValue, this.activeGroupBy);
    }
  }

  /**
   * Sets up the jobevent groups based on the specified groupBy param
   *
   * @param {DispatchJobEvent[]} jobEvents The jobEvent list to be grouped
   * @param {'Market' | 'Customer' | 'Origin' | 'Destination'} groupBy The selected groupBy option
   */
  setupJobEventList(jobEvents: DispatchJobEvent[], groupBy: 'Market' | 'Customer' | 'Origin' | 'Destination') {
    this.jobEventCount = {
      available: jobEvents.length
    };
    this.activeGroupBy = groupBy;
    switch (groupBy) {
      case 'Market':
        this.jobEventList = Array.from(
          new Set(
            jobEvents.map(j => (
              <ItemGroup>{
                id: j.tags.length && j.tags[0] && j.tags[0].id,
                name: j.tags.length && j.tags[0] && j.tags[0].name,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => {
          const filteredJobEvents = jobEvents.filter(jobEvent => (
            (jobEvent.tags.length && jobEvent.tags[0] && jobEvent.tags[0].id) === group.id
          ));
          return Object.assign(group, {
            items: filteredJobEvents,
            assigned: filteredJobEvents.map(j => (j.assignedLoads)).reduce((a, b) => (a + b)),
            ordered: filteredJobEvents.map(j => (Number(j.dailyDeliveryTarget))).reduce((a, b) => (a + b))
          });
        });
        break;
      case 'Customer':
        this.jobEventList = Array.from(
          new Set(
            jobEvents.map(j => (
              <ItemGroup>{
                id: j.customerId,
                name: j.customer,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => {
          const filteredJobEvents = jobEvents.filter(jobEvent => (
            jobEvent.customerId === group.id
          ));
          return Object.assign(group, {
            items: filteredJobEvents,
            assigned: filteredJobEvents.map(j => (j.assignedLoads)).reduce((a, b) => (a + b)),
            ordered: filteredJobEvents.map(j => (Number(j.dailyDeliveryTarget))).reduce((a, b) => (a + b))
          });
        });
        break;
      case 'Origin':
        this.jobEventList = Array.from(
          new Set(
            jobEvents.map(j => (
              <ItemGroup>{
                id: j.startLocation,
                name: j.startLocationName,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => {
          const filteredJobEvents = jobEvents.filter(jobEvent => (
            jobEvent.startLocation === group.id
          ));
          return Object.assign(group, {
            items: filteredJobEvents,
            assigned: filteredJobEvents.map(j => (j.assignedLoads)).reduce((a, b) => (a + b)),
            ordered: filteredJobEvents.map(j => (Number(j.dailyDeliveryTarget))).reduce((a, b) => (a + b))
          });
        });
        break;
      case 'Destination':
        this.jobEventList = Array.from(
          new Set(
            jobEvents.map(j => (
              <ItemGroup>{
                id: j.endLocation,
                name: j.endLocationName,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => {
          const filteredJobEvents = jobEvents.filter(jobEvent => (
            jobEvent.endLocation === group.id
          ));
          return Object.assign(group, {
            items: filteredJobEvents,
            assigned: filteredJobEvents.map(j => (j.assignedLoads)).reduce((a, b) => (a + b)),
            ordered: filteredJobEvents.map(j => (Number(j.dailyDeliveryTarget))).reduce((a, b) => (a + b))
          });
        });
        break;
    }

    this.jobEventList = this.jobEventList.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
    this.filteredJobEventList = cloneDeep(this.jobEventList);
  }

  /**
   * Selects a new groupBy option and re-triggers the jobevent grid setup based on that option
   *
   * @param {'Market' | 'Customer' | 'Origin' | 'Destination'} groupBy The selected groupBy option
   */
  selectGroupBy(groupBy: 'Market' | 'Customer' | 'Origin' | 'Destination') {
    this.setupJobEventList(this.jobEvents, groupBy);
  }

  /**
   * Returns a set of class names in a single string to be appended to item elements in the item grid
   *
   * @param {DispatchJobEvent} jobevent The jobevent object
   * @returns {string} The class names to be appended to item elements
   */
  generateItemClassNames(jobEvent: DispatchJobEvent): string {
    let classNames = '';
    if ((Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) > 0) {
      classNames += 'red ';
    } else if ((Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) < 0) {
      classNames += 'black ';
    } else if (Number(jobEvent.dailyDeliveryTarget) > 0 && jobEvent.assignedLoads > 0 &&
              (Number(jobEvent.dailyDeliveryTarget) - jobEvent.assignedLoads) === 0) {
      classNames += 'green ';
    }
    return classNames;
  }
}
