<notification *ngFor="let error of errors" translate>{{error}}</notification>

<div class="container trucks-container">
  <ruckit-edit-truck #editDrawer [truck]='truck' (completed)="onEditComplete($event)" (closed)="onClose()"></ruckit-edit-truck>

  <div class="actions-wrapper" *ngIf="view === 'list'">
    <fancy-table #trucksTable
                 [(availableColumns)]="availableColumns"
                 [(displayedColumns)]="displayedColumns"
                 [(filters)]="appliedFilters"
                 [(search)]="search"
                 [config]="tableConfig"
                 [(query)]="query"
                 [customClasses]="customClasses"
                 (onClickAction)="clickAction($event)"
                 (columnChange)="columnsChanged($event)"
                 (onDataLoaded)="onDataLoaded($event)">
      <ng-template #columnTemplates let-row="row" let-column="column" let-value="value">
        <ng-container [ngSwitch]="column">
          <div class="value truck-type" *ngSwitchCase="'truck-type'">
            <div class="data">
              <div class="truck-image">
                <img [src]="row.gridTruckImage" *ngIf="row.image && displayAsGrid" />
                <img [src]="row.truckListImage" *ngIf="row.image && !displayAsGrid" />
                <div class="default-image" *ngIf="!row.image"><i class="icon-fleet"></i></div>
              </div>
              {{ row.truckType && row.truckType.name }}
            </div>
          </div>
          <div class="value" *ngSwitchCase="'name'">
            <div class="data"> {{ row.name || '&nbsp;' }}</div>
          </div>
          <div class="value uppercase" *ngSwitchCase="'license-plate'">
            <div class="data">{{ row.licensePlate || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'carrier'">
            <div class="data"> {{ row.carrierOrganizationName || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'markets'">
            <div class="data">
              <tag-display [tags]="row.tags" label="Markets"></tag-display>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'created-by'">
            <div class="data"> {{ !row.createdBy ? 'CONNEX' : 'User' }}</div>
          </div>
          <div class="value status" *ngSwitchCase="'serviceStatus'">
            <div class="data"> {{ (row.displayServiceStatus | translate) || '&nbsp;' }}</div>
          </div>
          <div class="value" *ngSwitchCase="'deleted'">
            <div class="data">
              <span class="value">{{ row.lastModified | moment: 'M-D-YYYY' }}</span>
              <div class="label">{{ row.lastModifiedBy }}</div>
            </div>
          </div>
          <div class="value" *ngSwitchCase="'action'">
            <action-menu icon="icon-more">
              <ng-container *ngFor="let option of row.filterOptions(tableConfig.menuOptions)">
                <a *ngIf="option.link" mat-menu-item [routerLink]="row.url(option.action)">
                  {{ option.name }}
                </a>
                <a *ngIf="!option.link" mat-menu-item (click)="menuAction(option.action, row)">{{ option.name }}</a>
              </ng-container>
            </action-menu>
          </div>
          <div class="value" *ngSwitchDefault>{{ value }}</div>
        </ng-container>
      </ng-template>
      <div header-content>
        <div class="segmented-buttons">
          <a (click)="switchView('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
            <i class="icon-rows" matTooltip="{{ 'Table' | translate }}"></i>
          </a>
          <a (click)="switchView('grid')" class="btn btn-default" [ngClass]="{active: view === 'grid'}">
            <i class="icon-grid" matTooltip="{{ 'Grid' | translate }}"></i>
          </a>
        </div>
        <div class="title" translate>Trucks</div>
        <div class="segmented-buttons">
          <a (click)="viewArchive.emit(false)" class="btn btn-default" [ngClass]="{active: !archive}" translate>
            Active
          </a>
          <a (click)="viewArchive.emit(true)" class="btn btn-default" [ngClass]="{active: archive}" translate>
            Archive
          </a>
        </div>
      </div>
      <div applied-filter-content>
        <applied-filters #appliedFiltersDisplay
                [(filters)]="appliedFilters"
                [(search)]="search"
                (filtersChange)="appliedFilters=$event">
        </applied-filters>
      </div>
    </fancy-table>
    <div class="actions">
      <column-toggle #columnToggle
                     [(availableColumns)]="availableColumns"
                     [(displayedColumns)]="displayedColumns">
      </column-toggle>
      <filters-panel [(appliedFilters)]="appliedFilters"
                     [customFilter]="true"
                     (openCustomFilter)="openFilters()"
                     [search]="search" (searchChange)="search = $event"></filters-panel>
      <!-- add new truck dialog-->
      <a mat-icon-button color="accent" class="add-button" (click)="addTruck()">
        <mat-icon>add_circle</mat-icon>
      </a>
    </div>
  </div>

  <div class="management-grid" *ngIf="view === 'grid'">
    <timeline-legend [labels]="gridLabels">
      <div title translate>Legend</div>
    </timeline-legend>

    <item-grid
      *ngIf="truckGroups && loadingProgress === 100"
      [multiselect]="false"
      [itemGroups]="truckGroups"
      [itemClassNames]="generateItemClassNames"
      [displayKeys]="displayKeys"
      [searchProps]="displayKeys"
      [groupByOptions]="groupByOptions"
      [activeGroupBy]="activeGroupBy"
      (groupByChange)="selectGroupBy($event)"
      (onSelect)="handleGridSelection($event)"
    >
      <div header-stats class="header-stats">
        <div class="segmented-buttons">
          <a (click)="switchView('list')" class="btn btn-default" [ngClass]="{active: view === 'list'}">
            <i class="icon-rows" matTooltip="{{ 'Table' | translate }}"></i>
          </a>
          <a (click)="switchView('grid')" class="btn btn-default" [ngClass]="{active: view === 'grid'}">
            <i class="icon-grid" matTooltip="{{ 'Grid' | translate }}"></i>
          </a>
        </div>
        {{ truckList.length + ' Trucks' | translate }}
      </div>
    </item-grid>

    <div class="grid-loader" *ngIf="!truckGroups || loadingProgress < 100">
      <h2 translate>Getting Full Truck List</h2>
      <h3 translate>This may take longer depending on your fleet size</h3>
      <div class="loading-bar">
        <span class="text">{{ loadingProgress + '% Loaded' | translate }}</span>
        <div class="progress" [ngStyle]="{'width': loadingProgress + '%'}"></div>
      </div>
    </div>
  </div>
</div>
