"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var job_event_share_stat_service_1 = require("../../job-event-stats/job-event-share-stat.service");
var assignment_service_1 = require("../../assignments/assignment.service");
// import { TableConfig } from '../../shared/fancy-table/fancy-table.component';
var AllCollaboratorsComponent = /** @class */ (function () {
    function AllCollaboratorsComponent(assignmentService, translationService) {
        this.assignmentService = assignmentService;
        this.translationService = translationService;
        this.availableColumns = [
            { key: 'name', title: this.translationService.instant('Collaborator Name') },
            { key: 'dispatched-drivers-count', title: this.translationService.instant('Dispatched Trucks') },
            { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
            { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
            { key: 'assigned-trucks', title: this.translationService.instant('Assigned Trucks') },
            { key: 'dispatched-estimated-number-of-loads', title: this.translationService.instant('Dispatched Loads') },
            { key: 'dispatched-estimated-number-of-tons', title: this.translationService.instant('Dispatched Tons') },
            { key: 'invoice-rate', title: this.translationService.instant('Invoice Rate') },
            { key: 'invoice-type', title: this.translationService.instant('Invoice Type') },
            { key: 'invoice-weight-unit', title: this.translationService.instant('Invoice Weight Unit') },
            { key: 'total-loads', title: this.translationService.instant('Delivered Loads') },
            { key: 'total-tons', title: this.translationService.instant('Delivered Tons') },
            { key: 'weight-in-transit', title: this.translationService.instant('Weight in Transit') },
            { key: 'ticketed-weight', title: this.translationService.instant('Ticketed Weight') },
            { key: 'avg-trip-time', title: this.translationService.instant('Avg Trip Time') },
        ];
        this.displayedColumns = [
            'name', 'avg-trip-time', 'dispatched-drivers-count', 'confirmed-trucks', 'num-trucks', 'assigned-trucks',
            'total-loads', 'total-tons', 'weight-in-transit', 'ticketed-weight',
            'dispatched-estimated-number-of-loads', 'dispatched-estimated-number-of-tons',
            'invoice-rate', 'invoice-type', 'invoice-weight-unit'
        ];
        this.filters = [];
        this.allCollaboratorsTableConfig = {
            hasHeader: false,
            service: job_event_share_stat_service_1.JobEventShareStatService,
            preferenceKey: '',
            // preferenceKey: 'AllCollaboratorsComponent-JobEventShareStatService',
            query: {},
            collectionTitle: this.translationService.instant('Collaborators'),
            noResultsText: this.translationService.instant('a collaborator'),
            customHeight: true,
            automaticallyHidePagination: true
        };
        this.assignedTrucks = [];
    }
    AllCollaboratorsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['jobEventId']) {
            var jobEventId = changes['jobEventId'].currentValue;
            this.getAssignedTrucks(jobEventId);
            this.allCollaboratorsTableConfig['slug'] = "jobevents/" + jobEventId + "/stats-by-collaborator/";
            if (this.allCollaboratorsTable) {
                // this.allCollaboratorsTable.config = <TableConfig>this.allCollaboratorsTableConfig;
                this.allCollaboratorsTable.getRecords();
            }
        }
    };
    AllCollaboratorsComponent.prototype.getAssignedTrucks = function (jobEventId) {
        var _this = this;
        this.assignmentService
            .listAll(50, { jobevent: jobEventId })
            .subscribe(function (assignments) {
            var assignedTrucks = assignments.map(function (assignment) {
                return assignment.truck
                    ? {
                        id: assignment.truck.id,
                        // collaborators service is returning ids without dashes..
                        organizationId: assignment.truck.carrierOrganizationId.replace(/-/g, ''),
                        name: assignment.truck.name + " - " + assignment.truck.truckTypeName,
                    }
                    : null;
            });
            _this.assignedTrucks = assignedTrucks.filter(function (truck) { return truck !== null; });
        });
    };
    return AllCollaboratorsComponent;
}());
exports.AllCollaboratorsComponent = AllCollaboratorsComponent;
