"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var moment = require("moment");
var assignment_service_1 = require("../../../assignments/assignment.service");
var shared_1 = require("../../../shared");
var truck_service_1 = require("../../../trucks/truck.service");
var DispatchScheduleJobEventEditPanelComponent = /** @class */ (function () {
    function DispatchScheduleJobEventEditPanelComponent(assignmentService, _eRef) {
        this.assignmentService = assignmentService;
        this._eRef = _eRef;
        this.editing = false;
        this.close = new core_1.EventEmitter();
        this.editsMade = new core_1.EventEmitter();
        this.assignmentsDeleted = new core_1.EventEmitter();
        this.bulkEdits = {};
        this.selectedAssignments = [];
        this.trucksDropdownConfig = {
            small: true,
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            nameProperty: 'ticketName',
            service: truck_service_1.TruckService,
            query: { ordering: 'name' }
        };
        this.jobEventChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DispatchScheduleJobEventEditPanelComponent.prototype, "jobEvent", {
        get: function () { return this.jobEventValue; },
        set: function (row) {
            this.jobEventValue = row;
            this.jobEventChange.emit(row);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleJobEventEditPanelComponent.prototype.documentClick = function (event) {
        var eventClassesName = event.composedPath ? event.composedPath().map(function (el) { return (el.className); }).join(' ') : [];
        if (!eventClassesName.includes('driver-pay-timeline-list-container') &&
            !event.target.classList.contains('assignment-label') &&
            !eventClassesName.includes('timepicker') &&
            !eventClassesName.includes('mat-calendar') &&
            !this._eRef.nativeElement.contains(event.target)) {
            if (!this.editing) {
                this.close.emit(true);
            }
        }
    };
    DispatchScheduleJobEventEditPanelComponent.prototype.bulkEdit = function (all) {
        var _this = this;
        if (all === void 0) { all = false; }
        this.editing = true;
        var updatedAssignments = this.jobEvent.assignments;
        if (!all) {
            updatedAssignments = this.jobEvent.assignments.filter(function (assignment) { return (assignment.selected); });
        }
        updatedAssignments.map(function (assignment, i) {
            Object.keys(_this.bulkEdits).forEach(function (key) {
                if (key === 'uniqueStartTime') {
                    assignment.uniqueStart = all ? moment(assignment.uniqueStart.split('T')[0] + ' ' + _this.bulkEdits[key], 'YYYY-MM-DD H:mm A').add(Number(_this.jobEvent.deliveryInterval) * i, 'minutes').toISOString() :
                        moment(assignment.uniqueStart.split('T')[0] + ' ' + _this.bulkEdits[key], 'YYYY-MM-DD H:mm A').toISOString();
                }
                else {
                    assignment[key] = _this.bulkEdits[key];
                }
            });
            return assignment;
        });
        if (updatedAssignments && updatedAssignments.length &&
            this.assignmentService && typeof this.assignmentService.bulkUpdate === 'function') {
            this.assignmentService.bulkUpdate(updatedAssignments).subscribe(function (res) {
                res.assignments.forEach(function (assignment) {
                    var matchedIndex = _this.jobEvent.assignments.findIndex(function (a) { return (a.id === assignment.id); });
                    _this.jobEvent.assignments[matchedIndex].maxNumberOfLoads = assignment.maxNumberOfLoads;
                    _this.jobEvent.assignments[matchedIndex].uniqueStart = assignment.uniqueStart;
                    _this.jobEvent.assignments[matchedIndex].uniqueStartTime = moment(assignment.uniqueStart).format('H:mm A');
                });
                _this.editing = false;
                _this.editsMade.emit(_this.jobEvent);
            }, function (err) {
                _this.editing = false;
                _this.errors = shared_1.parseErrors(err);
            });
        }
    };
    DispatchScheduleJobEventEditPanelComponent.prototype.selectAssignment = function (assignment) {
        assignment.selected = !assignment.selected;
    };
    DispatchScheduleJobEventEditPanelComponent.prototype.updateAssignment = function (assignment, field, value) {
        var _this = this;
        var _a;
        if ((field === 'uniqueStart' && value.length && value[0] === assignment.uniqueStart) ||
            (field === 'truck' && (!value.id || !assignment.truck || (value.id === assignment.truck.id)))) {
            return;
        }
        this.editing = true;
        if (field === 'uniqueStart' || field === 'uniqueStartTime') {
            if (field === 'uniqueStart') {
                assignment.uniqueStartDate = value[0];
            }
            else {
                assignment.uniqueStartTime = value;
                field = 'uniqueStart';
            }
            var formatString = 'MM/DD/YYYY h:mm A';
            var dateString = moment(assignment.uniqueStartDate).format('MM/DD/YYYY');
            value = moment(dateString + " " + assignment.uniqueStartTime, formatString);
        }
        this.assignmentService.save((_a = { id: assignment.id }, _a[field] = value, _a), { can_dispatch: 'True' }).subscribe(function (res) {
            var matchedIndex = _this.jobEvent.assignments.findIndex(function (a) { return (a.id === res.id); });
            _this.jobEvent.assignments[matchedIndex][field] = value;
            _this.editing = false;
            _this.editsMade.emit(_this.jobEvent);
        }, function (err) {
            _this.editing = false;
            _this.errors = shared_1.parseErrors(err);
        });
    };
    DispatchScheduleJobEventEditPanelComponent.prototype.removeAssignment = function (assignment) {
        var _this = this;
        this.assignmentService.remove(assignment.id).subscribe(function () { return _this.assignmentsDeleted.emit([assignment]); }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleJobEventEditPanelComponent.prototype.getAssignmentStartRange = function (jobEvent) {
        return {
            start: moment(jobEvent.shift1Start).startOf('day').toISOString(),
            end: moment(jobEvent.jobEndDate).endOf('day').toISOString()
        };
    };
    return DispatchScheduleJobEventEditPanelComponent;
}());
exports.DispatchScheduleJobEventEditPanelComponent = DispatchScheduleJobEventEditPanelComponent;
