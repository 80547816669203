"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_serializer_1 = require("./../drivers/driver.serializer");
var checkin_1 = require("../checkins/checkin");
var lodash_1 = require("lodash");
var moment = require("moment");
var resizer_1 = require("../images/resizer");
var job_event_share_serializer_1 = require("../job-event-shares/job-event-share.serializer");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var Trip = /** @class */ (function () {
    function Trip(tripInfo) {
        this.loading = false;
        this.currentTrip = false;
        tripInfo = camelcaseKeysDeep(tripInfo);
        this.id = tripInfo.id;
        this.assignment = tripInfo.assignment;
        if (tripInfo.driver) {
            this.driver = new driver_serializer_1.DriverSerializer().fromJson(tripInfo.driver);
        }
        if (tripInfo.loadingCheckin) {
            if (tripInfo.jobevent && tripInfo.jobevent.job) {
                tripInfo.loadingCheckin.options = tripInfo.jobevent.job.checkinOptions;
            }
            this.loadingCheckin = new checkin_1.Checkin(tripInfo.loadingCheckin);
        }
        if (tripInfo.unloadingCheckin) {
            if (tripInfo.jobevent && tripInfo.jobevent.job) {
                tripInfo.unloadingCheckin.options = tripInfo.jobevent.job.checkoutOptions;
            }
            this.unloadingCheckin = new checkin_1.Checkin(tripInfo.unloadingCheckin);
        }
        this.jobEvent = tripInfo.jobevent;
        this.truck = tripInfo.truck;
        this.shift = tripInfo.shift;
        this.phaseCode = tripInfo.phaseCode;
        this.tripStatus = tripInfo.tripStatus;
        this.routePolyline = tripInfo.routePolyline;
        this.enrouteLoadingTime = tripInfo.enrouteLoadingTime;
        this.loadingArrivalTime = tripInfo.loadingArrivalTime;
        this.loadingCompleteTime = tripInfo.loadingCompleteTime;
        this.enrouteUnloadingTime = tripInfo.enrouteUnloadingTime;
        this.unloadingArrivalTime = tripInfo.unloadingArrivalTime;
        this.unloadingCompleteTime = tripInfo.unloadingCompleteTime;
        this.connexPaverStartUnloading = tripInfo.connexPaverStartUnloading;
        this.connexPaverEndUnloading = tripInfo.connexPaverEndUnloading;
        this.startTimeTimestamp = tripInfo.startTime;
        this.endTimeTimestamp = tripInfo.endTime;
        this.completed = tripInfo.completed;
        this.edited = tripInfo.edited;
        this.retakeStatus = tripInfo.retakeStatus;
        this.weight = tripInfo.weight;
        this.weightUnit = tripInfo.weightUnit;
        this.invoiceTotal = tripInfo.invoiceTotal;
        this.added = tripInfo.added;
        this.void = tripInfo.void;
        this.haulTotal = tripInfo.haulTotal;
        this.ticketNumber = tripInfo.ticketNumber;
        this.index = tripInfo.index;
        this.currentTrip = tripInfo.currentTrip;
        this.job = tripInfo.job;
        this.jobDisplayName = this.job && this.job.name;
        this.timezone = tripInfo.timezone;
        this.origin = tripInfo.origin;
        this.decision = tripInfo.decision;
        this.latestDecision = tripInfo.latestDecision;
        this.latestDecisionStatus = tripInfo.latestDecisionStatus;
        this.latestDecider = tripInfo.latestDecider;
        this.latestDeciderName = tripInfo.latestDeciderName;
        this.latestExportedAt = tripInfo.latestExportedAt;
        this.exported = !!this.latestExportedAt;
        if (this.endTimeTimestamp && Date.parse(this.endTimeTimestamp) < Date.parse(this.startTimeTimestamp)) {
            this.endTimeTimestamp = moment(new Date(Date.parse(this.startTimeTimestamp)).toDateString() + ' ' + new Date(Date.parse(this.endTimeTimestamp)).toTimeString()).toISOString();
        }
        if (this.startTimeTimestamp && !this.endTimeTimestamp) {
            var duration = moment.duration(moment(new Date()).diff(this.startTimeTimestamp));
            this.completedTripDuration = duration.asMilliseconds();
            this.completedTripTime = duration.format('D[ days], H[ hrs], m[ mins] total');
        }
        else {
            var duration = moment.duration(moment(this.endTimeTimestamp).diff(this.startTimeTimestamp));
            this.completedTripDuration = duration.asMilliseconds();
            this.completedTripTime = duration.format('D[ days], H[ hrs], m[ mins] total');
        }
        if (this.startTimeTimestamp) {
            var d = new Date(this.startTimeTimestamp);
            this._startTime = moment(d).format('h:mm a');
        }
        else {
            this._startTime = '';
        }
        if (this.endTimeTimestamp) {
            var d = new Date(this.endTimeTimestamp);
            this._endTime = moment(d).format('h:mm a');
        }
        else {
            this._endTime = '';
        }
        if (this._endTime && this._endTime.length) {
            this.schedule = [this._startTime, this._endTime].join(' - ');
        }
        else {
            this.schedule = [this._startTime, 'OPEN'].join(' - ');
        }
        if (this.startTimeTimestamp && !this.endTimeTimestamp) {
            var duration = moment.duration(moment(new Date()).diff(this.startTimeTimestamp));
            this.duration = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else {
            var duration = moment.duration(moment(this.endTimeTimestamp).diff(this.startTimeTimestamp));
            this.duration = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        if (this.enrouteLoadingTime && !this.loadingArrivalTime) {
            var duration = moment.duration(moment(new Date()).diff(this.enrouteLoadingTime));
            this.loadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else if (this.enrouteLoadingTime && this.loadingArrivalTime) {
            var duration = moment.duration(moment(this.loadingArrivalTime).diff(this.enrouteLoadingTime));
            this.loadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else {
            this.loadingEnRouteTime = '';
        }
        if (this.loadingArrivalTime && !this.loadingCompleteTime) {
            var duration = moment.duration(moment(new Date()).diff(this.loadingArrivalTime));
            this.loadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else if (this.loadingArrivalTime && this.loadingCompleteTime) {
            var duration = moment.duration(moment(this.loadingCompleteTime).diff(this.loadingArrivalTime));
            this.loadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else {
            this.loadingTime = '';
        }
        var startTime = this.enrouteUnloadingTime || this.loadingCompleteTime;
        if (startTime && !this.unloadingArrivalTime) {
            var duration = moment.duration(moment(new Date()).diff(startTime));
            this.unloadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else if (startTime && this.unloadingArrivalTime) {
            var duration = moment.duration(moment(this.unloadingArrivalTime).diff(startTime));
            this.unloadingEnRouteTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else {
            this.unloadingEnRouteTime = '';
        }
        if (this.unloadingArrivalTime && !this.unloadingCompleteTime) {
            var duration = moment.duration(moment(new Date()).diff(this.unloadingArrivalTime));
            this.unloadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else if (this.unloadingArrivalTime && this.unloadingCompleteTime) {
            var duration = moment.duration(moment(this.unloadingCompleteTime).diff(this.unloadingArrivalTime));
            this.unloadingTime = duration.format('D[ days], H[ hrs], m[ mins]');
        }
        else {
            this.unloadingTime = '';
        }
        if (tripInfo.jobeventshare && typeof (tripInfo.jobeventshare) === 'object') {
            this.jobEventShare = new job_event_share_serializer_1.JobEventShareSerializer().fromJson(tripInfo.jobeventshare);
        }
        else {
            this.jobEventShare = new job_event_share_serializer_1.JobEventShareSerializer().fromJson({ id: tripInfo.jobeventshare });
        }
        this.reviewerNotes = tripInfo.reviewerNotes;
    }
    Trip.prototype.save = function () {
        // Save to API
        console.log(this.id);
    };
    Object.defineProperty(Trip.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (value) {
            if (value) {
                var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
                var date = moment(this.startTimeTimestamp).format('YYYY-MM-DD');
                var parsedDate = moment(date + ' ' + value, pattern);
                this.startTimeTimestamp = parsedDate.toISOString();
                this._startTime = parsedDate.format('h:mm a');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "endTime", {
        get: function () {
            return this._endTime;
        },
        set: function (value) {
            if (value) {
                var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
                var date = moment(this.endTimeTimestamp).format('YYYY-MM-DD');
                var parsedDate = moment(date + ' ' + value, pattern);
                this.endTimeTimestamp = parsedDate.toISOString();
                this._endTime = parsedDate.format('h:mm a');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "startDate", {
        get: function () {
            return new Date(this.startTimeTimestamp);
        },
        set: function (value) {
            var date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.startTime);
            this.startTimeTimestamp = date.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "endDate", {
        get: function () {
            if (this.endTimeTimestamp) {
                return new Date(this.endTimeTimestamp);
            }
            else {
                return this.startDate;
            }
        },
        set: function (value) {
            var date = new Date(value.year + '/' + value.month + '/' + value.day + ' ' + this.endTime);
            this.endTimeTimestamp = date.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "truckTypeName", {
        get: function () {
            if (typeof this.truck.truckType === 'string') {
                return this.truck.truckType;
            }
            else {
                return this.truck.truckType.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "loadingTicket", {
        get: function () {
            return this.loadingCheckin && this.loadingCheckin.ticketImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "unloadingTicket", {
        get: function () {
            return this.unloadingCheckin && this.unloadingCheckin.ticketImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "loadingTicketNumber", {
        get: function () {
            return this.loadingCheckin && this.loadingCheckin.ticketNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "unloadingTicketNumber", {
        get: function () {
            return this.unloadingCheckin && this.unloadingCheckin.ticketNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "loadingSignature", {
        get: function () {
            return this.loadingCheckin && this.loadingCheckin.signatureImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "unloadingSignature", {
        get: function () {
            return this.unloadingCheckin && this.unloadingCheckin.signatureImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "signatureImagesRequired", {
        get: function () {
            if ((this.loadingCheckin && this.loadingCheckin.options.signatureImage) ||
                (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage)) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasSignatureImages", {
        get: function () {
            if ((this.loadingCheckin && this.loadingCheckin.options.signatureImage && !this.loadingSignature) ||
                (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage && !this.unloadingSignature)) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "loadingSignatureImagesRequired", {
        get: function () {
            if (this.loadingCheckin && this.loadingCheckin.options.signatureImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasLoadingSignatureImages", {
        get: function () {
            if (this.loadingCheckin && this.loadingCheckin.options.signatureImage && !this.loadingSignature) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "inlineLoadingSignature", {
        get: function () {
            if (this.loadingCheckin && this.loadingCheckin.signatureImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.loadingCheckin.signatureImageKey, 24, 24);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "unloadingSignatureImagesRequired", {
        get: function () {
            if (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "inlineUnloadingSignature", {
        get: function () {
            if (this.unloadingCheckin && this.unloadingCheckin.signatureImageKey) {
                return resizer_1.ImageResizer.getResizedUrl(this.unloadingCheckin.signatureImageKey, 24, 24);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasUnloadingSignatureImages", {
        get: function () {
            if (this.unloadingCheckin && this.unloadingCheckin.options.signatureImage && !this.unloadingSignature) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "ticketImagesRequired", {
        get: function () {
            if ((this.loadingCheckin && this.loadingCheckin.options.ticketImage) ||
                (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage)) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasTicketImages", {
        get: function () {
            if ((this.loadingCheckin && this.loadingCheckin.options.ticketImage && !this.loadingTicket) ||
                (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage && !this.unloadingTicket)) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "loadingTicketImagesRequired", {
        get: function () {
            if (this.loadingCheckin && this.loadingCheckin.options.ticketImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasLoadingTicketImages", {
        get: function () {
            if (this.loadingCheckin && this.loadingCheckin.options.ticketImage && !this.loadingTicket) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "unloadingTicketImagesRequired", {
        get: function () {
            if (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "hasUnloadingTicketImages", {
        get: function () {
            if (this.unloadingCheckin && this.unloadingCheckin.options.ticketImage && !this.unloadingTicket) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "administrativeStatus", {
        get: function () {
            if (this.void) {
                return 'void';
            }
            else if (this.retakeStatus === 'requested') {
                return 'retake';
            }
            else if (this.edited) {
                return 'edited';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "displayableStatus", {
        get: function () {
            if (this.administrativeStatus && this.administrativeStatus.length) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "retakeRequested", {
        get: function () {
            if (this.retakeStatus && this.retakeStatus === 'requested') {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "ticketNumbers", {
        get: function () {
            var values = [];
            if (this.loadingTicketNumber && this.loadingTicketNumber.length) {
                values.push(this.loadingTicketNumber);
            }
            if (this.unloadingTicketNumber && this.unloadingTicketNumber.length) {
                values.push(this.unloadingTicketNumber);
            }
            return values.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "jobName", {
        get: function () {
            return this.jobEvent && this.jobEvent.job && this.jobEvent.job.name || '&nbsp;';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "projectName", {
        get: function () {
            return this.jobEvent && this.jobEvent.job && this.jobEvent.job.project &&
                this.jobEvent.job.project.name || '&nbsp;';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "customerName", {
        get: function () {
            return this.jobEvent && this.jobEvent.job && this.jobEvent.job.project &&
                this.jobEvent.job.project.customerOrganization &&
                this.jobEvent.job.project.customerOrganization.name || '&nbsp;';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "truckName", {
        get: function () {
            var values = [];
            values.push(this.truck && this.truck.name);
            if (typeof this.truck.truckType === 'string') {
                values.push(this.truck.truckType && this.truck.truckType);
            }
            else {
                values.push(this.truck.truckType && this.truck.truckType.name);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "stage", {
        get: function () {
            var value = '';
            if (this.unloadingCompleteTime) {
                value = 'complete';
            }
            else if (this.unloadingArrivalTime) {
                value = 'unloading';
            }
            else if (this.loadingCompleteTime) {
                value = 'en-route-two';
            }
            else if (this.loadingArrivalTime) {
                value = 'loading';
            }
            else if (this.enrouteLoadingTime) {
                value = 'en-route-one';
            }
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Trip.prototype.url = function (action) {
        return '/trips/' + this.id + '/' + action;
    };
    Object.defineProperty(Trip.prototype, "name", {
        get: function () {
            var values = [];
            if (this.jobName) {
                values.push(this.jobName);
            }
            if (this.driver) {
                var value = this.driver.name;
                if (this.truckName) {
                    value = value + ' (' + this.truckName + ')';
                }
                values.push(value);
            }
            return values.filter(Boolean).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Trip.prototype.filterOptions = function (options) {
        options = lodash_1.clone(options);
        if (this.void) {
            var _option = lodash_1.find(options, { action: 'void' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        if (!this.void) {
            var _option = lodash_1.find(options, { action: 'unvoid' });
            if (_option) {
                lodash_1.remove(options, _option);
            }
        }
        return options;
    };
    Object.defineProperty(Trip.prototype, "weightRequired", {
        get: function () {
            if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.allowWeight) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Trip.prototype, "pluralizedWeightUnit", {
        /*
         get mapImageURL(): string {
           let staticURL;
           if (this.routePolyline) {
             staticURL = 'https://maps.googleapis.com/maps/api/staticmap?size=60x45&path=color:0x002649|weight:1|enc:';
             staticURL += this.routePolyline;
             staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
           }
       
           return staticURL;
         }
       
         get fullsizeMapImageURL(): string {
           let staticURL;
           if (this.routePolyline) {
             staticURL = 'https://maps.googleapis.com/maps/api/staticmap?size=800x600&path=color:0x002649|weight:4|enc:';
             staticURL += this.routePolyline;
             staticURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
           }
       
           return staticURL;
         } */
        get: function () {
            var plural = true;
            if (this.weight && parseFloat(this.weight) === 1.0) {
                plural = false;
            }
            switch (this.weightUnit) {
                case 'cuyds':
                    return plural ? 'cu. yds.' : 'cu. yd.';
                default:
                    return plural ? this.weightUnit + 's' : this.weightUnit;
            }
        },
        enumerable: true,
        configurable: true
    });
    return Trip;
}());
exports.Trip = Trip;
