import {
  Component, Input, SimpleChanges, OnChanges, ViewChild,
  Output, EventEmitter
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';

// angular material
import { MatDialog, MatTable, MatTableDataSource } from '@angular/material';

// lodash
import { find as _find } from 'lodash'

// components
import { DriverAssignmentsListComponent } from '../../assignments/driver-assignments-list.component';
import { NewTripDialogComponent } from '../../trips/new-trip-dialog/new-trip-dialog.component';

// services
import { AuthenticationService } from '../../shared';
import { TripDecisionService } from '../../trips/trip-decision.service';
import { PunchCardDecisionService } from '../../punch-cards/punch-card-decision.service';

// types
import { Driver } from '../driver';
import { Trip } from '../../trips/trip';
import { TripDecision } from '../../trips/trip-decision';
import { User } from '../../users/user';
import { Organization } from '../../organizations/organization';
import { PunchCardDecision } from '../../punch-cards/punch-card-decision';
import { PunchCard } from '../../punch-cards/punch-card';

@Component({
  selector: 'driver-daily-tasks',
  templateUrl: './driver-daily-tasks.component.html',
  styleUrls: ['./driver-daily-tasks.component.scss']
})
export class DriverDailyTasksComponent implements OnChanges {
  @ViewChild(DriverAssignmentsListComponent, { static: false }) driverAssignmentsList: DriverAssignmentsListComponent;
  @ViewChild('driverTable', { static: false }) driverTable: MatTable<Driver>;
  @Input() reportDate: Date;
  @Input() driver: Driver;
  @Input() loading = true;
  @Input() filtersApplied = false;
  @Input() keyStatus: {driverId: string, key: string, loading: boolean, statuses: string[]}[] = [];
  @Output() shouldAuditDecisions: EventEmitter<string> = new EventEmitter<string>();
  @Output() rateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  displayedColumns: string[] = [
    'driver', 'completed-trips', 'total-time', 'invoice-total', 'expense-total', 'assignments'
  ];
  hideApproved = false;
  showVoided = false;
  showUnworkedJobs = false;
  dataSource = new MatTableDataSource();
  view: 'trips' | 'punch-cards' = 'trips';
  minTripStart: string;
  maxTripEnd: string;
  tripTimes = {};
  totalTimeOfAllJobs = '';

  saveTripCallback = (trip: Trip) => {
    if (this.route.snapshot.queryParamMap.get('refreshPage')) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'refreshPage': null,
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigateByUrl(`${this.router.url}&refreshPage=true`);
    }
  }

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private tripDecisionService: TripDecisionService,
    private punchCardDecisionService: PunchCardDecisionService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = [this.driver];
    if (this.driver && this.driver.jobEventShares) { this.getTripTimes(); }
    if (this.driverTable) { this.driverTable.renderRows(); }
  }

  switchView(view: 'trips' | 'punch-cards'): void {
    this.view = view;
  }

  toggleApproved(): void {
    this.hideApproved = !this.hideApproved;
  }

  toggleVoided(): void {
    this.showVoided = !this.showVoided;
  }

  toggleUnworkedJobs(): void {
    this.showUnworkedJobs = !this.showUnworkedJobs;
  }

  showDriverAssignments(driverData): void {
    this.driverAssignmentsList.setOpen(driverData);
  }

  getTripTimes(): void {
    this.tripTimes = {};
    let totalTimeOfAllJobs = 0;

    this.driver.jobEventShares.forEach(jobEventShare => {
      let tripTimesForJob = [];
      let totalTime = 0;

      this.driver.trips.forEach(trip => {
        let tripJobId = trip.jobEventShare ? trip.jobEventShare.jobId : trip.jobEvent.job.id;
        if (tripJobId === jobEventShare.jobId && !trip.void) {
          tripTimesForJob.push({
            'start': trip.startTimeTimestamp ? moment(trip.startTimeTimestamp).toDate() : '',
            'end': trip.endTimeTimestamp ? moment(trip.endTimeTimestamp).toDate() : ''
          });
          totalTime += trip.completedTripDuration;
        }
      });

      if (tripTimesForJob.length) {
        tripTimesForJob.sort(function (a, b) {
          return a.start - b.start;
        });

        this.tripTimes[jobEventShare.jobId + jobEventShare.organizationId] = {
          'start': tripTimesForJob[0].start,
          'end': tripTimesForJob[tripTimesForJob.length - 1].end,
          'totalTime': moment.duration(totalTime, 'milliseconds').format('D[ days] H[ hrs] m[ min] total')
        };
      }
      totalTimeOfAllJobs += totalTime;
    });
    this.totalTimeOfAllJobs = moment.duration(totalTimeOfAllJobs, 'milliseconds').format('H[ hrs] m[ min]');
  }

  openAddTrip(driver: Driver): void {
    const dialog = this.dialog.open(NewTripDialogComponent, {
      width: '455px'
    });
    dialog.componentInstance.model.driver = driver;
    dialog.componentInstance.model.truck = driver.latestTruck;
    dialog.componentInstance.jobEventDate = this.reportDate;

    dialog.componentInstance.callback = this.saveTripCallback;
  }

  changeStatus(decisionStatus = 'approved'): void {
    const user = this.authenticationService.user();
    const organization = this.authenticationService.getOrganization();
    if (this.view === 'trips') {
      this.changeTripDecisionStatuses(decisionStatus, user, organization);
    } else if (this.view === 'punch-cards') {
      this.changePunchCardsDecisionStatuses(decisionStatus, user, organization);
    }
  }

  changeTripDecisionStatuses(
    decisionStatus: string,
    user: User,
    organization: Organization
  ): void {
    this.driver.jobEventShares.forEach((jobEventShare) => {
      let requests: Observable<TripDecision>[] = [];
      let newStatus = false;
      let status = _find(this.keyStatus, {
        driverId: this.driver.id,
        key: jobEventShare.jobId,
      });
      if (status) {
        status.loading = true;
      } else {
        newStatus = true;
        status = {
          driverId: this.driver.id,
          key: jobEventShare.jobId,
          loading: true,
          statuses: [],
        };
      }

      if (this.driver) {
        this.driver.trips.forEach((trip: Trip) => {
          let alreadyApproved =
            trip.latestDecisionStatus === 'approved' &&
            decisionStatus === 'approved';
          if (
            trip &&
            trip.jobEvent.job.id === jobEventShare.jobId &&
            !alreadyApproved
          ) {
            trip.loading = true;
            let data = {
              trip: trip,
              decisionStatus: decisionStatus,
              decidedBy: user,
              organization: organization,
              decidedAt: new Date().toISOString(),
            };
            requests.push(this.tripDecisionService.save(data));
          }
        });
      }

      combineLatest(requests).subscribe(
        (decisions: TripDecision[]) => {
          decisions.forEach((decision) => {
            let trip: Trip = <Trip>_find(this.driver.trips, {
              id: decision.trip && decision.trip.id,
            });
            if (trip) {
              trip.latestDecision = decision.id;
              trip.latestDecisionStatus = decision.decisionStatus;
              trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
              trip.latestDeciderName =
                decision.decidedBy && decision.decidedBy.name;
              status.statuses = [decision.decisionStatus];
              trip.loading = false;
            }
          });
        },
        (errors) => {
          console.log('Decision Errors: ', errors);
        },
        () => {
          status.loading = false;
          this.driver.trips = [...this.driver.trips];
          if (newStatus) {
            this.keyStatus.push(status);
          }
        }
      );
    });
  }

  changePunchCardsDecisionStatuses(
    decisionStatus: string,
    user: User,
    organization: Organization
  ): void {
    this.driver.jobEventShares.forEach((jobEventShare) => {
      let requests: Observable<PunchCardDecision>[] = [];
      let newStatus = false;
      let status = _find(this.keyStatus, {
        driverId: this.driver.id,
        key: jobEventShare.jobId,
      });
      if (status) {
        status.loading = true;
      } else {
        newStatus = true;
        status = {
          driverId: this.driver.id,
          key: jobEventShare.jobId,
          loading: true,
          statuses: [],
        };
      }

      if (this.driver) {
        this.driver.punchCards.forEach((punchCard: PunchCard) => {
          let alreadyApproved =
            punchCard.latestDecisionStatus === 'approved' &&
            decisionStatus === 'approved';
          if (
            punchCard &&
            punchCard.job.id === jobEventShare.jobId &&
            punchCard.job.project.ownerOrganization ===
              jobEventShare.organizationId &&
            !alreadyApproved
          ) {
            punchCard.loading = true;
            let data = {
              punchcard: punchCard,
              decisionStatus: decisionStatus,
              decidedBy: user,
              organization: organization,
              decidedAt: new Date().toISOString(),
            };
            requests.push(this.punchCardDecisionService.save(data));
          }
        });
      }

      combineLatest(requests).subscribe(
        (decisions: PunchCardDecision[]) => {
          decisions.forEach((decision) => {
            let punchCard: PunchCard = <PunchCard>(
              _find(this.driver.punchCards, {
                id: decision.punchcard && decision.punchcard.id,
              })
            );
            if (punchCard) {
              punchCard.latestDecision = decision.id;
              punchCard.latestDecisionStatus = decision.decisionStatus;
              punchCard.latestDecider =
                decision.decidedBy && decision.decidedBy.id;
              punchCard.latestDeciderName =
                decision.decidedBy && decision.decidedBy.name;
              status.statuses = [decision.decisionStatus];
              punchCard.loading = false;
            }
          });
        },
        (errors) => {
          console.log('Decision Errors: ', errors);
        },
        () => {
          status.loading = false;
          this.driver.punchCards = [...this.driver.punchCards];
          if (newStatus) {
            this.keyStatus.push(status);
          }
        }
      );
    });
  }
}
