import {
  Component, Output, OnInit, EventEmitter, Input, TemplateRef,
  ViewChild, ChangeDetectorRef, SimpleChanges, OnChanges
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { difference } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { TruckService } from './truck.service';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { parseErrors } from '../shared/api.service';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { EditTruckComponent } from './edit-truck.component';
import { NewTruckDialogComponent } from './new-truck-dialog.component';
import { AuthenticationService, TimelineLabel } from '../shared';
import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { Truck } from './truck';
import { FiltersDialogComponent } from '../shared/filters-dialog/filters-dialog.component';
import { TagService } from '../tags/tag.service';
import { DropdownConfig } from '../shared/ruckit-dropdown/ruckit-dropdown.component';
import { TruckTypeService } from './truck-type.service';
import { ItemGroup, ItemList } from '../shared/item-grid/item-grid.component';
import { Subscription } from 'rxjs';

// animations
import { editTruckAnimation } from '../shared/animations/edit-truck.animation';

@Component({
  selector: 'trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss'],
  animations: [editTruckAnimation]
})
export class TrucksComponent implements OnInit {
  view: 'list' | 'grid' = 'list';
  archive: boolean;
  @Output() viewArchive: EventEmitter<boolean> = new EventEmitter<boolean>();

  truckList: Truck[];
  truckGroups: ItemList;
  loadingProgress = 0;
  displayKeys = ['name', 'displayName'];
  groupByOptions = ['Carrier', 'Truck Type', 'Market', 'Condition'];
  activeGroupBy: 'Carrier' | 'Truck Type' | 'Condition' | 'Market' = 'Truck Type';
  // DEV NOTE: removing this only for now until BE supports this capability
  // gridLabels: TimelineLabel[] = [
  //   {
  //     name: 'Driver Assigned',
  //     color: '#015BC5'
  //   },
  //   {
  //     name: 'No Driver Assigned',
  //     color: '#ffffff'
  //   },
  //   {
  //     name: 'Out of Service',
  //     color: 'rgba(208, 2, 27, 0.15)'
  //   }
  // ];
  gridLabels: TimelineLabel[] = [
    {
      name: 'In Service',
      color: '#ffffff'
    },
    {
      name: 'Out of Service',
      color: 'rgba(208, 2, 27, 0.15)'
    }
  ];

  @Input() availableColumns = [
    { key: 'select' },
    { key: 'truck-type', title: this.translationService.instant('Truck Type'), sortable: true, sortBy: 'truck_type__name' },
    { key: 'name', title: this.translationService.instant('Truck #'), sortable: true },
    { key: 'license-plate', title: this.translationService.instant('License Plate'), sortable: true },
    { key: 'carrier', title: this.translationService.instant('Fleet'), sortable: true, sortBy: 'carrier_organization_name'  },
    { key: 'markets', title: this.translationService.instant('Markets') },
    { key: 'created-by', title: this.translationService.instant('Created by') },
    { key: 'serviceStatus', title: this.translationService.instant('Condition') },
    { key: 'deleted', title: this.translationService.instant('Deleted') },
    { key: 'action', title: this.translationService.instant('Action') },
  ];
  @Input() displayedColumns = [
    'select', 'truck-type', 'name', 'license-plate', 'markets', 'created-by', 'serviceStatus', 'action'
  ];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Input() query = {};
  @Input() carrierId;
  @Input() customHeight;
  @Input() customClasses = 'trucks';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  errors = [];
  truck: Truck;
  tableConfig = {
    hasHeader: true,
    service: TruckService,
    preferenceKey: 'TrucksComponent-TruckService',
    filterQuery: false,
    query: {},
    collectionTitle: this.translationService.instant('Truck'),
    noResultsText: this.translationService.instant('a truck'),
    newRecordModal: () => { this.addTruck(); },
    sortBy: 'name',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: false },
      { name: this.translationService.instant('Remove'), action: 'remove', link: false, external: false }
    ]
  };
  filters = [];
  loading = true;
  type = 'all';
  confirmDialog: MatDialogRef<any>;
  @ViewChild(EditTruckComponent, { static: false }) editDrawer;
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('trucksTable', { static: false }) trucksTable: FancyTableComponent;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle;
  filtersDialog: FiltersDialogComponent;
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  saveTruckCallback = () => {
    this.trucksTable.getRecords();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public truckService: TruckService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslateService
  ) { }

  /**
   * Parses user settings to set user tags as part of the table query.
   * Subs to any updates in the listAllProgress subject in the truck service
   */
  ngOnInit() {
    let query = {...this.query};
    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
    }
    this.tableConfig['query'] = this.query = { ...this.tableConfig['query'], ...query };

    if (this.view === 'grid') {
      this.allSubscriptionsToUnsubscribe.push(
        this.truckService.listAllProgress.subscribe(progress => {
          this.loadingProgress = Math.ceil(progress * 100);
        })
      );
    }
  }

  /**
   * Parses the url params and queries to set values used by the table and grid
   */
  ngAfterViewInit() {
    this.tableConfig['customHeight'] = this.customHeight;
    if (this.route && this.route.queryParams) {
      this.route.queryParams.forEach((params: Params) => {
        this.loading = true;
        this.type = params['type'];
        this.search = params['search'] || '';
      });
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.trucksTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
      this.trucksTable.query = this.query;
      this.refreshTable();
      if (this.query['status']) {
        this.archive = !!(this.query['status'] === 'deleted');
      } else {
        this.archive = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * @param  {} event
   * This function would get called on the click of row of the table
   * On click of row it will open the edit drawer for the selected truck.
   */
  clickAction(event) {
    if (event) {
      if (this.editDrawer && this.editDrawer.truckForm && !this.editDrawer.truckForm.form.pristine) {
        this.editDrawer.confirmBeforeClosing().subscribe((dialogResult) => {
          if (dialogResult) {
            this.editDrawer.submit();
          } else {
            this.editDrawer.close();
            this.selectTruck(event, event[1]);
          }
        });
      } else {
        this.editDrawer.close();
        this.selectTruck(event, event[1]);
      }
    }
  }

  /**
   * @param  {} action
   * @param  {} truck
   * Check the action to be performed and call the corresponding function.
   * other functions like export can be added here in future.
   */
  menuAction(action: string, truck) {
    switch (action) {
      case 'edit':
        this.selectTruck(null, truck);
        break;
      case 'remove':
        this.removeTruck(truck);
        break;
      case 'unarchive':
        this.unarchive(truck);
        break;
    }
  }

  /**
   * @param  {} e
   * @param  {} truck
   * This function would open a edit window for updating or removing
   * the truck
   */
  selectTruck(e, truck) {
    let target = e && (e.target || e.srcElement || e.currentTarget);
    if (
      target && target.className && target.className.includes('action-menu-icon') ||
      target && target.type === 'checkbox'
    ) {
      // Do nothing
    } else {
      // if click action is performed on the opened truck then editor will be closed.
      if (truck && this.truck && this.truck.id === truck.id) {
        this.truck = null;
      } else {
        setTimeout(() => {
          this.truck = truck;
          this.editDrawer.setOpen();
          this.editDrawer.afterEdit = this.saveTruckCallback;
        }, 100);
      }
    }
  }

  handleGridSelection(truckIds: string[]) {
    if (truckIds[0]) {
      const matchedTruck = this.truckList.find(t => (t.id === truckIds[0]));
      this.selectTruck({}, matchedTruck);
    } else {
      this.editDrawer.close();
    }
  }

  unarchive(truck: Truck) {
    this.truckService.save({ id: truck.id, status: 'active' }).subscribe(() => {
      this.refreshTable();
    });
  }

  /**
   * @param  {} truck
   * Removes the selected truck and refresh the table with new data
   * Opens the remove confirm dialog
   */
  removeTruck(truck) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Remove Truck?'),
      body: this.translationService.instant('This truck will be deleted and placed in the archived section where you can reactivate at a later time.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Remove')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        this.truckService.remove(truck).subscribe((res) => {
          this.refreshTable();
        }, (err) => {
          this.errors = parseErrors(err);
          this.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;
    params['type'] = params['type'] ? params['type'] : this.type;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  /**
   * opens a page for adding new price list
   */
  addTruck() {
    const dialog = this.dialog.open(NewTruckDialogComponent, {
      width: '430px',
      height: '800px',
    });
    if (dialog && dialog.componentInstance) {
      if (this.carrierId) { dialog.componentInstance.carrierId = this.carrierId; }
      dialog.componentInstance.callback = this.saveTruckCallback;
    }
  }

  /**
   * refresh/reload the trucks table on any action performed like
   * add, edit, remove
   */
  refreshTable() {
    if (this.trucksTable) {
      let query = {};
      if (this.authenticationService.hasFavoriteTags()) {
        query['user_tags'] = 'True';
      }
      this.trucksTable.getRecords({ ...this.tableConfig['query'], ...this.query, ...query });
    } else {
      const query = {...this.tableConfig['query'], ...this.query};
      if (this.authenticationService.hasFavoriteTags()) {
        query['user_tags'] = 'True';
      }
      if (this.view === 'grid') {
        this.getFullTruckList(query);
      }
    }
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  openFilters(): void {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.filters = [
      {
        type: 'dropdown',
        field: 'tags',
        label: 'Markets',
        dropdownConfig: <DropdownConfig>{
          service: TagService,
          multiselect: true
        }
      }, {
        type: 'dropdown',
        field: 'truckType',
        label: 'Truck Type',
        dropdownConfig: <DropdownConfig>{
          service: TruckTypeService
        }
      },
      {
        type: 'text',
        field: 'licensePlate',
        label: 'License Plate Search'
      }
    ];
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      truckType: 'truck_type',
      tags: 'tags',
      licensePlate: 'license_plate'
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (filterRes[key]) {
        if (key === 'tags') {
          if (values && values.length > 0) {
            values = values.map(tag => tag.name).join(',');
            query[queryKeys[key]] = values;
          }
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id || values;
        }
      }
      let filter = new FilterOption({
        filterType: 'text',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (!filter.values) { falseyFilters.push(filter); }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }

   /**
   * This function is called when edit is successfully completed
   * This would update the table with the changed fields
   *
   * @returns {void}
   */
  onEditComplete(modifiedTruck): void {
    if (this.view === 'list') {
      this.trucksTable.updateTable(modifiedTruck);
    } else {
      this.truckList = this.truckList.map(t => (
        t.id === modifiedTruck.id ?
        Object.assign(t, modifiedTruck) : t
      ));
      this.setupItemList(this.truckList, this.activeGroupBy);
    }
  }

  /**
   * Switched the UI view and then triggers the truck list setup if no list is currently set up
   *
   * @param {'list' | 'grid'} view The selected view
   */
  switchView(view: 'list' | 'grid') {
    const query = {...this.tableConfig.query, ...this.query};
    if (this.authenticationService.hasFavoriteTags()) {
      query['user_tags'] = 'True';
      this.tableConfig.query = query;
    }
    if (this.view === 'list' && view === 'grid') {
      this.view = view;
    } else if (this.view === 'grid' && view === 'list') {
      this.truckGroups = undefined;
      this.view = view;
    }
    if (view === 'grid' && !this.truckGroups) {
      this.getFullTruckList(query);
    }
  }

  /**
   * Fetches the full truck list based on the same query as the truck table
   *
   * @param {any} query The query to be appended to the full list request
   */
  getFullTruckList(query: any) {
    this.truckService.listAll(25, query).subscribe(trucks => {
      this.truckList = trucks;
      this.setupItemList(this.truckList, this.activeGroupBy);
    });
  }

  /**
   * Sets up the truck groups based on the specified groupBy param
   *
   * @param {Truck[]} trucks The truck list to be grouped
   * @param {'Carrier' | 'Truck Type' | 'Condition' | 'Market'} groupBy The selected groupBy option
   */
  setupItemList(trucks: Truck[], groupBy: 'Carrier' | 'Truck Type' | 'Condition' | 'Market') {
    this.activeGroupBy = groupBy;
    switch (groupBy) {
      case 'Carrier':
        this.truckGroups = Array.from(
          new Set(
            trucks.map(t => (
              <ItemGroup>{
                id: t.carrierOrganizationId,
                name: t.carrierOrganizationName,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: trucks.filter(truck => (truck.carrierOrganizationId === group.id))
        })));
        break;
      case 'Market':
        let marketGroups = [];
        trucks.forEach(t => {
          if (t.tags && t.tags.length) {
            t.tags.forEach(tag => {
              marketGroups.push(<ItemGroup>{
                id: tag.id,
                name: tag.name,
                groupBy: groupBy,
                items: []
              });
            });
          } else {
            marketGroups.push(<ItemGroup>{
              id: '',
              groupBy: groupBy,
              items: []
            });
          }
        });
        this.truckGroups = marketGroups.filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: trucks.filter(truck => (
            group.id === '' ? (!truck.tags || truck.tags.length === 0) :
            truck.tags.map(t => (t.id)).join(' ').includes(group.id)
          ))
        })));
        break;
      case 'Truck Type':
        this.truckGroups = Array.from(
          new Set(
            trucks.map(t => (
              <ItemGroup>{
                name: t.truckType && t.truckType.name,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: trucks.filter(truck => ((truck.truckType && truck.truckType.name) === group.name))
        })));
        break;
      case 'Condition':
        this.truckGroups = Array.from(
          new Set(
            trucks.map(t => (
              <ItemGroup>{
                id: t.serviceStatus,
                name: t.displayServiceStatus,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: trucks.filter(truck => (group.id === truck.serviceStatus))
        })));
        break;
    }
    this.truckGroups = this.truckGroups.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
  }

  onDataLoaded() {
    const query = {...this.tableConfig.query, ...this.query};
    if (this.view === 'grid') {
      this.getFullTruckList(query);
    }
  }

  /**
   * Selects a new groupBy option and re-triggers the truck grid setup based on that option
   *
   * @param {'Carrier' | 'Truck Type' | 'Condition'} groupBy The selected groupBy option
   */
  selectGroupBy(groupBy: 'Carrier' | 'Truck Type' | 'Condition') {
    this.setupItemList(this.truckList, groupBy);
  }

  /**
   * Returns a set of class names in a single string to be appended to item elements in the item grid
   *
   * @param {Truck} truck The truck object
   * @returns {string} The class names to be appended to item elements
   */
  generateItemClassNames(truck: Truck): string {
    let classNames = '';
    if (truck.serviceStatus === 'out-of-service') {
      classNames += 'red ';
    }
    // DEV NOTE: removing this only for now until BE supports this capability
    // } else if (truck.driver && truck.driver.id) {
    //   classNames += 'blue ';
    // }
    return classNames;
  }

  onClose() {
    this.truck = null;
  }
}
