<my-toolbar class="desktop" title="{{ 'Job Dispatch' | translate }}"></my-toolbar>

<action-bar>
  <div class="left-actions">
    <ruckit-dropdown #dropdownWrapper
                     class="job-dropdown"
                     [config]="jobDropdownConfig"
                     [selectedOption]="jobId"
                     (onUserSelect)="selectJob($event)"
                     (onSelect)="selectJob($event, false)">
    </ruckit-dropdown>
    <dropdown title="{{ jobEventsLoading ? 'Loading Job Days...' : 'Select a Job Day' }}"
              [disabled]="jobsLoading || jobEventsLoading"
              #jobEventsDropdown
              [loading]="jobEventsLoadingNext"
              [options]="jobEvents"
              [config]="jobEventsDropdownConfig"
              (onSelect)="selectJobEvent($event)"
              (nextPage)="getJobEvents({}, true)"
              class="job-events-dropdown"></dropdown>
  </div>
  <div class="right-actions">
    <dropdown #actionsDropdown
              title="Actions"
              [options]="actionsDropdownOptions"
              [config]="actionsDropdownConfig"
              (onSelect)="setSelectedAction($event)"
              class="actions-dropdown"></dropdown>
    <a *ngIf="job && jobEvent && jobEvent.canShare" translate
       [routerLink]="['/jobs', job.id, jobEvent.id, 'collaborators']"
       [queryParams]="{returnTo: '/dispatch/' + job.id + '/' + jobEvent.id, returnToTitle: 'Job Dispatch'}"
       fragment="addCollaborator" class="btn btn-primary">
      Add Collaborators
    </a>
    <button class="btn btn-primary dispatch-button" (click)="sendDispatch()" [ngClass]="{loading: dispatchLoading}"
      [disabled]="dispatchDisabled()" translate>
      Dispatch Drivers
    </button>
  </div>
</action-bar>

<div class="no-results desktop" *ngIf="!loading && !errors.length && (!job && !jobEvent)" fxLayout="column"
  fxLayoutAlign="start center">
  <div *ngIf="!job">
    <div class="icon"><i class="icon-projects"></i></div>
    <h2 translate>No Job Selected</h2>
    <p translate>Choose a job from the dropdown above to begin Dispatching.</p>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="start stretch" [ngClass]="{ 'visible': job && jobEvent }" class="job desktop">
  <div class="job-container" *ngIf="job && jobEvent">
    <day-details [job]="job" [jobEvent]="jobEvent" [linkToJobEvent]="true" [includeJobStats]="true"></day-details>

    <div class='job-assignment' fxFlex fxFlexAlign="stretch">
      <notification level="info" *ngIf="displayLastDispatched()">
        <ng-container translate>
          Dispatched at {{ jobEvent.lastDispatchedTime | date:'shortTime'}} on
          {{ jobEvent.lastDispatchedTime | date:'shortDate' }}
        </ng-container>
        <ng-container *ngIf="jobEvent.lastDispatchedBy" translate>
          by {{ jobEvent.lastDispatchedBy.firstName }} {{ jobEvent.lastDispatchedBy.lastName }}
        </ng-container>
      </notification>

      <div class="center-header">
        <div class="mobile-view-controls">
          <button
            [ngClass]="{ active: view === 'assignments' }"
            (click)="onTabClick('assignments')"
            translate
          >
            Assignments
          </button>
          <button
            *ngIf="hasLoadListsEnabled && loadList.length"
            [ngClass]="{ active: view === 'loadList' }"
            (click)="onTabClick('loadList')"
            translate
          >
            Load List
          </button>
        </div>
        <div class="estimates" *ngIf="jobEvent && (jobEvent.loadStatus || jobEvent.dayEstimate)">
          <div class="delivery-status">{{ jobEvent.loadStatus }}</div>
          <div class="day-estimate">{{ jobEvent.dayEstimate }}</div>
        </div>
      </div>
      
      <div class="bulk-actions">
        <!-- <div class="load-list-legend" *ngIf="view === 'loadList'">
          <div class="label" *ngFor="let label of loadListLegend">
            <span class="color" [ngStyle]="{ 'background-color': label.color }"></span>
            <p>{{ label.name }}</p>
          </div>
        </div> -->
        <ng-container *ngIf="jobEvent">
          <split-button 
            *ngIf="view === 'assignments'"
            title="Auto-Assign Previous Trucks & Drivers"
            [disabled]="autoAssignLoading"
            (onPrimaryAction)="autoAssign()"
          >
            <a class="save-all-changes" [ngClass]="{loading: bulkSaveLoading}" (click)="triggerBulkSave()" translate>
              Save All Changes
            </a>
            <a class="move-to-new-day" [ngClass]="{loading: bulkSaveLoading}" (click)="modifySelectedAssignments()" translate>
              Move to a New Day
            </a>
            <a class="copy-to-new-day" [ngClass]="{loading: bulkSaveLoading}" (click)="modifySelectedAssignments(true)" translate>
              Copy to a New Day
            </a>
            <a class="remove-assignments" [ngClass]="{loading: bulkSaveLoading}" (click)="removeSelectedAssignments()" translate>
              Remove Assignments
            </a>
          </split-button>
          <div class="load-list-action" *ngIf="view === 'loadList'">
            <button
              class="btn btn-default"
              (click)="removeDriversFromLoads()"
              [disabled]="!loadListSelection.selected.length"
              translate
            >
              Remove Drivers
            </button>
          </div>
        </ng-container>
      </div>
      <my-loader *ngIf="loading || assignmentsLoading"></my-loader>

      <span class='value' *ngIf="job && !jobEvent && !loading" translate>
        No job event exists for this day.
      </span>
      <div *ngIf="!assignmentsLoading" class="shifts" [ngClass]="{'multiple-shifts': jobEvent && jobEvent.shifts > 1}">
        <div class="shift" *ngFor="let shift of shifts; let shiftIndex = index">
          <div class="shift-header" [ngClass]="{'load-list-shift-header': view === 'loadList'}">
            <div class="shift-detail" *ngIf="view === 'assignments' && jobEvent && jobEvent.shift1StartTimestamp">
              <span class="shift-name" translate>SHIFT {{ shiftIndex + 1 }}</span>
              <span class="shift-schedule">
                {{ jobEvent['shift' + (shiftIndex + 1) + 'StartTimestamp'] | date: 'h:mm a' | uppercase }} - 
                {{ jobEvent['shift' + (shiftIndex + 1) + 'EndTimestamp'] | date: 'h:mm a' | uppercase }}
              </span>
            </div>
            <div *ngIf="view === 'assignments'" class="select-all">
              <label translate>
                Select All
                <input 
                  #selectAllAssignments
                  class="select-all-assignments" 
                  type="checkbox"
                  (change)="selectAssignment($event, null, shift)" 
                />
              </label>
            </div>
            <div *ngIf="view === 'loadList'" class="select-all">
              <label translate>
                Select All
                <input 
                  class="select-all-loads" 
                  type="checkbox"
                  [(ngModel)]="loadListSelectAll"
                  (change)="clickSelectAllLoads($event)" 
                />
              </label>
            </div>
          </div>
          <!-- assignments view -->
          <ng-container *ngIf="view === 'assignments'">
            <div class="slot" *ngFor="let slot of shift.slots; let i = index"
                [ngClass]="{
                  over: slot.over, filled: slot.driver && slot.driver.id,
                  empty: !slot.driver || !slot.driver.id, loading: slot.updating
                }"
                aria-dropeffect="move"
                (dragenter)="slotDrag(slot, $event)"
                (dragleave)="slotDrag(slot, $event)"
                (dragexit)="slotDrag(slot, $event)"
                (dragover)="slotDragover($event)"
                (drop)="dropDriver(shift, slot, $event)">
              <div translate *ngIf="slot.updating" class="loading-text" translate>Updating Assignment...</div>
              <div *ngIf="slot && slot.driver && slot.driver.id" class="driver"
                  draggable="true" (dragstart)="driverDragstart(slot.driver)">
                <div class="photo-set">
                  <div class="truck-photo" [ngClass]="{empty: !slot.truck, 'icon-plus': !slot.truck}"
                    (click)="openAssignTruck(slot.driver, slot.assignment && slot.assignment.id)">
                    <div class="image" *ngIf="slot.truck">
                      <img *ngIf="slot.truck.image" [src]="slot.truck.dispatchImage" />
                      <i *ngIf="!slot.truck.image" class="icon-fleet"></i>
                    </div>
                    <div class="overlay"><i class="icomoon icon-plus"></i></div>
                  </div>
                  <div class="driver-photo">
                  <span *ngIf="!slot.driver.image">{{slot.driver.name[0]}}</span>
                  <img *ngIf="slot.driver.image" [src]="slot.driver.dispatchImage" />
                  <div class="overlay"></div>
                  <div class="overlay"><i class="icomoon icon-plus"></i></div>
                  </div>
                </div>
                <div class="info">
                  <div class="name">{{ slot.driver.name }}</div>
                  <div *ngIf="slot.truck" class="truck"># {{slot.truck.displayName}}</div>
                  <div class="driver-status">
                    {{ slot.driver.carrier && slot.driver.carrier.leasedOrg ? 'Leased' : slot.driver.organizationName }}
                  </div>
                  <div class="start-time">
                    <span class="label" translate>Start Time: </span>
                    <date-range-picker (click)="slot.saveButton = true"
                                      [config]="{decreaseControl: false, increaseControl: false}"
                                      [disabled]="!slot.assignment"
                                      [selectedDates]="[slot.assignment.uniqueStart]"
                                      (dateChanged)="assignmentUniqueStartDateChanged(slot, $event)"></date-range-picker>
                      <input type="text"
                            required
                            name="driverStartTime"
                            class="timepicker"
                            [placeholder]="slot.assignment.uniqueStartTime ? slot.assignment.uniqueStartTime : (jobEvent['shift' + (shiftIndex + 1) + 'StartTimestamp'] | date: 'h:mm a')"
                            [(ngModel)]="slot.assignment.uniqueStartTime"
                            (blur)="updateAssignment(slot)"
                            [options]="{
                              disableTextInput: false,
                              'timeFormat': 'g:i A',
                              showDuration: false,
                              step: 5
                            }"/>
                    <span class="dropdown-arrow"></span>
                  </div>
                  <div *ngIf="hasAllDriversEnabled" class="load-type">
                    <input type="radio" name="{{ 'max-loads-' + slot.assignment.id }}" required
                      (change)="updateAssignment(slot)" [(ngModel)]="slot.numberOfLoadsType" [value]="'numbered'" />
                    <label for="{{ 'max-loads-' + slot.assignment.id }}"
                      [ngClass]="{'active': slot.numberOfLoadsType === 'numbered'}" translate>Loads:</label>
                    <input type="number" name="{{ 'load-count-' + slot.assignment.id }}"
                      [disabled]="slot.numberOfLoadsType !== 'numbered'" (change)="updateAssignment(slot)"
                      [(ngModel)]="slot.assignment.maxNumberOfLoads" min="1" />
                    <input type="radio" name="{{ 'max-loads-' + slot.assignment.id }}" required
                      [(ngModel)]="slot.numberOfLoadsType" (change)="updateAssignment(slot)" [value]="'allDay'" />
                    <label for="{{ 'max-loads-' + slot.assignment.id }}"
                      [ngClass]="{'active': slot.numberOfLoadsType === 'allDay'}" translate>All Day</label>
                  </div>

                  <div class="field-group yard-buffer-time">
                    <span class="label" translate>Yard Buffer Time: </span>
                    <div class="input-group">
                      <input type="number" min="0" name="yardBufferMinutes" class="yard-buffer"
                        [(ngModel)]="slot.assignment.yardBufferMinutes"
                        (blur)="updateAssignment(slot)" />
                      <div class="add-on" translate>Minutes</div>
                    </div>
                  </div>

                  <div class="status">
                    <div class="completed-status">
                      <input type="checkbox" name="assignment-completed" [(ngModel)]="slot.assignment.completed"
                        (change)="updateAssignment(slot)">
                      <label for="assignment-completed" translate
                        [class.completed]="slot.assignment.completed">Completed</label>
                    </div>
                    <div class="dispatch-status">
                      <div class="dispatch-icon" name="dispatch-icon">
                        <i *ngIf="slot.assignment && slot.assignment.dispatched" class="icon-dispatched"></i>
                        <i *ngIf="!slot.assignment || !slot.assignment.dispatched" class="icon-not-dispatched"></i>
                      </div>
                      <label for="dispatch-icon" translate>Received</label>
                    </div>
                    <div class="accepted-status">
                      <i name="assignment-icon" class="icon-assignment_turned_in"
                        [class.accepted]="slot.assignment.driverStatus === 'confirmed'"></i>
                      <label for="assignment-icon" translate>Confirmed</label>
                    </div>
                    <div class="connex-status">
                      <i name="sync-icon" class="icon-sync" [class.synced]="slot.assignment.connexLastSentAt" title="{{'Connex Sync Status' | translate}}"></i>
                      <label for="sync-icon" translate *ngIf="!slot.assignment.connexLastSentAt" title="{{'Not Synced' | translate}}">Not Synced</label>
                      <label for="sync-icon" translate *ngIf="slot.assignment.connexLastSentAt" title="{{'Synced At ' | translate}} {{ slot.assignment.connexLastSentAt | date:'medium' }}">Synced</label>
                    </div>
                  </div>

                  <div *ngIf="slot.truck && slot.truck.leasedOrg" class="leased" translate>Leased</div>
                  <div *ngIf="!slot.truck" class="label-danger" translate>truck unassigned</div>
                  <div *ngIf="slot.driver.previousJob" class="previous-job" translate>Previous Job:
                    {{slot.driver.previousJob.name}}
                  </div>
                  <div *ngIf="slot.assignment.assignedEstimate && jobEvent.shifts > 1" class="job-estimate">
                    {{slot.assignment.assignedEstimate}}
                  </div>
                  <div
                    *ngIf="slot.driver && slot.driver.otherConflicts(this.jobEvent) && slot.driver.otherConflicts(this.jobEvent).length"
                    class="driver-assignments">
                    {{ 'Assigned:' | translate }}
                    <span *ngFor="let conflict of slot.driver.otherConflicts(this.jobEvent); let isLast = last">
                      {{ conflict.jobeventName }}{{ isLast ? '' : ', ' }}
                    </span>
                    <span *ngIf="slot.driver.otherConflicts(this.jobEvent).length > 1">
                      + {{ slot.driver.otherConflicts(this.jobEvent).length - 1 }}
                    </span>
                  </div>
                </div>
                <div class="right-column">
                  <div *ngIf="jobEvent.shifts <= 1" class="assigned-estimate">
                    <input class="select-assignment" type="checkbox" [checked]="slot.assignment.selected" (change)="selectAssignment($event, slot)" />
                    <span class="assigned-tons" translate>
                      {{slot.assignment.estimatedNumberOfTons || 0 | number}} tons
                    </span><br />
                    <span class="assigned-loads" translate>
                      {{slot.assignment.estimatedNumberOfLoads || 0 | number}} loads
                    </span><br />
                    <span class="assigned-notice" translate>
                      Estimated on {{shiftIndex === 0 ? jobEvent.shiftOneDuration : jobEvent.shiftTwoDuration}} shift
                    </span>
                  </div>
                  <div class="assignment-notes">
                    <span class="label" translate>Notes</span>
                    <textarea name="assignmentNotes" [(ngModel)]="slot.assignment.notes" (blur)="updateAssignment(slot)" draggable="true" (dragstart)="ignoreDragevent($event)"></textarea>
                  </div>
                </div>
              </div>
              <div *ngIf="!slot.driver || !slot.driver.id">{{ slot.over ? 'Assign' : i + 1 }}</div>
            </div>
          </ng-container>
          <!-- end of assignments view -->

          <!-- load list view -->
          <ng-container *ngIf="view === 'loadList'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="loadList">
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef translate></th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? toggleLoadListSelection(row, $event) : null"
                    [checked]="loadListSelectAll || loadListSelection.isSelected(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.vehicleId ? '#' + row.vehicleId : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  [ngClass]="{ 'drag-over': row.dragOver }"
                  (dragenter)="row.dragOver = row.ruckit && row.ruckit.assignmentId ? false : true"
                  (dragleave)="row.dragOver = false"
                  ondragover="event.preventDefault()"
                  (drop)="assignDriverToLoad(row)"></tr>
            </table>
          </ng-container>
          <!-- end of load list view -->
        </div>
      </div> <!-- end of ngIf="assignmentsLoading" -->
    </div>
  </div>

  <available-drivers #availableDrivers *ngIf="job && jobEvent"
                     [loading]="loading" [job]="job" [(jobEvent)]="jobEvent"
                     [selectedTags]="selectedTags"
                     [dispatchByJobTab]="view"
                     (selectedDriversChange)="selectDrivers($event)"
                     (dragover)="slotDragover($event)"
                     (drop)="dropDriver(null, null, $event)"
                     (assignDriversClicked)="selectDrivers($event, true)"
                     (shiftChange)="selectShift($event)"></available-drivers>
</div>