import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { ResourceService } from './../shared/resource.service';
import { JobEventStat } from './job-event-stat';
import { JobEventStatSerializer } from './job-event-stat.serializer';
import { requestHeaders, handleError } from '../shared/index';

@Injectable()
export class JobEventStatService extends ResourceService<JobEventStat> {
  baseUrl = environment.serverUrl;

  constructor(http: HttpClient) {
     super(http, 'jobevents/stats/', new JobEventStatSerializer());
  }

  getStats(jobEventId: string, query: any = null): Observable<JobEventStat> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key] && query[key].toString());
        }
      });
    }
    let statsUrl = this.baseUrl + 'jobevents/' + jobEventId + '/stats/';
    return this.http.get(statsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getJobStats(jobId: string, query: any = null): Observable<JobEventStat[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key] && query[key].toString());
        }
      });
    }
    let statsUrl = this.baseUrl + 'jobs/' + jobId + '/stats/';
    return this.http.get(statsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  getAllStats(query: any = null): Observable<JobEventStat[]> {
    let params: HttpParams = new HttpParams();
    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key] && query[key].toString());
        }
      });
    }
    let jobEventsUrl = this.baseUrl + 'jobevents/stats/';
    return this.http.get(jobEventsUrl, {
      headers: requestHeaders(),
      params: params
    }).pipe(
      map(res => { return this.extractData(res); }),
      catchError(handleError)
    );
  }

  private extractData(res: Object) {
    let resObj = res;
    let body = resObj['results'];
    if (body) {
      return body.map(stat => {
        return new JobEventStatSerializer().fromJson(stat);
      });
    } else if (resObj) {
      return new JobEventStatSerializer().fromJson(resObj);
    }
  }
}
