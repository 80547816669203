"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var lodash_1 = require("lodash");
var shared_1 = require("../../../shared");
var DispatchScheduleTruckGridComponent = /** @class */ (function () {
    function DispatchScheduleTruckGridComponent() {
        this.loadingProgress = 0;
        this.displayKeys = ['truckDriverDisplayName', 'name', 'truckName'];
        this.truckCount = { available: 0, assigned: 0 };
        this.groupByOptions = ['Carrier', 'Truck Type', 'Assigned', 'Market', 'Duty Status', 'Condition'];
        this.activeGroupBy = 'Carrier';
        this.selectCarrier = new core_1.EventEmitter();
        this.searchCarriers = new core_1.EventEmitter();
        this.dropdownNextPage = new core_1.EventEmitter();
        this.truckTypeOptions = [
            { id: '0', name: 'All Truck Types' }
        ];
        this.selectedDriversValue = [];
        this.selectedDriversChange = new core_1.EventEmitter();
    }
    Object.defineProperty(DispatchScheduleTruckGridComponent.prototype, "selectedDrivers", {
        get: function () { return this.selectedDriversValue; },
        set: function (data) {
            this.selectedDriversValue = data;
            this.selectedDriversChange.emit(data);
        },
        enumerable: true,
        configurable: true
    });
    DispatchScheduleTruckGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.drivers && changes.drivers.currentValue) {
            this.setupItemList(changes.drivers.currentValue, this.activeGroupBy);
        }
    };
    /**
     * Uses a groupBy to generate a list of groups for the input driver list
     *
     * @param {DispatchDriver[]} drivers The input driver list
     * @param {'Carrier' | 'Truck Type' | 'Assigned' | 'Market'} groupBy The option to group the list of drivers by
     */
    DispatchScheduleTruckGridComponent.prototype.setupItemList = function (drivers, groupBy) {
        var _this = this;
        this.truckCount = {
            available: drivers.length,
            assigned: drivers.filter(function (driver) { return (driver.assignments.length > 0); }).length
        };
        this.activeGroupBy = groupBy;
        switch (groupBy) {
            case 'Carrier':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    id: d.carrier.id,
                    name: d.carrier.name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (driver.carrier.id === group.id); })
                })); });
                break;
            case 'Market':
                var marketGroups_1 = [];
                drivers.forEach(function (d) {
                    if (d.tags && d.tags.length) {
                        d.tags.forEach(function (tag) {
                            marketGroups_1.push({
                                id: tag.id,
                                name: tag.name,
                                groupBy: groupBy,
                                items: []
                            });
                        });
                    }
                    else {
                        marketGroups_1.push({
                            id: '',
                            groupBy: groupBy,
                            items: []
                        });
                    }
                });
                this.truckList = marketGroups_1.filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.id === '' ? (!driver.tags || driver.tags.length === 0) :
                        driver.tags.map(function (t) { return (t.id); }).join(' ').includes(group.id)); })
                })); });
                break;
            case 'Truck Type':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.truckType && d.truck.truckType.name,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return ((driver.truck && driver.truck.truckType && driver.truck.truckType.name) === group.name); })
                })); });
                break;
            case 'Assigned':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.assignments.length > 0 ? 'Assigned' : 'Unassigned',
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.name === group.name); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.name === 'Assigned' ? driver.assignments.length > 0 : driver.assignments.length === 0); })
                })); });
                break;
            case 'Duty Status':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.displayDutyStatus,
                    id: d.dutyStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return (Object.assign(group, {
                    items: drivers.filter(function (driver) { return driver.dutyStatus === group.id; })
                })); });
                break;
            case 'Condition':
                this.truckList = Array.from(new Set(drivers.map(function (d) { return ({
                    name: d.truck && d.truck.displayServiceStatus,
                    id: d.truck && d.truck.serviceStatus,
                    groupBy: groupBy,
                    items: []
                }); }))).filter(function (group, i, groups) { return i === groups.findIndex(function (g) { return (g.id === group.id); }); }).map(function (group) { return Object.assign(group, {
                    items: drivers.filter(function (driver) { return (group.id ? driver.truck && driver.truck.serviceStatus === group.id : !driver.truck); })
                }); });
                break;
        }
        this.truckList = this.truckList.sort(function (a, b) { return ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0); });
        this.filteredTruckList = lodash_1.cloneDeep(this.truckList);
        if (this.truckTypeOptions.length === 1) {
            this.truckList.forEach(function (group) {
                group.items.forEach(function (d) {
                    if (d.truck && d.truck.truckType && _this.truckTypeOptions.findIndex(function (t) { return (t.name === d.truck.truckType.name); }) === -1) {
                        _this.truckTypeOptions.push(d.truck.truckType);
                    }
                });
            });
        }
        if (this.selectedTruckType) {
            this.selectTruckType(this.selectedTruckType);
        }
    };
    DispatchScheduleTruckGridComponent.prototype.selectGroupBy = function (groupBy) {
        this.setupItemList(this.drivers, groupBy);
    };
    /**
     * Uses a selected truck type to filter down the current displayed truck list
     *
     * @param {TruckType} type The selected trucktype
     */
    DispatchScheduleTruckGridComponent.prototype.selectTruckType = function (type) {
        var _this = this;
        this.selectedDrivers = [];
        this.selectedTruckType = type;
        if (this.selectedTruckType.id === '0') {
            this.filteredTruckList = lodash_1.cloneDeep(this.truckList);
        }
        else {
            this.filteredTruckList = lodash_1.cloneDeep(this.truckList).map(function (group) {
                group.items = group.items.filter(function (d) { return (d.truck && d.truck.truckType && d.truck.truckType.name === _this.selectedTruckType.name); });
                return group;
            });
        }
    };
    /**
     * Creates a list of class names to append to each of the item elements based on specified driver states
     *
     * @param {DispatchDriver} driver The input driver
     * @return {string} The list of class names combined in a single string
     */
    DispatchScheduleTruckGridComponent.prototype.generateItemClassNames = function (driver) {
        var classNames = '';
        if (driver.dutyStatus === 'off-duty' ||
            (driver.truck && driver.truck.serviceStatus === 'out-of-service')) {
            classNames += 'red ';
        }
        else if (driver.assignments.length) {
            classNames += 'blue ';
        }
        return classNames;
    };
    return DispatchScheduleTruckGridComponent;
}());
exports.DispatchScheduleTruckGridComponent = DispatchScheduleTruckGridComponent;
