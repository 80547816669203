import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ruckit-confirm-dialog',
  templateUrl: './ruckit-confirm-dialog.component.html',
  styleUrls: ['./ruckit-confirm-dialog.component.scss']
})
export class RuckitConfirmDialogComponent {
  attributes: any = {};

  constructor(
    public confirmDialog: MatDialogRef<any>
  ) {
    this.attributes = {
      title: 'Confirm your action!',
      body: 'Are you sure you wish to do this?',
      close: 'Cancel',
      accept: 'Continue',
      isErrorBtn: false
    };
  }
}
