"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var moment = require("moment-timezone");
var material_1 = require("@angular/material");
var router_2 = require("@angular/router");
var lodash_1 = require("lodash");
// services
var driver_service_1 = require("../../drivers/driver.service");
var tag_service_1 = require("../../tags/tag.service");
var shift_service_1 = require("../../shifts/shift.service");
var authentication_service_1 = require("../../shared/authentication.service");
var connection_service_1 = require("../../connections/connection.service");
var api_service_1 = require("../../shared/api.service");
var preference_service_1 = require("../../preferences/preference.service");
var map_service_1 = require("../../map/map.service");
// components
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var filters_dialog_component_1 = require("../../shared/filters-dialog/filters-dialog.component");
var edit_driver_component_1 = require("../../drivers/driver-edit/edit-driver.component");
var new_driver_dialog_component_1 = require("../../drivers/new-driver-dialog/new-driver-dialog.component");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
// constants
var options_1 = require("../../drivers/driver-context-menu/data/options");
var columns_1 = require("./columns");
var FleetHealthComponent = /** @class */ (function () {
    function FleetHealthComponent(authenticationService, route, router, driverService, shiftReportService, connectionService, translationService, preferenceService, mapService, dialog) {
        var _this = this;
        this.authenticationService = authenticationService;
        this.route = route;
        this.router = router;
        this.driverService = driverService;
        this.shiftReportService = shiftReportService;
        this.connectionService = connectionService;
        this.translationService = translationService;
        this.preferenceService = preferenceService;
        this.mapService = mapService;
        this.dialog = dialog;
        this.loadingProgress = 0;
        this.firstLoad = true;
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.appliedFilters = [];
        this.search = '';
        this.customClasses = 'drivers';
        this.availableColumnsChange = new core_1.EventEmitter();
        this.displayedColumnsChange = new core_1.EventEmitter();
        this.searchChange = new core_1.EventEmitter();
        this.errors = [];
        // config for fancy table
        this.tableConfig = {
            hasHeader: true,
            service: driver_service_1.DriverService,
            preferenceKey: 'FleetHealthComponent-DriverService',
            query: { is_placeholder: 'False', user_tags: 'True' },
            collectionTitle: this.translationService.instant('Drivers'),
            noResultsText: this.translationService.instant('a driver'),
            sortBy: 'profile__first_name',
            sortDirection: 'asc',
            menuOptions: [
                { name: this.translationService.instant('Edit'), action: 'edit', link: false, external: false },
                { name: this.translationService.instant('View Driver Profile'), action: 'details', link: false, external: false }
            ],
            newRecordModal: function () { return _this.addNewDriver(); }
        };
        this.drivers = [];
        this.driver = {};
        this.driversForEdit = [];
        this.stateOptionsForModal = [];
        this.customerOnly = null;
        this.carrierId = '';
        this.sortAsc = true;
        this.drawerOpen = false;
        this.enabledFeatures = [];
        this.inviteDriverEnabled = false;
        this.changeSearchEmitter = new core_1.EventEmitter();
        this.carrierDropdownData = {
            carriers: [],
            config: {
                nameProperty: 'name',
                searchable: true,
                loadingOptions: false
            },
            loading: false
        };
        this.primaryFilters = [];
        this.issuesSelected = false;
        this.onShiftSelected = false;
        this.allSubscriptionsToUnsubscribe = [];
        // context menu
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.viewDriverProfileAction = options_1.ViewDriverProfileAction;
        this.saveDriverCallback = function () {
            _this.refreshTable();
        };
        this.inviteDriverCallback = function () {
            _this.refreshTable();
        };
    }
    FleetHealthComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.enabledFeatures = this.authenticationService.enabledFeatures();
        if (this.enabledFeatures && this.enabledFeatures.includes('inviteDriver')) {
            this.inviteDriverEnabled = true;
        }
        this.tableConfig['query'] = this.query = __assign({}, this.tableConfig['query'], this.query);
        this.allSubscriptionsToUnsubscribe.push(this.driverService.listAllProgress.subscribe(function (progress) {
            _this.loadingProgress = Math.ceil(progress * 100);
        }));
        this.route.queryParams.forEach(function (params) {
            _this.search = params['search'] || '';
            _this.tableConfig['sortBy'] = 'profile__first_name';
        });
        this.getPreferencesAndCarriers();
    };
    FleetHealthComponent.prototype.ngAfterViewInit = function () {
        this.tableConfig['customHeight'] = this.customHeight;
    };
    FleetHealthComponent.prototype.ngOnChanges = function (changes) {
        if (this.driverTable && changes.query && changes.query.currentValue && Object.keys(changes.query.currentValue).length) {
            this.driverTable.query = this.query;
            this.refreshTable();
        }
    };
    FleetHealthComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    FleetHealthComponent.prototype.clickAction = function (event) {
        if (event) {
            this.selectDriver(event, event[1]);
        }
    };
    /**
     * @param  {} e
     * @param  {} driver
     * This function would open a edit window for updating the driver
     */
    FleetHealthComponent.prototype.selectDriver = function (e, driver) {
        var target = e.target || e.srcElement || e.currentTarget;
        if (target && target.className && target.className.includes('action-menu-icon') ||
            target && target.type === 'checkbox') {
            // Do nothing
        }
        else {
            this.driver = lodash_1.cloneDeep(driver);
            if (this.editDrawer) {
                this.editDrawer.setOpen();
            }
        }
    };
    /**
     * @returns void
     * This function is called when edit is successfully completed
     * This would update the table with the changed fields
     */
    FleetHealthComponent.prototype.onEditComplete = function () {
        this.refreshTable();
    };
    FleetHealthComponent.prototype.unarchive = function (driver) {
        var _this = this;
        this.driverService.save({ id: driver.id, status: 'active' }).subscribe(function () {
            _this.refreshTable();
        });
    };
    FleetHealthComponent.prototype.menuAction = function (name, driver) {
        var _this = this;
        switch (name) {
            case 'edit':
                setTimeout(function () {
                    _this.selectDriver(name, driver);
                }, 100);
                break;
            case 'details':
                if (driver && driver.id) {
                    this.router.navigate(['drivers', driver.id, 'details', 'details'], {
                        queryParams: { returnTo: this.router.url },
                    });
                }
                break;
            case 'unarchive':
                this.unarchive(driver);
                break;
        }
    };
    FleetHealthComponent.prototype.isSelected = function (driver) {
        return this.driver && driver.id === this.driver.id;
    };
    FleetHealthComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px'
        });
        dialog.componentInstance.filters = [
            {
                type: 'dropdown',
                field: 'tags',
                label: 'Markets',
                dropdownConfig: {
                    service: tag_service_1.TagService,
                    multiselect: true
                }
            },
            {
                type: 'text',
                field: 'truckName',
                label: 'Current Truck Search'
            },
            {
                type: 'text',
                field: 'cdl',
                label: 'CDL Search'
            }
        ];
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        this.filtersDialog = dialog.componentInstance;
    };
    FleetHealthComponent.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var queryKeys = {
            tags: 'tags',
            truckName: 'truck__name',
            cdl: 'cdl'
        };
        var falseyFilters = [];
        var newFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
            if (filterRes[key]) {
                if (key === 'tags') {
                    if (values && values.length > 0) {
                        values = values.map(function (tag) { return tag.name; }).join(',');
                        _this.filterByTags(values);
                        query[queryKeys[key]] = values;
                    }
                }
                else {
                    query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
                }
            }
            var filter = new filter_option_1.FilterOption({
                filterType: 'text',
                key: key,
                title: key.charAt(0).toUpperCase() + key.slice(1),
                displayValues: displayValues || null,
                values: values,
                query: query
            });
            if (!filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        var modifiedFilters = newFilters.filter(function (f) { return f.key !== 'tags'; });
        this.appliedFilters = lodash_1.difference(modifiedFilters, falseyFilters);
    };
    FleetHealthComponent.prototype.filterByEvent = function (key) {
        switch (key) {
            case 'on_shift':
                this.filterByShifts();
                break;
            case 'issues':
                this.filterByIssues();
                break;
            default:
                break;
        }
    };
    FleetHealthComponent.prototype.refreshTable = function () {
        this.driverTable.getRecords(__assign({}, this.tableConfig['query'], this.query));
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *  Gets shift data for each driver if displayedColumns contains 'shift-status'
     *  Gets location update data for each driver if displayedColumns contains 'last-location-update'
     *
     * @param {} columns List of columns to display (in order)
     */
    FleetHealthComponent.prototype.columnsChanged = function (columns) {
        if (columns.includes('shift-status') && !this.driverTable.displayedColumns.slice().includes('shift-status')) {
            this.captureShiftStatus(this.tableData);
        }
        if (columns.includes('last-location-update') && !this.driverTable.displayedColumns.slice().includes('last-location-update')) {
            this.getLocationUpdates(this.tableData);
        }
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = this.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    FleetHealthComponent.prototype.onRecordsLoaded = function (tableData) {
        if (tableData === void 0) { tableData = this.tableData; }
        this.tableData = tableData;
        this.driverTable.loading = false;
        this.driverList = tableData.data;
        var columns = this.driverTable.displayedColumns.slice();
        if (columns.includes('shift-status')) {
            this.captureShiftStatus(this.tableData);
        }
        if (columns.includes('last-location-update')) {
            this.getLocationUpdates(this.tableData);
        }
        if (this.firstLoad && this.columnToggle) {
            this.firstLoad = false;
            this.columnToggle.displayedColumns = this.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    FleetHealthComponent.prototype.captureShiftStatus = function (tableData) {
        var _this = this;
        tableData.data.forEach(function (driver) {
            driver.loading = true;
            if (driver.activeShifts && driver.activeShifts.length > 0) {
                if (driver.activeShifts.length > 1) {
                    // if multiple shifts, take the longest active one
                    var longestDate_1 = moment.min(driver.activeShifts.map(function (s) { return moment(s.startTime); }));
                    var longestShift = driver.activeShifts.find(function (d) {
                        return moment(d.startTime).toISOString() === longestDate_1.toISOString();
                    });
                    _this.displayShiftStatus(driver, longestShift.id, longestShift.startTime, longestShift.endTime);
                }
                else {
                    var activeShift = driver.activeShifts[0];
                    _this.displayShiftStatus(driver, activeShift.id, activeShift.startTime, activeShift.endTime);
                }
                driver.loading = false;
            }
            else {
                // if no active shifts in driver.activeShifts array - fetch API
                _this.shiftsReq = _this.shiftReportService
                    .list({
                    driver: driver.id,
                    page_size: 1,
                    ordering: '-end_time',
                })
                    .subscribe(function (shifts) {
                    if (shifts && shifts.length) {
                        var shift = shifts[0];
                        _this.displayShiftStatus(driver, shift.id, shift.startTime, shift.endTime);
                    }
                    else {
                        driver.displayShiftStatus =
                            _this.translationService.instant('No Shifts');
                    }
                }, function (err) {
                    console.log(err);
                }, function () { return (driver.loading = false); });
            }
        });
    };
    FleetHealthComponent.prototype.displayShiftStatus = function (driver, shiftId, startTime, endTime) {
        driver.lastShiftId = shiftId;
        var totalHours = moment
            .duration(moment(endTime ? endTime : undefined).diff(startTime))
            .asHours();
        if (!endTime) {
            if (totalHours > 8) {
                driver.displayShiftStatus = this.translationService.instant('HAS ACTIVE SHIFT of {{ hours }} Hours', { hours: totalHours.toFixed(2) });
            }
            else {
                driver.displayShiftStatus =
                    this.translationService.instant('HAS ACTIVE SHIFT');
            }
        }
        else if (totalHours > 8) {
            driver.displayShiftStatus = this.translationService.instant('{{ hours }} Hours', { hours: totalHours.toFixed(2) });
        }
        else {
            driver.displayShiftStatus = this.translationService.instant('Ended {{ endTime }}', { endTime: moment(endTime).format('MM/DD/YYYY h:mm a') });
        }
    };
    FleetHealthComponent.prototype.getLocationUpdates = function (tableData) {
        var _this = this;
        tableData.data.forEach(function (driver) {
            driver.lastLocationUpdateLoading = true;
            _this.mapService
                .getLocationUpdates({
                driver: driver.id,
                ordering: 'date',
            })
                .subscribe(function (updates) {
                if (updates.locationUpdates && updates.locationUpdates.length) {
                    driver.lastLocationUpdate = updates.locationUpdates[0];
                }
            }, function () { }, // error
            function () {
                // finally
                driver.lastLocationUpdateLoading = false;
            });
        });
    };
    FleetHealthComponent.prototype.getCarriers = function (query) {
        if (query === void 0) { query = {}; }
        return this.connectionService
            .list(__assign({ ordering: 'organization__name', is_carrier: 'True', allow_dispatch: 'True' }, query))
            .pipe(operators_1.map(function (connections) {
            var defaultCarriers = [
                { name: 'All Carriers', id: 'all_carriers' },
                { name: 'My Drivers', id: 'my_drivers' },
                { name: 'Leased', id: 'all_leased' }
            ];
            var carriers = connections.map(function (connection) {
                return ({
                    name: connection.organization.name,
                    id: connection.organization.carrier.id,
                });
            });
            return defaultCarriers.concat(carriers);
        }));
    };
    FleetHealthComponent.prototype.dropdownSearch = function (term) {
        var _this = this;
        this.getCarriers({ search: term }).subscribe(function (carriers) {
            _this.carrierDropdownData.carriers = carriers;
        });
    };
    FleetHealthComponent.prototype.carrierFilterTypeGetValue = function (value) {
        switch (value) {
            case 'my_drivers':
                return null;
            case 'all_carriers':
                return 'True';
            case 'all_leased':
                return 'True';
            default:
                return value;
        }
    };
    FleetHealthComponent.prototype.dropdownNextPage = function () {
        var _this = this;
        var config, service, options;
        config = this.carrierDropdownData.config;
        service = this.connectionService;
        options = this.carrierDropdownData.carriers;
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    var _carriers = results.map(function (connection) {
                        return {
                            name: connection.organization.name,
                            id: connection.organization.carrier.id
                        };
                    });
                    _this.carrierDropdownData.carriers = _this.carrierDropdownData.carriers.concat(_carriers);
                }, function (err) { return _this.errors = api_service_1.parseErrors(err); }, function () { return config.loadingOptions = false; });
            }
        }
    };
    FleetHealthComponent.prototype.filterByIssues = function () {
        this.saveFilterPreference({ key: 'issues', value: 'True' });
    };
    FleetHealthComponent.prototype.filterByShifts = function () {
        this.saveFilterPreference({ name: 'On Shift', key: 'on_shift', value: 'True' });
    };
    FleetHealthComponent.prototype.filterByTags = function (value) {
        this.saveFilterPreference({ name: 'Tags', key: 'tags', value: value });
    };
    FleetHealthComponent.prototype.saveFilterPreference = function (filter) {
        var _this = this;
        this.driverTable.loading = true;
        var preference = __assign({}, this.preference);
        var existingFilters = preference.blob.filters && preference.blob.filters.length
            ? preference.blob.filters
            : [];
        var filters = existingFilters.some(function (f) { return f.key === filter.key; })
            ? this.updateFilters(filter, existingFilters)
            : existingFilters.concat([filter]);
        this.preferenceService
            .save(__assign({}, this.preference, { blob: { filters: filters } }))
            .subscribe(function (updatedPreference) {
            _this.preference = updatedPreference;
            _this.savePreferenceFiltersToQuery(updatedPreference);
        });
    };
    FleetHealthComponent.prototype.updateFilters = function (filter, existingFilters) {
        switch (filter.key) {
            case 'carrier':
                return existingFilters.map(function (f) { return (f.key === 'carrier' ? filter : f); });
            case 'tags':
                var tagFilter = existingFilters.find(function (f) { return f.key === 'tags'; });
                if (tagFilter && tagFilter.value) {
                    if (tagFilter.value === filter.value) {
                        return existingFilters.filter(function (f) { return f.key !== 'tags'; });
                    }
                    else {
                        return existingFilters.map(function (f) { return (f.key === 'tags' ? filter : f); });
                    }
                }
                else {
                    return existingFilters.concat([filter]);
                }
            default:
                return existingFilters.filter(function (f) { return f.key !== filter.key; });
        }
    };
    FleetHealthComponent.prototype.getPreferencesAndCarriers = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        var preference = __assign({}, this.preference, { name: 'FleetHealthComponent-DriverTable', type: 'user', profile: currentUser.id, page_size: 1 });
        var carriers$ = this.getCarriers({});
        var preferences$ = this.preferenceService.list(preference);
        rxjs_1.forkJoin([carriers$, preferences$]).subscribe(function (_a) {
            var carriers = _a[0], preferences = _a[1];
            var allCarriers = _this.carrierDropdownData.carriers.concat(carriers);
            var newPreference = preferences && preferences.length
                ? preferences[0]
                : __assign({}, preference, { blob: { filters: [{ key: 'carrier', value: 'all_carriers' }] } });
            var preferenceCarrier = newPreference.blob.filters.find(function (f) { return f.key === 'carrier'; });
            var selectedCarrier = allCarriers.find(function (c) { return c.id === preferenceCarrier.value; });
            _this.carrierDropdownData.carriers = allCarriers;
            _this.carrierDropdownData.carrier = selectedCarrier
                ? selectedCarrier
                : carriers.find(function (c) { return c.id === 'all_carriers'; });
            _this.preference = newPreference;
            _this.savePreferenceFiltersToQuery(_this.preference);
        });
    };
    FleetHealthComponent.prototype.savePreferenceFiltersToQuery = function (preference) {
        var _this = this;
        var filters = preference.blob.filters;
        this.issuesSelected = filters.some(function (f) { return f.key === 'issues'; });
        this.onShiftSelected = filters.some(function (f) { return f.key === 'on_shift'; });
        var query = __assign({}, this.query, { issues: null, on_shift: null, all_carriers: null, all_leased: null, carrier: null, tags: null });
        var primaryFilters = [];
        filters.forEach(function (filter) {
            if (filter.key === 'carrier') {
                if (filter.value !== 'my_drivers') {
                    var carrierFilterType = _this.carrierFilterTypeGetValue(filter.value);
                    query[filter.value === carrierFilterType ? filter.key : filter.value] = carrierFilterType;
                }
            }
            else {
                query[filter.key] = filter.value;
                primaryFilters.push(filter);
            }
        });
        this.tableConfig['query'] = this.query = __assign({}, this.tableConfig['query'], query);
        this.primaryFilters = primaryFilters;
        // reset pagination to first page
        this.driverTable.resetPageNumber();
    };
    FleetHealthComponent.prototype.deleteAllPrimaryFilters = function () {
        var _this = this;
        this.primaryFilters = [];
        var existingFilters = this.preference.blob.filters;
        if (existingFilters &&
            existingFilters.length &&
            existingFilters.some(function (f) { return f.key !== 'carrier'; })) {
            var filters = existingFilters.filter(function (f) { return f.key === 'carrier'; });
            this.preferenceService
                .save(__assign({}, this.preference, { blob: { filters: filters } }))
                .subscribe(function (preference) {
                _this.preference = preference;
                _this.savePreferenceFiltersToQuery(preference);
            });
        }
    };
    FleetHealthComponent.prototype.addNewDriver = function () {
        var dialog = this.dialog.open(new_driver_dialog_component_1.NewDriverDialogComponent, {
            width: '444px'
        });
        dialog.componentInstance.leasedMode = true;
        dialog.componentInstance.callback = this.saveDriverCallback;
    };
    FleetHealthComponent.prototype.updatedMarkets = function () {
        this.refreshTable();
        this.getPreferencesAndCarriers();
    };
    FleetHealthComponent.prototype.onShiftEnd = function () {
        // refresh everything
        this.appliedFilters = this.appliedFilters.slice();
    };
    FleetHealthComponent.prototype.openContextMenu = function (event, driverId) {
        this.contextMenuEventSubject.next({
            event: event,
            driverId: driverId
        });
    };
    return FleetHealthComponent;
}());
exports.FleetHealthComponent = FleetHealthComponent;
