import { Component, OnInit, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { pull, remove, difference, get, find as _find, omit as _omit } from 'lodash';
import * as moment from 'moment';

import { TripService } from  '../trips/trip.service';
import { ExpenseService } from  './expense.service';
import { DriverService } from  '../drivers/driver.service';
import { TruckService } from  '../trucks/truck.service';
import { TripFiltersDialogComponent } from '../trips/trip-filters-dialog.component';
import { ViewSignaturesDialogComponent } from '../checkins/view-signatures-dialog.component';
import { TRIPSIGNATUREIMAGETYPE } from '../app.constants';
import { ViewTicketsDialogComponent } from '../checkins/view-tickets-dialog.component';

@Component({
  selector: 'ruckit-expense-trips-dialog',
  templateUrl: './expense-trips-dialog.component.html',
  styleUrls: ['./expense-trips-dialog.component.scss'],
  providers: [
    TripService, ExpenseService, DriverService, TruckService
  ]
})
export class ExpenseTripsDialogComponent implements OnInit {
  expense;
  count;
  selectedCount = 0;
  trips: any = [];
  allSelected = false;
  selectedTrips = [];
  excludeTrips = [];
  tripFilters;
  loading = true;
  errors = [];
  routeToExpense = true;
  expenseReq;
  tripReq;
  search = '';
  sortBy = '';
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  startDate = null;
  endDate = null;
  callback;
  multipleActionDropdownOptions = [];
  menuOptions = [
    {name: 'Add to Expense', action: 'add', link: false}
  ];
  signatureImageType = TRIPSIGNATUREIMAGETYPE;

  viewTicketsCallback = () => {
    // Update Ticket Status Icon
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tripService: TripService,
    private expenseService: ExpenseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ExpenseTripsDialogComponent>
  ) { }

  ngOnInit() {
    this.getTrips();
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.tripService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(
          trips => {
            this.trips = this.trips.concat(trips);
            this.loading = false;
          },
          err => this.errors = err,
          () => {
            this.loading = false;
          }
        );
      }
    }
  }

  getTrips(query = {}, append = false) {
    if (!append) {
      this.trips = [];
    }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => {
      return {...acc, ...filter.query};
    }, {});

    if (this.tripReq) { this.tripReq.unsubscribe(); }
    if (this.startDate) {
      let date = new Date(this.startDate);
      query['start_time__gte'] = date.toISOString();
    }
    if (this.endDate) {
      let date = new Date(this.endDate);
      date.setHours(23, 59, 59, 999);
      query['start_time__lte'] = date.toISOString();
    }

    this.tripFilters = {
      ordering: order,
      search: this.search,
      unexpensed: 'True',
      completed: 'True',
      expenseable: 'True',
      exclude_cf: 'True',
      ...filters,
      ...query
    };

    this.tripReq = this.tripService.getCondensedTrips(this.tripFilters).subscribe(
      trips => {
        if (append) {
          this.trips = this.trips.concat(trips);
        } else {
          this.trips = trips;
        }
        this.loading = false;
        this.count = this.tripService.count;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getTrips({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getTrips({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openTickets(trip) {
    this. tripService.getTrip(trip.id).subscribe(
      _trip => {
        const dialog = this.dialog.open(ViewTicketsDialogComponent, {
          width: '722px',
          data: { trip: _trip }
        });
        dialog.componentInstance.callback = this.viewTicketsCallback;
      },
      err => console.error(err)
    );
  }

  openSignatures(trip) {
    this.tripService.getTrip(trip.id).subscribe(
      _trip => {
        const dialog = this.dialog.open(ViewSignaturesDialogComponent, {
          width: '722px',
          data: {
            trip: _trip,
            callback: () => this.getTrips()
           }
        });
      },
      err => console.error(err)
    );
  }

  changeSearch(term?: string) {
    this.search = term || '';
    this.getTrips();
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters() {
    let model = {
      job: get(_find(this.filters, { key: 'job' }), 'value'),
      project: get(_find(this.filters, { key: 'project' }), 'value'),
      customer: get(_find(this.filters, { key: 'customer' }), 'value'),
      driver: get(_find(this.filters, { key: 'driver' }), 'value'),
      truck: get(_find(this.filters, { key: 'truck' }), 'value'),
      payableTo: get(_find(this.filters, { key: 'payableTo' }), 'value'),
      startDate: null,
      endDate: null
    };
    let startDate = get(_find(this.filters, { key: 'startDate' }), 'value');
    if (startDate) {
      model.startDate = <Date>startDate;
    }
    let endDate = get(_find(this.filters, { key: 'endDate' }), 'value');
    if (endDate) {
      model.endDate = <Date>endDate;
    }

    model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});

    const dialog = this.dialog.open(TripFiltersDialogComponent, {
      width: '430px',
      data: { model: model }
    });
    this.filtersDialog = dialog.componentInstance;
    dialog.componentInstance.callback = res => this.filterChanges(res);
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      payableTo: 'jobevent__owner_organization',
      job: 'jobevent__job',
      jobNumber: 'jobevent__job__job_number',
      driver: 'driver',
      truck: 'truck',
      startDate: 'start_time__gte',
      endDate: 'start_time__lte',
      edited: 'edited',
      incomplete: 'completed',
      retake: 'retake_status'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
          let filterValue = value.toString();
          filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
          query[queryKeys[key]] = filterValue;
        } else if (key === 'retake' && value) {
          value = 'requested';
          query[queryKeys[key]] = value;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = {
        key: key,
        value: displayValue || value,
        query: query
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });

    this.filters = difference(this.filters, falseyFilters);
    this.getTrips();
  }

  removeFilter(filter) {
    remove(this.filters, filter);
    this.getTrips();
  }

  menuAction(name, trip) {
    switch (name) {
      case 'add':
        this.addToExpense([trip.id]);
        break;
    }
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  selector(event, trip = null) {
    if (trip) {
      if (!event.target.checked) {
        trip.selected = false;
        pull(this.selectedTrips, trip.id);
        if (this.allSelected) {
          this.excludeTrips.push(trip.id);
          this.selectedCount = (this.count - this.excludeTrips.length);
        } else {
          this.selectedCount = this.selectedTrips.length;
        }
      } else {
        trip.selected = true;
        if (this.allSelected) {
          pull(this.excludeTrips, trip.id);
          this.selectedCount = (this.count - this.excludeTrips.length);
        } else {
          this.selectedTrips.push(trip.id);
          this.selectedCount = this.selectedTrips.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.trips.forEach((_trip) => { _trip.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludeTrips.length);
      }
      this.selectedTrips = [];
      this.excludeTrips = [];
    }
  }

  addToExpense(trips = null) {
    let model = {};
    if (this.expense && this.expense.id) {
      Object.assign(model, {id: this.expense.id});
      if (trips || this.selectedTrips.length) {
        Object.assign(model, {trips: trips || this.selectedTrips});
      } else if (this.allSelected) {
        let params = new HttpParams();
        let filters = _omit(this.tripFilters, ['ordering', 'expense']);
        Object.keys(filters).map(key => {
          if (filters[key].length) {
            params = params.set(key, filters[key]);
          }
        });
        if (params.toString().length) {
          Object.assign(model, {
            filters: params.toString(), excludeTrips: this.excludeTrips
          });
        }
      }

      this.expenseReq = this.expenseService.addToExpense(model).subscribe((res) => {
        this.expense = res;
        this.callback();
        this.dialogRef.close();
      }, err => console.error(err));
    } else {
      this.saveExpense();
    }
  }

  saveExpense() {
    let model = {
      customerOrganization: this.expense.customerOrganization.id,
      trips: this.selectedTrips
    };

    this.expenseReq = this.expenseService.save(model).subscribe((res) => {
      this.expense = res;
      this.dialogRef.close();
      if (this.routeToExpense) {
        this.router.navigate(['/expenses/' + this.expense.id + '/edit']);
      }
    }, err => console.error(err));
  }

  onDateChanged(values: Date[], type: string): void {
    if (values && values.length) {
      switch (type) {
        case 'startDate':
          this.startDate = values[0];
          break;
        case 'endDate':
          this.endDate = values[0];
          break;
      }
    }
    this.getTrips();
  }
}
