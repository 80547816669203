import {
  Component, OnInit, Input, OnDestroy, ViewChildren, QueryList
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, find as _find, pickBy } from 'lodash';

import { CollaboratorService } from '../collaborator.service';
import { JobEvent } from '../../job-events/job-event';
import { parseErrors } from '../../shared/api.service';
import { DropdownComponent } from '../../shared';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { JobEventShareService } from '../../job-event-shares/job-event-share.service';
import { CollaborationTemplate } from '../collaboration-template';
import { JOBWEIGHTOPTIONS } from '../../app.constants';
import { UnitsOfMeasureService } from '../../units/units-of-measure.service';
import { JobLoad } from '../../dispatch/dispatch-by-job/job-load';
import { collaboratorsAnimation } from '../../shared/animations/collaborators.animation';

@Component({
  selector: 'edit-collaborators-dialog',
  templateUrl: './edit-collaborators-dialog.component.html',
  styleUrls: ['./edit-collaborators-dialog.component.scss'],
  animations: [collaboratorsAnimation]
})
export class EditCollaboratorsDialogComponent implements OnInit, OnDestroy {
  @Input() jobEvent: JobEvent;
  @Input() allSelected = false;
  @Input() selectedShareIds: string[] = [];
  @Input() excludedShareIds: string[] = [];
  @Input() brokerRateKey: string;
  @Input() hasLoadListsEnabled: boolean = false;
  @Input() loadList: JobLoad[] = [];
  collaborationTemplate: CollaborationTemplate = {
    jobEventsLoading: false,
    jobEventOptions: [],
    jobevents: [],
    numTrucks: null,
    invoiceWeightUnit: null,
    invoiceType: null,
    invoiceRate: null,
    notes: '',
    cancelMissing: false,
    requestedAmount: null,
    requestedUnit: null,
  };
  loading = false;
  errors = [];
  callback: Function;
  jobEventShares: JobEventShare[] = [];
  jobEventSharesReq: Subscription;
  rateTrackingOptions = [
    { value: 'load', label: 'Load', name: 'Load' },
    { value: 'hour', label: 'Hour', name: 'Hour' }
  ];
  rateTrackingConfig = {
    nameProperty: 'name',
    loadingOptions: false
  };
  weightOptions = [...JOBWEIGHTOPTIONS];
  displayedColumns = ['loadNumber', 'loadTime', 'truck', 'driver'];
  weightOption;
  haulTypeOption;

  @ViewChildren('jobEventsDropdown') jobEventDropdowns: QueryList<DropdownComponent>;
  unitsOfMeasure: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditCollaboratorsDialogComponent>,
    private collaboratorService: CollaboratorService,
    private jobEventShareService: JobEventShareService,
    private unitsOfMeasureService: UnitsOfMeasureService,
  ) { }

  ngOnInit() {
    this.getJobEventShares();
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });

    if (this.jobEvent.job.allowWeight) {
      this.rateTrackingOptions = this.rateTrackingOptions.concat([
        { value: 'weight', label: 'Weight', name: 'Weight' }
      ]);
    }
    this.weightOption = _find(this.weightOptions, { value: this.jobEvent.haulWeightUnit });
    this.haulTypeOption = _find(this.rateTrackingOptions, { value: this.jobEvent.haulType });
    this.getUnitsOfMeasure();
  }

  ngOnDestroy() {
    if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
      this.jobEventSharesReq.unsubscribe();
    }
  }

  submit(): void {
    this.loading = true;
    const collaborations = this.jobEventShares.map(jobEventShare => {
      let collaboration = jobEventShare.collaboration;
      if (!collaboration.customFieldData) { collaboration.customFieldData = {}; }
      collaboration.customFieldData[this.brokerRateKey] = collaboration.brokerRateCode;
      if (collaboration.requestedUnit === null || collaboration.requestedUnit.name === 'Trucks') {
        collaboration.numTrucks = collaboration.requestedAmount;
      } else {
        collaboration.numTrucks = null;
      }
      collaboration.requestedUnit = collaboration.requestedUnit.value;
      collaboration.loadSchedule = jobEventShare.loadSchedule;
      return collaboration;
    });
    if (this.jobEvent && this.jobEvent.job) {
      this.collaboratorService.bulkSave(this.jobEvent.job.id, collaborations).subscribe(res => {
        this.dialogRef.close();
        this.callback();
      }, (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      });
    }
  }

  getJobEventShares(query = {}): void {
    if (this.jobEventSharesReq && typeof this.jobEventSharesReq.unsubscribe === 'function') {
      this.jobEventSharesReq.unsubscribe();
    }
    this.loading = true;
    this.jobEventSharesReq = this.jobEventShareService.list({
      ordering: 'created_at',
      jobevent: this.jobEvent && this.jobEvent.id,
      id__in: this.selectedShareIds,
      ...query
    }).subscribe(jobEventShares => {
      this.jobEventShares = filter(jobEventShares, (jobEventShare) => {
        if (
          (this.allSelected || this.selectedShareIds.includes(jobEventShare.id)) &&
          !this.excludedShareIds.includes(jobEventShare.id)
        ) {
          return jobEventShare;
        }
      });
      this.jobEventShares.forEach(jobEventShare => {
        jobEventShare.brokerRateCodeKey = this.brokerRateKey;
        jobEventShare.collaboration.jobevents = [this.jobEvent.id];
      });
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  setSelectedAction(share: JobEventShare, option, form: NgForm): void {
    share.collaboration.invoiceType = option.value;
    if (option.value === 'weight') {
      share.collaboration.invoiceWeightUnit = 'ton';
    }
    form.controls['share_' + share.id + '_haul_rate'].markAsDirty();
  }

  setSelectedWeightUnit(share: JobEventShare, option, form: NgForm): void {
    share.collaboration.invoiceWeightUnit = option.value;
    form.controls['share_' + share.id + '_haul_rate'].markAsDirty();
  }

  setSelectedTemplateAction(template: CollaborationTemplate, option): void {
    template.invoiceType = option.value;
    if (option.value === 'weight') {
      template.invoiceWeightUnit = 'ton';
    } else {
      template.invoiceWeightUnit = template.invoiceType;
    }
  }

  setSelectedWeightUnitTemplateAction(template: CollaborationTemplate, option): void {
    template.invoiceWeightUnit = option.value;
  }

  applyTemplate(form: NgForm): void {
    const template = pickBy(this.collaborationTemplate, v => v !== null && v !== undefined && v !== '' && v['length'] !== 0);
    this.jobEventShares.forEach(connection => {
      if (connection.readonly) {
        if (template.numTrucks.length) {
          connection.collaboration.numTrucks = template.numTrucks;
        }
      } else {
        connection.collaboration = {
          ...connection.collaboration,
          ...template
        };
      }
    });
    form.form.markAsDirty();
  }

  loadDropdownSelectionChanged(event: any, load: JobLoad, share: JobEventShare, form: NgForm): void {
    const loadSchedule: JobLoad[] = share.loadSchedule;
    const existingLoad = loadSchedule.findIndex(l => l.loadNumber === load.loadNumber);
    if (event.checked) {
      if (existingLoad === -1) {
        loadSchedule.push(load);
      }
    } else {
      if (existingLoad > -1) {
        loadSchedule.splice(existingLoad, 1);
      }
    }
    loadSchedule.sort((a, b) => a.loadNumber - b.loadNumber);
    form.form.markAsDirty();
  }

  getUnitsOfMeasure() {
    this.unitsOfMeasureService.list().subscribe((units) => {
      this.unitsOfMeasure = units.map(unit => ({
        value: unit.id,
        label: unit.name,
        name: unit.name,
        selected: false,
      }));
    });
  }
}
