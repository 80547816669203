"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var lodash_2 = require("lodash");
var moment = require("moment-timezone");
var truck_type_service_1 = require("../trucks/truck-type.service");
var job_event_service_1 = require("../job-events/job-event.service");
var shared_1 = require("../shared");
var EditJobEventDialogComponent = /** @class */ (function () {
    function EditJobEventDialogComponent(dialogRef, jobEventService, authenticationService) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.jobEventService = jobEventService;
        this.authenticationService = authenticationService;
        this.loading = false;
        this.jobEvent = {};
        this.anyTruckType = true;
        this.errors = [];
        this.truckTypes = [];
        this.allSelectedTrucks = [];
        this.selectedTrucks = [];
        this.anyTypeSelected = false;
        this.truckTypeDropdownConfig = {
            searchable: true,
            showLoading: true,
            multiselect: true,
            nameProperty: 'name',
            optionIcon: true,
            iconProperty: 'icon',
            sortBy: '-is_favorite',
            selectText: 'Select Truck Type',
            loadingText: 'Loading Truck Types...',
            noResultsText: 'No Truck Types',
            service: truck_type_service_1.TruckTypeService,
            serviceFunction: 'list',
            query: {}
        };
        this.dailyDeliveryTargetTypeOptions = [
            { id: 'tons', name: 'Tons' },
            { id: 'metric-tons', name: 'Metric Tons' },
            { id: 'tonnes', name: 'Tonnes' },
            { id: 'loads', name: 'Loads' },
            { id: 'lbs', name: 'Pounds' },
            { id: 'kgs', name: 'Kilograms' },
            { id: 'cuyds', name: 'Cubic Yards' },
            { id: 'bushels', name: 'Bushels' },
            { id: 'bags', name: 'Bags' }
        ];
        this.formatTimeFromTimestamp = function (timestampType) { return moment(_this.jobEvent[timestampType]).format('HH:mm'); };
    }
    EditJobEventDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authenticationService.user();
        if (!this.jobEvent.defaultYardBufferTime || !this.jobEvent.defaultYardBufferMinutes) {
            this.loadYardTimeDefaults();
        }
        this.anyTruckType = this.jobEvent.allowAnyTruckType;
        if (!this.anyTruckType) {
            lodash_2.each(this.jobEvent.truckTypes, function (truck) {
                _this.selectedTrucks.push(truck);
            });
        }
        this.selectedDailyDeliveryTargetType = lodash_1.find(this.dailyDeliveryTargetTypeOptions, { id: this.jobEvent.dailyDeliveryTargetType });
        // dev note: refactor this method so its only called one time
        this.checkIfOvernight('shift1');
        this.checkIfOvernight('shift2');
    };
    EditJobEventDialogComponent.prototype.anyTypeTruckSelected = function (filterType, event, form) {
        var _this = this;
        form.controls[filterType].markAsDirty();
        this.anyTypeSelected = event.target.checked;
        this.allSelectedTrucks = [];
        if (this.anyTypeSelected) {
            this.anyTruckType = true;
            this.truckTypes.forEach(function (truck) {
                _this.allSelectedTrucks.push(truck.id);
            });
        }
        else {
            this.anyTruckType = false;
        }
    };
    EditJobEventDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        this.jobEvent.allowAnyTruckType = this.anyTruckType;
        if (this.anyTruckType && this.allSelectedTrucks.length > 0) {
            this.jobEvent.truckTypes = this.allSelectedTrucks;
        }
        else if (this.selectedTrucks.length > 0) {
            this.jobEvent.truckTypes = this.selectedTrucks.map(function (truckType) { return truckType.id; });
        }
        // removing ownerOrganization property
        var _a = this.jobEvent, ownerOrganization = _a.ownerOrganization, jobEvent = __rest(_a, ["ownerOrganization"]);
        this.jobEventService.save(jobEvent).subscribe(function (_jobEvent) {
            _this.loading = false;
            _this.callback({ jobEvent: _jobEvent });
            _this.dialogRef.close();
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    EditJobEventDialogComponent.prototype.checkIfOvernight = function (shift) {
        if (shift === 'shift1' && this.jobEvent.shift1StartTimestamp && this.jobEvent.shift1EndTimestamp) {
            var startTime = Number(moment(this.jobEvent.shift1StartTimestamp).format('YYYYMMDD'));
            var endTime = Number(moment(this.jobEvent.shift1EndTimestamp).format('YYYYMMDD'));
            this.jobEvent.shift1Overnight = (endTime - startTime) > 0;
            if (!this.shiftOneStartTime) {
                this.shiftOneStartTime = this.formatTimeFromTimestamp('shift1StartTimestamp');
            }
            if (!this.shiftOneEndTime) {
                this.shiftOneEndTime = this.formatTimeFromTimestamp('shift1EndTimestamp');
            }
        }
        else if (shift === 'shift2' && this.jobEvent.shift2StartTimestamp && this.jobEvent.shift2EndTimestamp) {
            var startTime = Number(moment(this.jobEvent.shift2StartTimestamp).format('YYYYMMDD'));
            var endTime = Number(moment(this.jobEvent.shift2EndTimestamp).format('YYYYMMDD'));
            this.jobEvent.shift2Overnight = (endTime - startTime) > 0;
            if (!this.shiftTwoStartTime) {
                this.shiftTwoStartTime = this.formatTimeFromTimestamp('shift2StartTimestamp');
            }
            if (!this.shiftTwoEndTime) {
                this.shiftTwoEndTime = this.formatTimeFromTimestamp('shift2EndTimestamp');
            }
        }
    };
    EditJobEventDialogComponent.prototype.timeChange = function (prop, e, form) {
        this.jobEvent[prop] = e.target.value;
        form.controls[prop].markAsDirty();
        this.checkIfOvernight('shift1');
        this.checkIfOvernight('shift2');
    };
    /**
     * @param  {string} prop
     * @param  {} e
     * @param  {NgForm} form
     * @returns void
     * This will be called onChange of daily delivery target value
     * And also will mark the form as dirty to enable save button.
     */
    EditJobEventDialogComponent.prototype.dailyDeliveryTargetChange = function (prop, e, form) {
        this.jobEvent[prop] = e.target && e.target['value'] || 0;
        form.controls[prop].markAsDirty();
    };
    EditJobEventDialogComponent.prototype.dailyDeliveryTargetTypeChange = function (prop, option, form) {
        this.jobEvent[prop] = option.id;
        form.controls[prop].markAsDirty();
    };
    EditJobEventDialogComponent.prototype.onSelect = function (filterType, options, form) {
        form.controls[filterType].markAsDirty();
        if (options) {
            this.selectedTrucks = options;
        }
    };
    EditJobEventDialogComponent.prototype.addShift = function () {
        this.jobEvent.shift2StartTimestamp = this.jobEvent.shift2StartTimestamp || this.jobEvent.shift1StartTimestamp;
        this.jobEvent.shift2EndTimestamp = (new Date()).toISOString();
        this.checkIfOvernight('shift2');
    };
    EditJobEventDialogComponent.prototype.loadYardTimeDefaults = function () {
        if (this.jobEvent && this.jobEvent.job.defaultYardBufferTime) {
            this.jobEvent.defaultYardBufferTime = this.jobEvent.job.defaultYardBufferTime;
            this.jobEvent.defaultYardBufferMinutes = this.jobEvent.job.defaultYardBufferMinutes;
        }
        else if (this.user && this.user.organization) {
            this.jobEvent.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
            this.jobEvent.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
        }
    };
    return EditJobEventDialogComponent;
}());
exports.EditJobEventDialogComponent = EditJobEventDialogComponent;
