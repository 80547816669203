import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';
import { FilterOption } from './filters-panel/filter-option';

export class AppUtilities {
  /**
   * Returns the current time by rounding it to the nearest multiple of the duration provided.
   * @param duration Duration to round off
   * @param roundingMethod Rounding method
   * @returns Rounded time
   */
  static getCurrentTimeByRoundingMinutesAndSeconds(
    currentDate: moment.Moment = moment(),
    currentTime: moment.Moment = null,
    duration: moment.Duration = moment.duration(5, 'minutes'),
    roundingMethod: 'ceil' | 'floor' = 'ceil'
  ): moment.Moment {
    let round = false;
    if (!currentTime) {
      currentTime = moment();
      round = true;
    }
    const time = moment(
      `${moment(currentDate).format('YYYY-MM-DD')} ${moment(currentTime).format(
        'H:mm:ss'
      )}`
    );
    if (round) {
      return AppUtilities.roundTime(
        time,
        moment.duration(5, 'minutes'),
        'ceil'
      );
    } else {
      return time;
    }
  }

  static roundTime(
    date: moment.Moment,
    duration: moment.Duration,
    method: 'ceil' | 'floor'
  ): moment.Moment {
    return moment(Math[method](+date / +duration) * +duration);
  }

  /**
   * Returns true or false if file size in bytes is smaller than allowed with default value of 25mb.
   * @param fileSize File size in bytes
   * @param maxFileSize Max file size allowed in bytes - default is 25mb
   * @returns True or false
   */
  static fileSmallerThanAllowed(fileSize: number, maxFileSize = 25000000) {
    return fileSize < maxFileSize;
  }

  /**
   * Returns formatted string with hours and minutes with minutes input
   * E.g. - 90minutes returns 1h 30m
   * @param totalMinutes
   * @returns string with hours and minutes
   */
  static getFormattedHoursAndMinutesFromMinutes(totalMinutes: number): string {
    const hours = Math.trunc(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${hours}h ${minutes}m`;
  }

  static getExportParamsAndScope(
    appliedFilters: any[] = [],
    selectedRows: any[] = [],
    excludeRows: any[] = [],
    allSelected: boolean,
    search: string,
    params = new HttpParams(),
    scope = {}
  ): { params: HttpParams; scope: any } {
    if (selectedRows.length) {
      Object.assign(scope, { include: selectedRows.map((t) => t.id) });
    } else if (allSelected) {
      Object.assign(scope, { exclude: excludeRows.map((t) => t.id) });
    }
    Object.keys(appliedFilters).map((key) => {
      if (appliedFilters[key] && appliedFilters[key]['query']) {
        let query = appliedFilters[key]['query'];
        Object.keys(query).map((queryKey) => {
          params = params.set(queryKey, query[queryKey]);
        });
      }
    });
    if (search && search.length) {
      params = params.set('search', search);
    }
    return { params, scope };
  }

  static getFilterQuery(appliedFilters: FilterOption[], search?: string): {[key: string]: string} {
    let query = {};
    
    appliedFilters.forEach(filter => {
      const filterQueries = Object.keys(filter.query);
      filterQueries.map((queryKey) => {
        filterQueries[queryKey] = filter.query[queryKey];
      });
    });

    query['search'] = search
    return query;
  }

  static getParamsFromQuery(query: {[key:string]: any}) {
    let params: HttpParams = new HttpParams();

    if (query) {
      Object.keys(query).forEach((key) => {
        if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
          params = params.set(key, query[key].toString());
        }
      });
    }
    return params
  }
}
