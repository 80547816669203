"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var assignment_service_1 = require("./assignment.service");
var assignment_serializer_1 = require("./assignment.serializer");
var job_serializer_1 = require("../jobs/job.serializer");
var ConfirmAssignmentComponent = /** @class */ (function () {
    function ConfirmAssignmentComponent(route, router, elementRef, assignmentService) {
        this.route = route;
        this.router = router;
        this.elementRef = elementRef;
        this.assignmentService = assignmentService;
        this.loading = true;
        this.errors = [];
        this.displayNotes = false;
    }
    ConfirmAssignmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        this.disableSidebar();
        this.route.params.subscribe(function (params) {
            _this.getAssignment(params['id']);
        });
    };
    ConfirmAssignmentComponent.prototype.disableSidebar = function () {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            currentUser.sidebar = false;
        }
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    };
    ConfirmAssignmentComponent.prototype.getAssignment = function (id) {
        var _this = this;
        if (this.assignmentReq) {
            this.assignmentReq.unsubscribe();
        }
        this.assignmentReq = this.assignmentService.get(id)
            .subscribe(function (assignment) {
            _this.assignment = new assignment_serializer_1.AssignmentSerializer().fromJson(assignment);
            _this.jobEvent = assignment.jobevent;
            if (_this.jobEvent && _this.jobEvent.job) {
                _this.job = new job_serializer_1.JobSerializer().fromJson(_this.jobEvent.job);
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    ConfirmAssignmentComponent.prototype.confirmAssignment = function () {
        var _this = this;
        if (this.assignmentReq) {
            this.assignmentReq.unsubscribe();
        }
        var _assignment = lodash_1.clone(this.assignment);
        _assignment.driverConfirmed = true;
        this.assignmentReq = this.assignmentService.save(_assignment).subscribe(function (assignment) {
            _this.assignment = new assignment_serializer_1.AssignmentSerializer().fromJson(assignment);
            _this.jobEvent = assignment.jobevent;
            if (_this.jobEvent && _this.jobEvent.job) {
                _this.job = new job_serializer_1.JobSerializer().fromJson(_this.jobEvent.job);
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    return ConfirmAssignmentComponent;
}());
exports.ConfirmAssignmentComponent = ConfirmAssignmentComponent;
