import { Injectable } from '@angular/core';
import {
  forkJoin, timer as observableTimer, BehaviorSubject, of, Subscription
} from 'rxjs';

import { JobEvent } from '../job-events/job-event';
import { CondensedTrip } from '../trips/condensed-trip';
import { TripService } from '../trips/trip.service';
import { MapService } from '../map/map.service';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocationSerializer } from '../locations/location.serializer';
import { Location } from '../locations/location';

type OrderData = [ CondensedTrip[], any ];

@Injectable()
export class OrderService {
  mapsApiKey = environment.googleMapsKey;

  initOrderValue: OrderData = [[new CondensedTrip({})], { locationUpdates: [], locations: [{}, {}] }];
  orderData: BehaviorSubject<OrderData> = new BehaviorSubject(this.initOrderValue);
  orderDataReq: Subscription;
  jobEventId = new BehaviorSubject('');

  constructor(
    public tripService: TripService,
    private mapService: MapService
  ) {
    this.jobEventId.subscribe(id => {
      if (this.orderDataReq && typeof this.orderDataReq.unsubscribe === 'function') { this.orderDataReq.unsubscribe(); }
      this.orderDataReq = observableTimer(0, 30000).pipe(switchMap(() => (
        id ? forkJoin([
          this.tripService.getAllCondensedTrips(20, { jobevent: this.jobEventId.getValue() }),
          this.mapService.getLocationUpdates({ driver__assignments__jobevent: this.jobEventId.getValue() })
        ]) : of(this.orderData.getValue())
      ))).subscribe((data: [CondensedTrip[], { locations: Location[], locationUpdates: any[], routes: any[] }]) => {
        data[1].locations = data[1].locations.map(location => (new LocationSerializer().fromJson(location)));
        this.orderData.next(data);
      });
    });
  }

  setJobEventId(id: string) { this.jobEventId.next(id); }

  getDistanceInMiles(origin: { lat: number, lng: number }, destination: { lat: number, lng: number }): number {
    const R = 6959; // earth's radius (mi)
    const latDistance = (destination.lat - origin.lat) * Math.PI / 180;
    const lngDistance = (destination.lng - destination.lng) * Math.PI / 180;
    const lat1 = destination.lat * Math.PI / 180;
    const lat2 = origin.lat * Math.PI / 180;

    const a = Math.sin(latDistance / 2) * Math.sin(latDistance / 2) +
              Math.sin(lngDistance / 2) * Math.sin(lngDistance / 2) *
              Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = Math.round(R * c * 100) / 100;
    return distance;
  }

  getLoadStatus(tripStatus: string): string {
    return tripStatus === 'loading_complete' ||
           tripStatus === 'enroute_unloading' ||
           tripStatus === 'waiting_to_unload' ||
           tripStatus === 'active_unloading' ||
           tripStatus === 'unloading' ? 'Loaded' :
           tripStatus === 'unloading' ||
           tripStatus === 'unloading_complete' ? 'Unloaded' :
           'N/A';
  }
}
