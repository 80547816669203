import * as moment from 'moment-timezone';
import {
  Component, ElementRef, OnInit, Inject, Output, EventEmitter, OnDestroy, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { clone } from 'lodash';
import { NgForm } from '@angular/forms';

import { JobService } from '../jobs/job.service';
import { AuthenticationService } from '../shared/authentication.service';
import { parseErrors } from '../shared/api.service';
import { Job } from './job';

@Component({
  selector: 'edit-job-dialog',
  templateUrl: './edit-job-dialog.component.html',
  styleUrls: ['./edit-job-dialog.component.scss']
})
export class EditJobDialogComponent implements OnInit, OnDestroy {
  @Output() completed = new EventEmitter();
  @ViewChild('editJobDays', { static: false }) editJobDays: NgForm;

  job: Job;
  jobId: string;
  jobReq: Subscription;
  jobDaysReq: Subscription;
  errors = [];
  existingDates: Date[] = [];
  selectedDates: Date[] = [];
  loading = false;
  shiftValid = false;
  secondShift = false;
  isCreator = false;
  callback;
  model = {
    dates: []
  };

  constructor(
    public dialogRef: MatDialogRef<EditJobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobService: JobService,
    private authenticationService: AuthenticationService,
    private elementRef: ElementRef
  ) {
    this.job = this.data && this.data.job;
    this.jobId = this.data && this.data.jobId;
  }

  ngOnInit() {
    if (this.jobId) {
      this.getJob();
    } else if (this.job) {
      this.checkShiftValidity();
      this.processJob();
    }
  }

  ngOnDestroy() {
    if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
      this.jobReq.unsubscribe();
    }
    if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
      this.jobDaysReq.unsubscribe();
    }
  }

  getJob(): void {
    if (this.jobReq && typeof this.jobReq.unsubscribe === 'function') {
      this.jobReq.unsubscribe();
    }

    this.loading = true;

    this.jobReq = this.jobService.get(this.jobId).subscribe(job => {
      this.job = job;
      this.checkShiftValidity();
      this.processJob();
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }

  processJob(): void {
    let myOrganization = this.authenticationService.getOrganization();
    if (myOrganization && this.job && this.job.project) {
      this.isCreator = this.job.project.ownerOrganization === myOrganization.id;
    }
    this.getJobDays();
  }

  onDatesChanged(dates: Date[]): void {
    this.model.dates = dates;
    this.selectedDates = this.model.dates.map(d => moment(d).toDate());
    if (this.editJobDays && this.editJobDays.form) {
      this.editJobDays.form.markAsTouched();
    }
  }

  checkShiftValidity(): void {
    if (!this.job) {
      this.shiftValid = false;
    } else if (this.job.shift1StartTime === null) {
      this.shiftValid = this.model['shift1_start_time'] && this.model['shift1_end_time'];
    } else {
      this.shiftValid = true;
      this.checkIfOvernight('shift1');
      this.checkIfOvernight('shift2');
    }
  }

  checkIfOvernight(shift: string): void {
    if (shift === 'shift1' && this.job.shift1StartTime && this.job.shift1EndTime) {
      const startTime = moment(this.job.shift1StartTime, 'h:mm a');
      const endTime = moment(this.job.shift1EndTime, 'h:mm a');
      this.job.shift1Overnight = endTime.isBefore(startTime);
    } else if (shift === 'shift2' && this.job.shift2StartTime && this.job.shift2EndTime) {
      const startTime = moment(this.job.shift2StartTime, 'h:mm a');
      const endTime = moment(this.job.shift2EndTime, 'h:mm a');
      this.job.shift2Overnight = endTime.isBefore(startTime);
    }
  }

  submit(): void {
    let addedDates;
    if (this.model.dates.length > 0) {
      addedDates = this.model.dates.filter(d => {
        return !this.existingDates.map(date => moment(date).format('YYYY-MM-DD')).includes(d);
      });
      this.model.dates = this.model.dates.map(d => moment(d).format('YYYY-MM-DD'));
    } else {
      this.model.dates = [];
    }

    if (this.model['shift1_start_time']) {
      // Convert shift times to 24-hour format
      this.model['shift1_start_time'] = moment(this.model['shift1_start_time'], ['h:mm A']).format('HH:mm');
      this.model['shift1_end_time'] = moment(this.model['shift1_end_time'], ['h:mm A']).format('HH:mm');
      if (this.model['shift2_start_time']) {
        this.model['shift2_start_time'] = moment(this.model['shift2_start_time'], ['h:mm A']).format('HH:mm');
      }
      if (this.model['shift2_end_time']) {
        this.model['shift2_end_time'] = moment(this.model['shift2_end_time'], ['h:mm A']).format('HH:mm');
      }
    }

    this.jobService.updateDays(this.job.id, this.model).subscribe(result => {
      this.loading = false;
      this.dialogRef.close();
      this.callback(addedDates);
    }, (err) => {
      this.errors = ['Job could not be updated.'];
      this.errors.push(parseErrors(err));
      this.loading = false;
    });
  }

  getJobDays(): void {
    if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
      this.jobDaysReq.unsubscribe();
    }

    this.loading = true;
    this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(days => {
      this.existingDates = days.map(day => moment(day).toDate());
      this.selectedDates = clone(this.existingDates);
      this.model.dates = days.map(day => moment(day).format('YYYY-MM-DD'));
    }, err => {
      this.errors = err;
    }, () => {
      this.loading = false;
    });
  }
}
