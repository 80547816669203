import { Injectable } from '@angular/core';
import { ResourceService } from '../shared/resource.service';
import { HttpClient } from '@angular/common/http';
import { Preference } from './preference';
import { PreferenceSerializer } from './preference.serializer';

@Injectable()
export class PreferenceService extends ResourceService<Preference> {
  constructor(http: HttpClient) {
    super(http, 'preferences/', new PreferenceSerializer());
  }
}
