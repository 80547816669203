<my-toolbar title="{{ this.project ? this.project.name + ' - New Job' : 'New Job' }}"></my-toolbar>

<form #f='ngForm' [ngClass]="{'mobile': !device.desktop}">
  <h1 translate>New Job</h1>
  <p translate>You can create multiple jobs for each project. Jobs are what trucks and drivers are dispatched to.</p>
  <h2 translate>Job Details</h2>

  <div class="field-group dropdown">
    <label class="required" translate>Project</label>
    <mat-form-field>
      <input type="text" name="project-name"
             attr.data-label="{{ 'Project' | translate }}"
             matInput [formControl]="projectControl"
             [matAutocomplete]="projectAutocomplete">
      <mat-autocomplete #projectAutocomplete [displayWith]="projectDisplayFn"
                        (optionSelected)="selectProject($event.option.value)">
        <mat-option *ngFor="let option of projectOptions" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="field-group dropdown">
    <label class="required" translate>Customer</label>
    <!-- <input type="hidden" name="customer" *ngIf="job && job.project"
           required [(ngModel)]="job.project.customerOrganization"
           #customer="ngModel" /> -->
    <dropdown #customerDropdown
              id="customer-dropdown" required
              [options]="customerOptions"
              [selectedOption]="job.customerOrganization"
              [config]="customerDropdownConfig"
              (onSelect)="selectCustomer($event)"
              (onSearch)="customerDropdownSearch($event)"
              (nextPage)="customerDropdownNextPage()"
              title="{{ 'Select Customer' | translate }}"></dropdown>
  </div>
  <div class="field-group">
    <label class="required" translate>Job Title</label>
    <input type="text" name="name" [(ngModel)]="job.name" />
  </div>
  <div class="field-group">
    <label translate>PO #</label>
    <input type="text" name="po-num" [(ngModel)]="job.poNumber" />
  </div>
  <div class="field-group">
    <label translate>Job #</label>
    <input type="text" name="job-num" [(ngModel)]="job.jobNumber" />
  </div>
  <div class="field-group">
    <label translate>Order #</label>
    <input type="text" name="order-num" [(ngModel)]="job.orderNumber" />
  </div>
  <div class="field-group">
    <label translate>Markets</label>
    <tag-input name="tags" (onSelect)="tagChange($event)"></tag-input>
  </div>
  <div class="field-group">
    <label class="required" translate>What material is being moved?</label>
    <input type="text" name="material" [(ngModel)]="job.material" />
  </div>
  <div class="field-group">
    <label translate>Phase Code</label>
    <input type="text" name="phase-code" [(ngModel)]="job.phaseCode" />
  </div>

  <h2>Rate</h2>
  <div class="field-group">
    <label translate>How is this job invoiced?</label>
    <div class="radio-buttons">
      <input type="radio" id="invoice_type_weight" name="invoice_type" [(ngModel)]="job.invoiceType" value="weight"
        (change)="invoiceTypeChanged()" />
      <label attr.data-label="{{ 'Weight' | translate }}" for="invoice_type_weight"></label>

      <input type="radio" id="invoice_type_load" name="invoice_type" [(ngModel)]="job.invoiceType" value="load"
        (change)="invoiceTypeChanged()" />
      <label attr.data-label="{{ 'Load' | translate }}" for="invoice_type_load"></label>

      <input type="radio" id="invoice_type_hour" name="invoice_type" [(ngModel)]="job.invoiceType" value="hour"
        (change)="invoiceTypeChanged()" />
      <label attr.data-label="{{ 'Hour' | translate }}" for="invoice_type_hour"></label>
    </div>
  </div>
  <div class="field-group">
    <label class="required" translate>What is the rate?</label>
    <div class="input-group" data-prefix="$">
      <input numberInRange [minValue]="0" type="text" name="job_rate" [(ngModel)]="job.rate" min="0" (change)="changeCloneHaulRate()" />
      <dropdown [options]="jobWeightOptions" *ngIf="job.invoiceType === 'weight'" title="-" (onSelect)="weightUnitChange('invoice', $event)"></dropdown>
      <div class="add-on" *ngIf="job.invoiceType !== 'weight'">{{job.invoiceType}}</div>
    </div>
  </div>

  <div class="field-group clone-haul-rate">
    <input type="checkbox" id="clone_haul_rate" name="clone_haul_rate" [(ngModel)]="cloneHaulRate" (change)="changeCloneHaulRate()" />
    <label attr.data-label="{{ 'Haul rate is the same' | translate }}" for="clone_haul_rate"></label>
  </div>

  <div class="field-group haul-rate-tracking" *ngIf="!cloneHaulRate">
    <label translate>How is the haul rate tracked?</label>
    <div class="radio-buttons">
      <input type="radio" id="haul_type_weight" name="haul_type" [(ngModel)]="job.haulType" (change)="haulTypeChanged()"
        value="weight" [disabled]="cloneHaulRate" />
      <label attr.data-label="{{ 'Weight' | translate }}" for="haul_type_weight"></label>
      <input type="radio" id="haul_type_load" name="haul_type" [(ngModel)]="job.haulType" (change)="haulTypeChanged()"
        value="load" [disabled]="cloneHaulRate" />
      <label attr.data-label="{{ 'Load' | translate }}" for="haul_type_load"></label>
      <input type="radio" id="haul_type_hour" name="haul_type" [(ngModel)]="job.haulType" (change)="haulTypeChanged()"
        value="hour" [disabled]="cloneHaulRate" />
      <label attr.data-label="{{ 'Hour' | translate }}" for="haul_type_hour"></label>
    </div>
  </div>
  <div class="field-group" *ngIf="!cloneHaulRate">
    <label class="required" translate>What is the haul rate?</label>
    <div class="input-group" data-prefix="$">
      <input numberInRange [minValue]="0" type="text" name="haul_rate" [(ngModel)]="job.haulRate" [disabled]="cloneHaulRate" min="0" />
      <dropdown [options]="haulWeightOptions" *ngIf="job.haulType === 'weight'" title="-" [disabled]="cloneHaulRate"
        (onSelect)="weightUnitChange('haul', $event)"></dropdown>
      <div class="add-on" *ngIf="job.haulType !== 'weight'">{{job.haulType}}</div>
    </div>
  </div>

  <h2 translate>Date, Time, and Volume</h2>
  <div class="field-group amount-needed">
    <label translate>Total amount ordered?</label>
    <div class="input-group">
      <input type="text" name="amount_needed" [(ngModel)]="job.amountNeeded" pattern="[^.]+" placeholder="Amount" />
      <dropdown [options]="orderTypeOptions" [selectedOption]="orderTypeOptions[0]" (onSelect)="changeTotalAmountType($event)"></dropdown>
    </div>
  </div>
  <div class="dates">
    <div class="group">
      <label translate>Dates</label>
      <date-range-picker [config]="{decreaseControl: false, increaseControl: false, multipleDates: true}"
                         [selectedDates]="job.dates"
                         (dateChanged)="onDateChanged($event)"></date-range-picker>
    </div>
  </div>

  <div class="group second-shift-toggle">
    <input type="checkbox" [(ngModel)]="secondShift" id="second_shift" name="secondShift" />
    <label attr.data-label="{{ 'Multiple Shifts' | translate }}" for="second_shift"></label>
  </div>

  <div class="times">
    <div class="group">
      <label [ngClass]="{required: job.dates && job.dates.length > 0}" translate>Shift 1 Start Time</label>
      <div class="timepicker-wrapper" *ngIf="device.desktop">
          <input 
            type="time" 
            name="shift1_start_time" 
            [(ngModel)]="job.shift1StartTime"
            class="timepicker"
            (change)="checkIfOvernight('shift1')"
          />
      </div>
      <input type="time" name="shift1_start_time"
             [(ngModel)]="job.shift1StartTime" *ngIf="!device.desktop"
             (blur)="checkIfOvernight('shift1')" />
    </div>

    <div class="group">
      <label [ngClass]="{required: job.dates && job.dates.length > 0}" translate>Shift 1 End Time</label>
      <div class="timepicker-wrapper" *ngIf="device.desktop">
            <input 
              type="time" 
              name="shift1_end_time" 
              [(ngModel)]="job.shift1EndTime"
              class="timepicker"
              (change)="checkIfOvernight('shift1')"
            />
      </div>
      <input type="time" name="shift1_end_time"
             [(ngModel)]="job.shift1EndTime" *ngIf="!device.desktop"
             (blur)="checkIfOvernight('shift1')" />
    </div>
    <notification translate *ngIf="job.shift1Overnight" level="warn">
      Warning: This shift will end on the next day
    </notification>
  </div>

  <div class="times" *ngIf="secondShift">
    <div class="group">
      <label class="required" translate>Shift 2 Start Time</label>
      <div class="timepicker-wrapper" *ngIf="device.desktop">
        <input 
          type="time" 
          name="shift2_start_time" 
          [(ngModel)]="job.shift2StartTime"
          class="timepicker"
          (change)="checkIfOvernight('shift2')"
        />
      </div>
      <input type="time" name="shift2_start_time"
             [(ngModel)]="job.shift2StartTime" *ngIf="!device.desktop"
             (blur)="checkIfOvernight('shift2')" />
    </div>

    <div class="group">
      <label class="required" translate>Shift 2 End Time</label>
      <div class="timepicker-wrapper" *ngIf="device.desktop">
        <input 
          type="time" 
          name="shift2_end_time" 
          [(ngModel)]="job.shift2EndTime"
          class="timepicker"
          (change)="checkIfOvernight('shift2')"
        />
      </div>
      <input type="time" name="shift2_end_time"
             [(ngModel)]="job.shift2EndTime" *ngIf="!device.desktop"
             (blur)="checkIfOvernight('shift2')" />
    </div>
    <notification translate *ngIf="job.shift2Overnight" level="warn">
      Warning: This shift will end on the next day
    </notification>
  </div>

  <h2 translate>Yard Times</h2>
  <div class="field-group default-yard-buffer-time">
    <label translate>Default Yard Buffer Time</label>
    <p translate>
      This default value can be changed within your 
      <a [routerLink]="['/settings', 'organization']" routerLinkActive="active" translate>Organization Settings</a> 
      screen.
    </p>
    <div class="input-group">
      <input type="number" min="0" [(ngModel)]="job.defaultYardBufferMinutes" name="default_yard_buffer_minutes" />
      <div class="add-on" translate>Minutes</div>
    </div>
  </div>

  <h2 translate>Dispatch Request</h2>
  <div class="field-group num-trucks">
    <label translate>Number of trucks needed</label>
    <input numberInRange type="number" name="num-trucks" [(ngModel)]="job.numTrucks" min="1" step="1" />
  </div>
  <div class="field-group truck-types">
    <label translate>Types of trucks requested</label>
    <div>
      <div class="any">
        <input type="checkbox" name="anyTruckType" id="truck_type_any" [(ngModel)]="job.allowAnyTruckType" (change)="truckTypeChange($event)" />
        <label attr.data-label="{{ 'Any Type' | translate }}" for="truck_type_any" class="checkbox any"></label>
      </div>

      <div *ngFor="let type of truckTypes | propertyFilter: 'isFavorite': 'true'">
        <input type="checkbox" name="truck_type[]" [checked]="truckTypeMap[type.id]" [value]="type.id" id="truck_type_{{type.id}}"
          (change)="truckTypeChange($event)" />
        <label [attr.data-label]="type.name" for="truck_type_{{type.id}}" class="checkbox"></label>
      </div>
      <button *ngIf="!truckTypesExpanded" class="btn btn-default expand-truck-types" (click)="truckTypesExpanded = true">More</button>
      <ng-container *ngIf="truckTypesExpanded">
        <div *ngFor="let type of truckTypes | propertyFilter: 'isFavorite': 'false'">
          <input type="checkbox" name="truck_type[]" [checked]="truckTypeMap[type.id]" [value]="type.id" id="truck_type_{{type.id}}"
            (change)="truckTypeChange($event)" />
          <label [attr.data-label]="type.name" for="truck_type_{{type.id}}" class="checkbox"></label>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="field-group delivery-target">
    <label translate>Daily Delivery Target</label>
    <div class="input-group">
      <input numberInRange type="text" placeholder="{{ 'Amount' | translate }}" name="daily_delivery_target"
        [(ngModel)]="job.dailyDeliveryTarget" />
      <!-- <div class="add-on">{{job.invoiceType === 'weight' ? job.invoiceWeightUnit : job.invoiceType}}</div> -->
      <dropdown [options]="orderTypeOptions" [selectedOption]="orderTypeOptions[0]" (onSelect)="changeDailyDeliveryTargetType($event)"></dropdown>
    </div>
  </div>
  <div class="field-group">
    <label translate>Stagger Start Time</label>
    <div class="input-group">
      <input numberInRange type="number" placeholder="{{ 'Time' | translate }}" name="delivery_interval" min="0"
        [(ngModel)]="job.deliveryInterval" />
      <dropdown [options]="deliveryIntervalUnits" (onSelect)="changeDeliveryIntervalUnits($event)"></dropdown>
    </div>
  </div>

  <ng-container *ngIf="user.organization && user.organization.qrEnabled">
    <h2 translate>Job Type</h2>
    <div class="field-group">
      <label translate>Are materials imported to the job site or exported from the job site?</label>
      <div class="fields">
        <div class="radio-buttons">
          <input type="radio" name="job_type" id="job_type_loading_import" [(ngModel)]="job.qrJobType" value="loading_import"
            (change)="jobTypeChanged()" />
          <label attr.data-label="{{ 'Import (Ticket at Loading)' | translate }}" for="job_type_loading_import"></label>
          <input type="radio" name="job_type" id="job_type_unloading_import" [(ngModel)]="job.qrJobType" value="unloading_import"
            (change)="jobTypeChanged()" />
          <label attr.data-label="{{ 'Import (Ticket at Unloading)' | translate }}" for="job_type_unloading_import"></label>
          <input type="radio" name="job_type" id="job_type_export" [(ngModel)]="job.qrJobType" value="export" (change)="jobTypeChanged()" />
          <label attr.data-label="{{ 'Export' | translate }}" for="job_type_export"></label>
          <input type="radio" name="job_type" id="job_type_other" [(ngModel)]="job.qrJobType" value="other" (change)="jobTypeChanged()" />
          <label attr.data-label="{{ 'Other' | translate }}" for="job_type_other"></label>
        </div>
      </div>
    </div>

    <div class="job-type-description">
      <label translate>Job Type Description</label>
      <div *ngIf="job.qrJobType === 'loading_import'" translate>
        Use this option when the driver
        <span class="description-highlight">receives a paper ticket at loading</span>
        and the Ruckit Driver App generates a QR code for the Pit TicketPro App
        to scan at unloading.
      </div>
      <div *ngIf="job.qrJobType === 'unloading_import'" translate>
        Use this option when there is
        <span class="description-highlight">no ticket at loading</span>
        and the Pit TicketPro App generates a QR code for the Ruckit Driver App to scan at unloading.
      </div>
      <div *ngIf="job.qrJobType === 'export'" translate>
        Use this option when
        <span class="description-highlight">materials are removed from the construction site</span>.
        The Pit TicketPro App will generate a QR code for the Ruckit Driver App
        to scan at loading.
      </div>
      <div *ngIf="job.qrJobType === 'other'" translate>
        Use this option when creating a job that does not integrate with Pit TicketPro.
      </div>
    </div>
  </ng-container>

  <h2 translate>Loading</h2>
  <div class="location">
    <div class="fields">
      <label class="required" translate>Select Address</label>
      <dropdown id="loading-dropdown" #startLocationDropdown [options]="loadingLocations" (onSelect)="changeLoadingLocation($event)"
        title="-" [config]="locationsDropdownConfig.start" (onSearch)="locationDropdownSearch('start', $event)" (nextPage)="locationDropdownNextPage('start')"></dropdown>
      <label class="options-header" translate>Loading Options</label>

      <div class="config-option">
        <label>{{ 'Log Weight' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.weightConfig"
          [disabled]="job.checkinOptions.states['log_weight']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>

      <div class="config-option">
        <label>{{ 'Ticket Number' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.ticketNumberConfig"
          [disabled]="job.checkinOptions.states['ticket_number']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Ticket Photo' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.ticketImageConfig"
          [disabled]="job.checkinOptions.states['ticket_image']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Signature' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.signatureImageConfig"
          [disabled]="job.checkinOptions.states['signature_image']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Track Loading Time' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.activeTrackingConfig"
          [disabled]="job.checkinOptions.states['active_tracking']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
        <label>{{ 'QR Code' | translate }}</label>
        <mat-select [(value)]="job.checkinOptions.qrConfig"
          [disabled]="job.checkinOptions.states['qr_code']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="map">
      <div class="map-actions" *ngIf="job.startLocation">
        <button class="btn btn-primary-alt btn-small" (click)="editLocation(job.startLocation, 'start')" translate>
          Edit
        </button>
      </div>
      <div class="overlay" *ngIf="job.startLocation && job.startLocation.name && !job.startLocation.mappable">
        <a [routerLink]="['/locations', job.startLocation.id, 'edit']"
           [queryParams]="{ returnTo: '/jobs/new' }">
          <button class="btn btn-default" translate>Add Address</button>
        </a>
      </div>
      <ngui-map center="{{getCoord('start', 1)}}, {{getCoord('start', 0)}}" disableDefaultUI="true"
        disableDoubleClickZoom="true" fullscreenControl="false" mapTypeControl="false" streetViewControl="false"
        zoomControl="false" clickableIcons="false" panControl="false" [zoom]="15">
        <marker *ngIf="job.startLocation && job.startLocation.mappable" draggable="false" [label]="''" [title]="job.startLocation.name" position="{{ getCoord('start', 1) }}, {{ getCoord('start', 0) }}">
        </marker>
        <polygon *ngIf="job.startLocation" [editable]="false" [paths]="getPaths('start')">
        </polygon>
      </ngui-map>
    </div>
  </div>

  <h2 translate>Unloading</h2>
  <div class="location">
    <div class="fields">
      <label>
        Select Address
      </label>
      <dropdown id="unloading-dropdown" #endLocationDropdown [(options)]="unloadingLocations" title="-" (onSelect)="changeUnloadingLocation($event)"
        [config]="locationsDropdownConfig.end" (onSearch)="locationDropdownSearch('end', $event)" (nextPage)="locationDropdownNextPage('end')"></dropdown>
      <label class="options-header" translate>Unloading Options</label>

      <div class="config-option">
        <label>{{ 'Log Weight' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.weightConfig"
          [disabled]="job.checkoutOptions.states['log_weight']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Ticket Number' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.ticketNumberConfig"
          [disabled]="job.checkoutOptions.states['ticket_number']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Ticket Photo' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.ticketImageConfig"
          [disabled]="job.checkoutOptions.states['ticket_image']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option">
        <label>{{ 'Signature' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.signatureImageConfig"
          [disabled]="job.checkoutOptions.states['signature_image']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option" *ngIf="user.organization && user.organization.activeTrackingEnabled">
        <label>{{ 'Track Unloading Time' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.activeTrackingConfig"
          [disabled]="job.checkoutOptions.states['active_tracking']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
      
      <div class="config-option" *ngIf="user.organization && user.organization.qrEnabled">
        <label>{{ 'QR Code' | translate }}</label>
        <mat-select [(value)]="job.checkoutOptions.qrConfig"
          [disabled]="job.checkoutOptions.states['qr_code']">
          <mat-option *ngFor="let option of configOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="map">
      <div class="map-actions" *ngIf="job.endLocation">
        <button class="btn btn-primary-alt btn-small" (click)="editLocation(job.endLocation, 'end')" translate>
          Edit
        </button>
      </div>
      <div class="overlay" *ngIf="job.endLocation && job.endLocation.name && !job.endLocation.mappable">
        <a [routerLink]="['/locations', job.endLocation.id, 'edit']"
           [queryParams]="{ returnTo: '/jobs/new' }">
          <button class="btn btn-default" translate>Add Address</button>
        </a>
      </div>
      <ngui-map center="{{getCoord('end', 1)}}, {{getCoord('end', 0)}}" disableDefaultUI="true" disableDoubleClickZoom="true"
        fullscreenControl="false" mapTypeControl="false" streetViewControl="false" zoomControl="false" clickableIcons="false"
        panControl="false" [zoom]="15">
        <marker *ngIf="job.endLocation && job.endLocation.mappable" draggable="false" [label]="''" [title]="job.endLocation.name" position="{{ getCoord('end', 1) }}, {{ getCoord('end', 0) }}">
        </marker>
        <polygon *ngIf="job.endLocation" [editable]="false" [paths]="getPaths('end')">
        </polygon>
      </ngui-map>
    </div>
  </div>

  <ng-container *ngIf="hasWorkOrder">
    <h2 translate>Work Order</h2>
    <div class="field-group dropdown work-order-template">
      <label translate>Work Order Template</label>
      <input type="hidden" name="work_order_template" [(ngModel)]="job.workOrderTemplate" #workOrderTemplate="ngModel" />
      <div class="work-order-template-inner">
        <dropdown id="work-order-template-dropdown" #workOrderTemplateDropdown
                  [options]="workOrderTemplateOptions"
                  [selectedOption]="job.workOrderTemplate"
                  [config]="workOrderTemplateDropdownConfig"
                  (onSelect)="onSelect('workOrderTemplate', $event)"
                  (onSearch)="dropdownSearch('workOrderTemplate', $event)"
                  (nextPage)="dropdownNextPage('workOrderTemplate', $event)"
                  title="{{ 'Select Work Order Template' | translate }}"></dropdown>
        <div class="template-preview">
          <a *ngIf="job.workOrderTemplate" [href]="job.workOrderTemplate.html" target="_blank">
            <mat-icon>picture_as_pdf</mat-icon> Preview
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="customFields && customFields.length > 0">
    <h2 translate>Custom Fields</h2>
    <div class="field-group">
      <div class="fields">
        <ng-container *ngFor="let field of customFields" class="custom-fields">
          <label>{{ field.displayName }}</label>
          <input name="{{ field.key }}" type="text" placeholder="Value" [(ngModel)]="job.customFieldData[field.key]">
        </ng-container>
      </div>
    </div>
  </ng-container>

  <h2 translate>Additional Notes</h2>
  <label translate>Notes</label>
  <textarea [(ngModel)]="job.notes" name="notes"></textarea>

  <notification *ngFor="let error of errors">{{error}}</notification>

  <button id="create-job-button" class="btn btn-primary submit"
          [disabled]="!isValid()" (click)="submit(); false"
          [ngClass]="{loading: loading}" translate>
    Create Job
  </button>

  <button id="create-and-dispatch-job-button" class="btn btn-primary-alt submit"
          [disabled]="!isValid()" (click)="submit(true); false"
          [ngClass]="{loading: loading}" translate>
    Create and Dispatch Job
  </button>
</form>
