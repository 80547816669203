"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var map_1 = require("@ngui/map");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var driver_serializer_1 = require("./../drivers/driver.serializer");
var static_data_1 = require("../shared/static-data");
var map_service_1 = require("../map/map.service");
var index_1 = require("../shared/index");
var assignment_service_1 = require("../assignments/assignment.service");
var job_event_service_1 = require("../job-events/job-event.service");
var preference_service_1 = require("../preferences/preference.service");
var location_service_1 = require("../locations/location.service");
var truck_serializer_1 = require("../trucks/truck.serializer");
var DriverMapComponent = /** @class */ (function () {
    function DriverMapComponent(mapService, locationService, jobEventService, assignmentService, authenticationService, preferenceService, cdr, dialog) {
        this.mapService = mapService;
        this.locationService = locationService;
        this.jobEventService = jobEventService;
        this.assignmentService = assignmentService;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.cdr = cdr;
        this.dialog = dialog;
        this.assignmentDateChanged = new rxjs_1.Subject();
        this.search = '';
        this.searching = false;
        this.searchChanged = new rxjs_1.Subject();
        this.markerType = 'driver-initials';
        this.markerStatus = 'each-segment';
        this.locations = [];
        this.locationUpdates = [];
        this.paverLocationUpdates = [];
        this.routes = [];
        this.parsedRoutes = [];
        this.polygons = [];
        this.loading = true;
        this.mapLoading = true;
        this.errors = [];
        this.customLocationMarkers = [];
        this.customUpdateMarkers = [];
        this.customPaverUpdateMarkers = [];
        this.coordinates = [];
        this.mapControls = {
            traffic: false,
            heatmap: false,
            heatmapRadius: 20
        };
        this.mapOptions = {};
        this.heatmapPoints = [];
        // mobile-specific declarations
        this.driverListOpen = false;
        this.hours = [];
        this.assignments = {
            items: [],
            errors: [],
            loading: false,
        };
        this.activeJobs = [];
        this.activeJobEvents = [];
        this.punchCardFlag = false;
        this.driversList = [];
        this.assignmentDetails = {};
        this.preferenceReqs = {};
        this.preferences = {};
        this.loadingMapPreferences = true;
        this.assignmentTitle = new core_1.EventEmitter();
        this.selectedDriver = new core_1.EventEmitter();
    }
    DriverMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mapLoading = true;
        this.mapOptions = static_data_1.mapOptions({
            disableDefaultUI: true,
            scrollwheel: true
        }, {}, {
            mapStyle: 'google-map-style'
        });
        var currentOrganization = this.authenticationService.getOrganization();
        this.getPreferences('GeneralOrganizationPreferences', {
            name: 'GeneralOrganizationPreferences',
            type: 'organization',
            organization: currentOrganization.id
        });
        this.getPreferences('GeneralUserPreferences');
        var allDriversMapRefresh = this.authenticationService.getFeature('allDriversMapRefresh') || 300000;
        this.jobEventsTimer = rxjs_1.timer(1, allDriversMapRefresh);
        this.jobEventsTimerSub = this.jobEventsTimer.subscribe(function (t) {
            if (_this.metaData) {
                _this.getMapLocationUpdates(_this.mapLoading);
            }
            _this.mapLoading = false;
        });
        this.organization = this.authenticationService.getOrganization();
        if (window.innerWidth <= 900) {
            this.loadAssignments();
        }
        this.searchChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            if (_this.metaData) {
                _this.getMapLocationUpdates(_this.mapLoading, true, true);
            }
        });
        this.assignmentDateChanged.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (assignmentDate) {
            _this.assignmentDate = assignmentDate;
            if (_this.metaData) {
                _this.getMapLocationUpdates(_this.mapLoading, true, true);
            }
        });
    };
    DriverMapComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        if (this.jobEventsTimerSub) {
            try {
                this.jobEventsTimerSub.unsubscribe();
                this.jobEventsTimer = null;
            }
            catch (e) {
                this.jobEventsTimerSub = null;
                this.jobEventsTimer = null;
            }
        }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        Object.keys(this.preferenceReqs).forEach(function (preferenceReqKey) {
            if (_this.preferenceReqs[preferenceReqKey] && typeof _this.preferenceReqs[preferenceReqKey].unsubscribe === 'function') {
                try {
                    _this.preferenceReqs[preferenceReqKey].unsubscribe();
                }
                catch (e) { }
            }
        });
    };
    DriverMapComponent.prototype.ngOnChanges = function (changes) {
        if (!this.assignmentToggle) {
            this.assignmentDetails = {};
            this.assignmentTitle.emit(null);
        }
        if (changes.metaData && changes.metaData.currentValue &&
            !lodash_1.isEqual(changes.metaData.currentValue, changes.metaData.previousValue)) {
            this.getMapLocationUpdates(this.mapLoading, true);
        }
    };
    DriverMapComponent.prototype.onResize = function (event) {
        if (window.innerWidth <= 900 && this.assignments.loading !== false) {
            this.loadAssignments();
        }
    };
    DriverMapComponent.prototype.changeSearch = function (term) {
        this.searchChanged.next(term);
        this.assignmentDetails = {};
        this.assignmentTitle.emit(null);
    };
    DriverMapComponent.prototype.getMapLocationUpdates = function (loading, centerMap, openInfoWindow) {
        var _this = this;
        if (loading === void 0) { loading = true; }
        if (centerMap === void 0) { centerMap = false; }
        if (openInfoWindow === void 0) { openInfoWindow = false; }
        this.loading = loading;
        this.startDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(this.startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentDate) {
            this.startDate = new Date(this.assignmentDate);
            this.startDate.setHours(0, 0, 0, 0);
            endDate = lodash_1.clone(this.startDate);
            endDate.setHours(23, 59, 59, 999);
        }
        if (this.mapReq && typeof this.mapReq.unsubscribe === 'function') {
            this.mapReq.unsubscribe();
        }
        this.mapReq = this.mapService.getLocationUpdates({
            exclude_pending: 'True',
            search: this.search
        }, 'drivers', this.metaData).subscribe(function (locationUpdates) {
            _this.resetMarkers();
            _this.locations = locationUpdates.locations;
            _this.locationUpdates = locationUpdates.locationUpdates.map(function (update) {
                if (update.driver) {
                    update.driver = new driver_serializer_1.DriverSerializer().fromJson(update.driver);
                }
                return update;
            });
            _this.paverLocationUpdates = locationUpdates.paverLocationUpdates.map(function (update) {
                if (update.truck) {
                    update.truck = new truck_serializer_1.TruckSerializer().fromJson(update.truck);
                }
                return update;
            });
            _this.routes = locationUpdates.routes;
            _this.parseRoutes();
            _this.polygons = _this.locations.map(function (location) {
                return location.paths;
            }).filter(Boolean);
            _this.loading = false;
            _this.mapData = locationUpdates;
            if (centerMap) {
                _this.centerMap();
            }
            if (openInfoWindow && _this.search && _this.search.length &&
                _this.locationUpdates.length === 1 && window.innerWidth > 900) {
                _this.openDriverInfoWindow(0, _this.locationUpdates[0]);
            }
            else if (!_this.search || _this.search.length || _this.locationUpdates.length === 0) {
                if (_this.nguiMapComponent) {
                    _this.nguiMapComponent.closeInfoWindow('driver-info-window');
                }
            }
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    DriverMapComponent.prototype.parseRoutes = function () {
        if (this.map) {
            this.parsedRoutes = this.routes.map(function (route) {
                if (route) {
                    try {
                        return new google.maps.geometry.encoding.decodePath(route);
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
            }).filter(Boolean);
        }
    };
    DriverMapComponent.prototype.centerMap = function () {
        var _centerOnOrganization = true;
        if (this.map) {
            var bounds_1 = new google.maps.LatLngBounds();
            if (this.locations && this.locations.length) {
                this.locations.forEach(function (location) {
                    var _lat = location.location && location.location.coordinates[1];
                    var _lng = location.location && location.location.coordinates[0];
                    if (_lat && _lng) {
                        var latLng = new google.maps.LatLng(_lat, _lng);
                        bounds_1.extend(latLng);
                    }
                });
                _centerOnOrganization = false;
            }
            var locationUpdates = this.locationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            for (var i = 0, latLngLen = locationUpdates.length; i < latLngLen; i++) {
                var list = locationUpdates[i];
                bounds_1.extend(list);
            }
            var paverLocationUpdates = this.paverLocationUpdates.map(function (locationUpdate) {
                var _lat = locationUpdate.location && locationUpdate.location.coordinates[1];
                var _lng = locationUpdate.location && locationUpdate.location.coordinates[0];
                if (_lat && _lng) {
                    return new google.maps.LatLng(_lat, _lng);
                }
            });
            for (var i = 0, latLngLen = paverLocationUpdates.length; i < latLngLen; i++) {
                var list = paverLocationUpdates[i];
                bounds_1.extend(list);
            }
            if ((locationUpdates && locationUpdates.length) ||
                (paverLocationUpdates && paverLocationUpdates.length)) {
                _centerOnOrganization = false;
            }
            if (_centerOnOrganization) {
                this.centerOnOrganization();
            }
            this.map.fitBounds(bounds_1);
        }
    };
    DriverMapComponent.prototype.centerOnOrganization = function () {
        var _this = this;
        if (this.organizationLocationReq && typeof this.organizationLocationReq.unsubscribe === 'function') {
            this.organizationLocationReq.unsubscribe();
        }
        this.locationService.getLocationByAddress(this.organization.street + ", " + this.organization.location).subscribe(function (coords) {
            if (coords && coords[0] && coords[0].geometry) {
                var fallbackCenter = new google.maps.LatLng(coords[0].geometry.location.lat, coords[0].geometry.location.lng);
                _this.mapOptions = static_data_1.mapOptions({
                    zoom: 10,
                    center: fallbackCenter,
                    disableDefaultUI: true,
                    scrollwheel: false,
                    geoFallbackCenter: fallbackCenter
                }, {}, {
                    mapStyle: 'google-map-style'
                });
            }
        }, function (err) { return _this.errors = ['Cannot get your organizations location']; });
    };
    DriverMapComponent.prototype.initMap = function (event) {
        if (event) {
            this.map = event;
        }
        this.parseRoutes();
        this.centerMap();
    };
    DriverMapComponent.prototype.onCustomLocationMarkerInit = function (customMarker) {
        this.customLocationMarkers.push(customMarker);
    };
    DriverMapComponent.prototype.onCustomUpdateMarkerInit = function (customMarker) {
        this.customUpdateMarkers.push(customMarker);
    };
    DriverMapComponent.prototype.onCustomPaverUpdateMarkerInit = function (customMarker) {
        this.customPaverUpdateMarkers.push(customMarker);
    };
    DriverMapComponent.prototype.openDriverInfoWindow = function (index, update) {
        this.driverInfoWindowModel = update;
        if (update && update.driver) {
            var markers = lodash_1.filter(this.customUpdateMarkers, function (m) {
                return m.htmlEl.id === update.driver.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('driver-info-window', markers[0]);
            }
            this.selectedDriver.emit(update.driver);
        }
    };
    DriverMapComponent.prototype.openTruckInfoWindow = function (index, update) {
        this.truckInfoWindowModel = update;
        if (update && update.truck) {
            var markers = lodash_1.filter(this.customPaverUpdateMarkers, function (m) {
                return m.htmlEl.id === update.truck.id;
            });
            if (markers && markers.length) {
                this.nguiMapComponent.openInfoWindow('truck-info-window', markers[0]);
            }
        }
    };
    DriverMapComponent.prototype.openLocationInfoWindow = function (index, location) {
        this.locationInfoWindowModel = {
            title: location.displayName,
            address: location.street,
            city: location.city,
            state: location.state,
            zip: location.zip
        };
        this.nguiMapComponent.openInfoWindow('location-info-window', this.customLocationMarkers[index]);
    };
    DriverMapComponent.prototype.toggleTraffic = function () {
        if (this.mapControls.traffic) {
            this.mapControls.traffic = false;
        }
        else {
            this.mapControls.traffic = true;
        }
        this.cdr.detectChanges();
    };
    DriverMapComponent.prototype.toggleHeatmap = function () {
        if (this.mapControls.heatmap) {
            this.mapControls.heatmap = false;
            this.heatmap.setMap(null);
        }
        else {
            this.mapControls.heatmap = true;
            this.heatmap.setMap(this.map);
        }
        this.cdr.detectChanges();
    };
    DriverMapComponent.prototype.onHeatmapInitialized = function (e) {
        if (e === void 0) { e = null; }
        this.heatmap = e;
        this.coordinates = [];
        this.updateHeatmap();
    };
    DriverMapComponent.prototype.onMapIdle = function (e) {
        if (e === void 0) { e = null; }
        if (this.heatmap) {
            this.updateHeatmap();
        }
    };
    DriverMapComponent.prototype.updateHeatmap = function () {
        // this.locationUpdateService.coordinates(this.map.getBounds(), {
        //   jobevent: this.jobEvent && this.jobEvent.id,
        //   page_size: 2500
        // }).subscribe(coordinates => {
        //   this.coordinates = coordinates;
        //   this.heatmapPoints = coordinates && coordinates.map((coordinate) => {
        //     return { location: new google.maps.LatLng(coordinate.lon, coordinate.lat), weight: coordinate.weight || 1 };
        //   });
        //   if (this.heatmapPoints && this.heatmapPoints.length > 0) {
        //     this.heatmap.setData(this.heatmapPoints);
        //   }
        //   this.cdr.detectChanges();
        // }, err => { console.error(err); });
    };
    DriverMapComponent.prototype.changeHeatmapRadius = function (e) {
        if (e === void 0) { e = null; }
        if (e && e.target && this.mapControls) {
            this.mapControls.heatmapRadius = e.target.value;
            this.heatmap.set('radius', e.target.value);
            // this.cdr.detectChanges();
        }
    };
    DriverMapComponent.prototype.openDriverList = function () {
        this.driverListOpen = !this.driverListOpen;
    };
    DriverMapComponent.prototype.loadAssignments = function () {
        var _this = this;
        this.assignments.loading = true;
        this.startDate = new Date();
        this.startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(this.startDate);
        endDate.setHours(23, 59, 59, 999);
        if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
            this.assignmentsReq.unsubscribe();
        }
        this.assignmentsReq = this.assignmentService.list({
            include_trips: 'True',
            dispatched: 'True',
            jobevent__shift1_start__gte: this.startDate.toISOString(),
            jobevent__shift1_start__lte: endDate.toISOString()
        }).subscribe(function (assignments) {
            _this.assignments.items = assignments.map(function (assignment) {
                if (assignment.driver) {
                    assignment.driver = new driver_serializer_1.DriverSerializer().fromJson(assignment.driver);
                    var driverMatch = lodash_1.find(_this.driversList, assignment.driver.id);
                    if (!driverMatch) {
                        _this.driversList.push(assignment.driver.id);
                    }
                    if (assignment.trips && assignment.trips.length) {
                        if (!assignment.trips[0].completed) {
                            assignment.driver.trip = assignment.trips[0];
                            _this.durationInMinutes(assignment.driver.trip);
                        }
                    }
                }
                if (assignment.jobevent) {
                    var jobEventId = assignment.jobevent.toString();
                    var jobEventMatch = lodash_1.find(_this.activeJobEvents, { id: jobEventId });
                    if (!jobEventMatch) {
                        if (_this.jobEventReq && typeof _this.jobEventReq.unsubscribe === 'function') {
                            _this.jobEventReq.unsubscribe();
                        }
                        _this.jobEventReq = _this.jobEventService.getJobEvent(jobEventId).subscribe(function (jobEvent) {
                            _this.activeJobEvents.push(jobEvent);
                            _this.punchCardFlag = (jobEvent.invoiceType === 'hourly') ? true : false;
                            _this.loading = false;
                            var jobId = jobEvent.job && jobEvent.job.id;
                            var jobMatch = lodash_1.find(_this.activeJobs, { id: jobId });
                            if (!jobMatch) {
                                _this.activeJobs.push(jobEvent.job);
                            }
                        }, function (err) {
                            _this.errors = err;
                            _this.loading = false;
                        });
                    }
                }
                return assignment;
            });
            _this.metaData = _this.assignmentService.metaData;
            _this.getMapLocationUpdates();
        }, function (err) {
            _this.assignments.errors = err;
        }, function () {
            _this.assignments.loading = false;
        });
    };
    DriverMapComponent.prototype.durationInMinutes = function (obj) {
        obj.durationTimer = rxjs_1.timer(1, 60000);
        obj.durationTimer.subscribe(function (t) {
            var duration = moment().diff(obj.startTimeTimestamp, 'minutes') + ' mins';
            obj.duration = duration;
        });
    };
    DriverMapComponent.prototype.openAssignmentDetails = function (update, index, focusList) {
        if (focusList === void 0) { focusList = true; }
        if (window.innerWidth < 900) {
            var latLng = void 0;
            this.assignmentDetails = {};
            if (update && update.location && update.driver && update.driver.id) {
                this.assignmentDetails = lodash_1.find(this.assignments.items, { driver: { id: update.driver.id } });
                latLng = new google.maps.LatLng(update.location.coordinates[1], update.location.coordinates[0]);
            }
            else if (update && update.shift && update.driver && update.driver.id) {
                this.assignmentDetails = update;
                var locationUpdate = lodash_1.find(this.locationUpdates, { driver: { id: update.driver.id } });
                if (locationUpdate && locationUpdate['location']) {
                    latLng = new google.maps.LatLng(locationUpdate['location'].coordinates[1], locationUpdate['location'].coordinates[0]);
                }
            }
            if (this.map && typeof this.map.setCenter === 'function' && latLng) {
                this.map.setCenter(latLng);
                this.map.setZoom(17);
            }
            if (this.assignmentDetails && this.assignmentDetails.jobevent) {
                this.selectedJobEvent = lodash_1.find(this.activeJobEvents, { id: this.assignmentDetails['jobevent'] });
                this.selectedJob = lodash_1.find(this.activeJobs, { id: this.selectedJobEvent['job']['id'] });
                this.assignmentTitle.emit(this.assignmentDetails['driver']['profile']['name']);
            }
            else {
                this.errors = ['There was an issue getting the assignment details.'];
            }
        }
        else {
            this.openDriverInfoWindow(index, update);
            if (focusList && this.listComponent) {
                var driverId_1 = update && update.driver && update.driver.id;
                if (driverId_1) {
                    var drivers = this.listComponent.dataSource && this.listComponent.dataSource.data && this.listComponent.dataSource.data.value;
                    if (drivers) {
                        drivers.forEach(function (driver) {
                            if (driver.hasOwnProperty('highlighted') && driver.id !== driverId_1) {
                                driver['highlighted'] = false;
                            }
                            else if (driver.hasOwnProperty('highlighted') && driver.id === driverId_1) {
                                driver['highlighted'] = true;
                            }
                        });
                    }
                }
            }
        }
    };
    DriverMapComponent.prototype.focusDriver = function (driverId) {
        var update = lodash_1.find(this.locationUpdates, { driver: { id: driverId } });
        var index = lodash_1.findIndex(this.locationUpdates, { driver: { id: driverId } });
        if (update) {
            this.openAssignmentDetails(update, index, false);
        }
    };
    DriverMapComponent.prototype.resetMarkers = function () {
        this.customLocationMarkers = [];
        this.customUpdateMarkers = [];
        this.customPaverUpdateMarkers = [];
    };
    DriverMapComponent.prototype.getPreferences = function (preferenceKey, params) {
        var _this = this;
        if (preferenceKey === void 0) { preferenceKey = 'GeneralUserPreferences'; }
        if (params === void 0) { params = null; }
        if (this.preferenceReqs[preferenceKey] && typeof this.preferenceReqs[preferenceKey].unsubscribe === 'function') {
            try {
                this.preferenceReqs[preferenceKey].unsubscribe();
            }
            catch (e) { }
        }
        var currentUser = this.authenticationService.user();
        if (!params) {
            params = {
                name: preferenceKey,
                type: 'user',
                profile: currentUser.id
            };
        }
        this.preferenceReqs[preferenceKey] = this.preferenceService.list(params).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preferences[preferenceKey] = preferences[0];
                _this.parsePreferences(preferenceKey);
            }
            else if (preferenceKey === 'GeneralUserPreferences') {
                _this.loadingMapPreferences = false;
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Examine the found preference for the provided key to determine if it has
     * the necessary data.
     *
     * Once the preferences data is handled, update the map
     */
    DriverMapComponent.prototype.parsePreferences = function (preferenceKey) {
        if (preferenceKey === void 0) { preferenceKey = 'GeneralUserPreferences'; }
        if (preferenceKey === 'GeneralOrganizationPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
                mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
                this.markerType = this.preferences[preferenceKey].blob['markerType'];
            }
            this.markerStatus = 'each-segment';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
                this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
            }
            this.mapOptions = static_data_1.mapOptions({
                disableDefaultUI: true,
                scrollwheel: true,
                fullscreenControl: true,
                streetViewControl: false
            }, {}, {
                mapStyle: mapStyle
            });
            this.loadingMapPreferences = false;
        }
        if (preferenceKey === 'GeneralUserPreferences') {
            var mapStyle = 'google-map-style';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['mapStyle']) {
                mapStyle = this.preferences[preferenceKey].blob['mapStyle'];
            }
            this.markerType = 'driver-initials';
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerType']) {
                this.markerType = this.preferences[preferenceKey].blob['markerType'];
            }
            if (!this.markerStatus) {
                this.markerStatus = 'each-segment';
            }
            if (this.preferences[preferenceKey] && this.preferences[preferenceKey].blob && this.preferences[preferenceKey].blob['markerStatus']) {
                this.markerStatus = this.preferences[preferenceKey].blob['markerStatus'];
            }
            this.mapOptions = static_data_1.mapOptions({
                disableDefaultUI: true,
                scrollwheel: true
            }, {}, {
                mapStyle: mapStyle
            });
            this.loadingMapPreferences = false;
        }
    };
    return DriverMapComponent;
}());
exports.DriverMapComponent = DriverMapComponent;
