<div class="user-container">
  <notification *ngFor="let error of errors" translate>{{error}}</notification>
  <div class="user-data">
    <h2 translate>Notification Preferences</h2>
    <form #editNotifications="ngForm" class="notification-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <div class="row">
            <!-- <div class="new-connections" *ngIf="1===0">
              <div class="label-group">
                <label translate>New Connections</label>
                <label translate>Web</label>
                <label translate>Email</label>
                <label translate>SMS</label>
              </div>
              <div class="field-group new-connections">
                <div class="toggle-note" translate>
                  You'll receive a notificatin when a new connection has been
                  made for your organization.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_connections[web]" checked="{model.newConnections['web']}"
                    [(ngModel)]="model.newConnections['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_connections[email]" checked="{model.newConnections['email']}"
                    [(ngModel)]="model.newConnections['email']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_connections[sms]" checked="{model.newConnections['sms']}"
                    [(ngModel)]="model.newConnections['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="new-jobs">
              <div class="label-group">
                <label translate>New Jobs</label>
                <label translate>Web</label>
                <label translate>Email</label>
                <label translate>SMS</label>
              </div>
              <div class="field-group new-jobs">
                <div class="toggle-note" translate>
                  Know when a new job has been created or accepted.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_jobs[web]" checked="{model.newJobs['web']}" [(ngModel)]="model.newJobs['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_jobs[email]" checked="{model.newJobs['email']}" [(ngModel)]="model.newJobs['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_jobs[sms]" checked="{model.newJobs['sms']}" [(ngModel)]="model.newJobs['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="row" *ngIf="1===0">
            <div class="canceled-jobs">
              <div class="label-group">
                <label translate>Canceled Jobs</label>
              </div>
              <div class="field-group canceled-jobs">
                <div class="toggle-note" translate>
                  If a job or individual shift for a job has been canceled,
                  you'll be alerted right away.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="canceled_jobs[web]" checked="{model.canceledJobs['web']}" [(ngModel)]="model.canceledJobs['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="canceled_jobs[email]" checked="{model.canceledJobs['email']}"
                    [(ngModel)]="model.canceledJobs['email']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="canceled_jobs[sms]" checked="{model.canceledJobs['sms']}" [(ngModel)]="model.canceledJobs['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="1===0">
            <div class="not-on-shift">
              <div class="label-group">
                <label translate>Driver Not on Shift</label>
              </div>
              <div class="field-group not-on-shift">
                <div class="toggle-note" translate>
                  Be alerted when a driver is scheduled to work but has not
                  started his or her shift.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="not_on_shift[web]" checked="{model.notOnShift['web']}" [(ngModel)]="model.notOnShift['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="not_on_shift[email]" checked="{model.notOnShift['email']}" [(ngModel)]="model.notOnShift['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="not_on_shift[sms]" checked="{model.notOnShift['sms']}" [(ngModel)]="model.notOnShift['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="1===0">
            <div class="new-trucks">
              <div class="label-group">
                <label translate>New Trucks</label>
              </div>
              <div class="field-group new-trucks">
                <div class="toggle-note" translate>
                  Received a notification when a new truck is added.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_trucks[web]" checked="{model.newTrucks['web']}" [(ngModel)]="model.newTrucks['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_trucks[email]" checked="{model.newTrucks['email']}" [(ngModel)]="model.newTrucks['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_trucks[sms]" checked="{model.newTrucks['sms']}" [(ngModel)]="model.newTrucks['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div> -->
          
          <div class="row">
            <div class="changed-jobs">
              <div class="label-group">
                <label translate>Job Changes</label>
              </div>
              <div class="field-group new-drivers">
                <div class="toggle-note" translate>
                  Receive a notification when a job has changed.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="changed_jobs[web]" checked="{model.changedJobs['web']}" [(ngModel)]="model.changedJobs['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="changed_jobs[email]" checked="{model.changedJobs['email']}" [(ngModel)]="model.changedJobs['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="changed_jobs[sms]" checked="{model.changedJobs['sms']}" [(ngModel)]="model.changedJobs['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="new-drivers">
              <div class="label-group">
                <label translate>New Drivers</label>
              </div>
              <div class="field-group new-drivers">
                <div class="toggle-note" translate>
                  Receive a notification when a new driver is added.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_drivers[web]" checked="{model.newDrivers['web']}"
                    [(ngModel)]="model.newDrivers['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_drivers[email]" checked="{model.newDrivers['email']}" [(ngModel)]="model.newDrivers['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_drivers[sms]" checked="{model.newDrivers['sms']}"
                    [(ngModel)]="model.newDrivers['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="new-dispatches">
              <div class="label-group">
                <label translate>New Third-Party Hauler Dispatches</label>
              </div>
              <div class="field-group new-dispatches">
                <div class="toggle-note" translate>
                  Receive a notification when a third-party hauler is dispatched.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_dispatches[web]" checked="{model.newDispatches['web']}"
                    [(ngModel)]="model.newDispatches['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_dispatches[email]" checked="{model.newDispatches['email']}" [(ngModel)]="model.newDispatches['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_dispatches[sms]" checked="{model.newDispatches['sms']}"
                    [(ngModel)]="model.newDispatches['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="new-tickets">
              <div class="label-group">
                <label translate>New Tickets</label>
              </div>
              <div class="field-group new-tickets">
                <div class="toggle-note" translate>
                  Receive a notification when a ticket is created.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[web]" checked="{model.newAutoPilotTickets['web']}"
                    [(ngModel)]="model.newAutoPilotTickets['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[email]" checked="{model.newAutoPilotTickets['email']}" [(ngModel)]="model.newAutoPilotTickets['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[sms]" checked="{model.newAutoPilotTickets['sms']}"
                    [(ngModel)]="model.newAutoPilotTickets['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="new-shifts">
              <div class="label-group">
                <label translate>New Shifts</label>
              </div>
              <div class="field-group new-shifts">
                <div class="toggle-note" translate>
                  Receive a notification when a driver starts a shift.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_shifts[web]" checked="{model.newAutoPilotShifts['web']}"
                    [(ngModel)]="model.newAutoPilotShifts['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_shifts[email]" checked="{model.newAutoPilotShifts['email']}" [(ngModel)]="model.newAutoPilotShifts['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_shifts[sms]" checked="{model.newAutoPilotShifts['sms']}"
                    [(ngModel)]="model.newAutoPilotShifts['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="new-tickets">
              <div class="label-group">
                <label translate>Late to be Ticketed</label>
              </div>
              <div class="field-group new-tickets">
                <div class="toggle-note" translate>
                  Receive a notification when a driver is assigned to a job but has not started a trip within the defined time.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[web]" checked="{model.ticketNotification['web']}"
                    [(ngModel)]="model.ticketNotification['web']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[email]" checked="{model.ticketNotification['email']}" [(ngModel)]="model.ticketNotification['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_tickets[sms]" checked="{model.ticketNotification['sms']}"
                    [(ngModel)]="model.ticketNotification['sms']" class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="new-invoices" *ngIf="1===0">
              <div class="label-group">
                <label translate>New Invoices</label>
              </div>
              <div class="field-group new-invoices">
                <div class="toggle-note" translate>
                  Received a notification when a new invoice has been created.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_invoices[web]" checked="{model.newInvoices['web']}" [(ngModel)]="model.newInvoices['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_invoices[email]" checked="{model.newInvoices['email']}" [(ngModel)]="model.newInvoices['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="new_invoices[sms]" checked="{model.newInvoices['sms']}" [(ngModel)]="model.newInvoices['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="new-tickets">
              <div class="label-group">
                <label translate>GPS / Location Information Missing</label>
              </div>
              <div class="field-group new-tickets">
                <div class="toggle-note" translate>
                  Receive a notification when a driver is on shift but is not continuously sending GPS information.
                </div>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="gps_dispatch_notification[web]"
                    checked="{model.truckGpsDispatchNotification['web']}"
                    [(ngModel)]="model.truckGpsDispatchNotification['web']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="gps_dispatch_notification[email]"
                    checked="{model.truckGpsDispatchNotification['email']}"
                    [(ngModel)]="model.truckGpsDispatchNotification['email']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
                <label class="toggle-label toggles">
                  <input type="checkbox" name="gps_dispatch_notification[sms]"
                    checked="{model.truckGpsDispatchNotification['sms']}"
                    [(ngModel)]="model.truckGpsDispatchNotification['sms']"
                    class="toggle-input">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <button class="btn btn-primary" (click)="saveNotifications(); false" [disabled]="loading || !editNotifications.form.valid || editNotifications.pristine"
              [ngClass]="{loading: loading}" translate>
              Save Preferences
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>
  </div>
</div>
