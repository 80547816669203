"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./ruckit-confirm-dialog.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("@angular/flex-layout/extended");
var i4 = require("@angular/flex-layout/core");
var i5 = require("./ruckit-confirm-dialog.component");
var i6 = require("@angular/material/dialog");
var styles_RuckitConfirmDialogComponent = [i0.styles];
var RenderType_RuckitConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RuckitConfirmDialogComponent, data: {} });
exports.RenderType_RuckitConfirmDialogComponent = RenderType_RuckitConfirmDialogComponent;
function View_RuckitConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn accept"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-warning": 0, "btn-primary": 1 }), i1.ɵdid(4, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(5, { "btn-warning": 0, "btn-primary": 1 }), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn accept"; var currVal_1 = _ck(_v, 3, 0, (_co.attributes.accept !== "Save"), (_co.attributes.accept == "Save")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 5, 0, (_co.attributes.accept !== "Save"), (_co.attributes.accept == "Save")); var currVal_3 = "btn accept"; _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.attributes.accept; _ck(_v, 6, 0, currVal_4); }); }
function View_RuckitConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "button", [["class", "btn btn-cancel close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDialog.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "btn-delete": 0 }), i1.ɵdid(9, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(10, { "btn-delete": 0 }), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RuckitConfirmDialogComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn btn-cancel close"; var currVal_3 = _ck(_v, 8, 0, _co.attributes.isErrorBtn); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 10, 0, _co.attributes.isErrorBtn); var currVal_5 = "btn btn-cancel close"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_7 = _co.attributes.accept; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.attributes.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.attributes.body; _ck(_v, 3, 0, currVal_1); var currVal_6 = _co.attributes.close; _ck(_v, 11, 0, currVal_6); }); }
exports.View_RuckitConfirmDialogComponent_0 = View_RuckitConfirmDialogComponent_0;
function View_RuckitConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ruckit-confirm-dialog", [], null, null, null, View_RuckitConfirmDialogComponent_0, RenderType_RuckitConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.RuckitConfirmDialogComponent, [i6.MatDialogRef], null, null)], null, null); }
exports.View_RuckitConfirmDialogComponent_Host_0 = View_RuckitConfirmDialogComponent_Host_0;
var RuckitConfirmDialogComponentNgFactory = i1.ɵccf("ruckit-confirm-dialog", i5.RuckitConfirmDialogComponent, View_RuckitConfirmDialogComponent_Host_0, {}, {}, []);
exports.RuckitConfirmDialogComponentNgFactory = RuckitConfirmDialogComponentNgFactory;
