"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var ngx_device_detector_1 = require("ngx-device-detector");
// services
var truck_service_1 = require("./truck.service");
var truck_type_service_1 = require("./truck-type.service");
var custom_field_service_1 = require("../custom-fields/custom-field.service");
var api_service_1 = require("../shared/api.service");
var connection_service_1 = require("../connections/connection.service");
var shared_1 = require("../shared");
// types
var custom_field_1 = require("../custom-fields/custom-field");
var NewTruckDialogComponent = /** @class */ (function () {
    function NewTruckDialogComponent(dialogRef, truckService, elementRef, deviceDetectorService, customFieldService, translate, authentiationService) {
        this.dialogRef = dialogRef;
        this.truckService = truckService;
        this.elementRef = elementRef;
        this.deviceDetectorService = deviceDetectorService;
        this.customFieldService = customFieldService;
        this.translate = translate;
        this.authentiationService = authentiationService;
        this.loading = false;
        this.model = { tags: [], customFieldData: {} };
        this.errors = [];
        this.truckTypesDropdownConfig = {
            selectText: this.translate.instant('Select Truck Type'),
            loadingText: this.translate.instant('Loading Truck Types...'),
            noResultsText: this.translate.instant('No Truck Types'),
            nameProperty: 'name',
            service: truck_type_service_1.TruckTypeService,
            query: { ordering: 'name' },
        };
        // carriers dropdown config
        this.carriersConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'organization.carrier.id',
            selectText: this.translate.instant('Select Fleet'),
            loadingText: this.translate.instant('Loading Fleets...'),
            noResultsText: this.translate.instant('No Fleets'),
            service: connection_service_1.ConnectionService,
            serviceFunction: 'list',
            query: {
                ordering: 'organization__name',
                is_carrier: 'True',
                allow_dispatch: 'True',
            },
            prefilledOptions: [],
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop(),
        };
    }
    NewTruckDialogComponent.prototype.ngOnInit = function () {
        var organization = this.authentiationService.getOrganization();
        if (organization && organization.carrier) {
            this.carriersConfig.prefilledOptions.push(organization.carrier);
            if (this.carrierId) {
                this.model.carrier = this.carrierId;
            }
            else {
                this.carrierId = organization.carrier.id;
                this.model.carrier = organization.carrier.id;
            }
        }
        this.getCustomFields();
    };
    NewTruckDialogComponent.prototype.getCustomFields = function () {
        var _this = this;
        var type = custom_field_1.CustomFieldKind.Truck;
        this.customFieldService
            .getFieldsForKind(type)
            .subscribe(function (fields) { return (_this.customFields = fields); });
    };
    NewTruckDialogComponent.prototype.tagChange = function (tags) {
        this.model['tags'] = tags.map(function (t) { return t.name; });
    };
    NewTruckDialogComponent.prototype.selectTruckType = function (truckType) {
        this.selectedTruckType = truckType;
        this.model.truckType = truckType;
    };
    NewTruckDialogComponent.prototype.selectCarrier = function (carrier) {
        this.carrierId = carrier.id;
        this.model.carrier = carrier.id;
    };
    NewTruckDialogComponent.prototype.fileChange = function (e) {
        var _this = this;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = function (_e) {
            _this.model.image = _e.target['result'];
        };
        reader.readAsDataURL(file);
    };
    NewTruckDialogComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        var fileInput = this.elementRef.nativeElement.querySelector('input[name="file"]');
        var model = lodash_1.cloneDeep(this.model);
        delete model.image;
        this.truckService.save(model).subscribe(function (truck) {
            if (_this.model.image && truck && truck.id) {
                _this.truckService
                    .uploadImage('PUT', [truck.id], fileInput.files)
                    .subscribe(function () {
                    if (typeof _this.callback === 'function') {
                        _this.callback();
                    }
                }, function (err) {
                    console.error('err', err);
                });
            }
            else {
                if (typeof _this.callback === 'function') {
                    _this.callback();
                }
            }
            _this.dialogRef.close();
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    return NewTruckDialogComponent;
}());
exports.NewTruckDialogComponent = NewTruckDialogComponent;
