import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { combineLatest as observableCombineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { reject } from 'lodash';

// services
import { JobService } from '../../jobs/job.service';
import { AttachmentService } from '../../attachments/attachment.service';
import { TripService } from '../trip.service';
import { JobEventService } from '../../job-events/job-event.service';

// models
import { Job } from '../../jobs/job';
import { Carrier } from '../../carriers/carrier';
import { Truck } from '../../trucks/truck';
import { Driver } from '../../drivers/driver';
import { JobEvent } from '../../job-events/job-event';
import { CondensedTrip } from './../condensed-trip';

// components
import { MultipleImagesEditComponent } from '../../shared/multiple-images-edit/multiple-images-edit.component';

// constants
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { User } from '../../users/user';
import { AuthenticationService, parseErrors } from '../../shared';
import {
  CARRIER_DROPDOWN_CONFIG,
  DRIVER_DROPDOWN_CONFIG,
  JOB_DROPDOWN_CONFIG,
  JOB_EVENTS_DROPDOWN_CONFIG,
  TRUCK_DROPDOWN_CONFIG,
} from './dropdownsConfig';
import {
  TRIPSIGNATUREIMAGETYPE,
  TRIPTICKETIMAGETYPE,
} from '../../app.constants';

// serializers
import { JobSerializer } from '../../jobs/job.serializer';
import { ProjectSerializer } from '../../projects/project.serializer';

// validators
import { globalTripTimeValidator } from '../trip-time-validators';

@Component({
  selector: 'new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.scss'],
})
export class NewTripComponent implements OnInit {
  user: User;
  jobDates: Date[] = [];
  errors = [];
  job: Job;
  carrier: Carrier;
  truck: Truck;
  driver: Driver;
  jobEvent: JobEvent;
  trip: CondensedTrip = new CondensedTrip({});

  returnTo: string;

  loading = false;
  daysLoading = false;

  @ViewChild('editImagesContainer', { static: false })
  editImagesContainer: MultipleImagesEditComponent;
  loadingTicketImages = [];
  unloadingTicketImages = [];
  loadingSignatures = [];
  unloadingSignatures = [];
  uploading = false;
  skipResetFormOnJobChange = false;

  newTripForm: FormGroup;

  @ViewChild('jobDropdown', { static: false })
  jobsDropdown: RuckitDropdownComponent;
  jobDropdownConfig = JOB_DROPDOWN_CONFIG(this.translate);

  @ViewChild('jobEventsDropdown', { static: false })
  jobEventsDropdown: RuckitDropdownComponent;
  jobEventsDropdownConfig = JOB_EVENTS_DROPDOWN_CONFIG(this.translate);

  @ViewChild('carriersDropdown', { static: false })
  carriersDropdown: RuckitDropdownComponent;
  carrierDropdownConfig = CARRIER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('driversDropdown', { static: false })
  driversDropdown: RuckitDropdownComponent;
  driverDropdownConfig = DRIVER_DROPDOWN_CONFIG(this.translate);

  @ViewChild('truckDropdown', { static: false })
  truckDropdown: RuckitDropdownComponent;
  truckDropdownConfig = TRUCK_DROPDOWN_CONFIG(this.translate);

  fieldsToCheck = [
    'activeTracking',
    'ticketImage',
    'signatureImage',
    'ticketNumber',
    'weight',
  ];

  requiredImages = {
    loadingImages: false,
    unloadingImages: false,
    loadingSignature: false,
    unloadingSignature: false,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tripService: TripService,
    private attachmentsService: AttachmentService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params,
      this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    combinedParams.subscribe((result) => {
      this.returnTo = result && result.qparams['returnTo'];
      if (result && result.params['jobId']) {
        const jobId = result.params['jobId'];
        const jobEventId = result.params['jobEventId'];
        this.getJob(jobId, jobEventId);
      }
    });
    this.user = this.authenticationService.user();
    this.carrierDropdownConfig.query = {
      exclude: this.user.organization.id,
    };
    this.carrierDropdownConfig.prefilledOptions.push(this.user.organization);
    this.initForm();
  }

  submitTrip(): void {
    this.errors = [];
    const newTrip = this.parseTrip();

    this.loading = true;
    this.tripService.saveWithCheckinsAndAttachments(newTrip).subscribe(
      () => {
        if (this.returnTo) {
          this.router.navigate([this.returnTo]);
        } else {
          this.router.navigate(['/trips']);
        }
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  parseTrip(): CondensedTrip {
    const formTrip = this.newTripForm.value;
    const loadingImages = [
      ...this.loadingTicketImages,
      ...this.loadingSignatures.map((signature: File) => {
        Object.assign(signature, {
          tempType: TRIPSIGNATUREIMAGETYPE,
        });
        return signature;
      }),
    ];
    const unloadingImages = [
      ...this.unloadingTicketImages,
      ...this.unloadingSignatures.map((signature) => {
        Object.assign(signature, {
          tempType: TRIPSIGNATUREIMAGETYPE,
        });
        return signature;
      }),
    ];

    const parsedLoadingImages = loadingImages.map((img: any) =>
      this.attachmentsService.parseAttachment(
        img,
        img.tempType ? img.tempType : TRIPTICKETIMAGETYPE,
        null
      )
    );

    const parsedUnloadingImages = unloadingImages.map((img: any) =>
      this.attachmentsService.parseAttachment(
        img,
        img.tempType ? img.tempType : TRIPTICKETIMAGETYPE,
        null
      )
    );

    const trip: CondensedTrip = {
      ...this.newTripForm.value,
      job: formTrip.job.id,
      jobEvent: formTrip.jobEvent.id,
      jobevent: formTrip.jobEvent.id,
      carrier: formTrip.carrier.id,
      driver: formTrip.driver.id,
      truck: formTrip.truck.id,
      startTime: this.parseDateTime(formTrip.start.date, formTrip.start.time),
      endTime: this.parseDateTime(formTrip.end.date, formTrip.end.time),
      loadingCheckin: {
        ...formTrip.loadingCheckin,
        attachments: parsedLoadingImages,
        date: this.parseDateTime(
          formTrip.loadingCheckin.date,
          formTrip.loadingCheckin.time
        ),
      },
      unloadingCheckin: {
        ...formTrip.unloadingCheckin,
        date: this.parseDateTime(
          formTrip.unloadingCheckin.date,
          formTrip.unloadingCheckin.time
        ),
        attachments: parsedUnloadingImages,
      },
    };
    if (trip.loadingCheckin['images']) {
      delete trip.loadingCheckin['images'];
    }
    if (trip.unloadingCheckin['images']) {
      delete trip.unloadingCheckin['images'];
    }
    return trip;
  }

  parseDateTime = (date: any, time: string): string => {
    return !!date && !!time
      ? moment(`${moment(date).format('YYYY-MM-DD')} ${time}`).toISOString()
      : null;
  };

  getJob(id: string, jobEventId: string): void {
    if (id && jobEventId) {
      this.skipResetFormOnJobChange = true;
    }
    if (id) {
      this.loading = true;
      this.jobService.get(id).subscribe(
        (job) => {
          this.job = job;
          if (this.job) {
            this.jobsDropdown.options = reject(
              this.jobsDropdown.options,
              this.job
            );
            this.jobsDropdown.options.unshift(this.job);
            this.getJobDays(this.job && this.job.id);
            this.jobsDropdown.selectedOption = this.job;
          } else {
            let project = new ProjectSerializer().fromJson({
              id: this.job.project.id,
              name: this.job.project.name,
            });
            this.job = new JobSerializer().fromJson({
              id: this.job.id,
              name: this.job.name,
              project: project,
            });
            this.jobsDropdown.options.unshift(this.job);
            this.getJobDays(this.job && this.job.id);
            this.jobsDropdown.selectedOption = this.job;
          }
          this.loading = false;
        },
        (err) => {
          this.errors = err;
          this.loading = false;
        }
      );
    }
    if (jobEventId) {
      this.jobEventService.getJobEvent(jobEventId).subscribe(
        (jobEvent) => {
          this.jobEventsDropdown.selectedOption = jobEvent;
          this.jobEvent = jobEvent;
        },
        // error
        () => {},
        // complete
        () => {
          this.newTripFormSetRequiredFields(this.jobEvent);
          this.setRequiredImages(this.jobEvent);

          const query = {
            ...this.carrierDropdownConfig.query,
            ...{ carriers_for_jobevent: this.jobEvent.id },
          };

          this.carriersDropdown.config.query = query;
          this.carriersDropdown.getRecords(query);
        }
      );
    }
  }

  onDateChange(args: string[], dates: Date[]) {
    if (args && args.length > 0 && dates) {
      this.newTripFormPatchValueNested(args, dates[0]);
    }
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        if (this.job !== e) {
          this.job = e;
          this.jobEventsDropdown.getRecords({
            job: e.id,
          });
          this.resetForm();
          this.getJobDays(this.job && this.job.id);
        }
        break;
      case 'jobEvent':
        if (!this.jobEvent || this.jobEvent.id !== e.id) {
          this.jobEvent = e;
          this.newTripFormSetRequiredFields(e);
          this.setRequiredImages(e);

          const query = {
            ...this.carrierDropdownConfig.query,
            ...{ carriers_for_jobevent: e.id },
          };

          this.carriersDropdown.config.query = query;
          this.carriersDropdown.getRecords(query);
          // preselect current user's carrier
          if (this.carrier === null || this.carrier === undefined) {
            this.carriersDropdown.setSelectedOption(this.user.organization);
          }
        }
        break;
      case 'carrier':
        const carrier = e.carrier;
        if (this.carrier === null || this.carrier.id !== carrier.id) {
          this.carrier = carrier;
          this.driver = null;
          this.truck = null;
          this.driversDropdown.getRecords({
            carrier: carrier.id,
            shared_jobevent: this.jobEvent.id,
          });
          this.truckDropdown.getRecords({
            carrier: carrier.id,
          });
        }
        break;
      case 'driver':
        this.driver = e;
        break;
      case 'truck':
        this.truck = e;
        break;
    }
    this.newTripFormPatchValue(filterType, e);
  }

  getJobDays(jobId: string): void {
    this.jobService.getDays(jobId).subscribe(
      (days) => {
        this.jobDates = days.map((day) => moment(day).toDate());
        this.daysLoading = false;
      },
      (err) => {
        this.errors = err;
        this.daysLoading = false;
      }
    );
  }

  handleImagesDialogVisibility() {
    this.editImagesContainer.handleVisibility();
  }

  parseImageOnChange(image: any) {
    if (image) {
      let reader = new FileReader();
      reader.onload = () => {
        image.file = reader.result;
        image.src = reader.result;
      };
      reader.readAsDataURL(image);
      return image;
    }
    return image;
  }

  onImagesChangeParser = (images: any[]): any[] =>
    images
      .filter((img: any) => img.isDeleted !== true)
      .map((img: any) =>
        img.isNew || img.isEdited
          ? Object.assign(img, {
              tempPath: URL.createObjectURL(this.parseImageOnChange(img)),
            })
          : this.parseImageOnChange(img)
      );

  onImagesChange(
    type:
      | 'loadingTicketImages'
      | 'unloadingTicketImages'
      | 'unloadingSignatures'
      | 'loadingSignatures',
    checkin: 'loadingCheckin' | 'unloadingCheckin',
    images: any[]
  ) {
    const parsedImages = this.onImagesChangeParser(images);
    this[type] = parsedImages;
    this.newTripFormPatchValueNested(
      [checkin, type.includes('Signature') ? 'signatures' : 'images'],
      [...parsedImages]
    );
  }

  fileChange(type: any, img: any): void {
    this.onImagesChange(
      type,
      type === 'loading' ? 'loadingCheckin' : 'unloadingCheckin',
      [img]
    );
  }

  fileUploading(uploading: boolean) {
    this.uploading = uploading;
  }

  newTripFormPatchValue(key, value): void {
    this.newTripForm.patchValue({
      [key]: value,
    });
  }

  newTripFormPatchValueNested(keys: string[], value: any) {
    if (keys && keys.length > 0 && value) {
      let objToPatch = {};
      keys.reduce((prev, curr, i) => {
        return (prev[curr] = i + i === keys.length ? value : {});
      }, objToPatch);
      this.newTripForm.patchValue(objToPatch);
    }
  }

  newTripFormSetRequiredFields(jobEvent: JobEvent) {
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'loadingCheckin',
        field,
        jobEvent.job.checkinOptions[`${field}Config`] === 'required'
      );
    });
    this.fieldsToCheck.forEach((field) => {
      this.newTripFormValidatorsSwitch(
        'unloadingCheckin',
        field,
        jobEvent.job.checkoutOptions[`${field}Config`] === 'required'
      );
    });

    this.updateFormValueAndValidity(this.newTripForm);
  }

  updateFormValueAndValidity(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];
      if (
        abstractControl instanceof FormGroup ||
        abstractControl instanceof FormArray
      ) {
        this.updateFormValueAndValidity(abstractControl);
      } else {
        abstractControl.updateValueAndValidity();
      }
    });
  }

  newTripFormSetValidators(field: string[], validators) {
    this.newTripForm.get(field).setValidators(validators);
  }

  newTripFormClearValidators(field: string[]) {
    this.newTripForm.get(field).clearValidators();
  }

  newTripFormValidatorsSwitch(parent: string, key: string, required: boolean) {
    switch (key) {
      case 'activeTracking':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'date'],
            [Validators.required, Validators.minLength(1)]
          );
          this.newTripFormSetValidators(
            [parent, 'time'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'date']);
          this.newTripFormClearValidators([parent, 'time']);
        }
        break;

      case 'ticketImage':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'images'],
            [Validators.required]
          );
        } else {
          this.newTripFormClearValidators([parent, 'images']);
        }
        break;

      case 'signatureImage':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'signatures'],
            [Validators.required]
          );
        } else {
          this.newTripFormClearValidators([parent, 'signatures']);
        }
        break;

      case 'ticketNumber':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'ticketNumber'],
            [Validators.required, Validators.minLength(1)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'ticketNumber']);
        }
        break;

      case 'weight':
        if (required) {
          this.newTripFormSetValidators(
            [parent, 'weight'],
            [Validators.required, Validators.min(0)]
          );
        } else {
          this.newTripFormClearValidators([parent, 'weight']);
        }
        break;

      default:
        break;
    }
  }

  setRequiredImages(jobEvent: JobEvent) {
    this.requiredImages = {
      loadingImages:
        jobEvent.job.checkinOptions.ticketImageConfig === 'required',
      unloadingImages:
        jobEvent.job.checkoutOptions.ticketImageConfig === 'required',
      loadingSignature:
        jobEvent.job.checkinOptions.signatureImageConfig === 'required',
      unloadingSignature:
        jobEvent.job.checkoutOptions.signatureImageConfig === 'required',
    };
  }

  // Start time: Required, always
  // Loading Checkin, optional overall, date/time must be later than start time and earlier than unloading date/time and end time
  // Unloading Checkin, optional overall, date/time must be later than start time and loading checkin date/time, and earlier than end time
  // End time: Optional, must be later than start time, loading / unloading date/time.

  initForm() {
    this.newTripForm = this.fb.group(
      {
        job: [this.job, Validators.required],
        jobEvent: [this.jobEvent, Validators.required],
        driver: [this.driver, Validators.required],
        truck: [this.truck, Validators.required],
        carrier: [this.carrier, Validators.required],
        start: this.fb.group({
          date: [this.trip.startDate, [Validators.required]],
          time: [this.trip.startTime, [Validators.required]],
        }),
        end: this.fb.group({
          date: [this.trip.endDate, []],
          time: [this.trip.endTime, []],
        }),

        loadingCheckin: this.fb.group({
          ticketNumber: [this.trip.loadingCheckin.ticketNumber],
          weight: [this.trip.loadingCheckin.weight],
          date: [this.trip.loadingCheckin.date],
          time: [this.trip.loadingCheckin.time],
          images: [null],
          signatures: [null],
        }),

        unloadingCheckin: this.fb.group({
          ticketNumber: [this.trip.unloadingCheckin.ticketNumber],
          weight: [this.trip.unloadingCheckin.weight],
          date: [this.trip.unloadingCheckin.date],
          time: [this.trip.unloadingCheckin.time],
          images: [null],
          signatures: [null],
        }),
      },
      { validators: globalTripTimeValidator }
    );
  }

  resetForm() {
    if (this.skipResetFormOnJobChange) {
      this.skipResetFormOnJobChange = false;
    } else {
      this.jobEvent = null;
    }
    this.carrier = null;
    this.driver = null;
    this.truck = null;
    this.newTripForm.reset();
  }

  get startDate() {
    return this.newTripForm.get(['start', 'date']).value;
  }

  get endDate() {
    return this.newTripForm.get(['end', 'date']).value;
  }

  get loadingCheckingDate() {
    return this.newTripForm.get(['loadingCheckin', 'date']).value;
  }

  get unloadingCheckingDate() {
    return this.newTripForm.get(['unloadingCheckin', 'date']).value;
  }

  get loadingCheckingTime() {
    return this.newTripForm.get(['loadingCheckin', 'time']).value;
  }

  get unloadingCheckingTime() {
    return this.newTripForm.get(['unloadingCheckin', 'time']).value;
  }
}
