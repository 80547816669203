import {
  Component, OnInit, Input, Output, EventEmitter, Injector, OnChanges,
  SimpleChanges, NgZone, OnDestroy
} from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { find as _find, get as _get, uniq, pull, differenceWith, clone } from 'lodash';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ScrollDispatcher, CdkScrollable } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, delay, tap, map, takeUntil } from 'rxjs/operators';

import { parseErrors } from '../api.service';
import { FilterOption } from './filter-option';
import { KeyCodes } from '../enums/key-codes.enum';

@Component({
  selector: 'filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss']
})
export class FiltersPanelComponent implements OnInit, OnChanges, OnDestroy {
  form: FormGroup;
  @Input() appliedFilters: FilterOption[] = [];
  @Input() availableFilters: FilterOption[] = [];
  @Input() allowSearch = true;
  @Input() customFilter = false;
  @Input() search = '';
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  @Output() openCustomFilter: EventEmitter<any> = new EventEmitter();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  protected _onDestroy = new Subject<void>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private injector: Injector,
    private ngZone: NgZone,
    public scroll: ScrollDispatcher
  ) {
    this.allSubscriptionsToUnsubscribe.push(
      this.scroll.scrolled().subscribe((data: CdkScrollable) => {
        if (data) {
          let filterKey: string;
          const element = data.getElementRef();
          if (element && element.nativeElement) {
            if (element.nativeElement.scrollTop >= (element.nativeElement.scrollHeight * 0.25)) {
              filterKey = element.nativeElement.getAttribute('data-filter');
              if (filterKey) { this.getNextPage(filterKey); }
            }
          }
        }
      })
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['search']) {
      if (this.searchField) {
        this.searchField.setValue(changes['search'].currentValue);
      }
      this.searchChange.emit(changes['search'].currentValue);
    }
    if (changes['appliedFilters']) {
      const previousValue = changes['appliedFilters'].previousValue;
      const currentValue = changes['appliedFilters'].currentValue;
      const removedFilters = <FilterOption[]>differenceWith(
        previousValue, currentValue, this.compareKeys
      );
      for (const filterOption of removedFilters) {
        filterOption.values = [];
        this.updateControl(filterOption.key, filterOption.values);
        const filter = _find(this.appliedFilters, { key: filterOption.key });
        if (filter) {
          this.appliedFiltersChange.emit(pull(this.appliedFilters, filter));
        }
      }
      this.availableFiltersChange.emit(this.availableFilters);
      this.appliedFiltersChange.emit(this.appliedFilters);
    }
  }

  get searchField() {
    return this.form && this.form.get('search');
  }

  compareIds(id1: any, id2: any): boolean {
    let a1 = id1;
    let a2 = id2;
    if (id1 && id1.constructor.name === 'Array' && id1.length > 0) {
      a1 = '' + id1[0];
    }
    if (id2 && id2.constructor.name === 'Array' && id2.length > 0) {
      a2 = '' + id2[0];
    }

    return a1 === a2;
  }

  compareKeys(a: any, b: any): boolean {
    return a.key === b.key;
  }

  onSearchboxKeypress(event: KeyboardEvent) {
    if (event.code === KeyCodes.Enter) {
      if (this.searchField.value && this.searchField.value.trim()) {
        this.searchChange.emit(event.target['value']);
      }
      event.preventDefault();
    }
  }

  onBlur() {
    if (this.searchField.value && this.searchField.value.trim()) {
      this.searchChange.emit(this.searchField.value);
    }
  }

  onChanges(): void {
    if (this.form) {

      for (let filter of this.availableFilters) {
        this.allSubscriptionsToUnsubscribe.push(
          this.form.get(filter.key).valueChanges.subscribe(value => {
            if (value || value === false) {
              let removeFilter = false;
              if (filter) {
                if (!filter.values) { filter.values = []; }
                if (!filter.displayValues) { filter.displayValues = []; }

                if (filter.filterType === 'autocomplete') {
                  filter.values = uniq(filter.values.concat(value));
                } else if (filter.filterType === 'checkbox') {
                  filter.values = value;
                  if (value !== true) {
                    pull(this.appliedFilters, _find(this.appliedFilters, { key: filter.key }));
                    removeFilter = true;
                  }
                } else if (filter.filterType === 'text' && filter.customField) {
                  filter.values = (value && typeof value === 'string') ? [value] : value;
                  if (value === false) {
                    pull(this.appliedFilters, _find(this.appliedFilters, { key: filter.key }));
                    removeFilter = true;
                  }
                } else {
                  if (value && typeof value === 'string') {
                    if (value && value.trim()) {
                      filter.values = [value];
                    } else {
                      pull(this.appliedFilters, _find(this.appliedFilters, { key: filter.key }));
                      removeFilter = true;
                    }
                  } else {
                    filter.values = value;
                  }
                }
                if (filter.multiple && !filter.values.length) {
                  pull(this.appliedFilters, filter);
                } else if (!removeFilter) {
                  if (!_find(this.appliedFilters, { key: filter.key })) {
                    this.appliedFilters.push(filter);
                  }
                }
              }
              for (const selectedValue of filter.values) {
                const option = _find(filter.options, { id: selectedValue });
                if (option) {
                  if (filter.multiple) {
                    filter.displayValues = filter.displayValues.concat(option.name);
                  } else {
                    filter.displayValues = [option.name];
                  }
                }
              }
            } else {
              pull(this.appliedFilters, filter);
            }
            this.appliedFiltersChange.emit(clone(this.appliedFilters));
          })
        );
      }
    }
  }

  buildForm(): void {
    this.form = this.fb.group({ search: [''] });
    this.addFilterControls();
    this.onChanges();
  }

  addFilterControls() {
    this.availableFilters.map(filter => {
      if (filter.service) { this.getOptions(filter); }
      const control: FormControl = new FormControl(filter.values);
      this.form.controls[filter.key] = control;
      this.form.controls[filter.searchKey] = new FormControl();
      this.form.controls[filter.searchKey].valueChanges
        .pipe(
          tap(() => filter.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(500),
          map(search => {
            this.getOptions(filter, false, search);
          }),
          delay(200)
        ).subscribe(() => {
          filter.searching = false;
        }, error => {
          filter.searching = false;
        });
    });
  }

  getOptions(filter: FilterOption, append = false, search = null): void {
    let service = this.injector.get<any>(filter.service);
    if (!service || (!service.list && !service.listFilters)) { return; }
    let request;
    filter.loading = true;
    if (filter.request) { filter.request.unsubscribe(); }

    if (!filter.hasOwnProperty('slug') || filter.slug === undefined || !filter.slug) {
      request = service.list({
        ordering: filter.ordering,
        search: search
      });
    } else {
      request = service.listFilters(filter.slug, {
        ordering: filter.ordering,
        filter_search: search,
        page_size: 10
      });
    }

    filter.request = request.subscribe(options => {
      if (append) {
        filter.options = filter.options.concat(options);
      } else {
        filter.options = options;
      }
      if (filter.idProperty) {
        filter.options.forEach(option => {
          option.id = _get(option, filter.idProperty, option.id);
        });
      }
      filter.count = service.count;
      filter.nextUri = service.nextUri;
      filter.loading = false;
    }, err => {
      filter.errors = parseErrors(err);
      filter.loading = false;
    });
  }

  getNextPage(filterKey: string): void {
    const filter = _find(this.availableFilters, { key: filterKey });
    if (filter) {
      let service = this.injector.get<any>(filter.service);
      let request = service.listNext(filter.nextUri);
      if (!request || filter.loading) { return; }

      this.ngZone.run(() => {
        filter.loading = true;
        if (filter.request) { filter.request.unsubscribe(); }

        filter.request = request.subscribe(options => {
          // otherwise there is a problem if filters use the same service. The service is instantiated only once.
          filter.nextUri = service.nextUri;
          filter.options = filter.options.concat(options);
          if (filter.idProperty) {
            filter.options.forEach(option => {
              option.id = _get(option, filter.idProperty, option.id);
            });
          }
          filter.count = filter.service.count;
          filter.loading = false;
        }, err => {
          filter.errors = parseErrors(err);
          filter.loading = false;
        });
      });
    }
  }

  resetFilters(): void {
    this.appliedFilters = [];
    this.search = '';
    this.appliedFiltersChange.emit(this.appliedFilters);
    this.searchChange.emit(this.search);
    this.availableFilters.forEach(filter => filter.values = null);
    this.buildForm();
  }

  addFilterOption(event, filterOption: FilterOption): void {
    if (!filterOption.displayValues) { filterOption.displayValues = []; }
    filterOption.values = uniq(filterOption.values.concat(event.option.value));
    for (const selectedValue of filterOption.values) {
      const option = _find(filterOption.options, { id: selectedValue });
      if (option) {
        filterOption.displayValues.concat(option.name);
      }
    }
    this.updateControl(filterOption.key, filterOption.values);
  }

  removeFilterOption(value, filterOption: FilterOption): void {
    if (filterOption.values) {
      pull(filterOption.values, value);
      this.updateControl(filterOption.key, filterOption.values);
    }
  }

  updateControl(key: string, values: any[]): void {
    let control = this.form.get(key);
    if (control) { control.setValue(values); }
  }

  setValues(): void {
    for (const filter of this.appliedFilters) {
      this.form.get(filter.key).setValue(filter.values, { emitEvent: false });
    }
  }

  setValue(event): void {
    if (!event || !event.source || !event.source.ngControl) {
      return;
    }
    let filterKey = event.source.ngControl.name;
    let values = (event.value && typeof event.value === 'string') ? [event.value] : event.value;
    let appliedFilter = _find(this.appliedFilters, { key: filterKey });
    let availableFilter = _find(this.availableFilters, { key: filterKey });
    for (const selectedValue of values) {
      const option = _find(availableFilter.options, { id: selectedValue });
      if (option) {
        if (appliedFilter.multiple) {
          appliedFilter.displayValues = availableFilter.displayValues.concat(option.name);
          availableFilter.displayValues = availableFilter.displayValues.concat(option.name);
        } else {
          appliedFilter.displayValues = [option.name];
          availableFilter.displayValues = [option.name];
        }
      }
    }
    appliedFilter.values = values;
    availableFilter.values = values;
  }

  onDateChanged(filterKey: string, selectedDates): void {
    let appliedFilter = _find(this.appliedFilters, { key: filterKey });
    let availableFilter = _find(this.availableFilters, { key: filterKey });
    if (!appliedFilter) {
      this.appliedFilters.push(availableFilter);
      appliedFilter = _find(this.appliedFilters, { key: filterKey });
    }

    appliedFilter.displayValues = selectedDates;
    appliedFilter.values = selectedDates.map(date => date.toISOString());
    this.appliedFiltersChange.emit(clone(this.appliedFilters));
  }
}
