"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JOBWEIGHTOPTIONS = [
    { value: 'ton', label: 'Tons', name: 'Ton', selected: true },
    { value: 'metric-tons', label: 'Metric Tons', name: 'Metric Ton' },
    { value: 'pound', label: 'Pounds', name: 'Pound' },
    { value: 'cuyds', label: 'Cu. Yds.', name: 'Cubic Yard' },
    { value: 'bushel', label: 'Bushels', name: 'Bushel' },
    { value: 'bag', label: 'Bags', name: 'Bag' },
];
exports.TRIPTICKETIMAGETYPE = 'ticket_image';
exports.TRIPSIGNATUREIMAGETYPE = 'signature_image';
exports.LOADINGTRIPTICKETIMAGETYPE = 'loading_ticket_image';
exports.LOADINGTRIPSIGNATUREIMAGETYPE = 'loading_signature_image';
exports.UNLOADINGTRIPTICKETIMAGETYPE = 'unloading_ticket_image';
exports.UNLOADINGTRIPSIGNATUREIMAGETYPE = 'unloading_signature_image';
// organization attachments
exports.ORGANIZATIONIMAGETYPE = 'organization_image';
exports.ORGANIZATIONINSURANCE = 'insurance_document';
exports.ORGANIZATIONBUSINESSLICENSE = 'business_license';
exports.ORGANIZATIONOVERWEIGHTPERMIT = 'overweight_permit';
// dialogs
exports.DEFAULT_DIALOG_SIZE = {
    width: '430px',
    height: '250px',
};
