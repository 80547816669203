"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var RuckitConfirmDialogComponent = /** @class */ (function () {
    function RuckitConfirmDialogComponent(confirmDialog) {
        this.confirmDialog = confirmDialog;
        this.attributes = {};
        this.attributes = {
            title: 'Confirm your action!',
            body: 'Are you sure you wish to do this?',
            close: 'Cancel',
            accept: 'Continue',
            isErrorBtn: false
        };
    }
    return RuckitConfirmDialogComponent;
}());
exports.RuckitConfirmDialogComponent = RuckitConfirmDialogComponent;
