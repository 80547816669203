<div class="user-container">
  <div class="user-data">
    <h2 translate>General Preferences</h2>
    <form #editPreferences="ngForm" class="preferences-form" *ngIf="user">
      <div class="form-block">
        <div class="left-side">
          <!-- dispatch preferences -->
          <div class="row">
            <div class="dispatch-preferences">
              <div class="label-group">
                <label translate>Dispatch Preference</label>
              </div>
              <div class="field-group new-connections">
                <div class="note" translate>
                  Select dispatch mode to set up an assignment.
                </div>
                <div
                  class="radio-buttons"
                  *ngIf="preference && preference.blob"
                >
                  <label
                    attr.data-label="{{ 'All Day' | translate }}"
                    for="all-day"
                  >
                    <input
                      type="radio"
                      id="all-day"
                      name="all-day"
                      value="all-day"
                      [(ngModel)]="preference.blob['dispatchLoadType']"
                      (change)="selectDispatchLoadType($event)"
                    />
                  </label>
                  <label
                    attr.data-label="{{ 'By Load' | translate }}"
                    for="by-load"
                  >
                    <input
                      type="radio"
                      id="by-load"
                      name="by-load"
                      value="by-load"
                      [(ngModel)]="preference.blob['dispatchLoadType']"
                      (change)="selectDispatchLoadType($event)"
                    />
                  </label>
                  <label
                    attr.data-label="{{ 'Load Count' | translate }}"
                    for="load-count"
                    [ngClass]="{
                      disabled:
                        !preference.blob['dispatchLoadType'] ||
                        preference.blob['dispatchLoadType'] !== 'by-load'
                    }"
                  >
                    <input
                      type="number"
                      id="load-count"
                      name="load-count"
                      [(ngModel)]="preference.blob['dispatchLoadCount']"
                      (change)="selectDispatchLoadCount($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Assignment Times preferences -->
          <div class="row">
            <div class="dispatch-preferences">
              <div class="label-group">
                <label translate>Stagger Start Times</label>
              </div>
              <div class="field-group">
                <div class="note" translate>
                  Enable staggered assignment unique start times.
                </div>
                <div class="toggle-container">
                  <label class="toggle-label toggles">
                    <input
                      type="checkbox"
                      name="assignment-stagger-time"
                      [checked]="
                        preference.blob['staggerAssignmentTime'] ===
                        'staggered_start'
                      "
                      class="toggle-input"
                      (change)="assignmentTimeChanged($event)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- map preferences -->
          <div class="row">
            <div class="map-preferences">
              <div class="label-group">
                <label translate>Map Preference</label>
              </div>
              <div class="field-group map-style">
                <div class="note" translate>
                  Select map inteterface and configuration.
                </div>
                <div
                  class="radio-buttons"
                  *ngIf="preference && preference.blob"
                >
                  <label
                    attr.data-label="{{ 'Ruckit Map Style' | translate }}"
                    for="ruckit-map-style"
                  >
                    <input
                      type="radio"
                      id="ruckit-map-style"
                      name="ruckit-map-style"
                      value="ruckit-map-style"
                      [(ngModel)]="preference.blob['mapStyle']"
                      (change)="selectMapStyle($event)"
                    />
                  </label>
                  <label
                    attr.data-label="{{ 'Google Map Style' | translate }}"
                    for="google-map-style"
                  >
                    <input
                      type="radio"
                      id="google-map-style"
                      name="google-map-style"
                      value="google-map-style"
                      [(ngModel)]="preference.blob['mapStyle']"
                      (change)="selectMapStyle($event)"
                    />
                  </label>
                </div>
              </div>
              <div class="field-group marker-type">
                <div class="note" translate>Select map marker type.</div>
                <div
                  class="radio-buttons"
                  *ngIf="preference && preference.blob"
                >
                  <label
                    attr.data-label="{{ 'Driver Initials' | translate }}"
                    for="driver-initials"
                  >
                    <input
                      type="radio"
                      id="driver-initials"
                      name="driver-initials"
                      value="driver-initials"
                      [(ngModel)]="preference.blob['markerType']"
                      (change)="selectMarkerType($event)"
                    />
                  </label>
                  <label
                    attr.data-label="{{ 'Truck Number' | translate }}"
                    for="truck-number"
                  >
                    <input
                      type="radio"
                      id="truck-number"
                      name="truck-number"
                      value="truck-number"
                      [(ngModel)]="preference.blob['markerType']"
                      (change)="selectMarkerType($event)"
                    />
                  </label>
                </div>
              </div>
              <div class="field-group marker-type">
                <div class="note" translate>Select map marker status type.</div>
                <div
                  class="radio-buttons"
                  *ngIf="preference && preference.blob"
                >
                  <label
                    attr.data-label="{{ 'Each Segment' | translate }}"
                    for="each-segment"
                  >
                    <input
                      type="radio"
                      id="each-segment"
                      name="each-segment"
                      value="each-segment"
                      [(ngModel)]="preference.blob['markerStatus']"
                      (change)="selectMarkerStatus($event)"
                    />
                  </label>
                  <label
                    attr.data-label="{{ 'Load Status' | translate }}"
                    for="load-status"
                  >
                    <input
                      type="radio"
                      id="load-status"
                      name="load-status"
                      value="load-status"
                      [(ngModel)]="preference.blob['markerStatus']"
                      (change)="selectMarkerStatus($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <button
              class="btn btn-primary"
              (click)="savePreferences(); (false)"
              [disabled]="loading || !editPreferences.form.valid"
              [ngClass]="{ loading: loading }"
              translate
            >
              Save Preferences
            </button>
          </div>
        </div>
        <div class="right-side"></div>
      </div>
    </form>
  </div>
</div>
