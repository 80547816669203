"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var mock_user_1 = require("../users/mock-user");
var mock_organization_1 = require("../organizations/mock-organization");
var MockAuthenticationService = /** @class */ (function () {
    function MockAuthenticationService() {
    }
    MockAuthenticationService.prototype.login = function (username, password) {
        localStorage.setItem('currentUser', JSON.stringify({
            username: mock_user_1.USER.email, token: mock_user_1.USER.token, name: mock_user_1.USER.name,
            organization: mock_user_1.USER.organization, driver: false, sidebar: true,
            carrier: false, ruckit: true, id: mock_user_1.USER.id, canCreateJobs: true
        }));
        return rxjs_1.of(true);
    };
    MockAuthenticationService.prototype.user = function () {
        return mock_user_1.USER;
    };
    MockAuthenticationService.prototype.getOrganization = function () {
        return mock_organization_1.ORGANIZATION;
    };
    MockAuthenticationService.prototype.isRuckit = function () {
        return true;
    };
    MockAuthenticationService.prototype.displaySidebar = function () {
        return true;
    };
    MockAuthenticationService.prototype.isLoggedIn = function () {
        return true;
    };
    MockAuthenticationService.prototype.isCrh = function () {
        return true;
    };
    MockAuthenticationService.prototype.getFilterProperty = function (filter) {
        return false;
    };
    MockAuthenticationService.prototype.hasAllDriversEnabled = function () {
        return true;
    };
    MockAuthenticationService.prototype.canCreateJobs = function () {
        return true;
    };
    MockAuthenticationService.prototype.logout = function () {
        localStorage.removeItem('currentUser');
    };
    MockAuthenticationService.prototype.hasFavoriteTags = function () {
        return false;
    };
    MockAuthenticationService.prototype.isScaleit = function () {
        return false;
    };
    MockAuthenticationService.prototype.hasWorkOrder = function () {
        return false;
    };
    MockAuthenticationService.prototype.hasSignature = function () {
        return false;
    };
    MockAuthenticationService.prototype.storeUser = function (user) {
        localStorage.setItem('currentUser', JSON.stringify({
            username: user.email, token: user.token, name: user.name,
            organization: user.organization, driver: user.isDriver,
            sidebar: user.sidebar, carrier: user.isCarrier, ruckit: user.isRuckit,
            id: user.id, image: user.image,
            canCreateJobs: user.organization && user.organization.canCreateJobs
        }));
        return true;
    };
    MockAuthenticationService.prototype.enabledFeatures = function () {
        return [];
    };
    MockAuthenticationService.prototype.getFeature = function (feature) {
        return null;
    };
    return MockAuthenticationService;
}());
exports.MockAuthenticationService = MockAuthenticationService;
