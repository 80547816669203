import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';

import { DispatchDriver } from '../dispatch-schedule.component';
import { Carrier } from '../../../carriers/carrier';
import { DropdownComponent } from '../../../shared';
import { ItemGroup, ItemList } from '../../../shared/item-grid/item-grid.component';
import { TruckType } from '../../../trucks/truck-type';

type truckGroupByType = 'Carrier' | 'Truck Type' | 'Assigned' | 'Market'  | 'Duty Status' | 'Condition';

@Component({
  selector: 'dispatch-schedule-truck-grid',
  templateUrl: './dispatch-schedule-truck-grid.component.html',
  styleUrls: ['../dispatch-schedule.component.scss']
})
export class DispatchScheduleTruckGridComponent {
  searchTerm: string;
  @Input() loadingProgress = 0;
  @Input() drivers: DispatchDriver[];
  truckList: ItemList;
  filteredTruckList: ItemList;
  displayKeys = ['truckDriverDisplayName', 'name', 'truckName'];
  truckCount = { available: 0, assigned: 0 };
  groupByOptions = [ 'Carrier', 'Truck Type', 'Assigned', 'Market', 'Duty Status', 'Condition' ];
  activeGroupBy: truckGroupByType = 'Carrier';

  @Input() carrierDropdownData: {
    carrier?: Carrier,
    carriers: Carrier[],
    config: any,
    loading: boolean
  };
  @ViewChild('carriersDropdown', { static: false }) carriersDropdown: DropdownComponent;
  @Output() selectCarrier: EventEmitter<Carrier> = new EventEmitter();
  @Output() searchCarriers: EventEmitter<{search: string}> = new EventEmitter();
  @Output() dropdownNextPage: EventEmitter<any> = new EventEmitter();

  @ViewChild('truckTypesDropdown', { static: false }) truckTypesDropdown: DropdownComponent;
  truckTypeOptions: TruckType[] = [
    <TruckType>{ id: '0', name: 'All Truck Types' }
  ];
  selectedTruckType: TruckType;

  selectedDriversValue: string[] = [];
  @Output() selectedDriversChange: EventEmitter<string[]> = new EventEmitter();
  @Input() get selectedDrivers() { return this.selectedDriversValue; }
  set selectedDrivers(data: string[]) {
    this.selectedDriversValue = data;
    this.selectedDriversChange.emit(data);
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.drivers && changes.drivers.currentValue) {
      this.setupItemList(changes.drivers.currentValue, this.activeGroupBy);
    }
  }

  /**
   * Uses a groupBy to generate a list of groups for the input driver list
   *
   * @param {DispatchDriver[]} drivers The input driver list
   * @param {'Carrier' | 'Truck Type' | 'Assigned' | 'Market'} groupBy The option to group the list of drivers by
   */
  setupItemList(drivers: DispatchDriver[], groupBy: truckGroupByType) {
    this.truckCount = {
      available: drivers.length,
      assigned: drivers.filter(driver => (driver.assignments.length > 0)).length
    };
    this.activeGroupBy = groupBy;
    switch (groupBy) {
      case 'Carrier':
        this.truckList = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                id: d.carrier.id,
                name: d.carrier.name,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (driver.carrier.id === group.id))
        })));
        break;
      case 'Market':
        let marketGroups = [];
        drivers.forEach(d => {
          if (d.tags && d.tags.length) {
            d.tags.forEach(tag => {
              marketGroups.push(<ItemGroup>{
                id: tag.id,
                name: tag.name,
                groupBy: groupBy,
                items: []
              });
            });
          } else {
            marketGroups.push(<ItemGroup>{
              id: '',
              groupBy: groupBy,
              items: []
            });
          }
        });
        this.truckList = marketGroups.filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (
            group.id === '' ? (!driver.tags || driver.tags.length === 0) :
            driver.tags.map(t => (t.id)).join(' ').includes(group.id)
          ))
        })));
        break;
      case 'Truck Type':
        this.truckList = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                name: d.truck && d.truck.truckType && d.truck.truckType.name,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => ((driver.truck && driver.truck.truckType && driver.truck.truckType.name) === group.name))
        })));
        break;
      case 'Assigned':
        this.truckList = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                name: d.assignments.length > 0 ? 'Assigned' : 'Unassigned',
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.name === group.name))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => (group.name === 'Assigned' ? driver.assignments.length > 0 : driver.assignments.length === 0))
        })));
        break;
      case 'Duty Status':
        this.truckList = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                name: d.displayDutyStatus,
                id: d.dutyStatus,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => (Object.assign(group, {
          items: drivers.filter(driver => driver.dutyStatus === group.id)
        })));
        break;
      case 'Condition':
        this.truckList = Array.from(
          new Set(
            drivers.map(d => (
              <ItemGroup>{
                name: d.truck && d.truck.displayServiceStatus,
                id: d.truck && d.truck.serviceStatus,
                groupBy: groupBy,
                items: []
              }
            ))
          )
        ).filter(
          (group, i, groups) => i === groups.findIndex(g => (g.id === group.id))
        ).map(group => Object.assign(group, {
          items: drivers.filter(driver => (group.id ? driver.truck && driver.truck.serviceStatus === group.id : !driver.truck))
        }));
        break;
    }
    this.truckList = this.truckList.sort((a, b) => ((a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0));
    this.filteredTruckList = cloneDeep(this.truckList);
    if (this.truckTypeOptions.length === 1) {
      this.truckList.forEach(group => {
        group.items.forEach(d => {
          if (
            d.truck && d.truck.truckType && this.truckTypeOptions.findIndex(t => (t.name === d.truck.truckType.name)) === -1
          ) {
            this.truckTypeOptions.push(d.truck.truckType);
          }
        });
      });
    }
    if (this.selectedTruckType) { this.selectTruckType(this.selectedTruckType); }
  }

  selectGroupBy(groupBy: 'Carrier' | 'Truck Type') {
    this.setupItemList(this.drivers, groupBy);
  }

  /**
   * Uses a selected truck type to filter down the current displayed truck list
   *
   * @param {TruckType} type The selected trucktype
   */
  selectTruckType(type: TruckType) {
    this.selectedDrivers = [];
    this.selectedTruckType = type;
    if (this.selectedTruckType.id === '0') {
      this.filteredTruckList = cloneDeep(this.truckList);
    } else {
      this.filteredTruckList = cloneDeep(this.truckList).map(group => {
        group.items = group.items.filter(d => (
          d.truck && d.truck.truckType && d.truck.truckType.name === this.selectedTruckType.name
        ));
        return group;
      });
    }
  }

  /**
   * Creates a list of class names to append to each of the item elements based on specified driver states
   *
   * @param {DispatchDriver} driver The input driver
   * @return {string} The list of class names combined in a single string
   */
  generateItemClassNames(driver: DispatchDriver): string {
    let classNames = '';
    if (
      driver.dutyStatus === 'off-duty' ||
      (driver.truck && driver.truck.serviceStatus === 'out-of-service')
    ) {
      classNames += 'red ';
    } else if (driver.assignments.length) {
      classNames += 'blue ';
    }
    return classNames;
  }
}
