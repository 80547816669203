"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var resource_service_1 = require("../shared/resource.service");
var assignment_serializer_1 = require("./assignment.serializer");
var index_1 = require("../shared/index");
var AssignmentService = /** @class */ (function (_super) {
    __extends(AssignmentService, _super);
    function AssignmentService(http) {
        return _super.call(this, http, 'assignments/all/', new assignment_serializer_1.AssignmentSerializer()) || this;
    }
    AssignmentService.prototype.saveBulkAssignments = function (body) {
        var _this = this;
        if (body) {
            var assigmentBulkUrl = this.baseUrl + 'assignments/';
            return this.http.post(assigmentBulkUrl, body, {
                headers: index_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkCreate = function (assignments, query) {
        var _this = this;
        if (assignments && assignments.length) {
            var params_1 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_1 = params_1.set(key, query[key].toString());
                    }
                });
            }
            var _assignments = assignments.map(function (assignment) {
                return new assignment_serializer_1.AssignmentSerializer().toJson(assignment);
            });
            var assigmentBulkUrl = this.baseUrl + 'assignments/';
            return this.http.post(assigmentBulkUrl, _assignments, {
                headers: index_1.requestHeaders(),
                params: params_1
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkUpdate = function (assignments, query) {
        var _this = this;
        if (assignments && assignments.length) {
            var params_2 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_2 = params_2.set(key, query[key].toString());
                    }
                });
            }
            var _assignments = assignments.map(function (assignment) {
                return new assignment_serializer_1.AssignmentSerializer().toJson(assignment);
            });
            var assigmentBulkUrl = this.baseUrl + 'assignments/bulk-update/';
            return this.http.put(assigmentBulkUrl, _assignments, {
                headers: index_1.requestHeaders(),
                params: params_2
            }).pipe(operators_1.map(function (res) { return _this.convertBulkResponse(res); }), operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.bulkRemove = function (recordIds, query) {
        var _this = this;
        if (recordIds) {
            var params_3 = new http_1.HttpParams();
            if (query) {
                Object.keys(query).forEach(function (key) {
                    if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                        params_3 = params_3.set(key, query[key].toString());
                    }
                });
            }
            var assigmentBulkUrl = this.baseUrl + 'assignments/bulk-delete/';
            return this.http.request('delete', assigmentBulkUrl, {
                body: recordIds,
                headers: index_1.requestHeaders(),
                params: params_3
            }).pipe(operators_1.catchError(function (err) { return rxjs_1.throwError(_this.convertBulkResponse(err.error)); }));
        }
    };
    AssignmentService.prototype.copyAssignment = function (body) {
        if (body) {
            var assigmentCopyUrl = this.baseUrl + 'assignments/copy/';
            return this.http.post(assigmentCopyUrl, body, {
                headers: index_1.requestHeaders()
            }).pipe(operators_1.map(function (res) { return res; }), operators_1.catchError(index_1.handleError));
        }
    };
    AssignmentService.prototype.convertBulkResponse = function (res) {
        var errors = [];
        var assignments = [];
        if (res && res.errors) {
            errors = res.errors;
        }
        if (res && res.success) {
            assignments = res.success.map(function (record) { return new assignment_serializer_1.AssignmentSerializer().fromJson(record); });
        }
        return { errors: errors, assignments: assignments };
    };
    return AssignmentService;
}(resource_service_1.ResourceService));
exports.AssignmentService = AssignmentService;
