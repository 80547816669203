import { Component, Input, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import * as moment from 'moment';

import { Assignment } from '../../../assignments/assignment';
import { AssignmentService } from '../../../assignments/assignment.service';

import { DispatchJobEvent } from '../dispatch-schedule.component';
import { parseErrors } from '../../../shared';
import { TruckService } from '../../../trucks/truck.service';

type BulkEdits = {
  uniqueStartTime?: string,
  maxNumberOfLoads?: number
};

@Component({
  selector: 'dispatch-schedule-jobevent-edit-panel',
  templateUrl: './dispatch-schedule-jobevent-edit-panel.component.html',
  styleUrls: ['./dispatch-schedule-jobevent-edit-panel.component.scss']
})

export class DispatchScheduleJobEventEditPanelComponent {
  errors: any[];
  editing = false;

  @Output() close: EventEmitter<boolean> = new EventEmitter();
  @Output() editsMade: EventEmitter<DispatchJobEvent> = new EventEmitter();
  @Output() assignmentsDeleted: EventEmitter<Assignment[]> = new EventEmitter();

  bulkEdits: BulkEdits = {};
  selectedAssignments: string[] = [];

  trucksDropdownConfig = {
    small: true,
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    nameProperty: 'ticketName',
    service: TruckService,
    query: { ordering: 'name' }
  };

  jobEventValue: DispatchJobEvent;
  @Output() jobEventChange: EventEmitter<DispatchJobEvent> = new EventEmitter();
  @Input() get jobEvent() { return this.jobEventValue; }
  set jobEvent(row) {
    this.jobEventValue = row;
    this.jobEventChange.emit(row);
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    const eventClassesName = event.composedPath ? event.composedPath().map(el => (el.className)).join(' ') : []
    if (
      !eventClassesName.includes('driver-pay-timeline-list-container') &&
      !event.target.classList.contains('assignment-label') &&
      !eventClassesName.includes('timepicker') &&
      !eventClassesName.includes('mat-calendar') && 
      !this._eRef.nativeElement.contains(event.target)
    ) {
        if (!this.editing) {
          this.close.emit(true);
        }
    }
  }

  constructor (private assignmentService: AssignmentService, private _eRef: ElementRef) {}

  bulkEdit(all = false) {
    this.editing = true;
    let updatedAssignments = this.jobEvent.assignments;
    if (!all) { updatedAssignments = this.jobEvent.assignments.filter(assignment => (assignment.selected)); }
    updatedAssignments.map((assignment, i) => {
      Object.keys(this.bulkEdits).forEach(key => {
        if (key === 'uniqueStartTime') {
          assignment.uniqueStart = all ? moment(
            assignment.uniqueStart.split('T')[0] + ' ' + this.bulkEdits[key], 'YYYY-MM-DD H:mm A'
          ).add(Number(this.jobEvent.deliveryInterval) * i, 'minutes').toISOString() :
          moment(assignment.uniqueStart.split('T')[0] + ' ' + this.bulkEdits[key], 'YYYY-MM-DD H:mm A').toISOString();
        } else { assignment[key] = this.bulkEdits[key]; }
      });
      return assignment;
    });
    if (updatedAssignments && updatedAssignments.length &&
        this.assignmentService && typeof this.assignmentService.bulkUpdate === 'function') {
      this.assignmentService.bulkUpdate(updatedAssignments).subscribe(
        (res) => {
          res.assignments.forEach(assignment => {
            const matchedIndex = this.jobEvent.assignments.findIndex(a => (a.id === assignment.id));
            this.jobEvent.assignments[matchedIndex].maxNumberOfLoads = assignment.maxNumberOfLoads;
            this.jobEvent.assignments[matchedIndex].uniqueStart = assignment.uniqueStart;
            this.jobEvent.assignments[matchedIndex].uniqueStartTime = moment(assignment.uniqueStart).format('H:mm A');
          });
          this.editing = false;
          this.editsMade.emit(this.jobEvent);
        },
        err => {
          this.editing = false;
          this.errors = parseErrors(err);
        }
      );
    }
  }

  selectAssignment(assignment: Assignment) {
    assignment.selected = !assignment.selected;
  }

  updateAssignment(assignment: Assignment, field: string, value: any) {
    if (
      (field === 'uniqueStart' && value.length && value[0] === assignment.uniqueStart) ||
      (field === 'truck' && (!value.id || !assignment.truck || (value.id === assignment.truck.id)))
    ) { return; }

    this.editing = true;

    if (field === 'uniqueStart' || field === 'uniqueStartTime') {
      if (field === 'uniqueStart') {
        assignment.uniqueStartDate = value[0];
      } else {
        assignment.uniqueStartTime = value;
        field = 'uniqueStart';
      }
      const formatString = 'MM/DD/YYYY h:mm A';
      const dateString = moment(assignment.uniqueStartDate).format('MM/DD/YYYY');
      value = moment(`${dateString} ${assignment.uniqueStartTime}`, formatString);
    }

    this.assignmentService.save({ id: assignment.id, [field]: value }, { can_dispatch: 'True' }).subscribe(
      (res) => {
        const matchedIndex = this.jobEvent.assignments.findIndex(a => (a.id === res.id));
        this.jobEvent.assignments[matchedIndex][field] = value;
        this.editing = false;
        this.editsMade.emit(this.jobEvent);
      },
      err => {
        this.editing = false;
        this.errors = parseErrors(err);
      }
    );
  }

  removeAssignment(assignment: Assignment) {
    this.assignmentService.remove(assignment.id).subscribe(
      () => this.assignmentsDeleted.emit([assignment]),
      err => this.errors = parseErrors(err)
    );
  }

  getAssignmentStartRange(jobEvent: DispatchJobEvent): {start: string, end: string} {
    return {
      start: moment(jobEvent.shift1Start).startOf('day').toISOString(),
      end: moment(jobEvent.jobEndDate).endOf('day').toISOString()
    };
  }
}
