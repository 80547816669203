"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var forms_1 = require("@angular/forms");
var core_2 = require("@ngx-translate/core");
// libraries
var moment = require("moment-timezone");
// services
var trip_service_1 = require("./../trip.service");
var job_service_1 = require("../../jobs/job.service");
var checkin_service_1 = require("../../checkins/checkin.service");
var api_service_1 = require("../../shared/api.service");
var attachment_service_1 = require("../../attachments/attachment.service");
var authentication_service_1 = require("../../shared/authentication.service");
var carrier_1 = require("../../carriers/carrier");
var job_event_1 = require("../../job-events/job-event");
// components
var multiple_images_edit_component_1 = require("../../shared/multiple-images-edit/multiple-images-edit.component");
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
// constants
var app_constants_1 = require("../../app.constants");
var dropdownsConfig_1 = require("./dropdownsConfig");
// validators
var trip_time_validators_1 = require("../trip-time-validators");
var EditTripComponent = /** @class */ (function () {
    function EditTripComponent(route, router, tripService, jobService, checkinService, attachmentService, authenticationService, translate, fb) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.tripService = tripService;
        this.jobService = jobService;
        this.checkinService = checkinService;
        this.attachmentService = attachmentService;
        this.authenticationService = authenticationService;
        this.translate = translate;
        this.fb = fb;
        this.jobDates = [];
        this.weightRequired = false;
        this.errors = [];
        this.jobEventError = null;
        this.checkinsReq = { loading: null, unloading: null };
        this.loading = false;
        this.daysLoading = false;
        this.jobEventsLoading = false;
        this.loadingTicketImages = [];
        this.unloadingTicketImages = [];
        this.loadingSignatures = [];
        this.unloadingSignatures = [];
        this.imagesToDelete = [];
        this.uploading = false;
        this.jobDropdownConfig = dropdownsConfig_1.JOB_DROPDOWN_CONFIG(this.translate);
        this.jobEventsDropdownConfig = dropdownsConfig_1.JOB_EVENTS_DROPDOWN_CONFIG(this.translate);
        this.carrierDropdownConfig = dropdownsConfig_1.CARRIER_DROPDOWN_CONFIG(this.translate);
        this.driverDropdownConfig = dropdownsConfig_1.DRIVER_DROPDOWN_CONFIG(this.translate);
        this.truckDropdownConfig = dropdownsConfig_1.TRUCK_DROPDOWN_CONFIG(this.translate);
        this.fieldsToCheck = [
            'activeTracking',
            'ticketImage',
            'signatureImage',
            'ticketNumber',
            'weight',
        ];
        this.firstLoad = true;
        this.requiredImages = {
            loadingImages: false,
            unloadingImages: false,
            loadingSignature: false,
            unloadingSignature: false,
        };
        this.parseDateTime = function (date, time) {
            return !!date && !!time
                ? moment(moment(date).format('YYYY-MM-DD') + " " + time).toISOString()
                : null;
        };
        this.onImagesChangeParser = function (images) {
            return images.map(function (img) {
                return img.isNew || img.isEdited
                    ? Object.assign(img, {
                        tempPath: URL.createObjectURL(_this.parseImageOnChange(img)),
                    })
                    : _this.parseImageOnChange(img);
            });
        };
    }
    EditTripComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        combinedParams.subscribe(function (result) {
            _this.returnTo = result && result.qparams['returnTo'];
            if (result && result.params['id']) {
                _this.getTrip(result.params['id']);
            }
        });
    };
    EditTripComponent.prototype.getMomentDateFromDate = function (date, format) {
        if (format === void 0) { format = 'YYYY-MM-DD'; }
        return date ? moment(date).format(format) : null;
    };
    EditTripComponent.prototype.initForm = function () {
        this.editTripForm = this.fb.group({
            job: [this.job, forms_1.Validators.required],
            jobEvent: [this.jobEvent, forms_1.Validators.required],
            driver: [this.driver, forms_1.Validators.required],
            truck: [this.truck, forms_1.Validators.required],
            carrier: [this.carrier, forms_1.Validators.required],
            start: this.fb.group({
                date: [this.trip.startDate, [forms_1.Validators.required]],
                time: [
                    moment(this.trip.startDate).format('HH:mm'),
                    [forms_1.Validators.required],
                ],
            }),
            end: this.fb.group({
                date: [this.getMomentDateFromDate(this.trip.endDate)],
                time: [this.getMomentDateFromDate(this.trip.endDate, 'HH:mm')],
            }),
            loadingCheckin: this.fb.group({
                ticketNumber: [this.trip.loadingCheckin.ticketNumber],
                weight: [this.trip.loadingCheckin.weight],
                date: [this.trip.loadingCheckin.date],
                time: [this.trip.loadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
            unloadingCheckin: this.fb.group({
                ticketNumber: [this.trip.unloadingCheckin.ticketNumber],
                weight: [this.trip.unloadingCheckin.weight],
                date: [this.trip.unloadingCheckin.date],
                time: [this.trip.unloadingCheckin.time],
                images: [null],
                signatures: [null],
            }),
        }, { validators: trip_time_validators_1.globalTripTimeValidator });
        var user = this.authenticationService.user();
        this.carrierDropdownConfig.query = {
            exclude: user.organization.id,
        };
        this.carrierDropdownConfig.prefilledOptions.push(user.organization);
    };
    EditTripComponent.prototype.getTrip = function (id) {
        var _this = this;
        if (id) {
            this.tripService.getCondensedTrip(id).subscribe(function (trip) {
                _this.trip = trip;
                _this.job = {
                    id: trip.jobId,
                    name: trip.job,
                };
                _this.jobEvent = new job_event_1.JobEvent({ id: trip.jobEventId });
                _this.carrier = new carrier_1.Carrier({
                    id: trip.carrierId,
                    name: trip.carrier,
                });
                _this.driver = {
                    id: trip.driverId,
                    name: trip.driver,
                };
                _this.truck = {
                    id: trip.truckId,
                    name: trip.truck,
                };
                _this.setRequiredImages(trip.checkinOptions, trip.checkoutOptions);
                _this.getCheckins(_this.trip.id, 'loading');
                _this.getCheckins(_this.trip.id, 'unloading');
                _this.initForm();
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    EditTripComponent.prototype.submitTrip = function () {
        var _this = this;
        this.loading = true;
        var requests = this.attachmentService.combineTicketImagesAndAttachments(this.imagesToDelete, this.loadingTicketImages, this.loadingSignatures, this.unloadingTicketImages, this.unloadingSignatures, this.trip.loadingCheckin.id, this.trip.unloadingCheckin.id);
        var parsedTrip = this.parseTripForSubmit();
        delete parsedTrip.loadingCheckin.attachments;
        delete parsedTrip.unloadingCheckin.attachments;
        var updateTrip$ = this.tripService.saveWithCheckinsAndAttachments(parsedTrip);
        rxjs_1.forkJoin(requests.concat([updateTrip$])).subscribe(function () {
            _this.loading = false;
            _this.router.navigateByUrl(_this.returnTo);
        }, function (err) {
            _this.errors = api_service_1.parseErrors([err]);
            _this.loading = false;
        });
    };
    EditTripComponent.prototype.parseTripForSubmit = function () {
        return __assign({}, this.trip, { id: this.trip.id, job: this.editTripForm.value.job.id, jobEvent: this.editTripForm.value.jobEvent.id, jobevent: this.editTripForm.value.jobEvent.id, carrier: this.editTripForm.value.carrier.id, driver: this.editTripForm.value.driver.id, truck: this.editTripForm.value.truck.id, startTime: this.parseDateTime(this.editTripForm.value.start.date, this.editTripForm.value.start.time), endTime: this.parseDateTime(this.editTripForm.value.end.date, this.editTripForm.value.end.time), loadingCheckin: __assign({}, this.editTripForm.value.loadingCheckin, { id: this.trip.loadingCheckin.id, date: this.parseDateTime(this.editTripForm.value.loadingCheckin.date, this.editTripForm.value.loadingCheckin.time) }), unloadingCheckin: __assign({}, this.editTripForm.value.unloadingCheckin, { id: this.trip.unloadingCheckin.id, date: this.parseDateTime(this.editTripForm.value.unloadingCheckin.date, this.editTripForm.value.unloadingCheckin.time) }) });
    };
    EditTripComponent.prototype.deleteLegacyImagesfromTrip = function () {
        var _a = this.imagesToDelete.reduce(function (_a, e) {
            var p = _a[0], f = _a[1];
            return (e.legacy ? [p.concat([e]), f] : [p, f.concat([e])]);
        }, [[], []]), legacyImagesToBeDeleted = _a[0], otherImagesToBeDeleted = _a[1];
        return { legacyImagesToBeDeleted: legacyImagesToBeDeleted, otherImagesToBeDeleted: otherImagesToBeDeleted };
    };
    EditTripComponent.prototype.onStartDateChanged = function (dates) {
        this.trip.startDate = dates[0];
    };
    EditTripComponent.prototype.onEndDateChanged = function (dates) {
        this.trip.endDate = dates[0];
    };
    EditTripComponent.prototype.onCheckinLoadingDateChanged = function (dates) {
        if (this.trip && this.trip.loadingCheckin) {
            this.trip.loadingCheckin.date = dates[0];
        }
    };
    EditTripComponent.prototype.onCheckinUnloadingDateChanged = function (dates) {
        if (this.trip && this.trip.unloadingCheckin) {
            this.trip.unloadingCheckin.date = dates[0];
        }
    };
    EditTripComponent.prototype.onSelect = function (filterType, e) {
        switch (filterType) {
            case 'job':
                if (this.job !== e) {
                    if (this.firstLoad) {
                        this.firstLoad = false;
                    }
                    else {
                        this.job = e;
                        this.jobEventsDropdown.getRecords({
                            job: e.id,
                        });
                        this.resetForm();
                    }
                    this.jobEventsDropdown.getRecords({
                        job: e.id,
                    });
                    this.getJobDays(e.id);
                }
                break;
            case 'jobEvent':
                if (!this.jobEvent || this.jobEvent.id !== e.id) {
                    this.jobEvent = e;
                    this.setRequiredImages(e.job.checkinOptions, e.job.checkoutOptions);
                    this.newTripFormSetRequiredFields(e);
                }
                var query = __assign({}, this.carrierDropdownConfig.query, { carriers_for_jobevent: e.id });
                this.carriersDropdown.config.query = query;
                this.carriersDropdown.getRecords(query);
                break;
            case 'carrier':
                var carrier = e.carrier;
                if (this.carrier === null || this.carrier.id !== carrier.id) {
                    this.carrier = carrier;
                    this.driver = null;
                    this.truck = null;
                    this.driversDropdown.getRecords({
                        carrier: carrier.id,
                        shared_jobevent: this.jobEvent.id,
                    });
                    this.truckDropdown.getRecords({
                        carrier: carrier.id,
                    });
                }
                break;
            case 'driver':
                this.driver = e;
                break;
            case 'truck':
                this.truck = e;
                break;
        }
        this.newTripFormPatchValue(filterType, e);
    };
    EditTripComponent.prototype.getCheckins = function (tripId, kind) {
        var _this = this;
        if (kind === void 0) { kind = 'loading'; }
        if (this.checkinsReq[kind] &&
            typeof this.checkinsReq[kind].unsubscribe === 'function') {
            this.checkinsReq[kind].unsubscribe();
        }
        this.checkinsReq[kind] = this.checkinService
            .getCheckins({
            ordering: 'name',
            kind: kind,
            trip: tripId,
        })
            .subscribe(function (checkins) {
            if (checkins && checkins.length) {
                var checkin = checkins[0];
                _this.editTripFormPatchValueNested([kind + "Checkin", 'ticketNumber'], checkin.ticketNumber);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'weight'], checkin.weight);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'date'], checkin.date);
                _this.editTripFormPatchValueNested([kind + "Checkin", 'time'], moment(checkin.date).format('HH:mm'));
                switch (kind) {
                    case 'loading':
                        _this.trip.loadingCheckin = checkin;
                        _this.loadingTicketImages = _this.trip.loadingCheckin.attachments
                            .filter(function (img) { return img.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
                            .map(function (a) { return ({
                            id: a.id,
                            src: a.file,
                            file: a.file,
                            legacy: a.legacy,
                            imgType: app_constants_1.LOADINGTRIPTICKETIMAGETYPE,
                        }); });
                        _this.loadingSignatures = _this.trip.loadingCheckin.attachments
                            .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
                            .map(function (file) { return ({
                            id: file.id,
                            src: file.file,
                            file: file.file,
                            legacy: file.legacy,
                            imgType: app_constants_1.LOADINGTRIPSIGNATUREIMAGETYPE,
                        }); });
                        break;
                    case 'unloading':
                        _this.trip.unloadingCheckin = checkin;
                        _this.unloadingTicketImages =
                            _this.trip.unloadingCheckin.attachments
                                .filter(function (img) { return img.fileType === app_constants_1.TRIPTICKETIMAGETYPE; })
                                .map(function (a) { return ({
                                id: a.id,
                                src: a.file,
                                file: a.file,
                                legacy: a.legacy,
                                imgType: app_constants_1.UNLOADINGTRIPTICKETIMAGETYPE,
                            }); });
                        _this.unloadingSignatures =
                            _this.trip.unloadingCheckin.attachments
                                .filter(function (att) { return att.fileType === app_constants_1.TRIPSIGNATUREIMAGETYPE; })
                                .map(function (file) { return ({
                                id: file.id,
                                src: file.file,
                                file: file.file,
                                legacy: file.legacy,
                                imgType: app_constants_1.UNLOADINGTRIPSIGNATUREIMAGETYPE,
                            }); });
                        break;
                }
            }
        }, function (err) {
            _this.errors = err;
        }, function () {
            _this.loading = false;
        });
    };
    EditTripComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        this.jobService.getDays(jobId).subscribe(function (days) {
            _this.jobDates = days.map(function (day) { return moment(day).toDate(); });
            _this.daysLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.daysLoading = false;
        });
    };
    EditTripComponent.prototype.requestRetake = function (checkin) {
        var _this = this;
        this.checkinService.requestRetake(checkin.id).subscribe(function () {
            _this.trip.retakeStatus = 'requested';
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditTripComponent.prototype.undoRequestRetake = function (trip) {
        var _this = this;
        this.tripService.undoRetakeRequest(trip).subscribe(function () {
            _this.trip.retakeStatus = null;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditTripComponent.prototype.parseImageOnChange = function (image) {
        if (image && (image.isNew || image.isEdited)) {
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                image.file = reader_1.result;
                image.src = reader_1.result;
            };
            reader_1.readAsDataURL(image);
        }
        if (image && image.isDeleted) {
            this.imagesToDelete.push(image);
        }
        return image;
    };
    EditTripComponent.prototype.handleImagesDialogVisibility = function () {
        this.editImagesContainer.handleVisibility();
    };
    EditTripComponent.prototype.onImagesChange = function (type, checkin, images) {
        var parsedImages = this.onImagesChangeParser(images);
        this[type] = parsedImages;
        this.editTripFormPatchValueNested([checkin, type.includes('Signature') ? 'signatures' : 'images'], parsedImages.slice());
    };
    EditTripComponent.prototype.fileChange = function (type, img) {
        this.onImagesChange(type, type === 'loading' ? 'loadingCheckin' : 'unloadingCheckin', [img]);
    };
    EditTripComponent.prototype.fileUploading = function (uploading) {
        this.uploading = uploading;
    };
    EditTripComponent.prototype.editTripFormPatchValueNested = function (keys, value) {
        if (keys && keys.length > 0 && value) {
            var objToPatch = {};
            keys.reduce(function (prev, curr, i) {
                return (prev[curr] = i + i === keys.length ? value : {});
            }, objToPatch);
            this.editTripForm.patchValue(objToPatch);
        }
    };
    EditTripComponent.prototype.onDateChange = function (args, dates) {
        if (args && args.length > 0 && dates) {
            this.editTripFormPatchValueNested(args, dates[0]);
        }
    };
    EditTripComponent.prototype.newTripFormPatchValue = function (key, value) {
        var _a;
        this.editTripForm.patchValue((_a = {},
            _a[key] = value,
            _a));
    };
    EditTripComponent.prototype.newTripFormSetRequiredFields = function (jobEvent) {
        var _this = this;
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('loadingCheckin', field, jobEvent.job.checkinOptions[field + "Config"] === 'required');
        });
        this.fieldsToCheck.forEach(function (field) {
            _this.newTripFormValidatorsSwitch('unloadingCheckin', field, jobEvent.job.checkoutOptions[field + "Config"] === 'required');
        });
        this.updateFormValueAndValidity(this.editTripForm);
    };
    EditTripComponent.prototype.updateFormValueAndValidity = function (group) {
        var _this = this;
        Object.keys(group.controls).forEach(function (key) {
            var abstractControl = group.controls[key];
            if (abstractControl instanceof forms_1.FormGroup ||
                abstractControl instanceof forms_1.FormArray) {
                _this.updateFormValueAndValidity(abstractControl);
            }
            else {
                abstractControl.updateValueAndValidity();
            }
        });
    };
    EditTripComponent.prototype.newTripFormValidatorsSwitch = function (parent, key, required) {
        switch (key) {
            case 'activeTracking':
                if (required) {
                    this.editTripFormSetValidators([parent, 'date'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                    this.editTripFormSetValidators([parent, 'time'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'date']);
                    this.editTripFormClearValidators([parent, 'time']);
                }
                break;
            case 'ticketImage':
                if (required) {
                    this.editTripFormSetValidators([parent, 'images'], [forms_1.Validators.required]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'images']);
                }
                break;
            case 'signatureImage':
                if (required) {
                    this.editTripFormSetValidators([parent, 'signatures'], [forms_1.Validators.required]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'signatures']);
                }
                break;
            case 'ticketNumber':
                if (required) {
                    this.editTripFormSetValidators([parent, 'ticketNumber'], [forms_1.Validators.required, forms_1.Validators.minLength(1)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'ticketNumber']);
                }
                break;
            case 'weight':
                if (required) {
                    this.editTripFormSetValidators([parent, 'weight'], [forms_1.Validators.required, forms_1.Validators.min(0)]);
                }
                else {
                    this.editTripFormClearValidators([parent, 'weight']);
                }
                break;
            default:
                break;
        }
    };
    EditTripComponent.prototype.editTripFormSetValidators = function (field, validators) {
        this.editTripForm.get(field).setValidators(validators);
    };
    EditTripComponent.prototype.editTripFormClearValidators = function (field) {
        this.editTripForm.get(field).clearValidators();
    };
    EditTripComponent.prototype.setRequiredImages = function (checkinOptions, checkoutOptions) {
        this.requiredImages = {
            loadingImages: checkinOptions.ticketImageConfig === 'required',
            unloadingImages: checkoutOptions.ticketImageConfig === 'required',
            loadingSignature: checkinOptions.signatureImageConfig === 'required',
            unloadingSignature: checkoutOptions.signatureImageConfig === 'required',
        };
    };
    EditTripComponent.prototype.resetForm = function () {
        this.jobEvent = null;
        this.carrier = null;
        this.driver = null;
        this.truck = null;
        this.editTripForm.reset();
    };
    Object.defineProperty(EditTripComponent.prototype, "startDate", {
        get: function () {
            return this.editTripForm.get(['start', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripComponent.prototype, "endDate", {
        get: function () {
            return this.editTripForm.get(['end', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripComponent.prototype, "loadingCheckingDate", {
        get: function () {
            return this.editTripForm.get(['loadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripComponent.prototype, "unloadingCheckingDate", {
        get: function () {
            return this.editTripForm.get(['unloadingCheckin', 'date']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripComponent.prototype, "loadingCheckingTime", {
        get: function () {
            return this.editTripForm.get(['loadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditTripComponent.prototype, "unloadingCheckingTime", {
        get: function () {
            return this.editTripForm.get(['unloadingCheckin', 'time']).value;
        },
        enumerable: true,
        configurable: true
    });
    return EditTripComponent;
}());
exports.EditTripComponent = EditTripComponent;
