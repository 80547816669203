"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var rxjs_1 = require("rxjs");
var ngx_device_detector_1 = require("ngx-device-detector");
var order_service_1 = require("../order.service");
var driver_serializer_1 = require("../../drivers/driver.serializer");
var job_event_1 = require("../../job-events/job-event");
var job_event_stat_service_1 = require("../../job-event-stats/job-event-stat.service");
var ticket_detail_dialog_component_1 = require("../ticket-detail-dialog/ticket-detail-dialog.component");
var authentication_service_1 = require("../../shared/authentication.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var device_1 = require("../../shared/device");
var shared_1 = require("../../shared");
var OrderSummaryStatusComponent = /** @class */ (function () {
    function OrderSummaryStatusComponent(route, dialog, deviceDetectorService, orderService, jobEventService, jobEventStatService, authenticationService) {
        var _this = this;
        this.route = route;
        this.dialog = dialog;
        this.deviceDetectorService = deviceDetectorService;
        this.orderService = orderService;
        this.jobEventService = jobEventService;
        this.jobEventStatService = jobEventStatService;
        this.authenticationService = authenticationService;
        this.device = new device_1.Device();
        this.loading = false;
        this.errors = [];
        this.orderStats = {
            weight: { delivered: 0, remaining: 0, target: 0 },
            loads: { delivered: 0, remaining: 0, target: 0 }
        };
        this.completedPercentage = 0;
        this.tripsEnroute = [];
        this.tripsOnSite = [];
        this.activeStats = 'weight';
        this.jobEventStatuses = [
            {
                action: function () { return _this.changeStatus('cancel'); },
                name: 'Cancel Job'
            }
        ];
        this.allSubscriptionsToUnsubscribe = [];
    }
    OrderSummaryStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.allSubscriptionsToUnsubscribe.push(rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); }).subscribe(function (result) {
            if (result.params['jobEventId']) {
                _this.orderService.setJobEventId(result.params['jobEventId']);
            }
        }));
        this.orderService.orderData.subscribe(function (orderData) {
            _this.setupOrderData(orderData);
        });
    };
    OrderSummaryStatusComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
            this.jobEventStatsReq.unsubscribe();
        }
        if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
            this.jobEventStatsReq.unsubscribe();
        }
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    OrderSummaryStatusComponent.prototype.setupOrderData = function (orderData) {
        var _this = this;
        this.trips = orderData[0].filter(function (trip) { return (trip.completed === false); });
        this.latestLocations = orderData[1];
        this.lastUpdated = new Date().toISOString();
        this.tripsEnroute = [];
        this.tripsOnSite = [];
        this.trips.forEach(function (trip) {
            if (!trip.completed) {
                if (trip.tripStatus === 'loading_complete' || trip.tripStatus === 'enroute_unloading') {
                    _this.tripsEnroute.push(trip);
                }
                else if (trip.tripStatus === 'unloading') {
                    _this.tripsOnSite.push(trip);
                }
            }
        });
        this.latestLocations.locationUpdates = this.latestLocations.locationUpdates.map(function (update) {
            if (update.driver) {
                update.driver = new driver_serializer_1.DriverSerializer().fromJson(update.driver);
            }
            return update;
        });
        if (this.jobEvent) {
            var jobLocation = void 0;
            this.getOrderStats(this.jobEvent);
            if (this.jobEvent.job && this.jobEvent.job.endLocation) {
                jobLocation = this.latestLocations.locations.find(function (location) { return (location.id === _this.jobEvent.job.endLocation.id); });
            }
            this.getTruckInfo(this.tripsEnroute, this.latestLocations.locationUpdates, jobLocation);
        }
        else {
            this.jobEventService.getJobEvent(this.orderService.jobEventId.getValue()).subscribe(function (jobEvent) {
                _this.jobEvent = jobEvent;
                var jobLocation;
                _this.getOrderStats(_this.jobEvent);
                if (_this.jobEvent.job && _this.jobEvent.job.endLocation) {
                    jobLocation = _this.latestLocations.locations.find(function (location) { return (location.id === _this.jobEvent.job.endLocation.id); });
                }
                _this.getTruckInfo(_this.tripsEnroute, _this.latestLocations.locationUpdates, jobLocation);
            }, function (err) { return shared_1.parseErrors(err); });
        }
    };
    OrderSummaryStatusComponent.prototype.getOrderStats = function (jobevent) {
        var _this = this;
        if (this.jobEventStatsReq && typeof this.jobEventStatsReq.unsubscribe === 'function') {
            this.jobEventStatsReq.unsubscribe();
        }
        if (!this.jobEvent.stats) {
            var effectiveRateCalculator = '';
            var enabledFeatures = this.authenticationService.enabledFeatures();
            if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
                effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
            }
            this.jobEventStatsReq = this.jobEventStatService.getStats(jobevent.id, {
                calculator: effectiveRateCalculator
            }).subscribe(function (stats) {
                _this.setupOrderStats(stats);
                _this.loading = false;
            }, function (err) { return shared_1.parseErrors(err); });
        }
        else {
            this.setupOrderStats(this.jobEvent.stats);
        }
    };
    OrderSummaryStatusComponent.prototype.setupOrderStats = function (stats) {
        this.jobEventStats = stats;
        this.orderStats = {
            weight: {
                delivered: Math.round(stats.totalTons * 100) / 100 || 0,
                remaining: Math.round((stats.dailyDeliveryTarget - stats.totalTons) * 100) / 100,
                target: Math.round(stats.dailyDeliveryTarget * 100) / 100 || 0
            },
            loads: {
                delivered: stats.totalLoads,
                remaining: stats.weightInTransit,
                target: stats.dispatchedDriversCount
            }
        };
        this.completedPercentage = this.orderStats.weight.delivered && this.orderStats.weight.target ?
            Math.round((this.orderStats.weight.delivered * 100) / this.orderStats.weight.target) : 0;
    };
    OrderSummaryStatusComponent.prototype.changeOrderStats = function () {
        switch (this.activeStats) {
            case 'weight':
                this.activeStats = 'loads';
                break;
            case 'loads':
                this.activeStats = 'weight';
                break;
            default:
                this.activeStats = 'weight';
                break;
        }
    };
    OrderSummaryStatusComponent.prototype.getTruckInfo = function (trips, currentLocationUpdates, jobLocation) {
        var _this = this;
        var truckInfoList = [];
        trips.forEach(function (trip) {
            var tripLocationUpdate = currentLocationUpdates.find(function (update) { return (update.driver['id'] === trip.driverId); });
            if (tripLocationUpdate && tripLocationUpdate.location) {
                truckInfoList.push({
                    name: trip.truckName,
                    weight: Number(trip.weight),
                    eta: jobLocation && jobLocation.location ? _this.orderService.getDistanceInMiles({ lat: tripLocationUpdate.location.coordinates[0], lng: tripLocationUpdate.location.coordinates[0] }, { lat: jobLocation.location.coordinates[0], lng: jobLocation.location.coordinates[0] }) : null,
                    ticket: trip
                });
            }
        });
        truckInfoList.sort(function (a, b) { return ((a.eta < b.eta) ? -1 : (a.eta > b.eta) ? 1 : 0); });
        this.nextTruck = truckInfoList[0];
        this.followingTruck = truckInfoList[1];
    };
    OrderSummaryStatusComponent.prototype.openTicketDetails = function (ticket) {
        var _this = this;
        var dialog = this.dialog.open(ticket_detail_dialog_component_1.TicketDetailDialogComponent, {
            width: this.device.mobile ? '320px' : '480px',
            data: {
                ticket: ticket,
                jobEvent: this.jobEvent
            }
        });
        dialog.componentInstance.callback = function (updatedTrip) {
            _this.trips[_this.trips.findIndex(function (t) { return (t.id === updatedTrip.id); })] = updatedTrip;
            _this.setupOrderData([_this.trips, _this.latestLocations]);
        };
    };
    OrderSummaryStatusComponent.prototype.changeStatus = function (status) {
        var _this = this;
        var jobEventUpdates = { id: this.jobEvent.id };
        switch (status) {
            case 'cancel':
                jobEventUpdates.cancelled = true;
                break;
        }
        this.jobEventService.save(jobEventUpdates).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
        }, function (err) {
            _this.errors = err;
        });
    };
    return OrderSummaryStatusComponent;
}());
exports.OrderSummaryStatusComponent = OrderSummaryStatusComponent;
