"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dispatch.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./dispatch-by-job/dispatch-by-job.component.ngfactory");
var i3 = require("./dispatch-by-job/job-load.service");
var i4 = require("@angular/common/http");
var i5 = require("./dispatch-by-job/dispatch-by-job.component");
var i6 = require("@angular/router");
var i7 = require("../jobs/job.service");
var i8 = require("../preferences/preference.service");
var i9 = require("./dispatch.service");
var i10 = require("../job-events/job-event.service");
var i11 = require("../assignments/assignment.service");
var i12 = require("../shared/authentication.service");
var i13 = require("@angular/common");
var i14 = require("@ngx-translate/core");
var i15 = require("@angular/material/dialog");
var i16 = require("./mobile/mobile-dispatch.component.ngfactory");
var i17 = require("./mobile/mobile-dispatch.component");
var i18 = require("./dispatch.component");
var i19 = require("ngx-device-detector");
var styles_DispatchComponent = [i0.styles];
var RenderType_DispatchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DispatchComponent, data: {} });
exports.RenderType_DispatchComponent = RenderType_DispatchComponent;
function View_DispatchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dispatch-by-job", [], null, null, null, i2.View_DispatchByJobComponent_0, i2.RenderType_DispatchByJobComponent)), i1.ɵprd(512, null, i3.JobLoadService, i3.JobLoadService, [i4.HttpClient]), i1.ɵdid(2, 245760, null, 0, i5.DispatchByJobComponent, [i6.ActivatedRoute, i7.JobService, i8.PreferenceService, i9.DispatchService, i10.JobEventService, i11.AssignmentService, i12.AuthenticationService, i13.Location, i14.TranslateService, i15.MatDialog, i3.JobLoadService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_DispatchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-dispatch", [], null, null, null, i16.View_MobileDispatchComponent_0, i16.RenderType_MobileDispatchComponent)), i1.ɵdid(1, 245760, null, 0, i17.MobileDispatchComponent, [i6.Router, i6.ActivatedRoute, i10.JobEventService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DispatchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "app-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispatchComponent_1)), i1.ɵdid(2, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispatchComponent_2)), i1.ɵdid(4, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.device.mobile; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.device.mobile; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_DispatchComponent_0 = View_DispatchComponent_0;
function View_DispatchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dispatch", [], null, null, null, View_DispatchComponent_0, RenderType_DispatchComponent)), i1.ɵdid(1, 49152, null, 0, i18.DispatchComponent, [i19.DeviceDetectorService], null, null)], null, null); }
exports.View_DispatchComponent_Host_0 = View_DispatchComponent_Host_0;
var DispatchComponentNgFactory = i1.ɵccf("dispatch", i18.DispatchComponent, View_DispatchComponent_Host_0, {}, {}, []);
exports.DispatchComponentNgFactory = DispatchComponentNgFactory;
