import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'map-status-marker',
  templateUrl: './map-status-marker.component.html',
  styleUrls: ['./map-status-marker.component.scss'],
})
export class MapStatusMarkerComponent implements OnInit, OnChanges {
  @Input() locationUpdate: any;
  @Input() markerType: string;
  @Input() markerStatus: string;
  markerBackground = '';

  ngOnInit() {
    this.setMarkerBackground();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setMarkerBackground();
  }

  setMarkerBackground(): void {
    if (this.markerType === 'driver-initials') {
      this.markerBackground = this.getDriverBackground();
    } else {
      this.markerBackground = this.getTruckBackground();
    }
  }

  getDriverBackground(): string {
    let status = this.locationUpdate.status || this.locationUpdate.tripStatus;
    let background = 'assets/img/map/truck-marker-unloading.png';

    if (this.markerStatus === 'each-segment') {
      switch (status) {
        case 'enroute_unloading':
          background = 'assets/img/map/truck-marker-enroute-unloading.png';
          break;
        case 'unloading':
          background = 'assets/img/map/truck-marker-unloading.png';
          break;
        case 'enroute_loading':
          background = 'assets/img/map/truck-marker-enroute-loading.png';
          break;
        case 'loading':
          background = 'assets/img/map/truck-marker-loading.png';
          break;
        default:
          background = 'assets/img/map/truck-marker-unloading.png';
          break;
      }
    } else if (this.markerStatus === 'load-status') {
      switch (status) {
        case 'enroute_unloading':
          background = 'assets/img/map/truck-marker-enroute-unloading.png';
          break;
        case 'unloading':
          background = 'assets/img/map/truck-marker-yellow.png';
          break;
        case 'enroute_loading':
          background = 'assets/img/map/truck-marker-enroute-loading.png';
          break;
        case 'loading':
          background = 'assets/img/map/truck-marker-enroute-unloading.png';
          break;
        default:
          background = 'assets/img/map/truck-marker-yellow.png';
          break;
      }
    }

    return background;
  }

  getTruckBackground(): string {
    let status = this.locationUpdate.status || this.locationUpdate.tripStatus;
    let background = 'assets/img/map/truck-number-bubble-green.png';

    if (this.markerStatus === 'each-segment') {
      switch (status) {
        case 'enroute_unloading':
          background = 'assets/img/map/truck-number-bubble-green.png';
          break;
        case 'unloading':
          background = 'assets/img/map/truck-number-bubble-dkgreen.png';
          break;
        case 'enroute_loading':
          background = 'assets/img/map/truck-number-bubble-ltblue.png';
          break;
        case 'loading':
          background = 'assets/img/map/truck-number-bubble-blue.png';
          break;
        default:
          background = 'assets/img/map/truck-number-bubble-dkgreen.png';
          break;
      }
    } else if (this.markerStatus === 'load-status') {
      switch (status) {
        case 'enroute_unloading':
          background = 'assets/img/map/truck-number-bubble-green.png';
          break;
        case 'unloading':
          background = 'assets/img/map/truck-number-bubble-yellow.png';
          break;
        case 'enroute_loading':
          background = 'assets/img/map/truck-number-bubble-yellow.png';
          break;
        case 'loading':
          background = 'assets/img/map/truck-number-bubble-green.png';
          break;
        default:
          background = 'assets/img/map/truck-number-bubble-yellow.png';
          break;
      }
    }

    return background;
  }
}
