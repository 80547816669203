"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var LocationUpdate = /** @class */ (function () {
    function LocationUpdate(locationInfo) {
        this.id = locationInfo.id;
        this.trip = locationInfo.trip;
        this.driver = locationInfo.driver;
        this.truck = locationInfo.truck;
        this.assignment = locationInfo.assignment;
        this.date = locationInfo.date;
        this.jobevent = locationInfo.jobevent;
        this.location = locationInfo.location;
        this.orientation = locationInfo.orientation;
        this.speed = locationInfo.speed;
        this.provider = locationInfo.provider;
        this.tripStatus = locationInfo.tripStatus || '';
        this.createdAt = locationInfo.createdAt && new Date(locationInfo.createdAt);
        var currentDate = moment();
        this.expired = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 10;
        this.moreThanThirtyMinutesAgo = this.createdAt && currentDate.diff(moment(this.createdAt), 'minutes') > 30;
    }
    return LocationUpdate;
}());
exports.LocationUpdate = LocationUpdate;
