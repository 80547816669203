<form #editJobDays="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add/Remove Days</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>

  <div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="errors" class="errors"><notification *ngFor="let error of errors">{{error}}</notification></div>

    <div class="content">
      <div *ngIf="job" class="job-days">
        <div class="form-content">
          <div class="field-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="field-group" fxFlex="100">
              <input type="hidden" name="dates" [(ngModel)]="model.dates" />
              <date-range-picker [config]="{multipleDates: true}"
                                 [selectedDates]="selectedDates"
                                 [highlightedDates]="existingDates"
                                 (dateChanged)="onDatesChanged($event)"></date-range-picker>
            </div>
          </div>
        </div>
        <div *ngIf="job.shift1StartTime === null || job.shift1StartTime === undefined" class="form-content shifts">
          <div class="field-row" fxLayout="row" fxLayoutAlign="space-around left">
            <div class="field-group" fxFlex="100">
              <input type="checkbox" [(ngModel)]="secondShift" attr.data-label="{{ 'Multiple Shifts' | translate }}" name="secondShift"/>
            </div>
          </div>
          <div class="field-row" fxLayout="row" fxLayoutAlign="left">
            <div class="field-group" fxFlex="50">
              <label class="required" translate>Shift 1 Start Time</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="shift1_start_time" 
                  class="timepicker"
                  [(ngModel)]="model.shift1_start_time"
                  (focusout)="checkShiftValidity()"
                />
              </div>
            </div>
            <div class="field-group" fxFlex="50">
              <label class="required" translate>Shift 1 End Time</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="shift1_end_time" 
                  class="timepicker"
                  [(ngModel)]="model.shift1_end_time"
                  (focusout)="checkShiftValidity()"
                />
              </div>
            </div>
            <notification translate *ngIf="job.shift1Overnight" level="warn">
              Warning: This shift will end on the next day
            </notification>
          </div>
          <div class="field-row" fxLayout="row" fxLayoutAlign="left"  *ngIf="secondShift">
            <div class="field-group shift" fxFlex="50">
              <label class="required" translate>Shift 2 Start Time</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="shift2_start_time" 
                  class="timepicker"
                  [(ngModel)]="model.shift2_start_time"
                  (focusout)="checkShiftValidity()"
                />
              </div>
            </div>
            <div class="field-group" fxFlex="50">
              <label class="required" translate>Shift 2 End Time</label>
              <div class="timepicker-wrapper">
                <input 
                  type="time" 
                  name="shift2_end_time" 
                  class="timepicker"
                  [(ngModel)]="model.shift2_end_time"
                  (focusout)="checkShiftValidity()"
                />
              </div>
            </div>
            <notification translate *ngIf="job.shift2Overnight" level="warn">
              Warning: This shift will end on the next day
            </notification>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-primary"
            (click)="editJobDays.form.valid && submit(); false"
            [disabled]="!shiftValid || editJobDays.untouched || loading"
            [ngClass]="{loading: loading}" translate>
      Save Schedule
    </button>
  </div>
</form>
