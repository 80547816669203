<div class="job-stats" *ngIf="jobEvent && jobEvent.stats" [ngClass]="{'mobile': device.mobile}">
  <span class="last-updated" translate>Last Updated {{ lastUpdated | moment: 'h:mm A' }}</span>
  <div class="main-stats">
    <div class="job-value">{{ (jobEvent.stats.totalAmount || 0) | currency:'USD':'symbol' }}</div>
    <div class="summary-stats">
      <div class="actual-values" translate>
        {{ jobEvent.stats[totalHoursField] | number:'1.2-2' }} hrs,
        {{ jobEvent.stats.totalLoads }} loads,
        <span [ngPlural]="jobEvent.stats.totalTons">
          <ng-template ngPluralCase="=0">{{ jobEvent.stats.totalTons }} {{ jobEvent.stats.pluralMeasurementUnit }}
          </ng-template>
          <ng-template ngPluralCase="=1">{{ jobEvent.stats.totalTons }} {{ jobEvent.stats.measurementUnit }}</ng-template>
          <ng-template ngPluralCase="other">{{ jobEvent.stats.totalTons }} {{ jobEvent.stats.pluralMeasurementUnit }}
          </ng-template>
        </span>
      </div>
      <div class="estimated-values" translate>
        Transit: {{ jobEvent.stats.weightInTransit }}
        {{ jobEvent.stats.weightInTransit !== 1 ? jobEvent.stats.pluralMeasurementUnit : jobEvent.stats.measurementUnit }} |
        Ticketed: {{ jobEvent.stats.ticketedWeight }}
        {{ jobEvent.stats.ticketedWeight !== 1 ? jobEvent.stats.pluralMeasurementUnit : jobEvent.stats.measurementUnit }}
      </div>
      <div class="estimated-values" translate>
        Estimated:
        {{ jobEvent.stats.dispatchedEstimatedNumberOfLoads }} loads,
        <span [ngPlural]="jobEvent.stats.dispatchedEstimatedNumberOfTons">
          <ng-template ngPluralCase="=0">{{ jobEvent.stats.dispatchedEstimatedNumberOfTons }}
            {{ jobEvent.stats.pluralMeasurementUnit }}</ng-template>
          <ng-template ngPluralCase="=1">{{ jobEvent.stats.dispatchedEstimatedNumberOfTons }}
            {{ jobEvent.stats.measurementUnit }}</ng-template>
          <ng-template ngPluralCase="other">{{ jobEvent.stats.dispatchedEstimatedNumberOfTons }}
            {{ jobEvent.stats.pluralMeasurementUnit }}</ng-template>
        </span>
      </div>
      <div class="estimated-values tons-per-hour" translate>
        Tons Per Hour: {{ jobEvent.stats.tonsPerHour | number:'1.1-2' }}
      </div>
    </div>
  </div>
  <div class="secondary-stats">
    <p translate>First Truck Loaded: <b>{{ jobEvent.stats.firstLoad }}</b></p>
    <div class="calculated-stat">
      <p class="bold">{{ jobEvent.stats.avgTripTimeFormatted || '&mdash;' }}</p>
      <p translate>Avg. Trip Time</p>
    </div>
    <div class="calculated-stat">
      <p class="bold green">{{ (jobEvent.stats.effectiveTonRate || 0) | currency:'USD':'symbol' }} / {{ jobEvent.stats.totalOrderedType }}</p>
      <p translate>Effective Rate</p>
    </div>
  </div>
  

  <div class="dispatch-count">
    <span [ngPlural]="jobEvent.stats.dispatchedDriversCount">
      <ng-template ngPluralCase="=0">{{ jobEvent.stats.dispatchedDriversCount }} Drivers dispatched</ng-template>
      <ng-template ngPluralCase="=1">{{ jobEvent.stats.dispatchedDriversCount }} Driver dispatched</ng-template>
      <ng-template ngPluralCase="other">{{ jobEvent.stats.dispatchedDriversCount }} Drivers dispatched</ng-template>
    </span>
  </div>
</div>
