"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var resource_1 = require("../resource");
var UserNotificationKind;
(function (UserNotificationKind) {
    UserNotificationKind["JobCreated"] = "job-created";
    UserNotificationKind["JobDayChanged"] = "job-day-changed";
    UserNotificationKind["JobEventShared"] = "jobevent-shared";
    UserNotificationKind["JobEventShareAccepted"] = "jobevent-share-accepted";
    UserNotificationKind["JobEventShareDeclined"] = "jobevent-share-declined";
    UserNotificationKind["JobEventUpdated"] = "jobevent_update";
    UserNotificationKind["NewConnectionInvitation"] = "new-connection-invitation";
    UserNotificationKind["NewConnectionAccepted"] = "new-connection-accepted";
    UserNotificationKind["OwnDriversDispatched"] = "own-drivers-dispatched";
    UserNotificationKind["SharedDriversDispatched"] = "shared-drivers-dispatched";
    UserNotificationKind["AugmentedFleetDriversDispatched"] = "af-drivers-dispatched";
    UserNotificationKind["AssignmentAccepted"] = "assignment-accept";
    UserNotificationKind["AssignmentRejected"] = "assignment-reject";
    UserNotificationKind["AssignmentUpdated"] = "assignment_update";
    UserNotificationKind["Dispatch"] = "dispatch";
    UserNotificationKind["QRCodeScanned"] = "qr_scanned";
    UserNotificationKind["Retake"] = "retake";
})(UserNotificationKind = exports.UserNotificationKind || (exports.UserNotificationKind = {}));
var UserNotification = /** @class */ (function (_super) {
    __extends(UserNotification, _super);
    function UserNotification() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return UserNotification;
}(resource_1.Resource));
exports.UserNotification = UserNotification;
