import { clone } from 'lodash';

import { Truck } from '../trucks/truck';
import { Profile } from './profile';
import { Conflict } from './conflict';
import { Trip } from '../trips/trip';
import { PunchCard } from '../punch-cards/punch-card';
import { Carrier } from '../carriers/carrier';
import { ImageResizer } from '../images/resizer';
import { User } from '../users/user';
import { DeviceStatusEvent } from '../shared/device-status-event';
import { Assignment } from '../assignments/assignment';
import { JobEventShare } from '../job-event-shares/job-event-share';
import { Organization } from '../organizations/organization';
import { LocationUpdate } from '../jobs/locationUpdate';

export const DRIVER_DUTY_OPTIONS = [
  { id: 'on-duty', name: 'On Duty' },
  { id: 'off-duty', name: 'Off Duty' }
];

export type DriverShift = {
  id: string;
  startTime: string;
  endTime: string;
};

export class Driver {
  id: string;
  profile: Profile;
  name: string;
  user: any;
  image: string;
  imageKey: string;
  lastStatus: string;
  truck: Truck;
  trucks: Truck[];
  truckName?: string;
  truckDriverDisplayName?: string;
  city: string;
  state: string;
  country: string;
  street: string;
  zipcode: string;
  activeShift: boolean;
  activeShifts: DriverShift[];
  cdl: string;
  trip: Trip;
  punchCard: PunchCard;
  carrier: Carrier;
  conflicts: Conflict[];
  conflictIds: string[];
  busy: boolean;
  username: string;
  requireAssignmentConfirmation: boolean;
  initials: string;
  carrierId: string;
  carrierOrganizationId: string;
  carrierOrganizationName: string;
  carrierOrganization: Organization;
  carrierName: string;
  customName: string;
  phoneNumber: string;
  tags: any[];
  uniqueBillingId: string;
  customFieldData: { [key: string]: string };
  selected = false;
  loading: boolean;
  classes: string;
  status: 'active' | 'suspended' | 'deleted';
  dutyStatus: 'on-duty' | 'off-duty';
  displayDutyStatus: string;
  displayShiftStatus: string;
  lastShiftId: string;
  lastDeviceStatusEvent: DeviceStatusEvent;
  deviceErrors: any[];
  lastModified: string;
  lastModifiedBy: User;

  trips: Trip[];
  latestTruck: Truck;
  loadCount: number;
  invoiceTotal: number;
  expenseTotal: number;
  timeTotal: string;
  assignments: Assignment[];
  assignmentsCount: number;
  punchCards: PunchCard[];
  allDecisionsApproved: boolean;
  allTripsApproved: string[];
  completedTripsCount: number;
  jobNames: string[];
  jobEventShares?: JobEventShare[] = [];
  lastLocationUpdateLoading = false;
  lastLocationUpdate?: LocationUpdate;
  truckContainerId?: string;

  constructor() { }

  get dispatchImage(): string {
    if (this.image && this.imageKey) {
      return ImageResizer.getResizedUrl(this.imageKey, 50, 50);
    }
  }

  get driverListImage(): string {
    if (this.image && this.imageKey) {
      return ImageResizer.getResizedUrl(this.imageKey, 52, 52);
    }
  }

  get jobDetailImage(): string {
    if (this.image && this.imageKey) {
      return ImageResizer.getResizedUrl(this.imageKey, 30, 30);
    }
  }

  get assignmentBubbleImage(): string {
    if (this.image && this.imageKey) {
      return ImageResizer.getResizedUrl(this.imageKey, 22, 22);
    }
  }

  isOnShift() {
    let value = false;

    if (!this.punchCard) { return value; }

    if (this.punchCard.startTimeTimestamp && !this.punchCard.endTimeTimestamp) {
      value = true;
    }

    return value;
  }

  isActive(trip, status = 'loading') {
    let value = false;
    if (!trip || trip.tripStatus === 'unloading_complete') { return value; }
    switch (status) {
      case 'en-route-one':
        value = trip.tripStatus === 'enroute_loading';
        break;
      case 'loading':
        value = trip.tripStatus === 'loading';
        break;
      case 'en-route-two':
        value = (trip.tripStatus === 'enroute_unloading' ||
                 trip.tripStatus === 'loading_complete');
        break;
      case 'unloading':
        value = trip.tripStatus === 'unloading';
        break;
      case 'any':
        value = !!trip.tripStatus;
        break;
    }
    return value;
  }

  isComplete(trip, status = 'loading') {
    let value = false;
    if (!trip || trip.tripStatus === 'unloading_complete') { return value; }

    switch (status) {
      case 'en-route-one':
        value = (trip.tripStatus === 'loading' ||
                 trip.tripStatus === 'loading_complete' ||
                 trip.tripStatus === 'enroute_unloading' ||
                 trip.tripStatus === 'unloading');
        break;
      case 'loading':
        value = (trip.tripStatus === 'loading_complete' ||
                 trip.tripStatus === 'enroute_unloading' ||
                 trip.tripStatus === 'unloading');
        break;
      case 'en-route-two':
        value = trip.tripStatus === 'unloading';
        break;
      case 'unloading':
        value = !!trip.unloadingCompleteTime;
        break;
    }
    return value;
  }

  get organizationName(): string {
    return this.carrier && this.carrier.name || this.carrierName;
  }

  otherConflicts(jobEvent) {
    let values = [];
    if (jobEvent && jobEvent.id && this.conflicts && this.conflicts.length) {
      values = this.conflicts.map(conflict => {
        if (conflict.jobevent !== jobEvent.id) { return conflict; }
      });
    }
    return values.filter(Boolean);
  }

  url(action: any): string {
    return '/drivers/' + this.id + '/' + action;
  }

  filterOptions(options: any): any[] {
    options = clone(options);

    return options;
  }
}
