<div class="container" [ngClass]="{driver_filters: filtersApplied}" *ngIf="!loading">
  <ng-container *ngIf="driver">
    <ruckit-driver-assignments-list [date]="reportDate" #driverAssignmentsList
                                    (assignmentDeleted)="getDriverAssignments()"
                                    (assignmentsReload)="getDriverAssignments()">
    </ruckit-driver-assignments-list>

    <mat-table #driverTable [dataSource]="dataSource">
      <ng-container matColumnDef="driver">
        <mat-header-cell *matHeaderCellDef translate>DRIVER/TRUCK</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="driver" *ngIf="row">
            <div class="driver-name">{{ row.name }}</div>
            <div class="truck-name sub-title">
              {{ row.latestTruck && row.latestTruck.displayName }}
            </div>
            <div class="organization-name sub-title">
              {{ row.organization }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="completed-trips">
        <mat-header-cell *matHeaderCellDef translate>COMPLETED TRIPS</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ (row && row.completedTripsCount) || 0 }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total-time">
        <mat-header-cell *matHeaderCellDef translate>TOTAL TIME</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row && row.timeTotal }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="invoice-total">
        <mat-header-cell *matHeaderCellDef translate>INVOICE TOTAL</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ (row && row.invoiceTotal || 0) | currency:'USD':'symbol':'1.2-4' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expense-total">
        <mat-header-cell *matHeaderCellDef translate>EXPENSE TOTAL</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ (row && row.expenseTotal || 0) | currency:'USD':'symbol':'1.2-4' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignments">
        <mat-header-cell *matHeaderCellDef translate>ASSIGNMENTS</mat-header-cell>
        <mat-cell *matCellDef="let row">
          <div class="assignment-count" (click)="showDriverAssignments(row)" *ngIf="row && row.assignmentsCount > 0">
            <span class="assignment-number">{{ row && row.assignmentsCount }}</span>
          </div>
          <div class="assignment-count no-driver" *ngIf="!row || row.assignmentsCount === 0">
            <span class="assignment-number">0</span>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="filters">
      <div class="left-section">
        <div class="segmented-buttons">
          <a (click)="switchView('trips')" class="btn btn-default" [ngClass]="{active: view === 'trips'}" translate>
            Trips
          </a>

          <a (click)="switchView('punch-cards')" class="btn btn-default" [ngClass]="{active: view === 'punch-cards'}" translate>
            Punch Cards
          </a>
        </div>

        <div class="unapproved">
          <input type="checkbox" name="hide_approved" (change)="toggleApproved()" />
          <label attr.data-label="{{ 'Only Show Unapproved' | translate }}" for="hide_approved"></label>
        </div>

        <div class="show-voided">
          <input type="checkbox" name="show_voided" (change)="toggleVoided()" />
          <label attr.data-label="{{ 'Show Voided' | translate }}" for="show_voided"></label>
        </div>

        <div class="show-unworked-jobs">
          <input type="checkbox" name="show_unworked_jobs" (change)="toggleUnworkedJobs()" />
          <label attr.data-label="{{ 'Show Unworked Jobs' | translate }}" for="show_unworked_jobs"></label>
        </div>
      </div>

      <div class="actions">
        <button class="btn btn-default" (click)="openAddTrip(driver)" translate>
          <i class="icon-plus"></i>
          Add Trip
        </button>
        <button
          *ngIf="
            (view === 'trips' &&
              (driver?.trips
                | getTripsByStatusAndSharePipe
                  : null
                  : 'unapproved'
                  : driver.jobEventShares) > 0) ||
            (view === 'punch-cards' &&
              (driver?.punchCards
                | getPunchCardsByStatusAndSharePipe
                  : null
                  : 'unapproved'
                  : driver.jobEventShares) > 0)
          "
          class="btn btn-primary"
          (click)="changeStatus()"
          translate
        >
          Approve All
        </button>
        <button
          *ngIf="
            (view === 'trips' &&
              (driver?.trips
                | getTripsByStatusAndSharePipe
                  : null
                  : 'unapproved'
                  : driver.jobEventShares) <= 0 &&
              (driver?.trips
                | getTripsByStatusAndSharePipe
                  : null
                  : 'approved'
                  : driver.jobEventShares) > 0) ||
            (view === 'punch-cards' &&
              (driver?.punchCards
                | getPunchCardsByStatusAndSharePipe
                  : null
                  : 'unapproved'
                  : driver.jobEventShares) <= 0 &&
              (driver?.punchCards
                | getPunchCardsByStatusAndSharePipe
                  : null
                  : 'approved'
                  : driver.jobEventShares) > 0)
          "
          class="btn btn-delete"
          (click)="changeStatus('pending')"
          translate
        >
          Unapprove All
        </button>
      </div>
    </div>

    <driver-daily-jobs *ngIf="view === 'trips'" [(keyStatus)]="keyStatus" [reportDate]="reportDate"
                      [view]="view" [driver]="driver" [hideApproved]="hideApproved" [showVoided]="showVoided"
                      [tripTimes]="tripTimes" [showUnworkedJobs]="showUnworkedJobs"
                      (shouldAuditDecisions)="shouldAuditDecisions.emit($event)"
                      (rateChanged)="rateChanged.emit(true)">
    </driver-daily-jobs>

    <driver-daily-punch-cards *ngIf="view === 'punch-cards'" [(keyStatus)]="keyStatus" [reportDate]="reportDate"
                      [view]="view" [driver]="driver" [hideApproved]="hideApproved" [showVoided]="showVoided"
                      [tripTimes]="tripTimes" [showUnworkedJobs]="showUnworkedJobs"
                      (shouldAuditDecisions)="shouldAuditDecisions.emit($event)"
                      (rateChanged)="rateChanged.emit(true)">
    </driver-daily-punch-cards>
  </ng-container>

  <div class="no-driver-selected" *ngIf="!driver">
    <p class="select-driver" translate>
      Select a driver on the left to view the associated End of Day Report.
    </p>
  </div>
</div>

<my-loader *ngIf="loading" class="full-height"></my-loader>
