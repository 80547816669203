"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var api_service_1 = require("../api.service");
var dropdown_component_1 = require("../dropdown/dropdown.component");
var RuckitDropdownComponent = /** @class */ (function () {
    function RuckitDropdownComponent(injector, translateService) {
        this.injector = injector;
        this.translateService = translateService;
        this.defaultConfig = {
            service: null,
            serviceFunction: 'list',
            serviceId: null,
            serviceFunctionScope: null,
            initialLoad: false,
            getFilter: null,
            selectText: 'Select Option',
            noResultsText: 'No Options',
            loadingText: 'Loading Options...',
            sortBy: 'name',
            sortDirection: 'asc',
            pageSize: 6,
            query: {},
            searchable: true,
            searchKey: 'search',
            group: false,
            showLoading: true,
            nameProperty: 'name',
            idProperty: 'id',
            groupProperty: null,
            optionIcon: false,
            iconProperty: 'icon',
            multiselect: false,
            inline: false,
            prefilledOptions: null,
            customOptionKeys: null,
            small: false,
            noneOption: false,
            includeFullObject: false
        };
        this.open = false;
        this.search = '';
        this.searching = false;
        this.errors = [];
        this.options = [];
        this.selectedItems = [];
        this.config = this.defaultConfig;
        this.backgroundColor = 'rgba(255, 255, 255, 0.15)';
        this.loading = false;
        this.disabled = false;
        this.toggle = false;
        this.onSelect = new core_1.EventEmitter();
        this.onUserSelect = new core_1.EventEmitter();
        this.nextPage = new core_1.EventEmitter();
        this.onSearch = new core_1.EventEmitter();
    }
    RuckitDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.config = __assign({}, this.defaultConfig, this.config);
        if (this.config.initialLoad) {
            this.getRecords();
        }
        this.onSearch.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.getRecords();
        });
    };
    RuckitDropdownComponent.prototype.ngOnDestroy = function () {
        if (this.recordReq && typeof this.recordReq.unsubscribe === 'function') {
            this.recordReq.unsubscribe();
        }
        if (this.recordsReq && typeof this.recordsReq.unsubscribe === 'function') {
            this.recordsReq.unsubscribe();
        }
        if (this.onSearch && typeof this.onSearch.unsubscribe === 'function') {
            this.onSearch.unsubscribe();
        }
    };
    RuckitDropdownComponent.prototype.ngOnChanges = function (changes) {
        this.config = __assign({}, this.defaultConfig, this.config);
        if ((changes.selectedOption && changes.selectedOption.firstChange) || (changes.selectedItems && changes.selectedItems.firstChange)) {
            this.getRecords();
        }
    };
    RuckitDropdownComponent.prototype.getRecord = function (recordId) {
        var _this = this;
        var _a;
        if (this.recordReq && typeof this.recordReq.unsubscribe === 'function') {
            this.recordReq.unsubscribe();
        }
        if (!this.config.service) {
            return;
        }
        this.loading = true;
        var service = this.injector.get(this.config.service);
        var serviceFunction = service.get(recordId);
        if (this.config.getFilter) {
            serviceFunction = service.get(this.config.serviceFunctionScope, (_a = {}, _a[this.config.getFilter] = recordId, _a));
        }
        this.recordsReq = serviceFunction.subscribe(function (option) {
            _this.setSelectedOption(option);
            _this.loading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
    };
    /**
   * @param  {} query={}
   * @returns void
   * This function would subscribe to the observable provided in config
   */
    RuckitDropdownComponent.prototype.getRecords = function (query) {
        var _this = this;
        var _a;
        if (query === void 0) { query = {}; }
        if (this.recordsReq && typeof this.recordsReq.unsubscribe === 'function') {
            this.recordsReq.unsubscribe();
        }
        if (!this.config.service) {
            return;
        }
        this.loading = true;
        var service = this.injector.get(this.config.service);
        var order = (this.config.sortDirection === 'asc' ? '' : '-') + this.config.sortBy;
        if (this.config.query) {
            query = __assign({}, query, this.config.query);
        }
        var searchKey = this.config.searchKey ? this.config.searchKey : 'search';
        this.fullQuery = __assign((_a = { ordering: order }, _a[searchKey] = this.search, _a.page_size = this.config.pageSize, _a), this.config.query, query);
        var serviceFunction = service[this.config.serviceFunction](this.fullQuery);
        if (this.config.serviceFunctionScope) {
            serviceFunction = this.config.serviceId ?
                service[this.config.serviceFunction](this.config.serviceId, this.config.serviceFunctionScope, this.fullQuery) :
                service[this.config.serviceFunction](this.config.serviceFunctionScope, this.fullQuery);
        }
        this.recordsReq = serviceFunction.subscribe(function (options) {
            var _a, _b;
            _this.options = _this.config['noneOption'] ?
                _this.config['prefilledOptions'] ? [
                    (_a = {}, _a[_this.config['nameProperty'] || 'name'] = _this.translateService.instant('None'), _a.id = null, _a)
                ].concat(_this.config['prefilledOptions'], options.map(function (option) { return _this.mapOption(option); })) : [
                    (_b = {}, _b[_this.config['nameProperty'] || 'name'] = _this.translateService.instant('None'), _b.id = null, _b)
                ].concat(options.map(function (option) { return _this.mapOption(option); })) :
                _this.config['prefilledOptions'] ? _this.config['prefilledOptions'].concat(options.map(function (option) { return _this.mapOption(option); })) :
                    options.map(function (option) { return _this.mapOption(option); });
            if (_this.selectedOption) {
                if (typeof _this.selectedOption === 'string') {
                    _this.getRecord(_this.selectedOption);
                }
                else {
                    var optionId = _this.selectedOption && _this.selectedOption['id'];
                    var option = lodash_1.find(_this.options, { id: optionId });
                    if (option) {
                        _this.setSelectedOption(option);
                    }
                    else if (optionId) {
                        _this.getRecord(optionId);
                    }
                }
            }
            _this.loading = false;
        }, function (error) {
            _this.errors = api_service_1.parseErrors(error);
            _this.loading = false;
        });
    };
    RuckitDropdownComponent.prototype.setSelectedOption = function (option) {
        if (!option) {
            return;
        }
        this.selectedOption = option;
        this.options = lodash_1.reject(this.options, option);
        this.options.unshift(option);
        this.options = lodash_1.uniqBy(this.options, 'id');
        this.onSelect.emit(option);
        if (this.dropdownComponent) {
            this.dropdownComponent.selectedOption = option;
        }
    };
    RuckitDropdownComponent.prototype.toggleOption = function (option) {
        this.onSelect.emit(option);
        this.onUserSelect.emit(option);
    };
    RuckitDropdownComponent.prototype.deselectOptions = function () {
        this.dropdownComponent.deselectAll();
    };
    RuckitDropdownComponent.prototype.changeSearch = function (term) {
        this.onSearch.next(term);
    };
    RuckitDropdownComponent.prototype.onScroll = function () {
        var _this = this;
        var service = this.injector.get(this.config.service);
        var serviceFunction = service.listNext();
        if (service.nextUris && this.config.serviceFunctionScope) {
            service.nextUri = service.nextUris[this.config.serviceFunctionScope];
            serviceFunction = service.listNext(this.config.serviceFunctionScope);
        }
        if (service.nextUri) {
            this.dropdownComponent.loading = true;
            this.recordsReq = serviceFunction.subscribe(function (options) {
                // const idProperty = this.config.idProperty || this.defaultConfig.idProperty;
                var uniqueOptions = options
                    .map(function (u) { return _this.mapOption(u); })
                    .filter(function (opt) { return !_this.options.some(function (o) { return o.id === opt.id; }); });
                if (uniqueOptions && uniqueOptions.length) {
                    _this.options = _this.options.concat(uniqueOptions);
                }
            }, function () { }, // error
            function () {
                _this.dropdownComponent.loading = false;
            });
        }
        else {
            this.dropdownComponent.loading = false;
        }
    };
    RuckitDropdownComponent.prototype.mapOption = function (option) {
        var element = {
            id: lodash_1.get(option, this.config.idProperty),
            group: null,
            icon: null
        };
        if (this.config.nameProperty) {
            element[this.config.nameProperty] = option[this.config.nameProperty] || option['name'];
        }
        else {
            element['name'] = option['name'];
        }
        if (this.config.group) {
            element.group = option[this.config.groupProperty] || option['group'];
        }
        if (this.config.optionIcon) {
            element.icon = option[this.config.iconProperty] || option['icon'];
        }
        if (this.config.customOptionKeys) {
            this.config.customOptionKeys.forEach(function (key) {
                element[key] = option[key];
            });
        }
        if (this.config.includeFullObject) {
            element = __assign({}, option, element);
        }
        return element;
    };
    RuckitDropdownComponent.prototype.addAnOptionToStart = function (option) {
        this.options.unshift(option);
    };
    return RuckitDropdownComponent;
}());
exports.RuckitDropdownComponent = RuckitDropdownComponent;
