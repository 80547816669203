import {
  OnInit, Component, Input, EventEmitter, Output, ViewChild, ElementRef,
  OnChanges, Injector, SimpleChanges
} from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { find as _find, reject, uniqBy, get as _get } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

import { parseErrors } from '../api.service';
import { DropdownComponent } from '../dropdown/dropdown.component';

export type DropdownConfig = {
  service: any | null,
  serviceFunction: string,
  serviceId: string,
  serviceFunctionScope: string,
  initialLoad: boolean,
  getFilter: string,
  selectText: string,
  noResultsText: string,
  loadingText: string,
  sortBy: string,
  sortDirection: string,
  pageSize: number,
  query: any,
  searchable: boolean,
  searchKey: string,
  group: boolean,
  showLoading: boolean,
  nameProperty: string,
  idProperty: string,
  groupProperty: string,
  optionIcon: boolean,
  iconProperty: string,
  multiselect: boolean,
  inline: boolean,
  prefilledOptions: any[],
  customOptionKeys: string[],
  small: boolean,
  noneOption: boolean,
  includeFullObject: boolean
};

@Component({
  selector: 'ruckit-dropdown',
  templateUrl: './ruckit-dropdown.component.html',
  styleUrls: ['./ruckit-dropdown.component.scss']
})
export class RuckitDropdownComponent implements OnInit, OnChanges {
  defaultConfig: DropdownConfig = {
    service: null,
    serviceFunction: 'list',
    serviceId: null,
    serviceFunctionScope: null,
    initialLoad: false,
    getFilter: null,
    selectText: 'Select Option',
    noResultsText: 'No Options',
    loadingText: 'Loading Options...',
    sortBy: 'name',
    sortDirection: 'asc',
    pageSize: 6,
    query: {},
    searchable: true,
    searchKey: 'search',
    group: false,
    showLoading: true,
    nameProperty: 'name',
    idProperty: 'id',
    groupProperty: null,
    optionIcon: false,
    iconProperty: 'icon',
    multiselect: false,
    inline: false,
    prefilledOptions: null,
    customOptionKeys: null,
    small: false,
    noneOption: false,
    includeFullObject: false
  };
  fullQuery: {};
  @Input() open = false;
  autocompleteTerm;
  search = '';
  searching = false;
  errors = [];
  @Input() options = [];
  searchReq: Subscription;
  recordReq: Subscription;
  recordsReq: Subscription;

  @ViewChild('optionsEl', { static: false }) optionsElRef: ElementRef;
  @Input() selectedOption: Object | string;
  @Input() selectedItems = [];
  @Input() icon;
  @Input() config: DropdownConfig = this.defaultConfig;
  @Input() backgroundColor = 'rgba(255, 255, 255, 0.15)';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() toggle = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUserSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter();
  @ViewChild('dropdownComponent', { static: false }) dropdownComponent: DropdownComponent;

  constructor(
    public injector: Injector,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.config = { ...this.defaultConfig, ...this.config };
    if (this.config.initialLoad) { this.getRecords(); }
    this.onSearch.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.getRecords();
    });
  }

  ngOnDestroy() {
    if (this.recordReq && typeof this.recordReq.unsubscribe === 'function') {
      this.recordReq.unsubscribe();
    }

    if (this.recordsReq && typeof this.recordsReq.unsubscribe === 'function') {
      this.recordsReq.unsubscribe();
    }
    if (this.onSearch && typeof this.onSearch.unsubscribe === 'function') {
      this.onSearch.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.config = { ...this.defaultConfig, ...this.config };
    if ((changes.selectedOption && changes.selectedOption.firstChange) || (changes.selectedItems && changes.selectedItems.firstChange)) {
      this.getRecords();
    }
  }

  getRecord(recordId: string): void {
    if (this.recordReq && typeof this.recordReq.unsubscribe === 'function') {
      this.recordReq.unsubscribe();
    }
    if (!this.config.service) { return; }

    this.loading = true;
    let service = this.injector.get<any>(this.config.service);
    let serviceFunction = service.get(recordId);
    if (this.config.getFilter) {
      serviceFunction = service.get(this.config.serviceFunctionScope, { [this.config.getFilter]: recordId });
    }

    this.recordsReq = serviceFunction.subscribe(option => {
      this.setSelectedOption(option);
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  /**
 * @param  {} query={}
 * @returns void
 * This function would subscribe to the observable provided in config
 */
  getRecords(query = {}): void {
    if (this.recordsReq && typeof this.recordsReq.unsubscribe === 'function') {
      this.recordsReq.unsubscribe();
    }

    if (!this.config.service) { return; }

    this.loading = true;
    let service = this.injector.get<any>(this.config.service);
    let order = (this.config.sortDirection === 'asc' ? '' : '-') + this.config.sortBy;
    if (this.config.query) { query = { ...query, ...this.config.query }; }
    const searchKey = this.config.searchKey ? this.config.searchKey : 'search';
    this.fullQuery = {
      ordering: order,
      [searchKey]: this.search,
      page_size: this.config.pageSize,
      ...this.config.query,
      ...query
    };

    let serviceFunction = service[this.config.serviceFunction](this.fullQuery);
    if (this.config.serviceFunctionScope) {
      serviceFunction = this.config.serviceId ?
        service[this.config.serviceFunction](this.config.serviceId, this.config.serviceFunctionScope, this.fullQuery) :
        service[this.config.serviceFunction](this.config.serviceFunctionScope, this.fullQuery);
    }

    this.recordsReq = serviceFunction.subscribe(options => {
      this.options = this.config['noneOption'] ?
        this.config['prefilledOptions'] ?
          [
            {[this.config['nameProperty'] || 'name']: this.translateService.instant('None'), id: null},
            ...this.config['prefilledOptions'],
            ...options.map(option => this.mapOption(option))
          ] :
          [
            {[this.config['nameProperty'] || 'name']: this.translateService.instant('None'), id: null},
            ...options.map(option => this.mapOption(option))
          ] :
        this.config['prefilledOptions'] ?
          [ ...this.config['prefilledOptions'], ...options.map(option => this.mapOption(option)) ] :
          options.map(option => this.mapOption(option));
      if (this.selectedOption) {
        if (typeof this.selectedOption === 'string') {
          this.getRecord(this.selectedOption);
        } else {
          const optionId = this.selectedOption && this.selectedOption['id'];
          let option = _find(this.options, { id: optionId });
          if (option) {
            this.setSelectedOption(option);
          } else if (optionId) {
            this.getRecord(optionId);
          }
        }
      }
      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  setSelectedOption(option): void {
    if (!option) { return; }
    this.selectedOption = option;
    this.options = reject(this.options, option);
    this.options.unshift(option);
    this.options = uniqBy(this.options, 'id');
    this.onSelect.emit(option);
    if (this.dropdownComponent) {
      this.dropdownComponent.selectedOption = option;
    }
  }

  toggleOption(option): void {
    this.onSelect.emit(option);
    this.onUserSelect.emit(option);
  }

  deselectOptions(): void {
    this.dropdownComponent.deselectAll();
  }

  changeSearch(term?: string): void {
    this.onSearch.next(term);
  }

  onScroll(): void {
    let service = this.injector.get<any>(this.config.service);
    let serviceFunction = service.listNext();
    if (service.nextUris && this.config.serviceFunctionScope) {
      service.nextUri = service.nextUris[this.config.serviceFunctionScope];
      serviceFunction = service.listNext(this.config.serviceFunctionScope);
    }
    if (service.nextUri) {
      this.dropdownComponent.loading = true;
      this.recordsReq = serviceFunction.subscribe(
        (options) => {
          // const idProperty = this.config.idProperty || this.defaultConfig.idProperty;

          const uniqueOptions = options
            .map((u) => this.mapOption(u))
            .filter((opt) => !this.options.some((o) => o.id === opt.id));

          if (uniqueOptions && uniqueOptions.length) {
            this.options = [...this.options, ...uniqueOptions];
          }
        },
        () => {}, // error
        () => { // complete
          this.dropdownComponent.loading = false;
        });
    } else {
      this.dropdownComponent.loading = false;
    }
  }

  mapOption(option: any) {
    let element = {
      id: _get(option, this.config.idProperty),
      group: null,
      icon: null
    };
    if (this.config.nameProperty) {
      element[this.config.nameProperty] = option[this.config.nameProperty] || option['name'];
    } else {
      element['name'] = option['name'];
    }
    if (this.config.group) {
      element.group = option[this.config.groupProperty] || option['group'];
    }
    if (this.config.optionIcon) {
      element.icon = option[this.config.iconProperty] || option['icon'];
    }
    if (this.config.customOptionKeys) {
      this.config.customOptionKeys.forEach(key => {
        element[key] = option[key];
      });
    }

    if (this.config.includeFullObject) {
      element = {...option, ...element};
    }

    return element;
  }

  addAnOptionToStart(option: any) {
    this.options.unshift(option);
  }
}
