"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var moment = require("moment-timezone");
var lodash_1 = require("lodash");
var common_1 = require("@angular/common");
var job_service_1 = require("../jobs/job.service");
var job_event_service_1 = require("../job-events/job-event.service");
var job_event_stat_service_1 = require("../job-event-stats/job-event-stat.service");
var collaborator_service_1 = require("./collaborator.service");
var shared_1 = require("../shared");
var publish_job_dialog_component_1 = require("../jobs/publish-job-dialog.component");
var collaborator_list_component_1 = require("./collaborator-list/collaborator-list.component");
var app_constants_1 = require("../app.constants");
var CollaboratorsComponent = /** @class */ (function () {
    function CollaboratorsComponent(route, router, location, jobService, jobEventService, jobEventStatService, collaboratorService, authenticationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.location = location;
        this.jobService = jobService;
        this.jobEventService = jobEventService;
        this.jobEventStatService = jobEventStatService;
        this.collaboratorService = collaboratorService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.jobEvents = [];
        this.jobDays = [];
        this.enabledDays = [];
        this.addingCollaborator = false;
        this.sortAsc = true;
        this.collaborators = [];
        this.pristineCollaborators = [];
        this.loading = true;
        this.jobLoading = true;
        this.jobEventsLoading = true;
        this.daysLoading = true;
        this.weightOptions = app_constants_1.JOBWEIGHTOPTIONS.slice();
        this.rateTrackingOptions = [
            { value: 'load', label: 'Load', name: 'Load' },
            { value: 'hour', label: 'Hour', name: 'Hour' }
        ];
        this.rateTrackingConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.startDate = new Date('2017-01-01');
        this.endDate = new Date('2037-12-31');
        this.datesOptions = {
            min: this.startDate,
            max: this.endDate
        };
        this.actionsDropdownOptions = [];
        this.actionsDropdownConfig = {
            nameProperty: 'name',
            loadingOptions: false
        };
        this.returnToTitle = 'Back';
        this.days = {};
        this.errors = [];
        this.publishJobCallback = function (e) {
            // Update Published Status
        };
        this.extendCollaborationCallback = function (collaborator) {
            var index = lodash_1.findIndex(_this.collaborators, { id: collaborator.id });
            _this.collaborators.splice(index, 1, collaborator);
        };
    }
    CollaboratorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sortBy = 'status';
        this.route.fragment.subscribe(function (fragment) {
            _this.addingCollaborator = fragment === 'addCollaborator' ? true : false;
        });
        this.route.params.forEach(function (params) {
            _this.jobId = params['jobId'];
            _this.jobEventId = params['jobEventId'];
            if (_this.jobEventId) {
                _this.getJob(_this.jobEventId);
            }
        });
        this.route.queryParams.forEach(function (qparams) {
            if (qparams['returnTo'] && qparams['returnTo'].length) {
                _this.returnTo = qparams['returnTo'];
            }
            if (qparams['returnToTitle'] && qparams['returnToTitle'].length) {
                _this.returnToTitle = qparams['returnToTitle'];
            }
        });
    };
    CollaboratorsComponent.prototype.ngOnDestroy = function () {
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
    };
    CollaboratorsComponent.prototype.isSendable = function () {
        // TODO: What scenarios would render a Job "unsendable"?
        return true;
    };
    CollaboratorsComponent.prototype.isDispatchable = function () {
        if (this.jobEvent && this.jobEvent.job && this.jobEvent.job.project && this.jobEvent.job.project.customerOrganization) {
            var organization = this.authenticationService.getOrganization();
            if (organization && organization.id !== this.jobEvent.job.project.customerOrganization.id) {
                var endDate = new Date(this.jobEvent.jobEnd);
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                if (endDate >= today) {
                    return true;
                }
            }
        }
        return false;
    };
    CollaboratorsComponent.prototype.openShareDialog = function () {
        if (this.isSendable()) {
            this.dialogRef = this.dialog.open(publish_job_dialog_component_1.PublishJobDialogComponent, {
                width: '870px',
                data: { job: this.job, jobevent: this.jobEvent }
            });
            this.dialogRef.componentInstance.callback = this.publishJobCallback;
        }
    };
    CollaboratorsComponent.prototype.addActionButtons = function () {
        if (this.jobEvent && this.jobEvent.canEdit) {
            this.actionsDropdownOptions.push({ name: 'Edit Job Days', button: true });
        }
    };
    CollaboratorsComponent.prototype.validMultipliers = function (collaborator) {
        var _this = this;
        collaborator.multipliers.forEach(function (multiplier) {
            if (multiplier.haulPercentage < 0 || multiplier.haulPercentage > 100) {
                _this.errors = ['Ensure any percentages are between 0 and 100.'];
                return false;
            }
        });
        return true;
    };
    CollaboratorsComponent.prototype.submit = function (collaborator, range) {
        var _this = this;
        if (range === void 0) { range = null; }
        if (range === 'all') {
            collaborator['allDays'] = true;
        }
        else if (range === 'future') {
            collaborator['future'] = true;
        }
        else {
            collaborator['allDays'] = false;
            collaborator['future'] = false;
        }
        if (collaborator.multipliers && collaborator.multipliers.length > 0) {
            collaborator['invoiceType'] = this.jobEvent.invoiceType;
            if (this.validMultipliers(collaborator) === false) {
                return;
            }
        }
        this.collaboratorService.save(this.jobEventId, collaborator).subscribe(function () {
            collaborator.editing = false;
            _this.loading = false;
        }, function (err) {
            console.error(err);
            _this.loading = false;
        });
    };
    CollaboratorsComponent.prototype.getJob = function (jobEventId) {
        var _this = this;
        if (jobEventId === void 0) { jobEventId = null; }
        this.jobLoading = true;
        this.jobService.get(this.jobId).subscribe(function (job) {
            _this.job = job;
            _this.getJobDays(_this.jobId);
            if (_this.job.allowWeight) {
                _this.rateTrackingOptions = _this.rateTrackingOptions.concat([
                    { value: 'weight', label: 'Weight', name: 'Weight' }
                ]);
            }
            var start = new Date(_this.jobDate);
            var jobStart = new Date(_this.job.startDate);
            if (start && jobStart > start) {
                _this.setDefaultDate();
                start = new Date(_this.jobDate);
            }
            start.setHours(0, 0, 0, 0);
            var end = lodash_1.clone(start);
            end.setHours(23, 59, 59, 999);
            if (jobEventId) {
                _this.getJobEvent(jobEventId);
            }
            else {
                _this.getJobEvents({
                    shift1_start__gte: start.toISOString(),
                    shift1_start__lte: end.toISOString()
                }, true);
            }
            _this.addActionButtons();
        });
    };
    CollaboratorsComponent.prototype.getJobDays = function (jobId) {
        var _this = this;
        if (this.daysReq && typeof this.daysReq.unsubscribe === 'function') {
            this.daysReq.unsubscribe();
        }
        this.daysLoading = true;
        this.daysReq = this.jobService.getDays(jobId).subscribe(function (days) {
            _this.jobDays = days.map(function (day) {
                var parsedDate = moment(day);
                return { day: parsedDate.date(), month: parsedDate.month() + 1, year: parsedDate.year() };
            });
            _this.enabledDays = lodash_1.clone(_this.jobDays);
            _this.datesOptions = {
                min: _this.startDate,
                max: _this.endDate,
            };
            _this.daysLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.daysLoading = false;
        });
    };
    CollaboratorsComponent.prototype.setDefaultDate = function () {
        var d = new Date();
        if (this.job && this.job.startDate) {
            var start = new Date(this.job.startDate);
            start.setDate(start.getDate() + 1);
            if (start > d) {
                d = start;
            }
        }
        this.jobDate = d;
    };
    CollaboratorsComponent.prototype.showAddCollaboratorForm = function () {
        var _this = this;
        this.addingCollaborator = true;
        // NOTE: setTimeout is required to ensure full loading of the Add
        // Collaborator Form. Otherwise, the view isn't scrolled all the way down.
        setTimeout(function () {
            _this.contentContainer.nativeElement.scrollTop = _this.contentContainer.nativeElement.scrollHeight;
        }, 10);
    };
    CollaboratorsComponent.prototype.collaboratorAdded = function (jobEventShare) {
        if (jobEventShare) {
            this.addingCollaborator = false;
            if (this.collaboratorList) {
                this.collaboratorList.reloadRecords();
            }
        }
    };
    CollaboratorsComponent.prototype.getJobEvent = function (jobEventId) {
        var _this = this;
        this.jobEventService.getJobEvent(jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            _this.getJobEventStats();
            _this.getJobEvents({ job: _this.jobId });
            _this.jobEventId = jobEvent && jobEvent.id;
            if (_this.jobEvent) {
                _this.jobDate = new Date(jobEvent.shift1StartTimestamp);
                if (_this.jobEvent.multipliers && _this.jobEvent.multipliers.length > 0) {
                    _this.rateTrackingOptions = _this.rateTrackingOptions.concat([
                        { value: 'percentage', label: 'Percentage', name: 'Percentage' }
                    ]);
                }
            }
            _this.jobLoading = false;
        }, function (err) {
            _this.errors = err;
            _this.jobLoading = false;
        });
    };
    CollaboratorsComponent.prototype.getJobEvents = function (query, selectFirst) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (selectFirst === void 0) { selectFirst = false; }
        if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
            this.jobEventsReq.unsubscribe();
        }
        this.jobEventsReq = this.jobEventService.getJobEvents(__assign({ ordering: 'shift1_start', job: this.job && this.job.id }, query)).subscribe(function (jobEvents) {
            _this.jobEvents = jobEvents;
            if (selectFirst) {
                _this.selectJobEvent(_this.jobEvents[0]);
                _this.jobLoading = false;
            }
            if (_this.jobEvent) {
                var jobEvent = lodash_1.find(_this.jobEvents, { id: _this.jobEventId });
                if (jobEvent) {
                    _this.jobEvents = lodash_1.reject(_this.jobEvents, jobEvent);
                }
                else {
                    jobEvent = _this.jobEvent;
                }
                _this.jobEvents.unshift(jobEvent);
                if (_this.jobEventsDropdown) {
                    _this.jobEventsDropdown.selectedOption = jobEvent;
                }
                _this.jobEventsLoading = false;
            }
        }, function (err) {
            _this.errors = err;
            _this.jobLoading = false;
            _this.jobEventsLoading = false;
        });
    };
    CollaboratorsComponent.prototype.datepickerToDateFormat = function (value) {
        var _day = value;
        var timezone = moment.tz.guess();
        var date = new Date(_day.toDateString() + ' 00:00:00');
        return moment.tz(date, timezone).toDate();
    };
    CollaboratorsComponent.prototype.getNextDate = function (selectedDate, advance) {
        var _this = this;
        if (advance === void 0) { advance = true; }
        var _dates = this.enabledDays.map(function (day) {
            return _this.datepickerToDateFormat(day);
        });
        _dates = lodash_1.sortBy(_dates, function (d) { return d; });
        var pickedDate = selectedDate;
        var futureDates = _dates.filter(function (date) {
            return (date.getTime() - pickedDate.getTime()) > 0;
        });
        var pastDates = _dates.filter(function (date) {
            return (date.getTime() - pickedDate.getTime()) < 0;
        });
        if (advance) {
            return futureDates[0];
        }
        else {
            return pastDates[pastDates.length - 1];
        }
    };
    CollaboratorsComponent.prototype.selectJobEvent = function (jobEvent) {
        this.jobEvent = jobEvent;
        this.jobEventId = this.jobEvent && this.jobEvent.id;
        this.getJobEventStats();
        if (this.job && this.jobEvent) {
            this.location.replaceState('jobs/' + this.jobId + '/' + this.jobEventId + '/collaborators');
            if (this.jobEvent.multipliers && this.jobEvent.multipliers.length > 0) {
                this.rateTrackingOptions = this.rateTrackingOptions.concat([
                    { value: 'percentage', label: 'Percentage', name: 'Percentage' }
                ]);
            }
        }
        if (this.collaboratorList) {
            this.collaboratorList.reloadRecords();
        }
    };
    CollaboratorsComponent.prototype.getJobEventStats = function () {
        var _this = this;
        var jobEventId = this.jobEvent && this.jobEvent.id;
        var effectiveRateCalculator = '';
        var enabledFeatures = this.authenticationService.enabledFeatures();
        if (enabledFeatures && enabledFeatures.includes('effectiveRateCalculator')) {
            effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
        }
        this.jobEventStatService.getStats(jobEventId, {
            calculator: effectiveRateCalculator
        }).subscribe(function (stats) {
            _this.jobEvent.stats = stats;
        }, function (err) { return console.error(err); });
    };
    return CollaboratorsComponent;
}());
exports.CollaboratorsComponent = CollaboratorsComponent;
