"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
// angular material
var material_1 = require("@angular/material");
// services
var preference_service_1 = require("../preferences/preference.service");
var shared_1 = require("../shared");
// serializers
var preference_serializer_1 = require("../preferences/preference.serializer");
var EditUserPreferencesComponent = /** @class */ (function () {
    function EditUserPreferencesComponent(preferenceService, authenticationService, dialog) {
        this.preferenceService = preferenceService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.loading = false;
        this.errors = [];
        this.preference = new preference_serializer_1.PreferenceSerializer().fromJson({
            blob: {
                dispatchLoadType: 'all-day',
                mapStyle: 'ruckit-map-style',
                markerType: 'driver-initials',
                markerStatus: 'each-segment',
                staggerAssignmentTime: 'staggered_start',
            },
        });
        this.preferenceKey = 'GeneralUserPreferences';
    }
    EditUserPreferencesComponent.prototype.ngOnInit = function () {
        this.getPreferences();
    };
    EditUserPreferencesComponent.prototype.selectDispatchLoadType = function (event) {
        this.preference.blob['dispatchLoadType'] = event.target.value;
    };
    EditUserPreferencesComponent.prototype.selectDispatchLoadCount = function (event) {
        this.preference.blob['dispatchLoadCount'] = event.target.value;
    };
    EditUserPreferencesComponent.prototype.selectMapStyle = function (event) {
        this.preference.blob['mapStyle'] = event.target.value;
    };
    EditUserPreferencesComponent.prototype.selectMarkerType = function (event) {
        this.preference.blob['markerType'] = event.target.value;
    };
    EditUserPreferencesComponent.prototype.assignmentTimeChanged = function (event) {
        this.preference.blob['staggerAssignmentTime'] = event.currentTarget.checked
            ? 'staggered_start'
            : 'shift1_start';
    };
    EditUserPreferencesComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        if (this.preference && this.preference.id) {
            this.preferenceService.get(this.preference.id).subscribe(function (preference) {
                _this.preference = preference;
            });
        }
        else {
            this.preferenceService
                .list({
                name: this.preferenceKey,
                type: 'user',
                profile: currentUser && currentUser.id,
            })
                .subscribe(function (preferences) {
                if (preferences && preferences.length) {
                    _this.preference = preferences[0];
                }
            });
        }
    };
    EditUserPreferencesComponent.prototype.savePreferences = function () {
        var _this = this;
        this.loading = true;
        if (this.preferenceKey && this.user) {
            this.preference = __assign({}, this.preference, { name: this.preferenceKey, type: 'user', profile: this.user && this.user.id });
            this.preferenceService.save(this.preference).subscribe(function (preference) {
                _this.preference = preference;
                _this.loading = false;
            });
        }
    };
    return EditUserPreferencesComponent;
}());
exports.EditUserPreferencesComponent = EditUserPreferencesComponent;
