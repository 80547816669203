<div class="app-content order-summary-status" [ngClass]="{'mobile': device.mobile}">
  <!-- <my-toolbar class="mobile" title="{{ jobEvent && jobEvent.jobDisplayName }}">
    <div right-last>
      <action-menu icon="icon-more">
        <a *ngFor="let option of jobEventStatuses" class="action" mat-menu-item (click)="option.action()">{{ option.name }}</a>
      </action-menu>
    </div>
  </my-toolbar> -->
  <my-loader *ngIf="loading"></my-loader>
  <div class="container">
    <p class="light gray" *ngIf="lastUpdated" translate>Last Updated {{ lastUpdated | moment: 'h:mm A' }}</p>
    <div class="progress-bar">
      <p *ngIf="orderStats" translate>
        {{ this.completedPercentage }}% Completed
      </p>
      <div *ngIf="orderStats" class="progress" [ngStyle]="{'width': this.completedPercentage + '%'}"></div>
    </div>
    <div class="info overview" *ngIf="jobEvent" (click)="changeOrderStats()">
      <div class="stat">
        <p>{{ orderStats[activeStats].delivered }} {{
          activeStats === 'weight' ? jobEvent.haulWeightUnit : 'load' | translate
        }}</p>
        <p class="light" translate>Delivered</p>
      </div>
      <div class="stat">
        <p>{{ orderStats[activeStats].remaining }} {{ jobEvent.haulWeightUnit | translate }}</p>
        <p class="light">{{ activeStats === 'weight' ? 'Remaining' : 'In Transit' | translate }}</p>
      </div>
      <div class="stat">
        <p>{{ orderStats[activeStats].target }} {{
          activeStats === 'weight' ? jobEvent.haulWeightUnit : 'trucks' | translate
        }}</p>
        <p class="light">{{ activeStats === 'weight' ? 'Target' : 'Dispatched' | translate }}</p>
      </div>
    </div>
    <div class="info overview rates" *ngIf="jobEvent">
      <div class="stat">
        <p class="large approved">{{ jobEvent.rate | currency : 'USD' : 'symbol' : '1.2-4' }} / {{ jobEvent.invoiceRateUnit }}</p>
        <p class="light" translate>Invoice Rate</p>
      </div>
      <div class="stat">
        <p class="large approved">{{ jobEventStats ? (jobEventStats.effectiveTonRate | currency : 'USD' : 'symbol' : '1.2-4') : ('Loading' | translate) }} / {{ jobEventStats && jobEventStats.totalOrderedType }}</p>
        <p class="light" translate>Effective Rate</p>
      </div>
    </div>
    <div class="info overview first-load" *ngIf="jobEventStats && jobEventStats.firstLoad">
      <div class="stat">
        <p class="large">{{ jobEventStats.firstLoad }}</p>
        <p class="light" translate>First Truck Loaded</p>
      </div>
    </div>
    <div class="info truck">
      <p translate>Next Truck to Site</p>
      <ng-container *ngIf="nextTruck; else emptyNextTruck">
        <p>{{ nextTruck.name }}</p><div class="break"></div>
        <h2>{{ nextTruck.eta }}</h2><p class="light" translate>miles away</p><h2>{{ nextTruck.weight }}</h2><p class="light">{{ jobEvent.haulWeightUnit }}</p>
        <button class="btn btn-primary-inverted" (click)="openTicketDetails(nextTruck.ticket)" translate>View Ticket</button>
      </ng-container>
      <ng-template #emptyNextTruck>
        <div class="break"></div>
        <i class="icon-fleet"></i>
        <p class="color">No Active Trucks</p>
      </ng-template>
    </div>
    <div class="info truck">
      <p translate>Following Truck</p>
      <ng-container *ngIf="followingTruck; else emptyFollowingTruck">
        <p>{{ followingTruck.name }}</p><div class="break"></div>
        <h2>{{ followingTruck.eta }}</h2><p class="light" translate>miles away</p><h2>{{ followingTruck.weight }}</h2><p class="light">{{ jobEvent.haulWeightUnit }}</p>
        <button class="btn btn-primary-inverted" (click)="openTicketDetails(followingTruck.ticket)" translate>View Ticket</button>
      </ng-container>
      <ng-template #emptyFollowingTruck>
        <div class="break"></div>
        <i class="icon-fleet"></i>
        <p class="color" translate>No Active Trucks</p>
      </ng-template>
    </div>
    <div class="info truck-list">
      <div class="list">
        <div class="header">
          <div class="count"><p>{{ tripsEnroute.length }}</p></div>
          <div class="label"><p class="light" translate>Trucks Enroute</p></div>
        </div>
        <p *ngFor="let trip of tripsEnroute">{{ trip.truckName }}</p>
      </div>
      <div class="list">
        <div class="header">
          <div class="count"><p>{{ tripsOnSite.length }}</p></div>
          <div class="label"><p class="light" translate>Trucks On Site</p></div>
        </div>
        <p *ngFor="let trip of tripsOnSite">{{ trip.truckName }}</p>
      </div>
    </div>
    <!-- <a *ngIf="jobEvent" [routerLink]="[ '/orders', jobEvent.id, 'map' ]"><button class="btn btn-primary" translate>View On Map</button></a> -->
    <a [routerLink]="[ '/orders', jobEvent.id, 'tickets' ]"><button class="btn btn-primary" translate>View Tickets</button></a>
  </div>
</div>
