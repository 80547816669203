"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var lodash_1 = require("lodash");
var core_2 = require("@ngx-translate/core");
var location_service_1 = require("../location.service");
var location_type_service_1 = require("../location-type.service");
var shared_1 = require("../../shared");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var assignment_service_1 = require("../../assignments/assignment.service");
var filter_option_1 = require("../../shared/filters-panel/filter-option");
var location_manager_filters_dialog_component_1 = require("./location-manager-filters-dialog.component");
var LocationManagerComponent = /** @class */ (function () {
    function LocationManagerComponent(route, router, locationService, assignmentService, collaboratorService, authenticationService, translationService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.locationService = locationService;
        this.assignmentService = assignmentService;
        this.collaboratorService = collaboratorService;
        this.authenticationService = authenticationService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.searchChange = new core_1.EventEmitter();
        this.appliedFiltersChange = new core_1.EventEmitter();
        this.search = '';
        this.availableFilters = [
            new filter_option_1.FilterOption({
                key: 'location_type', filterType: 'select',
                title: this.translationService.instant('Location Type'),
                service: location_type_service_1.LocationTypeService
            })
        ];
        this.appliedFilters = [];
        this.locationStats = [];
        this.loading = true;
        this.errors = [];
        this.count = 0;
        this.view = 'cards';
        this.hasAllDriversEnabled = false;
        this.pendingJobsCount = 0;
        this.multipleActionDropdownOptions = [
            { name: this.translationService.instant('Export'), action: 'export' },
            { name: this.translationService.instant('Operator\'s Export'), action: 'operators_export' }
        ];
        this.locationTypeDropdownConfig = {
            inline: true,
            searchable: true,
            showLoading: true,
            nameProperty: 'name',
            sortBy: 'name',
            selectText: this.translationService.instant('Select Location Type'),
            loadingText: this.translationService.instant('Loading Location Types...'),
            noResultsText: this.translationService.instant('No Location Types'),
            service: location_type_service_1.LocationTypeService,
            query: {}
        };
        this.allSubscriptionsToUnsubscribe = [];
        this.allSubscriptionsToUnsubscribe.push(this.searchChange.pipe(operators_1.debounceTime(300), operators_1.distinctUntilChanged()).subscribe(function (search) {
            _this.search = search;
            _this.updateUrl({ search: _this.search, page: 1 });
        }));
    }
    LocationManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var query = {};
        if (this.authenticationService.hasFavoriteTags()) {
            query['user_tags'] = 'True';
        }
        this.getPendingCount();
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.view = result.params['view'] || 'cards';
            _this.search = result.qparams['search'] || '';
            _this.locationStatsTimer = rxjs_1.timer(1, 120000);
            _this.locationStatsTimerSub = _this.locationStatsTimer.subscribe(function (t) {
                _this.getLocationStats();
            });
        }));
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    };
    LocationManagerComponent.prototype.ngOnChanges = function (changes) {
        if (changes['search']) {
            this.searchChange.emit(changes['search'].currentValue);
        }
    };
    LocationManagerComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    LocationManagerComponent.prototype.switchView = function (view) {
        var _this = this;
        if (view === void 0) { view = 'list'; }
        this.router.navigate(['/'], { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/locations/manager/' + view]);
        });
    };
    // NOTE: This will be necessary in the future, however, the exact implementation hasn't been completed/determiend.
    // onScroll(e) {
    //   if (!this.loading &&
    //     e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
    //     let o = this.locationService.listNext();
    //     if (o) {
    //       this.loading = true;
    //       o.subscribe(locationStats => {
    //         this.locationStats = this.locationStats.concat(locationStats);
    //         this.loading = false;
    //       }, err => {
    //         this.errors = err;
    //       }, () => {
    //         this.loading = false;
    //       });
    //     }
    //   }
    // }
    LocationManagerComponent.prototype.getLocationStats = function (query, append, select) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (select === void 0) { select = null; }
        if (!append) {
            this.locationStats = [];
        }
        this.loading = true;
        if (this.locationStatsReq) {
            this.locationStatsReq.unsubscribe();
        }
        if (this.appliedFilters && this.appliedFilters.length) {
            query['filters'] = this.mergeFilters(this.appliedFilters);
        }
        this.locationStatsReq = this.locationService.averageTimeSpent(__assign({ ordering: 'name', search: this.search }, query)).subscribe(function (locationStats) {
            if (append) {
                _this.locationStats = _this.locationStats.concat(locationStats);
            }
            else {
                _this.locationStats = locationStats;
            }
            _this.count = _this.locationService.count;
            _this.loading = false;
        }, function (err) {
            _this.errors = err;
            _this.loading = false;
        });
    };
    LocationManagerComponent.prototype.getPendingCount = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(function (invitations) {
            _this.pendingJobsCount = _this.collaboratorService.count;
        }, function (err) {
            _this.errors = err;
        });
    };
    LocationManagerComponent.prototype.changeSearch = function (term) {
        this.searchChange.emit(term || '');
    };
    LocationManagerComponent.prototype.menuAction = function (action) {
        switch (action) {
            case 'export':
                this.createExport();
                break;
            case 'operators_export':
                this.createOperatorsExport();
                break;
        }
    };
    LocationManagerComponent.prototype.updateUrl = function (params) {
        params['search'] = params['search'] ? params['search'] : this.search;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams, params)
        });
    };
    LocationManagerComponent.prototype.createExport = function () {
        var _this = this;
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var filters = {
            'jobevent__shift1_start__gte': startDate && startDate.toISOString(),
            'jobevent__shift1_start__lte': endDate && endDate.toISOString()
        };
        this.assignmentService.export({}, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: {},
                    params: params,
                    service: _this.assignmentService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    LocationManagerComponent.prototype.createOperatorsExport = function () {
        var _this = this;
        var scope = {
            endpoint: 'operators'
        };
        var startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        var endDate = lodash_1.clone(startDate);
        endDate.setHours(23, 59, 59, 999);
        var filters = {
            'active_range': (startDate && startDate.toISOString()) + "," + (endDate && endDate.toISOString()),
            'ordering': 'jobevent__job__start__location__name,jobevent__job__order_number'
        };
        this.assignmentService.export(scope, filters).subscribe(function (response) {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    endpoint: 'operators'
                }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    scope: scope,
                    params: params,
                    service: _this.assignmentService,
                    buttonText: _this.translationService.instant('Try to Export Again')
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
            console.error(err);
        });
    };
    LocationManagerComponent.prototype.selectLocationType = function (locationType, locationStat, userSelection) {
        var _this = this;
        if (userSelection === void 0) { userSelection = true; }
        if (!userSelection) {
            return;
        }
        this.loading = true;
        locationStat.locationType = locationType;
        var location = { id: locationStat.id, location_type: locationType.id };
        this.locationService.save(location, {}, true).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.errors = err;
        });
    };
    LocationManagerComponent.prototype.updateTimeTarget = function (locationStat, type, event) {
        var _this = this;
        this.loading = true;
        var location = { id: locationStat.id };
        switch (type) {
            case 'loading':
                location['average_loading_time'] = event.target.value;
                break;
            case 'unloading':
                location['average_unloading_time'] = event.target.value;
                break;
        }
        this.locationService.save(location, {}, true).subscribe(function () {
            _this.loading = false;
        }, function (err) {
            _this.loading = false;
            _this.errors = err;
        });
    };
    LocationManagerComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(location_manager_filters_dialog_component_1.LocationManagerFiltersDialogComponent, {
            width: '430px',
            data: { model: {} }
        });
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = this.appliedFilters.reduce(function (acc, filter) {
            if (filter.filterType === 'checkbox') {
                if (filter.values === true) {
                    filter.values = ['True'];
                }
                acc[filter.key] = filter.values && filter.values[0] === 'True';
            }
            else if (filter.filterType === 'select') {
                acc[filter.key] = {
                    id: filter.values && filter.values[0],
                    name: filter.displayValues && filter.displayValues[0]
                };
            }
            else if (filter.filterType === 'date') {
                acc[filter.key] = filter.values;
            }
            else {
                acc[filter.key] = filter.value;
            }
            return acc;
        }, {});
        this.filtersDialog = dialog.componentInstance;
    };
    LocationManagerComponent.prototype.filterChanges = function (filterRes) {
        var _this = this;
        var triggerRefresh = false;
        if (filterRes) {
            Object.keys(filterRes).forEach(function (key) {
                if (filterRes[key] && filterRes[key] !== undefined) {
                    lodash_1.remove(_this.appliedFilters, function (_filter) { return _filter.key === key; });
                    var filter = lodash_1.find(_this.availableFilters, { key: key });
                    if (!filter) {
                        return;
                    }
                    filter.values = [filterRes[key].id];
                    filter.displayValues = [filterRes[key].name];
                    _this.appliedFilters = _this.appliedFilters.concat(filter);
                    triggerRefresh = true;
                }
            });
        }
        if (triggerRefresh) {
            this.getLocationStats();
        }
        this.changeSearch(this.search);
    };
    LocationManagerComponent.prototype.filtersReset = function () {
        this.appliedFilters = [];
        this.search = '';
        this.getLocationStats();
    };
    LocationManagerComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.appliedFilters, filter);
    };
    LocationManagerComponent.prototype.mergeFilters = function (filters) {
        return filters.map(function (filter) {
            if (filter.multiple && filter.values) {
                return filter.values.map(function (value) {
                    var _value = [filter.key, value].join('=');
                    return "(" + _value + ")";
                }).filter(Boolean).join('|');
            }
            else if (filter.values) {
                var values = filter.values;
                if (values === true) {
                    values = 'True';
                }
                if (values === false) {
                    values = 'False';
                }
                var _value = [filter.key, values].join('=');
                return "(" + _value + ")";
            }
        }).filter(Boolean).join('&');
    };
    return LocationManagerComponent;
}());
exports.LocationManagerComponent = LocationManagerComponent;
