"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var moment = require("moment-timezone");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var BulkEditJobsSerialzier = /** @class */ (function () {
    function BulkEditJobsSerialzier() {
    }
    /**
    * @param  {any} json
    * @returns BulkEditJob
    */
    BulkEditJobsSerialzier.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        return json;
    };
    /**
    * @param  {BulkEditJob} job
    * @returns any
    */
    BulkEditJobsSerialzier.prototype.toJson = function (job) {
        var editModelData = {};
        if (job.include && job.include.length) {
            editModelData.include = job.include;
        }
        if (job.rate) {
            editModelData.rate = job.rate;
        }
        if (job.invoiceType) {
            editModelData.invoiceType = job.invoiceType;
        }
        if (job.invoiceWeightUnit) {
            editModelData.invoiceWeightUnit = job.invoiceWeightUnit;
        }
        if (job.haulType) {
            editModelData.haulType = job.haulType;
        }
        if (job.haulRate) {
            editModelData.haulRate = job.haulRate;
        }
        if (job.haulWeightUnit) {
            editModelData.haulWeightUnit = job.haulWeightUnit;
        }
        if (job.dates && job.dates.length) {
            editModelData.dates = job.dates.map(function (x) { return moment(x).format('YYYY-MM-DD'); });
        }
        if (job.material) {
            editModelData.material = job.material;
        }
        if (job.amountNeeded) {
            editModelData.amountNeeded = job.amountNeeded;
        }
        if (job.totalAmountType) {
            editModelData.totalAmountType = job.totalAmountType;
        }
        if (job.dailyDeliveryTarget) {
            editModelData.dailyDeliveryTarget = job.dailyDeliveryTarget;
        }
        if (job.dailyDeliveryTargetType) {
            editModelData.dailyDeliveryTargetType = job.dailyDeliveryTargetType;
        }
        if (job.deliveryInterval) {
            editModelData.deliveryInterval = job.deliveryInterval;
        }
        if (job.deliveryIntervalUnit) {
            editModelData.deliveryIntervalUnit = job.deliveryIntervalUnit;
        }
        if (job.startLocation) {
            editModelData.startLocation = job.startLocation.id;
        }
        if (lodash_1.keys(job.checkinOptions).length) {
            editModelData.checkinOptions = job.checkinOptions;
        }
        if (job.endLocation) {
            editModelData.endLocation = job.endLocation.id;
        }
        if (lodash_1.keys(job.checkoutOptions).length) {
            editModelData.checkoutOptions = job.checkoutOptions;
        }
        return decamelizeKeysDeep(editModelData);
    };
    return BulkEditJobsSerialzier;
}());
exports.BulkEditJobsSerialzier = BulkEditJobsSerialzier;
