import { RouterModule, Routes } from '@angular/router';

// Settings
import { SettingsComponent } from './settings/settings.component';

// Jobs
import { NewJobComponent } from './jobs/new-job.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobDetailComponent } from './jobs/job-detail.component';
import { EditJobComponent } from './jobs/edit-job.component';
import { HaulerSharesComponent } from './jobs/hauler-shares/hauler-shares.component';

import { InvitationComponent } from './invitation/invitation.component';
import { DriverInvitationComponent } from './invitation/driver-invitation.component';
import { OrganizationInvitationComponent } from './invitation/organization-invitation.component';
import { ConnectionsComponent } from './connections/connections.component';

// Drivers
import { DriverDetailsPage } from './drivers/driver-details-page/driver-details-page.page';

// Fleet
import { FleetComponent } from './fleet/fleet.component';

// Accounts
import { AccountsComponent } from './accounts/accounts.component';
import { AccountFleetComponent } from './accounts/account-fleet.component';
import { AccountComponent } from './accounts/account.component';

import { LoginComponent } from './login/login.component';
import { JobInvitationsComponent } from './job-invitations/job-invitations.component';
import { DailyBoardComponent } from './daily-board/daily-board.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { NewProjectComponent } from './project-list/new-project.component';
import { TeamComponent } from './team/team.component';
import { StyleguideComponent } from './styleguide/styleguide.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

// Dispatching
import { DispatchComponent } from './dispatch/dispatch.component';
import { DispatchScheduleComponent } from './dispatch/schedule/dispatch-schedule.component';
import { MobileDispatchAssignComponent } from './dispatch/mobile/mobile-dispatch-assign.component';

// Invoices
import { EditInvoiceComponent } from './invoices/edit-invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceComponent } from './invoices/invoice.component';
import { CreateInvoiceComponent } from './invoices/create-invoice.component';

// Purchase Orders
import { PurchaseOrdersComponent } from './purchase-orders/purchase-orders.component';
import { PurchaseOrderComponent } from './purchase-orders/purchase-order.component';

// Assignments
import { ConfirmAssignmentComponent } from './assignments/confirm-assignment.component';
import { AllDriverAssignmentsComponent } from './assignments/all-driver-assignments/all-driver-assignments.component';

// Dashboard
import { DashboardComponent } from './dashboard/dashboard.component';

import { AuthGuard } from './guards/auth.guard';
// import { DriverGuard } from './guards/driver.guard';
import { AnyoneGuard } from './guards/anyone.guard';
import { LeasedOrgsGuard } from './guards/leased-orgs.guard';
import { DispatchGuard } from './guards/dispatch.guard';

// Prices
import { PricesComponent } from './prices/prices.component';

// Pay Records
import { PaySheetsComponent } from './pay-sheets/pay-sheets.component';
import { DriverPayComponent } from './pay-sheets/driver/driver-pay.component';

// Orders
import { OrderSummaryStatusComponent } from './orders/order-summary-status/order-summary-status.component';
import { OrderTicketListComponent } from './orders/order-ticket-list/order-ticket-list.component';
import { View } from './shared/view/view';
import { FleetHealthComponent } from './fleet/health/fleet-health.component';
import { TrackablesComponent } from './trackables/trackables.component';

export const routes: Routes = [
  // Dashboard
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  /*
   * New Modules
   */

  // Advanced Billing
  { path: 'billing-rates', loadChildren: () => import('./advanced-billing/advanced-billing.module').then(m => m.AdvancedBillingModule) },

  // Locations
  { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule) },
  { path: 'jobs/locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule) },


  // Plant Manager
  { path: 'plant-manager', loadChildren: () => import('./plant-manager/plant-manager.module').then(m => m.PlantManagerModule) },

  // Settings
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'settings/:area', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'settings/:area/:id/:tab', component: SettingsComponent, canActivate: [AuthGuard] },

  // Invitations
  { path: 'invitations', component: InvitationComponent },
  { path: 'invitations/:invite_code', component: InvitationComponent },
  { path: 'invitations/:invite_code/:step', component: InvitationComponent },
  { path: 'driver-invitations/:invite_code', component: DriverInvitationComponent },
  { path: 'driver-invitations/:invite_code/:step', component: DriverInvitationComponent },
  { path: 'create-organization/:invite_code', component: OrganizationInvitationComponent },
  { path: 'create-organization/:invite_code/:step', component: OrganizationInvitationComponent },

  // Connections
  { path: 'connections', component: ConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'connections/:type', component: ConnectionsComponent, canActivate: [AuthGuard] },
  { path: 'connections/:type/:search', component: ConnectionsComponent, canActivate: [AuthGuard] },

  // Fleet
  {
    path: 'trucks', component: FleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'trucks', title: 'Trucks', type: 'main' } }
  },
  {
    path: 'trucks/:status', component: FleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'trucks', title: 'Trucks', type: 'archive' } }
  },
  {
    path: 'drivers', component: FleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers', type: 'main' } }
  },
  {
    path: 'drivers/:id/details', component: DriverDetailsPage, canActivate: [AuthGuard]
  },
  {
    path: 'drivers/:id/details/:tab', component: DriverDetailsPage, canActivate: [AuthGuard]
  },
  {
    path: 'fleet-health', component: FleetHealthComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers Health', type: 'main' } }
  },
  {
    path: 'drivers/:status', component: FleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers', type: 'archive' } }
  },
  {
    path: 'trackables', component: TrackablesComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'trackables', title: 'Trackables' } }
  },

  // Leased Fleet
  { path: 'leased-fleet', component: AccountsComponent, canActivate: [LeasedOrgsGuard] },
  { path: 'leased-fleet/new', component: AccountComponent, canActivate: [LeasedOrgsGuard] },
  { path: 'leased-fleet/:accountId/edit', component: AccountComponent, canActivate: [LeasedOrgsGuard] },
  {
    path: 'leased-fleet/trucks', component: AccountFleetComponent, canActivate: [LeasedOrgsGuard],
    data: { view: <View>{ stub: 'trucks', title: 'Trucks' } }
  },
  {
    path: 'leased-fleet/drivers', component: AccountFleetComponent, canActivate: [LeasedOrgsGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers' } }
  },
  {
    path: 'leased-fleet/trucks/:accountId', component: AccountFleetComponent, canActivate: [LeasedOrgsGuard],
    data: { view: <View>{ stub: 'trucks', title: 'Trucks' } }
  },
  {
    path: 'leased-fleet/drivers/:accountId', component: AccountFleetComponent, canActivate: [LeasedOrgsGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers' } }
  },

  // Accounts
  { path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard] },
  { path: 'accounts/new', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'accounts/:accountId/edit', component: AccountComponent, canActivate: [AuthGuard] },
  {
    path: 'accounts/trucks/:accountId', component: AccountFleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'trucks', title: 'Trucks' } }
  },
  {
    path: 'accounts/drivers/:accountId', component: AccountFleetComponent, canActivate: [AuthGuard],
    data: { view: <View>{ stub: 'drivers', title: 'Drivers' } }
  },

  // Trips
  { path: 'trips', loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule) },
  { path: 'geotrips', loadChildren: () => import('./cyber-trips/cyber-trips.module').then(m => m.CyberTripsModule) },

  // TripHistory
  { path: 'trips/:id', loadChildren: () => import('./trips/trip-history/trip-history.module').then(m => m.TripHistoryModule) },

  // Punchcards
  { path: 'punchcards', loadChildren: () => import('./punch-cards/punch-cards.module').then(m => m.PunchCardsModule) },

  // Jobs
  { path: 'jobs', component: JobsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/all', component: JobsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/drivers', component: AllDriverAssignmentsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/new', component: NewJobComponent, canActivate: [AuthGuard] },
  { path: 'jobs/daily', component: DailyBoardComponent, canActivate: [AuthGuard] },
  { path: 'jobs/daily/:table', component: DailyBoardComponent, canActivate: [AuthGuard] },
  { path: 'daily', component: DailyBoardComponent, canActivate: [AuthGuard] },
  { path: 'daily/:table', component: DailyBoardComponent, canActivate: [AuthGuard] },
  { path: 'jobs/clone/:jobId', component: NewJobComponent, canActivate: [AuthGuard] },
  { path: 'jobs/:jobId/clone', component: NewJobComponent, canActivate: [AuthGuard] },
  { path: 'jobs/:id/edit', component: EditJobComponent, canActivate: [AuthGuard] },
  { path: 'jobs/hauler-shares', component: HaulerSharesComponent, canActivate: [AuthGuard] },
  { path: 'jobs/pending', component: JobInvitationsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/pending/:invitationId', component: JobInvitationsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/:jobId', component: JobDetailComponent, canActivate: [AuthGuard] },
  { path: 'jobs/:jobId/:jobEventId', component: JobDetailComponent, canActivate: [AuthGuard] },

  { path: 'project-list', component: ProjectListComponent, canActivate: [AuthGuard] },
  { path: 'projects/:id/new-job', component: NewJobComponent, canActivate: [AuthGuard] },
  { path: 'projects/new', component: NewProjectComponent, canActivate: [AuthGuard] },

  // Dispatching
  { path: 'dispatch-schedule', component: DispatchScheduleComponent, canActivate: [AuthGuard] },
  { path: 'dispatch', component: DispatchComponent, canActivate: [AuthGuard] },
  { path: 'dispatch/:jobId', component: DispatchComponent, canActivate: [AuthGuard] },
  { path: 'dispatch/:jobId/:jobEventId', component: DispatchComponent, canActivate: [AuthGuard, DispatchGuard] },
  { path: 'dispatch/:jobId/:jobEventId/:panel', component: MobileDispatchAssignComponent, canActivate: [AuthGuard] },

  // Invoices
  { path: 'invoices/:id/edit', component: EditInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'invoices/filter/:state', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'invoices/:jobId/trips', component: CreateInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'invoices/:id', component: InvoiceComponent },
  { path: 'invoices/:id/preview', component: InvoiceComponent },

  // Surcharges
  {
    path: 'surcharge-templates',
    loadChildren: () => import('./surcharge-templates/surcharge-templates.module').then(m => m.SurchargeTemplatesModule)
  },

  // Work Orders
  { path: 'work-orders', loadChildren: () => import('./work-orders/work-orders.module').then(m => m.WorkOrdersModule) },

  // Expenses
  { path: 'expenses', loadChildren: () => import('./expenses/expenses.module').then(m => m.ExpensesModule) },

  // Paystubs
  { path: 'paystubs', loadChildren: () => import('./paystubs/paystubs.module').then(m => m.PaystubsModule) },

  // Purchase Orders
  { path: 'purchase-orders', component: PurchaseOrdersComponent, canActivate: [AuthGuard] },
  { path: 'purchase-orders/new', component: PurchaseOrderComponent, canActivate: [AuthGuard] },
  { path: 'purchase-orders/:id/edit', component: PurchaseOrderComponent, canActivate: [AuthGuard] },

  // Prices
  { path: 'prices', component: PricesComponent, canActivate: [AuthGuard] },

  // Customers
  { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },

  { path: 'team', component: TeamComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'styleguide', component: StyleguideComponent },
  { path: 'login/reset', component: PasswordResetComponent },

   // Marketplace
  { path: 'marketplace', loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule) },

  // Collaborators
  {
    path: 'jobs/:jobId/:jobEventId/collaborators',
    loadChildren: () => import('./collaborators/collaborators.module').then(m => m.CollaboratorsModule)
  },

  // Tickets
  { path: '', loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule) },

  // Assignments
  { path: 'assignments/:id/confirm', component: ConfirmAssignmentComponent, canActivate: [AnyoneGuard] },

  // Ruckit Replay
  { path: 'replay', loadChildren: () => import('./replay/ruckit-replay.module').then(m => m.RuckitReplayModule) },

  // Driver Pay
  { path: 'pay-reports/all', component: PaySheetsComponent, canActivate: [AuthGuard] },
  { path: 'pay-reports/:type', component: PaySheetsComponent, canActivate: [AuthGuard] },
  { path: 'driver-pay/:id', component: DriverPayComponent, canActivate: [AuthGuard] },
  { path: 'driver-pay/:id/:type', component: DriverPayComponent, canActivate: [AuthGuard] },

  // Orders
  { path: 'orders/:jobEventId', component: OrderSummaryStatusComponent, canActivate: [AuthGuard] },
  { path: 'orders/:jobEventId/tickets', component: OrderTicketListComponent, canActivate: [AuthGuard] },

  // Driver Reports
  { path: 'driver-reports', loadChildren: () => import('./driver-reports/driver-reports.module').then(m => m.DriverReportsModule) },

  // Shifts
  { path: 'shifts', loadChildren: () => import('./shifts/shifts.module').then(m => m.ShiftsModule) },

  // Notifications
  { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) },

  // End of Day Report
  { path: 'end-of-day-reports', loadChildren: () => import('./reports/end-of-day/end-of-day.module').then(m => m.EndOfDayModule) },

  // Resources
  { path: 'resources', loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule) },
];

export const routing = RouterModule.forRoot(routes);
