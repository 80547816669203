import { Component, SimpleChanges, Input, ViewChild, TemplateRef, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { JobEventShareStatService } from '../../job-event-stats/job-event-share-stat.service';
import { AssignmentService } from '../../assignments/assignment.service';
// import { TableConfig } from '../../shared/fancy-table/fancy-table.component';

@Component({
  selector: 'all-collaborators',
  templateUrl: './all-collaborators.component.html',
  styleUrls: ['./all-collaborators.component.scss']
})
export class AllCollaboratorsComponent implements OnChanges {
  @Input() availableColumns = [
    { key: 'name', title: this.translationService.instant('Collaborator Name') },
    { key: 'dispatched-drivers-count', title: this.translationService.instant('Dispatched Trucks') },
    { key: 'confirmed-trucks', title: this.translationService.instant('Confirmed Trucks') },
    { key: 'num-trucks', title: this.translationService.instant('Requested Trucks') },
    { key: 'assigned-trucks', title: this.translationService.instant('Assigned Trucks') },
    { key: 'dispatched-estimated-number-of-loads', title: this.translationService.instant('Dispatched Loads') },
    { key: 'dispatched-estimated-number-of-tons', title: this.translationService.instant('Dispatched Tons') },
    { key: 'invoice-rate', title: this.translationService.instant('Invoice Rate') },
    { key: 'invoice-type', title: this.translationService.instant('Invoice Type') },
    { key: 'invoice-weight-unit', title: this.translationService.instant('Invoice Weight Unit') },
    { key: 'total-loads', title: this.translationService.instant('Delivered Loads') },
    { key: 'total-tons', title: this.translationService.instant('Delivered Tons') },
    { key: 'weight-in-transit', title: this.translationService.instant('Weight in Transit') },
    { key: 'ticketed-weight', title: this.translationService.instant('Ticketed Weight') },
    { key: 'avg-trip-time', title: this.translationService.instant('Avg Trip Time') },
  ];
  @Input() displayedColumns = [
    'name', 'avg-trip-time', 'dispatched-drivers-count', 'confirmed-trucks', 'num-trucks', 'assigned-trucks',
    'total-loads', 'total-tons', 'weight-in-transit', 'ticketed-weight',
    'dispatched-estimated-number-of-loads', 'dispatched-estimated-number-of-tons',
    'invoice-rate', 'invoice-type', 'invoice-weight-unit'
  ];
  @Input() filters = [];
  @Input() jobEventId: string;
  allCollaboratorsTableConfig = {
    hasHeader: false,
    service: JobEventShareStatService,
    preferenceKey: '',
    // preferenceKey: 'AllCollaboratorsComponent-JobEventShareStatService',
    query: {},
    collectionTitle: this.translationService.instant('Collaborators'),
    noResultsText: this.translationService.instant('a collaborator'),
    customHeight: true,
    automaticallyHidePagination: true
  };
  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('allCollaboratorsTable', { static: false }) allCollaboratorsTable;

  assignedTrucks = [];

  constructor(private assignmentService: AssignmentService, private translationService: TranslateService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['jobEventId']) {
      const jobEventId = changes['jobEventId'].currentValue;
      this.getAssignedTrucks(jobEventId);
      this.allCollaboratorsTableConfig['slug'] = `jobevents/${jobEventId}/stats-by-collaborator/`;

      if (this.allCollaboratorsTable) {
        // this.allCollaboratorsTable.config = <TableConfig>this.allCollaboratorsTableConfig;
        this.allCollaboratorsTable.getRecords();
      }
    }
  }

  getAssignedTrucks(jobEventId: string) {
    this.assignmentService
      .listAll(50, { jobevent: jobEventId })
      .subscribe((assignments) => {
        const assignedTrucks = assignments.map((assignment) =>
          assignment.truck
            ? {
                id: assignment.truck.id,
                // collaborators service is returning ids without dashes..
                organizationId: assignment.truck.carrierOrganizationId.replace(
                  /-/g,
                  ''
                ),
                name: `${assignment.truck.name} - ${assignment.truck.truckTypeName}`,
              }
            : null
        );
        this.assignedTrucks = assignedTrucks.filter((truck) => truck !== null);
      });
  }
}
