<form #addCollaborators="ngForm" class="carrier-dispatch-dialog-container" [ngClass]="{'mobile': device.mobile}">
  <div mat-dialog-title class="modal-title">
    <div *ngIf="device.mobile && mobileView === 'assignments'" class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
    <div *ngIf="device.mobile && mobileView === 'drivers'" class="close-action" (click)="mobileView = 'assignments'"><i class="icomoon icon-close"></i></div>
    <h1 translate>{{ mobileView === 'drivers' ? ('Drivers' | translate) : !loading && jobEvent ? jobEvent.jobDisplayName : 'Loading' }}</h1>
    <div *ngIf="!device.mobile" class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">

      <!-- left sidebar -->
      <div class="available-drivers" *ngIf="!device.mobile || mobileView === 'drivers'">
        <div *ngIf="mobileView !== 'drivers'" class="header" translate>Drivers</div>
        <div class="driver-controls">
          <search class="driver-search" (searchTermChange)="changeDriverSearch($event)" [(searchTerm)]="search"></search>
          <ruckit-dropdown class="driver-filter" #carriersDropdown [config]="carriersConfig"
                           [selectedOption]="carrier && carrier.id" (onSelect)="selectCarrier($event)"
          ></ruckit-dropdown>
        </div>
        
        <div class="driver-options" (scroll)="onScroll($event)" [class.center-msg]="!loading && ((search && drivers?.length === 0) || (!search && carrier && drivers?.length === 0))">
          <div class="option" *ngFor="let driver of drivers">
            <input type="checkbox" (click)="onSelect(driver, $event)" name="driver_{{ driver.id }}" [checked]="isDriverAssigned(driver.id)"/>
            <div class="driver-info">
              <p>{{ driver.name }}</p>
              <p *ngIf="driver.truck" class="label">{{ driver.truck.name }}</p>
            </div>
          </div>
          <my-loader *ngIf="loading" [ngClass]="{'full-height': drivers.length === 0}"></my-loader>
          <div *ngIf="!loading && ((search && drivers?.length === 0) || (!search && carrier && drivers?.length === 0))">
            <h2 translate>No Results Found</h2>
            <div class="actions" *ngIf="search">
              <button class="btn btn-primary" [ngClass]="{loading: loading}" (click)="changeDriverSearch()" translate>
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- left sidebar end -->

      <div class="dispatch-panel" *ngIf="!device.mobile || mobileView === 'assignments'">
        <!-- jobevent details -->
        <div *ngIf="jobEvent" class="jobevent-details">
          <div class="row">
            <div *ngIf="share" class="datum">
              <p>{{ share.sharedBy }}</p>
              <p class="label light" translate>Job Owner</p>
            </div>
            <div class="datum">
              <p>{{ jobEvent.job.project.customerOrganization.name }}</p>
              <p class="label light" translate>Customer</p>
            </div>
            <div class="datum">
              <p>{{ jobEvent.job.orderNumber }}</p>
              <p class="label light" translate>Order #</p>
            </div>
            <div class="datum">
              <p>{{ jobEvent.shift1StartTimestamp | moment: 'M/D h:mma' }} – {{ jobEvent.shift1EndTimestamp | moment: 'M/D h:mma' }}</p>
              <p class="label light" translate>Daily Schedule</p>
            </div>
            <div class="datum">
              <p>{{ jobEvent.job.material }}</p>
              <p class="label light" translate>Material</p>
            </div>
          </div>
          <div class="row">
            <div class="datum requested-amount">
              <!-- <p class="truck-number">{{ jobEvent.requestedAmount || 0 }}</p>
              <p class="label light" translate>Requested Amount</p> -->
              <div class="large-stat">
                <div class="truck-number">{{ jobEvent.requestedAmount || 0 }}</div>
                <div>{{ jobEvent.requestedUnit?.name || 'Trucks' | translate }}</div>
              </div>
              <div class="label light" translate>Requested Amount</div>
            </div>
            <div class="datum">
              <p>{{ jobEvent.truckTypeNames }}</p>
              <p class="label light" translate>Truck Type</p>
            </div>
            <div class="datum">
              <p class="green">{{ jobEvent.rate }} / {{ jobEvent.invoiceRateUnit }}</p>
              <p class="label light" translate>Invoice Rate</p>
            </div>
            <div *ngIf="share" class="datum">
              <p>{{ share.notes }}</p>
              <p class="label light" translate>Notes</p>
            </div>
          </div>
        </div>
        <!-- end jobevent details -->

        <!-- how many can you provide section -->
        <div *ngIf="jobEvent && share" class="assignment-details">
          <div class="field-group">
            <label translate>How many can you provide?</label>
            <div class="input-group">
              <input type="number" min="0" [max]="jobEvent.requestedAmount || 100" name="confirmed_amount" [(ngModel)]="share.confirmedAmount"/>
              <div class="requested-unit-name">{{share.requestedUnit?.name || 'Trucks' | translate}}</div> 
            </div>

          </div>
          <div class="field-group">
            <label translate>What is the haul rate?</label>
            <div class="input-group" data-prefix="$">
              <input numberInRange [minValue]="0" type="text" name="haul_rate" [(ngModel)]="share.haulRate" min="0" />
              <dropdown [options]="weightOptions" *ngIf="share.haulType === 'weight'" title="-"
                (onSelect)="weightUnitChange($event)"></dropdown>
              <div class="requested-unit-name" *ngIf="share.haulType !== 'weight'">{{share.haulType}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="jobEvent && device.mobile" class="driver-actions">
          <button class="btn btn-default" (click)="mobileView = 'drivers'" translate>Add Drivers & Trucks</button>
        </div>
        <!-- end provide section -->

        <!-- start load list accordion -->
        <div class="loadlist-accordion" *ngIf="hasLoadListsEnabled && share && share.loadSchedule && share.loadSchedule.length > 0">
          <div class="loadlist-accordion-header">
            <div translate>LOADS REQUESTED</div>
            <div class="loadlist-accordion-arrow">
              <mat-icon (click)="loadListExpanded=true" *ngIf="!loadListExpanded">expand_more</mat-icon>
              <mat-icon (click)="loadListExpanded=false" *ngIf="loadListExpanded">expand_less</mat-icon>
            </div>
          </div>
          <div class="loadlist-accordion-body" [@expanded]="loadListExpanded ? 'expanded' : 'collapsed'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="share.loadSchedule">
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.vehicle && row.ruckit.vehicle.description ? '#' + row.ruckit.vehicle.description : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <!-- end load list accordion -->

        <div class="driver-details-assignments" *ngFor="let assignment of assignments; let i = index">
          <div class="driver-details">
            <h3>{{ i + 1 }}</h3>
            <div class="dropdown-group">
              <label>Driver</label>
              <ruckit-dropdown [config]="driversConfig" [selectedOption]="assignment.driver" (onSelect)="changeDriver($event, i)"></ruckit-dropdown>
            </div>
            <div class="dropdown-group">
              <label>Truck</label>
              <ruckit-dropdown [config]="trucksConfig" [selectedOption]="assignment.truck" (onSelect)="changeTruck($event, i)"></ruckit-dropdown>
            </div>
            <div class="driver-loads">  
              <input type="radio" (change)="onAssignmentChange(assignment)" name="{{ 'max-loads-' + assignment.id }}" required [(ngModel)]="assignment.numberOfLoadsType" [value]="'numbered'" />
              <label for="{{ 'max-loads-' + assignment.id }}"
                [ngClass]="{'active': assignment.numberOfLoadsType === 'numbered'}" translate>Loads:</label>
              <input class="load-count" (change)="onAssignmentChange(assignment)" type="number" name="{{ 'load-count-' + assignment.id }}"
                [disabled]="assignment.numberOfLoadsType !== 'numbered'"
                [(ngModel)]="assignment.maxNumberOfLoads" min="1" />
              <input type="radio" (change)="onAssignmentChange(assignment)" name="{{ 'max-loads-' + assignment.id }}" required
                [(ngModel)]="assignment.numberOfLoadsType" [value]="'allDay'" />
              <label for="{{ 'max-loads-' + assignment.id }}"
                [ngClass]="{'active': assignment.numberOfLoadsType === 'allDay'}" translate>All Day</label>
            </div>
          </div>
          <div class="status">
            <div class="remove-action">
              <i name="remove" class="remove icon-delete" (click)="removeAssignment(assignment, i)"></i>
              <label for="remove" class="remove" translate>Remove</label>
            </div>
            <div class="completed-status">
              <input type="checkbox" [(ngModel)]="assignment.completed" name="assignment-completed"
                (change)="updateAssignment(assignment)">
              <label for="assignment-completed" translate [class.completed]="assignment.completed">Completed</label>
            </div>
            <div class="dispatch-status">
              <div class="dispatch-icon" name="dispatch-icon">
                <i *ngIf="assignment && assignment.dispatched" class="icon-dispatched"></i>
                <i *ngIf="!assignment || !assignment.dispatched" class="icon-not-dispatched"></i>
              </div>
              <label for="dispatch-icon" translate>Received</label>
            </div>
            <div class="accepted-status">
              <i name="assignment-icon" class="icon-assignment_turned_in" [class.accepted]="assignment.driverStatus === 'confirmed'"></i>
              <label for="assignment-icon" translate>Confirmed</label>
            </div>
          </div>
        </div>

        <no-results itemName="Driver Selected" [search]="search" [loading]="loading"
                    subTitle="{{
                      !this.device.mobile &&
                      'Choose driver(s) from the list on the left to create assignments.'
                    }}"
                    [results]="assignments.length"
                    [styles]="{
                      'min-height': 'unset', 'height': '400px', 'display': 'flex',
                      'flex-direction': 'column', 'justify-content': 'center'
                    }"
                    [hasAddText]="false" [hasAction]="false">
        </no-results>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>
  

  <div class="modal-footer">
    <ng-container *ngIf="device.mobile && mobileView === 'drivers'; else dispatchActions">
      <button class="btn btn-default full-width" (click)="mobileView = 'assignments'"
        [ngClass]="{loading: loading}" translate>
        Add Drivers
      </button>
    </ng-container>
    <ng-template #dispatchActions>
      <button *ngIf="!device.mobile" class="btn btn-cancel" (click)="dialogRef.close()"
        [ngClass]="{loading: loading}" translate>
        Cancel
      </button>
      <button *ngIf="jobEvent && jobEvent.status === 'accepted'" class="btn btn-default" (click)="save()"
        [ngClass]="{loading: loading}" translate>
        Accept & Save
      </button>
      <button *ngIf="jobEvent && jobEvent.status !== 'accepted'" class="btn btn-default" (click)="save()"
        [ngClass]="{loading: loading}" translate>
        Save
      </button>
      <button class="btn btn-primary save-button"
        *ngIf="assignments.length > 0"
        (click)="save(true)"
        [disabled]="loading"
        [ngClass]="{loading: loading}"
        translate>
        Dispatch
      </button>
    </ng-template>
  </div>
</form>
