"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
// libraries
var lodash_1 = require("lodash");
var ngx_device_detector_1 = require("ngx-device-detector");
// angular material
var material_1 = require("@angular/material");
// services
var truck_service_1 = require("./truck.service");
var api_service_1 = require("../shared/api.service");
var tag_service_1 = require("../tags/tag.service");
var custom_field_service_1 = require("../custom-fields/custom-field.service");
var truck_type_service_1 = require("./truck-type.service");
var location_service_1 = require("../locations/location.service");
var connection_service_1 = require("../connections/connection.service");
// components
var index_1 = require("../shared/dialogs/index");
var ruckit_dropdown_component_1 = require("../shared/ruckit-dropdown/ruckit-dropdown.component");
var shared_1 = require("../shared");
// types
var custom_field_1 = require("../custom-fields/custom-field");
var truck_1 = require("./truck");
var EditTruckComponent = /** @class */ (function () {
    function EditTruckComponent(_eref, dialog, truckService, customFieldService, deviceDetectorService, translateService) {
        this._eref = _eref;
        this.dialog = dialog;
        this.truckService = truckService;
        this.customFieldService = customFieldService;
        this.deviceDetectorService = deviceDetectorService;
        this.translateService = translateService;
        this.tags = [];
        this.completed = new core_1.EventEmitter();
        this.closed = new core_1.EventEmitter();
        this.open = false;
        this.customFields = {};
        this.loading = false;
        this.errors = [];
        this.tag = [];
        this.serviceStatusConfig = { nameProperty: 'name' };
        this.serviceStatusOptions = [
            { name: 'In Service', id: 'in-service' },
            { name: 'Out Of Service', id: 'out-of-service' }
        ];
        this.carriersConfig = {
            searchable: true,
            nameProperty: 'name',
            idProperty: 'organization.carrier.id',
            selectText: this.translateService.instant('Select Fleet'),
            loadingText: this.translateService.instant('Loading Fleets...'),
            noResultsText: this.translateService.instant('No Fleets'),
            service: connection_service_1.ConnectionService,
            serviceFunction: 'list',
            query: {
                ordering: 'organization__name',
                is_carrier: 'True',
                allow_dispatch: 'True',
            },
            prefilledOptions: [],
        };
        this.marketsConfig = {
            multiselect: true,
            selectText: this.translateService.instant('Select Markets'),
            loadingText: this.translateService.instant('Loading Markets...'),
            noResultsText: this.translateService.instant('No Markets'),
            service: tag_service_1.TagService,
            query: {}
        };
        this.locationsConfig = {
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Locations...'),
            noResultsText: this.translateService.instant('No Locations'),
            service: location_service_1.LocationService,
            query: {},
            noneOption: true
        };
        this.truckTypesConfig = {
            searchable: true,
            selectText: this.translateService.instant('Select Truck Type'),
            loadingText: this.translateService.instant('Loading Truck Types...'),
            noResultsText: this.translateService.instant('No Truck Types'),
            nameProperty: 'name',
            serviceFunction: 'list',
            service: truck_type_service_1.TruckTypeService,
            query: { ordering: 'name' }
        };
    }
    EditTruckComponent.prototype.documentClick = function (event) {
        var _this = this;
        var row = event && event.target && event.target.closest('.primary-row');
        if (!lodash_1.includes(event.target.classList, 'row') && !(row && row.contains(event.target)) && this.open) {
            var confirmDialog = document.querySelector('ruckit-confirm-dialog');
            if (!lodash_1.includes(event.target.classList, 'icon-more') &&
                !lodash_1.includes(event.target.classList, 'mat-chip-remove') &&
                !lodash_1.includes(event.target.classList, 'mat-option-text') &&
                !this._eref.nativeElement.contains(event.target) &&
                !(confirmDialog && confirmDialog.contains(event.target))) {
                if (this.truckForm && !this.truckForm.form.pristine) {
                    this.confirmBeforeClosing().subscribe(function (dialogResult) {
                        if (dialogResult) {
                            _this.submit();
                        }
                        else {
                            _this.close();
                        }
                    });
                }
                else {
                    this.close();
                }
            }
        }
    };
    EditTruckComponent.prototype.ngOnInit = function () {
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.getCustomFields();
    };
    EditTruckComponent.prototype.ngOnChanges = function (changes) {
        if (changes.truck && changes.truck.currentValue && changes.truck.currentValue.id) {
            this.modifiedTruck = lodash_1.clone(this.truck);
        }
    };
    EditTruckComponent.prototype.ngOnDestroy = function () {
        if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
            this.customFieldsReq.unsubscribe();
        }
    };
    EditTruckComponent.prototype.submit = function () {
        var _this = this;
        this.loading = true;
        delete this.modifiedTruck.carrierOrganizationId;
        this.truckService.save(this.modifiedTruck).subscribe(function (truck) {
            _this.open = false;
            _this.truck.selected = false;
            _this.completed.emit(Object.assign(truck, {
                truckType: _this.modifiedTruck.truckType,
                tags: truck.tags.map(function (t) { return ({ name: t }); })
            }));
            if (_this.truckForm) {
                _this.truckForm.form.markAsPristine();
            }
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    EditTruckComponent.prototype.remove = function () {
        var _this = this;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        if (this.confirmDialog && this.confirmDialog.componentInstance) {
            this.confirmDialog.componentInstance.attributes = {
                title: this.translateService.instant('Delete Truck?'),
                body: this.translateService.instant('You will no longer be able to dispatch this truck. Deleting a truck cannot be undone.'),
                close: this.translateService.instant('Cancel'),
                accept: this.translateService.instant('Delete')
            };
            this.confirmDialog.afterClosed().subscribe(function (dialogResult) {
                if (dialogResult) {
                    _this.truckService.remove(_this.modifiedTruck).subscribe(function () {
                        _this.modifiedTruck.status = 'removed';
                        _this.open = false;
                        _this.truck.selected = false;
                        if (_this.truckForm) {
                            _this.truckForm.form.markAsPristine();
                        }
                        _this.loading = false;
                        _this.completed.emit(_this.modifiedTruck);
                    }, function (err) {
                        _this.errors = api_service_1.parseErrors(err);
                        _this.loading = false;
                    });
                }
                _this.confirmDialog = null;
            });
        }
    };
    /**
     * Updates a specified field on the truck object, with specified functionality for particular fields
     *
     * @param {any} value The updated value to be applied to the truck object
     * @param {string} field The field name which the value will be set on
     */
    EditTruckComponent.prototype.optionSelected = function (e, field) {
        var _this = this;
        var editsMade = false;
        switch (field) {
            case 'carrier':
                if (e.organization && e.organization.carrier && e.organization.carrier.id &&
                    this.modifiedTruck.carrierOrganizationId !== e.organization.id) {
                    this.modifiedTruck.carrier = e.organization.carrier.id;
                    editsMade = true;
                }
                break;
            case 'tags':
                var tagSelected_1 = [];
                e.forEach(function (tag) {
                    var obj = _this.tags.find(function (o) { return o.name.toUpperCase() === tag.name.toUpperCase(); });
                    if (obj) {
                        tagSelected_1.push(obj);
                    }
                    else {
                        tagSelected_1.push({ name: tag.name });
                    }
                });
                if (tagSelected_1.length > 0) {
                    this.modifiedTruck['tags'] = tagSelected_1;
                    editsMade = true;
                }
                break;
            case 'location':
                if (!this.modifiedTruck.yardLocation || this.modifiedTruck.yardLocation.id !== e.id) {
                    this.modifiedTruck.yardLocation = e;
                    editsMade = true;
                }
                break;
            default:
                this.modifiedTruck[field] = e;
                editsMade = true;
                break;
        }
        if (this.truckForm && this.truckForm.form && editsMade) {
            this.truckForm.form.markAsDirty();
        }
    };
    EditTruckComponent.prototype.close = function () {
        if (this.truckForm && this.truckForm.form) {
            this.truckForm.form.markAsPristine();
            this.open = false;
            this.truck.selected = false;
            if (this.truck && this.modifiedTruck && (this.truck.id === this.modifiedTruck.id)) {
                this.modifiedTruck = undefined;
            }
            this.closed.emit();
        }
    };
    EditTruckComponent.prototype.markAsPristine = function () {
        if (this.truckForm && this.truckForm.form) {
            this.truckForm.form.markAsPristine();
        }
    };
    EditTruckComponent.prototype.setOpen = function () {
        var _this = this;
        this.open = true;
        setTimeout(function () {
            if (_this.carriersDropdown) {
                _this.carriersDropdown.deselectOptions();
                _this.carriersConfig = __assign({}, _this.carriersConfig, { selectText: _this.truck && _this.truck.carrierOrganizationName
                        ? _this.truck.carrierOrganizationName
                        : _this.translateService.instant('Select Fleet') });
            }
        }, 500);
    };
    EditTruckComponent.prototype.fileChange = function (e) {
        var _this = this;
        this.truckService.uploadImage('PUT', [this.modifiedTruck.id], e.srcElement.files).subscribe(function (res) {
            _this.modifiedTruck = Object.assign(res, { truckType: _this.modifiedTruck.truckType });
            _this.completed.emit(_this.modifiedTruck);
        }, function (err) {
            console.error('err', err);
        });
    };
    EditTruckComponent.prototype.getCustomFields = function () {
        var _this = this;
        if (this.customFieldsReq && typeof this.customFieldsReq.unsubscribe === 'function') {
            this.customFieldsReq.unsubscribe();
        }
        this.customFieldsReq = this.customFieldService.getIndexedFieldsForKind(custom_field_1.CustomFieldKind.Truck).subscribe(function (fields) {
            _this.customFields = fields;
        });
    };
    /**
     * To display confirm dialog
     * if user closes edit panel without saving data
     */
    EditTruckComponent.prototype.confirmBeforeClosing = function () {
        var observable;
        this.confirmDialog = this.dialog.open(index_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        if (this.confirmDialog && this.confirmDialog.componentInstance) {
            this.confirmDialog.componentInstance.attributes = {
                title: this.translateService.instant('You have unsaved Changes'),
                body: this.translateService.instant('Are you sure you want to leave? Your changes will not be saved.'),
                close: this.translateService.instant('Discard'),
                accept: this.translateService.instant('Save')
            };
            observable = this.confirmDialog.afterClosed();
            this.confirmDialog = null;
        }
        return observable;
    };
    return EditTruckComponent;
}());
exports.EditTruckComponent = EditTruckComponent;
