import { Driver } from './driver';
import { PROFILE } from './mock-profile';
import { CARRIER } from '../carriers/mock-carrier';

export let DRIVER: Driver = <Driver> {
  id: '00000000-0000-0000-0000-000000000001',
  profile: PROFILE,
  image: '',
  city: 'Austin',
  state: 'TX',
  country: 'US',
  street: '123 Main Street',
  zipcode: '12345',
  name: 'Testy Tester',
  activeShift: true,
  cdl: '123456',
  requireAssignmentConfirmation: false,
  initials: 'JL',
  carrierName: 'Jeff Lee',
  uniqueBillingId: '',
  conflictIds: null,
  carrier: CARRIER,
  carrierOrganization: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  }
};

export let DRIVER_TWO: Driver = <Driver> {
  id: '00000000-0000-0000-0000-000000000002',
  profile: PROFILE,
  image: '',
  city: 'Austin',
  state: 'TX',
  country: 'US',
  street: '456 Main Street',
  zipcode: '67890',
  name: 'Testy Tester Jr.',
  activeShift: false,
  cdl: '78910',
  requireAssignmentConfirmation: true,
  initials: 'AC',
  carrierName: 'Alton Chaney',
  carrierOrganization: null,
  uniqueBillingId: '',
  conflictIds: null,
  url: (action) => {
    return '';
  },
  filterOptions: (options: any[]) => {
    return options;
  }
};
