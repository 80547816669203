"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var moment = require("moment");
var trip_service_1 = require("../trips/trip.service");
var expense_service_1 = require("./expense.service");
var trip_filters_dialog_component_1 = require("../trips/trip-filters-dialog.component");
var view_signatures_dialog_component_1 = require("../checkins/view-signatures-dialog.component");
var app_constants_1 = require("../app.constants");
var view_tickets_dialog_component_1 = require("../checkins/view-tickets-dialog.component");
var ExpenseTripsDialogComponent = /** @class */ (function () {
    function ExpenseTripsDialogComponent(route, router, tripService, expenseService, data, dialog, dialogRef) {
        this.route = route;
        this.router = router;
        this.tripService = tripService;
        this.expenseService = expenseService;
        this.data = data;
        this.dialog = dialog;
        this.dialogRef = dialogRef;
        this.selectedCount = 0;
        this.trips = [];
        this.allSelected = false;
        this.selectedTrips = [];
        this.excludeTrips = [];
        this.loading = true;
        this.errors = [];
        this.routeToExpense = true;
        this.search = '';
        this.sortBy = '';
        this.sortAsc = true;
        this.filters = [];
        this.startDate = null;
        this.endDate = null;
        this.multipleActionDropdownOptions = [];
        this.menuOptions = [
            { name: 'Add to Expense', action: 'add', link: false }
        ];
        this.signatureImageType = app_constants_1.TRIPSIGNATUREIMAGETYPE;
        this.viewTicketsCallback = function () {
            // Update Ticket Status Icon
        };
    }
    ExpenseTripsDialogComponent.prototype.ngOnInit = function () {
        this.getTrips();
    };
    ExpenseTripsDialogComponent.prototype.onScroll = function (e) {
        var _this = this;
        if (!this.loading &&
            e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
            var o = this.tripService.getNextCondensed();
            if (o) {
                this.loading = true;
                o.subscribe(function (trips) {
                    _this.trips = _this.trips.concat(trips);
                    _this.loading = false;
                }, function (err) { return _this.errors = err; }, function () {
                    _this.loading = false;
                });
            }
        }
    };
    ExpenseTripsDialogComponent.prototype.getTrips = function (query, append) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (append === void 0) { append = false; }
        if (!append) {
            this.trips = [];
        }
        this.loading = true;
        var order = (this.sortAsc ? '' : '-') + this.sortBy;
        var filters = this.filters.reduce(function (acc, filter) {
            return __assign({}, acc, filter.query);
        }, {});
        if (this.tripReq) {
            this.tripReq.unsubscribe();
        }
        if (this.startDate) {
            var date = new Date(this.startDate);
            query['start_time__gte'] = date.toISOString();
        }
        if (this.endDate) {
            var date = new Date(this.endDate);
            date.setHours(23, 59, 59, 999);
            query['start_time__lte'] = date.toISOString();
        }
        this.tripFilters = __assign({ ordering: order, search: this.search, unexpensed: 'True', completed: 'True', expenseable: 'True', exclude_cf: 'True' }, filters, query);
        this.tripReq = this.tripService.getCondensedTrips(this.tripFilters).subscribe(function (trips) {
            if (append) {
                _this.trips = _this.trips.concat(trips);
            }
            else {
                _this.trips = trips;
            }
            _this.loading = false;
            _this.count = _this.tripService.count;
        }, function (err) { return _this.errors = err; }, function () {
            _this.loading = false;
        });
    };
    ExpenseTripsDialogComponent.prototype.sort = function (sortKey) {
        if (this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.loading = true;
        this.getTrips({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
    };
    ExpenseTripsDialogComponent.prototype.customSort = function (sortParameter, sortKey) {
        var _a;
        if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
            this.sortAsc = !this.sortAsc;
        }
        this.sortBy = sortKey;
        this.sortParameter = sortParameter;
        this.loading = true;
        this.getTrips((_a = {}, _a[this.sortParameter] = (this.sortAsc ? '' : '-') + this.sortBy, _a));
    };
    ExpenseTripsDialogComponent.prototype.openTickets = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            var dialog = _this.dialog.open(view_tickets_dialog_component_1.ViewTicketsDialogComponent, {
                width: '722px',
                data: { trip: _trip }
            });
            dialog.componentInstance.callback = _this.viewTicketsCallback;
        }, function (err) { return console.error(err); });
    };
    ExpenseTripsDialogComponent.prototype.openSignatures = function (trip) {
        var _this = this;
        this.tripService.getTrip(trip.id).subscribe(function (_trip) {
            var dialog = _this.dialog.open(view_signatures_dialog_component_1.ViewSignaturesDialogComponent, {
                width: '722px',
                data: {
                    trip: _trip,
                    callback: function () { return _this.getTrips(); }
                }
            });
        }, function (err) { return console.error(err); });
    };
    ExpenseTripsDialogComponent.prototype.changeSearch = function (term) {
        this.search = term || '';
        this.getTrips();
    };
    ExpenseTripsDialogComponent.prototype.expandSearch = function () {
        var _this = this;
        this.loading = true;
        setTimeout(function () {
            _this.search = '';
            _this.changeSearch();
        }, 1000);
    };
    ExpenseTripsDialogComponent.prototype.openFilters = function () {
        var _this = this;
        var model = {
            job: lodash_1.get(lodash_1.find(this.filters, { key: 'job' }), 'value'),
            project: lodash_1.get(lodash_1.find(this.filters, { key: 'project' }), 'value'),
            customer: lodash_1.get(lodash_1.find(this.filters, { key: 'customer' }), 'value'),
            driver: lodash_1.get(lodash_1.find(this.filters, { key: 'driver' }), 'value'),
            truck: lodash_1.get(lodash_1.find(this.filters, { key: 'truck' }), 'value'),
            payableTo: lodash_1.get(lodash_1.find(this.filters, { key: 'payableTo' }), 'value'),
            startDate: null,
            endDate: null
        };
        var startDate = lodash_1.get(lodash_1.find(this.filters, { key: 'startDate' }), 'value');
        if (startDate) {
            model.startDate = startDate;
        }
        var endDate = lodash_1.get(lodash_1.find(this.filters, { key: 'endDate' }), 'value');
        if (endDate) {
            model.endDate = endDate;
        }
        model = this.filters.reduce(function (acc, filter) {
            acc[filter.key] = filter.value;
            return acc;
        }, {});
        var dialog = this.dialog.open(trip_filters_dialog_component_1.TripFiltersDialogComponent, {
            width: '430px',
            data: { model: model }
        });
        this.filtersDialog = dialog.componentInstance;
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
    };
    ExpenseTripsDialogComponent.prototype.filterChanges = function (filterRes) {
        // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
        // where 'key' is the filter type such as 'customer',
        // 'value' is the original object for the options that was select from the dropdown,
        // and 'query' is an object representing the query fragment associated with that filter setting.
        var queryKeys = {
            customer: 'jobevent__customer_organization',
            project: 'jobevent__job__project',
            payableTo: 'jobevent__owner_organization',
            job: 'jobevent__job',
            jobNumber: 'jobevent__job__job_number',
            driver: 'driver',
            truck: 'truck',
            startDate: 'start_time__gte',
            endDate: 'start_time__lte',
            edited: 'edited',
            incomplete: 'completed',
            retake: 'retake_status'
        };
        var falseyFilters = [];
        this.filters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var value = filterRes[key];
            var displayValue;
            if (typeof (value) === 'boolean') {
                if (key === 'incomplete' && value) {
                    displayValue = value.toString();
                    displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
                    value = !value;
                    var filterValue = value.toString();
                    filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
                    query[queryKeys[key]] = filterValue;
                }
                else if (key === 'retake' && value) {
                    value = 'requested';
                    query[queryKeys[key]] = value;
                }
                else if (value) {
                    value = value.toString();
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    query[queryKeys[key]] = value;
                }
            }
            else {
                query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
            }
            var filter = {
                key: key,
                value: displayValue || value,
                query: query
            };
            if (filter.value === 'False' || !filter.value) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.filters = lodash_1.difference(this.filters, falseyFilters);
        this.getTrips();
    };
    ExpenseTripsDialogComponent.prototype.removeFilter = function (filter) {
        lodash_1.remove(this.filters, filter);
        this.getTrips();
    };
    ExpenseTripsDialogComponent.prototype.menuAction = function (name, trip) {
        switch (name) {
            case 'add':
                this.addToExpense([trip.id]);
                break;
        }
    };
    ExpenseTripsDialogComponent.prototype.formattedDuration = function (startTime) {
        var duration = moment.duration(moment().diff(startTime));
        return duration.format('D[ days], H[ hrs], m[ mins]');
    };
    ExpenseTripsDialogComponent.prototype.selector = function (event, trip) {
        if (trip === void 0) { trip = null; }
        if (trip) {
            if (!event.target.checked) {
                trip.selected = false;
                lodash_1.pull(this.selectedTrips, trip.id);
                if (this.allSelected) {
                    this.excludeTrips.push(trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedCount = this.selectedTrips.length;
                }
            }
            else {
                trip.selected = true;
                if (this.allSelected) {
                    lodash_1.pull(this.excludeTrips, trip.id);
                    this.selectedCount = (this.count - this.excludeTrips.length);
                }
                else {
                    this.selectedTrips.push(trip.id);
                    this.selectedCount = this.selectedTrips.length;
                }
            }
        }
        else {
            if (!event.target.checked) {
                this.allSelected = false;
                this.trips.forEach(function (_trip) { _trip.selected = false; });
                this.selectedCount = 0;
            }
            else {
                this.allSelected = true;
                this.selectedCount = (this.count - this.excludeTrips.length);
            }
            this.selectedTrips = [];
            this.excludeTrips = [];
        }
    };
    ExpenseTripsDialogComponent.prototype.addToExpense = function (trips) {
        var _this = this;
        if (trips === void 0) { trips = null; }
        var model = {};
        if (this.expense && this.expense.id) {
            Object.assign(model, { id: this.expense.id });
            if (trips || this.selectedTrips.length) {
                Object.assign(model, { trips: trips || this.selectedTrips });
            }
            else if (this.allSelected) {
                var params_1 = new http_1.HttpParams();
                var filters_1 = lodash_1.omit(this.tripFilters, ['ordering', 'expense']);
                Object.keys(filters_1).map(function (key) {
                    if (filters_1[key].length) {
                        params_1 = params_1.set(key, filters_1[key]);
                    }
                });
                if (params_1.toString().length) {
                    Object.assign(model, {
                        filters: params_1.toString(), excludeTrips: this.excludeTrips
                    });
                }
            }
            this.expenseReq = this.expenseService.addToExpense(model).subscribe(function (res) {
                _this.expense = res;
                _this.callback();
                _this.dialogRef.close();
            }, function (err) { return console.error(err); });
        }
        else {
            this.saveExpense();
        }
    };
    ExpenseTripsDialogComponent.prototype.saveExpense = function () {
        var _this = this;
        var model = {
            customerOrganization: this.expense.customerOrganization.id,
            trips: this.selectedTrips
        };
        this.expenseReq = this.expenseService.save(model).subscribe(function (res) {
            _this.expense = res;
            _this.dialogRef.close();
            if (_this.routeToExpense) {
                _this.router.navigate(['/expenses/' + _this.expense.id + '/edit']);
            }
        }, function (err) { return console.error(err); });
    };
    ExpenseTripsDialogComponent.prototype.onDateChanged = function (values, type) {
        if (values && values.length) {
            switch (type) {
                case 'startDate':
                    this.startDate = values[0];
                    break;
                case 'endDate':
                    this.endDate = values[0];
                    break;
            }
        }
        this.getTrips();
    };
    return ExpenseTripsDialogComponent;
}());
exports.ExpenseTripsDialogComponent = ExpenseTripsDialogComponent;
