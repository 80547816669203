<form #addCollaborators="ngForm">
  <div mat-dialog-title class="modal-title">
    <h1 translate>Add Collaborator</h1>
    <div class="close-action" (click)="dialogRef.close()"><i class="icomoon icon-close"></i></div>
  </div>
  <div mat-dialog-content>
    <div class="body form-content">

      <!-- left sidebar -->
      <div class="available-connections">
        <div class="header" translate>Connections</div>
        <div class="connection-controls">
          <search (searchTermChange)="changeConnectionSearch($event)" [(searchTerm)]="search"></search>
        </div>
        
        <div class="connection-options" (scroll)="onScroll($event)">
          <div class="option" *ngFor="let connection of connections">
            <input type="checkbox" [(ngModel)]="connection.selected"
                   name="connection_{{ connection.organization.id }}" />
            <label attr.data-label="{{ connection.organization.name }}" for="connection_{{ connection.organization.id }}"></label>
          </div>
          <my-loader *ngIf="loading" [ngClass]="{'full-height': connections.length === 0}"></my-loader>
        </div>
      </div>
      <!-- end left sidebar -->

      <div class="selected-connections">
        <!-- apply to all section -->
        <div class="template-wrapper" *ngIf="connections && connections.length">
          <div class="template">
            <div class="row apply-to-all-row">
              <div class="field rate" *ngIf="!hasLoadListsEnabled" [style.width.%]="45">
                <div class="label" translate>Requested Amount</div>
                <div class="input-group">
                  <input 
                    type="number" 
                    name="organization_requested_amount"
                    min="0" 
                    [(ngModel)]="collaborationTemplate.requestedAmount" />
                  <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="collaborationTemplate.requestedUnit"
                    (onSelect)="collaborationTemplate.requestedUnit = $event">
                  </dropdown>
                </div>
              </div>

              <div class="field rate-tracking" [style.width.%]="45">
                <div class="label" translate>How is the rate tracked?</div>
                <dropdown 
                  [options]="rateTrackingOptions"
                  [config]="rateTrackingConfig"
                  [selectedOption]="collaborationTemplate.invoiceType ? (collaborationTemplate.invoiceType | titlecase) : null"
                  (onSelect)="setSelectedTemplateAction(collaborationTemplate, $event)"
                  class="rate-tracking-dropdown">
                </dropdown>
              </div>
            <!-- </div> -->

            <!-- <div class="row"> -->
              <div class="field rate" [style.width.%]="45">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input 
                    type="number" 
                    name="organization_haul_rate" 
                    min="0"
                    [(ngModel)]="collaborationTemplate.invoiceRate" />
                  <dropdown 
                    *ngIf="collaborationTemplate.invoiceType === 'weight'" title="-"
                    [options]="weightOptions"
                    [config]="rateWeightConfig" 
                    [selectedOption]="collaborationTemplate.invoiceWeightUnit ? (collaborationTemplate.invoiceWeightUnit) : null"
                    (onSelect)="setSelectedTemplateWeightUnit($event)"
                  >
                  </dropdown>
                  <div class="add-on" *ngIf="collaborationTemplate.invoiceType !== 'weight'">
                    {{ (collaborationTemplate.invoiceType || '-') | titlecase }}
                  </div>
                </div>
              </div>

              <div class="field notes" [style.width.%]="hasLoadListsEnabled ? 100 : 45">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea [(ngModel)]="collaborationTemplate.notes" name="organization_notes"></textarea>
                </div>
              </div>
            </div>

            <div class="row apply-to-all">
              <button class="btn btn-primary-alt" (click)="applyTemplate(addCollaborators)"
                [ngClass]="{loading: loading}" translate>
                Apply To All
              </button>
            </div>
          </div>
        </div>
        <!-- end apply to all section -->

        <!-- start load list stuff -->
        <div class="loadlist-accordion" *ngIf="loadList.length && connections && connections.length">
          <div class="loadlist-accordion-header" (click)="loadListExpanded = !loadListExpanded">
            <div translate>LOAD LIST</div>
            <div class="loadlist-accordion-arrow">
              <mat-icon (click)="loadListExpanded=true" *ngIf="!loadListExpanded">expand_more</mat-icon>
              <mat-icon (click)="loadListExpanded=false" *ngIf="loadListExpanded">expand_less</mat-icon>
            </div>
          </div>
          <div class="loadlist-accordion-body" [@expanded]="loadListExpanded ? 'expanded' : 'collapsed'">
            <table mat-table #loadListTable class="load-list-table" [dataSource]="loadList">
              <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef translate>LOAD</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.loadNumber || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="loadTime">
                <th mat-header-cell *matHeaderCellDef translate>LOAD TIME</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ticketEvents && row.ticketEvents.LOADING_STARTED | moment: 'h:mm a' || '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef translate>TRUCK</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.vehicle && row.ruckit.vehicle.description ? '#' + row.ruckit.vehicle.description : '&mdash;' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef translate>DRIVER</th>
                <td mat-cell *matCellDef="let row">
                  {{ row.ruckit && row.ruckit.driverName ? row.ruckit.driverName : '&mdash;' }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>

        <div class="option" *ngFor="let connection of connections | propertyFilter: 'selected': 'true'">
          <h3>{{ connection.organization && connection.organization.name }}</h3>
          <div class="connection">
            <div class="row">
              <input required type="hidden" name="organization_{{ connection.id }}" [(ngModel)]="connection.collaboration.recipientOrganization" />
              <div class="field days">
                <div class="label required" translate>Days Allowed</div>
                <input required name="organization_{{ connection.id }}_job_events"
                      type="hidden" [(ngModel)]="connection.collaboration.jobevents" />
                <button class="calendar-button btn btn-default">
                  <div class="calendar-icon"></div> {{ (jobEvent.shift1StartTimestamp | date:'mediumDate') || '&nbsp;' }}
                </button>
              </div>

              <div class="field loads" *ngIf="loadList.length > 0">
                <div class="label" translate>Loads</div>
                <div class="dropdown">
                  <div class="dropdown-button" [matMenuTriggerFor]="loads">
                    <ng-container *ngIf="connection.collaboration.loadSchedule.length">
                      <ng-container *ngFor="let load of connection.collaboration.loadSchedule; let i = index">
                        {{ i === 0 ? load.loadNumber : ', ' + load.loadNumber }}
                      </ng-container>
                    </ng-container>
                    <span *ngIf="!connection.collaboration.loadSchedule.length" translate>None Selected</span>
                  </div>
                  <mat-menu #loads="matMenu" class="loadlist-dropdown-menu" [overlapTrigger]="false">
                      <div class="loadlist-load-option" *ngFor="let load of loadList" (click)="$event.stopPropagation()">
                        <mat-checkbox (change)="loadDropdownSelectionChanged($event, load, connection)" translate>
                          {{ load.loadNumber }} &nbsp;&nbsp; Load Time - {{ load.ticketEvents.LOADING_STARTED | moment: 'h:mm a' }}
                        </mat-checkbox>
                      </div>
                  </mat-menu>
                </div>
              </div>

              <div class="field rate" *ngIf="!loadList.length">
                <div class="label" translate>Requested Amount</div>
                <div class="input-group">
                  <input 
                    type="number" 
                    name="organization_{{ connection.id }}_requested_amount"
                    min="0" 
                    [(ngModel)]="connection.collaboration.requestedAmount" />
                  <dropdown
                    [options]="unitsOfMeasure"
                    [selectedOption]="connection.collaboration.requestedUnit"
                    (onSelect)="onUnitOfMeasureSelect(connection.id, $event)">
                  </dropdown>
                </div>
              </div>
              <!-- end load list stuff -->

              <div class="field rate-tracking">
                <div class="label" translate>How is the rate tracked?</div>
                <input required type="hidden" name="organization_{{ connection.id }}_rate_tracking"
                      [(ngModel)]="connection.collaboration.invoiceType" />
                <dropdown [options]="rateTrackingOptions"
                          [config]="rateTrackingConfig"
                          [selectedOption]="connection.collaboration.invoiceType ? (connection.collaboration.invoiceType | titlecase) : haulTypeOption"
                          (onSelect)="setSelectedAction(connection, $event)"
                          class="rate-tracking-dropdown"></dropdown>
              </div>
            </div>

            <div class="row">
              <div class="field rate">
                <div class="label" translate>What is the rate?</div>
                <div class="input-group" data-prefix="$">
                  <input 
                    type="number" 
                    name="organization_{{ connection.id }}_haul_rate"
                    min="0" 
                    [(ngModel)]="connection.collaboration.invoiceRate" 
                  />
                  <dropdown
                    *ngIf="connection.collaboration.invoiceType === 'weight'"
                    title="-"
                    [config]="rateWeightConfig"
                    [options]="weightOptions" 
                    [selectedOption]="connection.collaboration.invoiceWeightUnit"
                    (onSelect)="setSelectedWeightUnit(connection, $event)"
                  >
                  </dropdown>
                  <div class="add-on" *ngIf="connection.collaboration.invoiceType !== 'weight'">
                    {{ connection.collaboration.invoiceType | titlecase }}
                  </div>
                </div>
              </div>

              <div class="field broker-rate-code">
                <div class="label" translate>What is the broker rate code?</div>
                <input name="organization_{{ connection.id }}_broker_rate_code"
                       [(ngModel)]="connection.collaboration.brokerRateCode" />
              </div>

              <div class="field notes">
                <div class="label" translate>Notes</div>
                <div class="input-group">
                  <textarea [(ngModel)]="connection.collaboration.notes" name="organization_{{ connection.id }}_notes"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="no-results-section" *ngIf="(connections | propertyFilter: 'selected': 'true').length < 1">
          <no-results 
            itemName="{{'Connection Selected' | translate }}" 
            subTitle="{{'Choose connection(s) from the list on the left to begin adding collaborators.' | translate }}"
            [search]="search" 
            [loading]="loading"
            [results]="(connections | propertyFilter: 'selected': 'true').length"
            [styles]="noResultsStyles"
            [hasAddText]="false" 
            [hasAction]="false" 
            (clearSearch)="expandSearch()"
          >
          </no-results>
        </div>
      </div>
    </div>

    <notification *ngFor="let error of errors">{{error}}</notification>
  </div>

  <div class="modal-footer">
    <button class="btn btn-cancel" (click)="dialogRef.close()"
      [ngClass]="{loading: loading}" translate>
      Cancel
    </button>
    <button class="btn btn-primary save-button"
      (click)="addCollaborators.form.valid && submit(); false"
      [disabled]="!addCollaborators.form.valid || addCollaborators.pristine || loading"
      [ngClass]="{loading: loading}"
      translate>
      Save and Send
    </button>
  </div>
</form>
